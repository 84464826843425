import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import atp1000 from 'assets/images/atp1000.png';
import atp250 from 'assets/images/atp250.png';
import points from 'assets/images/points.png';
import statistics from 'assets/images/statistics.png';
import ratingHistory from 'assets/images/ratingHistory.png';
import { Box, Typography } from '@mui/material';

const RatingsSummary = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      width={'100%'}
      minHeight={'20vh'}
      display={'flex'}
      flexDirection={'column'}
    >
      <Box maxWidth={{ xs: '100%', sm: '80%' }} width={'100%'}>
        <Typography
          variant="h5"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 700, marginBottom: '20px' }}
        >
          {t('rankingsSummaryTitle')}
        </Typography>
        <Box display={'flex'} flexDirection="column" gap={2}>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText1')}
          </Typography>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText2')}
          </Typography>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText3')}
            <br />
            <span style={{ fontWeight: 700 }}>
              ŽIEMA - ATP 250 <br />
              VASARA - ATP 250 <br />
              RUDUO - ATP 1000 <br />
              PAVASARIS - ATP 1000
            </span>
          </Typography>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText4')}
          </Typography>
          <Box display={'flex'} flexDirection="column" gap={2}>
            <Box display={'flex'} flexDirection="column" gap={2}>
              <img src={atp250} alt="ATP 250" />
            </Box>
            <Box display={'flex'} flexDirection="column" gap={2}>
              <img src={atp1000} alt="ATP 1000" />
            </Box>
          </Box>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText5')}
          </Typography>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText6')}
          </Typography>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText7')}
          </Typography>
          <Typography
            variant="body3"
            fontWeight={600}
            color={theme.palette.darkGrey[600]}
          >
            {t('rankingsSummaryText8')}
          </Typography>
          <Typography
            variant="body3"
            fontWeight={600}
            color={theme.palette.darkGrey[600]}
          >
            {t('rankingsSummaryText9')}
          </Typography>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText10')}
          </Typography>
          <Box display={'flex'} flexDirection="column" gap={2}>
            <img
              src={points}
              alt="ATP 250"
              style={{ width: '100%', maxWidth: '300px' }}
            />
          </Box>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('rankingsSummaryText11')}
          </Typography>
          <Box display={'flex'} flexDirection="column" gap={2}>
            <Box display={'flex'} flexDirection="column" gap={2}>
              <img src={statistics} alt="statistics" />
            </Box>
            <Box display={'flex'} flexDirection="column" gap={2}>
              <img src={ratingHistory} alt="ranking history" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RatingsSummary;
