import React from 'react';
import { useParams } from 'react-router-dom';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { Partners, PartnerPost } from './components';

const FindPartnerPage = () => {
  const params = useParams();

  return (
    <Main>
      <ContainerSystem
        paddingTop={'72px !important'}
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
        minHeight={'80vh'}
      >
        {params.slug === 'skelbti' ? <PartnerPost /> : <Partners />}
      </ContainerSystem>
    </Main>
  );
};

export default FindPartnerPage;
