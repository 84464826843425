import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import xClose from 'assets/icons/x-close.svg';
import { Box, Typography } from '@mui/material';
import alertCircle from 'assets/icons/alert-circle.svg';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const ErrorToast = forwardRef((props, ref) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [snackPack, setSnackPack] = useState([]);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    Transition: SlideTransition,
  });
  const { vertical, horizontal, open } = state;

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setState({ ...state, open: true });
    } else if (snackPack.length && messageInfo && open) {
      setState({ ...state, open: false });
    }
  }, [snackPack, messageInfo, open]);

  const showError = (message) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  useImperativeHandle(ref, () => ({
    showError,
  }));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, open: false });
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        TransitionComponent={state.Transition}
        message={messageInfo ? messageInfo.message : undefined}
        sx={{}}
      >
        <Alert
          severity="error"
          icon={
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '37px',
                height: '37px',
                borderRadius: '50%',
                border: `6.607px solid ${theme.palette.error[100]}`,
                background: theme.palette.error[200],
              }}
            >
              <Box
                component={'img'}
                src={alertCircle}
                alt="error"
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </Box>
          }
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, open: false });
              }}
            >
              <Box
                component={'img'}
                src={xClose}
                width="20px"
                height="20px"
                alt="close"
                padding={0}
              />
            </IconButton>
          }
          sx={{
            backgroundColor: theme.palette.base.white,
            borderRadius: '12px',
            padding: '16px',
            width: '100%',
            maxWidth: '400px',
            boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            flexDirection={'column'}
          >
            <Typography
              variant={'body4'}
              sx={{
                fontWeight: 600,
                color: theme.palette.darkGrey[700],
                marginBottom: '4px',
              }}
            >
              {t('errorToast')}
            </Typography>
            <Typography
              variant={'body4'}
              sx={{
                fontWeight: 400,
                color: theme.palette.darkGrey[600],
              }}
            >
              {messageInfo ? messageInfo.message : ''}
            </Typography>
          </Box>
          <Box
            marginTop={'16px'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <Typography
              variant={'body4'}
              fontWeight={600}
              color={theme.palette.darkGrey[400]}
            >
              {t('dismiss')}
            </Typography>
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
});

export default ErrorToast;
