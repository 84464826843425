// material-ui
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// ==============================|| COLUMN SORTING - SELECT ||============================== //

export default function SelectColumnSorting({
  getState,
  getAllColumns,
  setSorting,
  size = 'small',
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <FormControl
      sx={{
        width: { xs: '100%', sm: 280 },
      }}
    >
      <Select
        id="column-sorting"
        sx={{
          height: '39px',
          maxHeight: '39px',
          display: 'flex',
          alignItems: 'center',
        }}
        displayEmpty
        value={getState().sorting.length > 0 ? getState().sorting : []}
        input={
          <OutlinedInput
            id="select-column-sorting"
            placeholder="select column"
          />
        }
        renderValue={(selected) => {
          const selectedColumn = getAllColumns().filter(
            (column) => selected.length > 0 && column.id === selected[0].id,
          )[0];
          if (selectedColumn) {
            return (
              <Typography variant="subtitle2">
                {t('sortBy')} (
                {typeof selectedColumn.columnDef.header === 'string'
                  ? selectedColumn.columnDef.header
                  : '#'}
                )
              </Typography>
            );
          }
          return <Typography variant="subtitle2">{t('sortBy')}</Typography>;
        }}
        size={size}
      >
        {getAllColumns().map(
          (column) =>
            // @ts-ignore
            column.columnDef.accessorKey &&
            column.getCanSort() && (
              <MenuItem
                key={column.id}
                value={column.id}
                onClick={() =>
                  setSorting(
                    getState().sorting.length > 0 &&
                      column.id === getState().sorting[0].id
                      ? []
                      : [{ id: column.id, desc: false }],
                  )
                }
              >
                <Checkbox
                  checked={
                    getState().sorting.length > 0 &&
                    column.id === getState().sorting[0].id
                  }
                  sx={{
                    padding: '0px',
                    marginRight: '12px',
                    color: theme.palette.darkGrey[300],
                    '&.Mui-checked': {
                      color: theme.palette.orange[600],
                    },
                  }}
                />
                <ListItemText
                  primary={column.columnDef.header}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: theme.palette.darkGrey[500],
                    fontWeight: 500,
                  }}
                />
              </MenuItem>
            ),
        )}
      </Select>
    </FormControl>
  );
}
