import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import chevronLeft from 'assets/icons/chevron-left.svg';
import calendar from 'assets/icons/calendar.svg';
import clock from 'assets/icons/clock.svg';
import locationIcon from 'assets/icons/location.svg';
import racquet from 'assets/icons/racquet.svg';
import infoSquare from 'assets/icons/infoSquare.svg';
import subjectIcon from 'assets/icons/pencil-line.svg';
import Button from 'components/Button';
import SpinningBox from 'components/SpinningBox';
import {
  get,
  getUserEmailFromToken,
  getUserFullNameFromToken,
  post,
} from 'api';
import moment from 'moment';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';

const SingleMessageCard = ({ state }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    mailText: '',
  });

  const {
    name,
    avatar,
    date,
    time,
    tennisCourt,
    ntrp,
    location,
    mailTo,
    mailSubject,
    isTournament,
    isProduct,
  } = state;

  useEffect(() => {
    setForm({
      ...form,
      mailTo: [mailTo],
      mailFrom: getUserEmailFromToken(),
      mailFromName: getUserFullNameFromToken(),
      mailSubject,
    });
  }, [state]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSend = async () => {
    setIsSubmitting(true);
    const mailText = form.mailText.replace(/\n/g, '<br>');
    const pageFrom = isProduct
      ? 'product'
      : isTournament
      ? 'tournament'
      : 'partner';
    const response = await post('/tournaments/sendmail', {
      ...form,
      mailText,
      pageFrom,
    });
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setIsSubmitting(false);
      setForm({
        ...form,
        mailText: '',
      });
      setTimeout(() => {
        navigate('/info');
      }, 2000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: '100px',
        margin: '0 auto',
        maxWidth: '416px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate('/info')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
      >
        <Box
          component={'img'}
          src={chevronLeft}
          marginRight={'12px'}
          height={1}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('sendMessage')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {isTournament
          ? t('sendMessageTournament')
          : isProduct
          ? t('productMessage')
          : t('sendMessagePartner')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '32px',
        }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Box
            display={'flex'}
            width={{ xs: 60, md: 60 }}
            height={{ xs: 60, md: 60 }}
          >
            <Box
              component={'img'}
              src={
                avatar
                  ? `https://backend.tenisopartneris.lt/public/images/${avatar}`
                  : avatarGrey
              }
              alt="avatar"
              height={1}
              width={1}
              borderRadius={'50%'}
              sx={{
                objectFit: 'cover',
              }}
            />
          </Box>
          <Typography variant={'body3'} fontWeight={600} marginLeft={'16px'}>
            {name}
          </Typography>
        </Box>
        {!isProduct && (
          <Box
            height={'28px'}
            backgroundColor={theme.palette.darkGrey[100]}
            borderRadius={'40px'}
            padding={'4px 20px'}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography
              variant={'body5'}
              fontWeight={600}
              color={theme.palette.orange[700]}
            >
              {ntrp} NTRP
            </Typography>
          </Box>
        )}
      </Box>
      {!isTournament && !isProduct && (
        <>
          <Box display={'flex'} alignItems={'center'} marginTop={'20px'}>
            <Box component={'img'} src={calendar} height={1} alt="calendar" />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('date')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {date}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
            <Box component={'img'} src={clock} height={1} alt="clock" />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('time')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {moment(time, 'HH:mm:ss').format('HH:mm')}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
            <Box component={'img'} src={racquet} height={1} alt="racquet" />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('tennisCourt')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {tennisCourt}
            </Typography>
          </Box>
        </>
      )}
      <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
        <Box component={'img'} src={locationIcon} height={1} alt="location" />
        <Typography
          variant={'body3'}
          fontWeight={600}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[700]}
        >
          {t('city')}:
        </Typography>
        <Typography
          variant={'body3'}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[600]}
        >
          {location}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
        <Box component={'img'} src={subjectIcon} height={1} alt="subject" />
        <Typography
          variant={'body3'}
          fontWeight={600}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[700]}
        >
          {t('subject')}:
        </Typography>
        <Typography
          variant={'body3'}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[600]}
        >
          {form.mailSubject}
        </Typography>
      </Box>
      <Box marginTop={'20px'}>
        <Typography
          variant="body4"
          fontWeight={500}
          color={theme.palette.darkGrey[700]}
        >
          {t('message')}
        </Typography>
        <TextField
          hiddenLabel
          margin="normal"
          fullWidth
          id="mailText"
          name="mailText"
          multiline
          rows={4}
          placeholder={t('typeMessage')}
          inputProps={{
            sx: {
              fontSize: '16px',
              lineHeight: 1.5,
              color: theme.palette.darkGrey[500],
              '&::placeholder': {
                color: theme.palette.darkGrey[500],
                opacity: 1,
                fontSize: '16px',
              },
            },
          }}
          sx={{
            margin: 0,
            mt: '6px',
          }}
          onChange={handleChange}
          value={form.mailText}
        />
      </Box>
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          maxWidth={{ xs: '100%', md: '100%' }}
          width={'100%'}
          buttontype={'orange'}
          onClick={() => handleSend()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : t('sendMessage')}
        </Button>
      </Box>
    </Box>
  );
};

export default SingleMessageCard;
