import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { PrimaryTab } from 'components/Tabs';
import MyMatches from '../MyMatches';
import MyTournamentMatches from '../MyTournamentMatches';
import Statistics from '../Statistics';
import { SelectInput } from 'components/Inputs';

const PanelView = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(
    id ? t('userTournaments') : t('myTournaments'),
  );
  const [open, setOpen] = useState({});

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setActiveTab(event.target.value.toLowerCase());
  };

  return (
    <Box>
      <Box
        maxWidth={'640px'}
        marginBottom={'48px'}
        display={{ xs: 'none', sm: 'flex' }}
        justifyContent={'space-between'}
        border={`1px solid ${theme.palette.darkGrey[300]}`}
        borderRadius={'8px'}
      >
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={id ? t('userTournaments') : t('myTournaments')}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={id ? t('userMatches') : t('myMatches')}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={t('statistics')}
        />
      </Box>
      <Box display={{ xs: 'flex', sm: 'none' }} marginBottom={'48px'}>
        <SelectInput
          name="viewMenu"
          id="viewMenu"
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleChange={handleChange}
          topMargin={'0px'}
          value={activeTab}
          options={[
            {
              value: id ? t('userTournaments') : t('myTournaments'),
              label: id ? t('userTournamentsLabel') : t('myTournamentsLabel'),
            },
            {
              value: id ? t('userMatches') : t('myMatches'),
              label: id ? t('userMatchesLabel') : t('myMatchesLabel'),
            },
            {
              value: t('statistics'),
              label: t('statisticsLabel'),
            },
          ]}
        />
      </Box>
      {(activeTab === t('myMatches') || activeTab === t('userMatches')) && (
        <MyMatches />
      )}
      {(activeTab === t('myTournaments') ||
        activeTab === t('userTournaments')) && <MyTournamentMatches />}
      {activeTab === t('statistics') && <Statistics />}
    </Box>
  );
};

export default PanelView;
