import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import plusIcon from 'assets/icons/plus-circle.svg';
import minusIcon from 'assets/icons/minus-circle.svg';
import { Box } from '@mui/material';

const PrimaryAccordion = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState('panel1');

  const primaryTexts = [
    {
      number: '1.',
      text: t('mainFaqTextOne'),
      text2: t('mainFaqTextTwo'),
    },
    {
      number: '2.',
      text: t('mainFaqTextThree'),
      text2: t('mainFaqTextFour'),
    },
    {
      number: '3.',
      text: t('mainFaqTextFive'),
      text2: t('mainFaqTextSix'),
    },
    {
      number: '4.',
      text: t('mainFaqTextSeven'),
      text2: t('mainFaqTextEight'),
    },
    {
      number: '5.',
      text: t('mainFaqTextNine'),
      text2: t('mainFaqTextTen'),
    },
  ];

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      sx={{
        backgroundColor:
          expanded === 'panel1'
            ? theme.palette.darkGrey[50]
            : theme.palette.base.white,
        boxShadow: 'none',
        borderRadius: '16px !important',
        padding: '24px !important',
        margin: '0 !important',
      }}
    >
      <AccordionSummary
        expandIcon={<img src={expanded === 'panel1' ? minusIcon : plusIcon} />}
        aria-controls="panel1d-content"
        alt="plus icon"
        id="panel1d-header"
        sx={{
          padding: '0 !important',
          minHeight: '0 !important',
          '& > .MuiAccordionSummary-content': {
            margin: '0 !important',
          },
        }}
      >
        <Typography
          variant="body2"
          color={theme.palette.darkGrey[800]}
          fontWeight={500}
        >
          {t('mainFaqQuestion')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 !important', marginTop: '8px' }}>
        <Box sx={{ maxWidth: '751px', width: '100%' }}>
          <Typography variant="body3" color={theme.palette.darkGrey[600]}>
            {t('mainFaqQuestionText')}:
          </Typography>
          {primaryTexts.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '16px',
                marginLeft: '12px',
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[600]}
                fontWeight={500}
              >
                {item.number} <strong>{item.text}</strong> {item.text2}
              </Typography>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PrimaryAccordion;
