import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { SecondaryTab } from 'components/Tabs';
import Description from '../Description';
import Participants from '../Participants';
import MiniTestinis from '../Rules/MiniTestinis';
import Testinis from '../Rules/Testinis';

const ContentView = ({ data, tournamentInfo }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(t('description'));
  const { isContinued } = tournamentInfo;
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.from === 'tournament-registration'
    ) {
      setActiveTab(t('participants'));
      setViewData(location.state.data);
    }
  }, [location]);

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent={'space-between'}
        maxWidth={'862px'}
        margin={'0 auto'}
        gap={{ xs: '12px' }}
        sx={{
          marginBottom: { xs: '48px', md: '64px' },
          borderBottom: {
            xs: 'none',
            md: `1px solid ${theme.palette.darkGrey[300]}`,
          },
        }}
      >
        <SecondaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={t('description')}
        />
        <SecondaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={t('participants')}
        />
        <SecondaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={t('rules')}
        />
      </Box>
      {activeTab === t('description') && (
        <Description tournamentInfo={tournamentInfo} />
      )}
      {activeTab === t('participants') && (
        <Participants data={data} viewData={viewData} />
      )}
      {activeTab === t('rules') && isContinued ? (
        <Testinis tournamentInfo={tournamentInfo} />
      ) : null}
      {activeTab === t('rules') && !isContinued ? (
        <MiniTestinis tournamentInfo={tournamentInfo} />
      ) : null}
    </Box>
  );
};

export default ContentView;
