import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { ContentView, Hero } from './components';
import { ServeCard, SponsorsCard } from 'components/Cards';
import { get } from 'api';

const TournamentPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [tournament, setTournament] = useState([]);
  const [tournamentInfo, setTournamentInfo] = useState([]);

  useEffect(() => {
    const fetchTournamentData = async () => {
      const response = await get(`/tournaments/tournament-info/${id}`);
      const data = await response.json();
      setTournament(data);
    };
    fetchTournamentData();
  }, []);

  useEffect(() => {
    const fetchTournamentInfo = async () => {
      const response = await get(`/tournaments/tournament/${id}`);
      const data = await response.json();
      const tournamentInfo = {
        id: data[0].id,
        title: data[0].tournament_name,
        date: `${moment(data[0].start_date).format('YYYY-MM-DD')} - ${moment(
          data[0].final_date,
        ).format('YYYY-MM-DD')}`,
        location: data[0].city,
        price: data[0].price,
        divisions: data[0].divisions,
        earlyBird: data[0].early_bird,
        earlyBirdDate: data[0].early_bird_date,
        registrationDate: data[0].registration_date,
        startDate: data[0].start_date,
        groupsDate: data[0].groups_date,
        eliminationsDate: data[0].eliminations_date,
        finalsDate: data[0].final_date,
        isRegOn: data[0].isRegON,
        isContinued: data[0].isContinued,
      };
      setTournamentInfo(tournamentInfo);
    };
    fetchTournamentInfo();
  }, [id]);

  return (
    <Main>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(180deg, #F1FBFC 0%, #F5F6F7 100%)',
          }}
        >
          <Container
            sx={{
              paddingTop: { xs: '40px !important' },
              paddingBottom: { xs: '60px !important', md: '64px !important' },
              height: { xs: '100%', sm: '700px', md: '90vh' },
            }}
          >
            <Hero tournamentInfo={tournamentInfo} />
          </Container>
        </Box>
      </Box>
      <Container paddingBottom={'0 !important'}>
        <ContentView data={tournament} tournamentInfo={tournamentInfo} />
      </Container>
      {tournamentInfo.isRegOn ? (
        <Container
          sx={{
            paddingBottom: {
              sm: '80px !important',
              md: '0px !important',
            },
            paddingTop: {
              sm: '80px !important',
              md: '96px !important',
            },
          }}
        >
          <ServeCard id={id} tournamentInfo={tournamentInfo} />
        </Container>
      ) : null}

      <Container
        sx={{
          paddingBottom: {
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            xs: tournamentInfo.isRegOn ? '0px !important' : '64px !important',
            sm: tournamentInfo.isRegOn ? '0px !important' : '96px !important',
            md: tournamentInfo.isRegOn ? '0px !important' : '96px !important',
          },
        }}
      >
        <SponsorsCard />
      </Container>
    </Main>
  );
};

export default TournamentPage;
