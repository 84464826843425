import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import chevronLeft from 'assets/icons/chevron-left.svg';
import chevronRight from 'assets/icons/chevron-right-white.svg';
import { PrimaryTab } from 'components/Tabs';
import { Grid, TextField } from '@mui/material';
import DropZonefile from '../Dropzone/Dropzone';
import uploadIcon from 'assets/icons/upload-cloud-02.svg';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { Categories, Cities, ProductCondition } from 'constants/Selections';
import SpinningBox from 'components/SpinningBox';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import { get, post, put, upload } from 'api';
import CryptoJS from 'crypto-js';

const MyProductsEdit = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('step 1');
  const [uploadFiles, setUploadFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [open, setOpen] = useState({});
  const [product, setProduct] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    title: '',
    category: '',
    productCondition: '',
    condition: '',
    price: '',
    city: '',
    phoneNumber: '',
    email: '',
    fullName: '',
    description: '',
  });
  const successToastRef = useRef();
  const errorToastRef = useRef();

  useEffect(() => {
    const getMarketplace = async () => {
      const response = await get(`/marketplace/marketplace/${id}`);
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setProduct(decryptedData[0]);
      setForm({
        title: decryptedData[0].product_title,
        category: decryptedData[0].product_category,
        productCondition: decryptedData[0].product_usednew,
        condition: decryptedData[0].condition,
        price: decryptedData[0].product_price,
        city: decryptedData[0].city,
        phoneNumber: decryptedData[0].phone_number,
        email: decryptedData[0].email,
        description: decryptedData[0].description,
        fullName: decryptedData[0].full_name,
      });
      setImages(decryptedData[0].images);
    };
    getMarketplace();
  }, [id]);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleDataChange = (key, value) => {
    setUploadFiles(value);
  };

  const handleRemove = (file) => {
    const remainingImages = images.filter((prevImage) => prevImage !== file);
    setImages(remainingImages);
    setRemovedImages((prevRemovedImages) => {
      const newRemovedImages = prevRemovedImages.filter(
        (prevRemovedImage) => !images.includes(prevRemovedImage),
      );
      return [...newRemovedImages, file];
    });
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (
      !form.title ||
      !form.category ||
      !form.productCondition ||
      !form.price ||
      !form.city ||
      !form.description
    ) {
      errorToastRef.current.showError(t('fillAllFields'));
      setIsSubmitting(false);
      return;
    }
    const formData = new FormData();
    if (uploadFiles && uploadFiles.length > 0) {
      uploadFiles.forEach((image) => {
        formData.append('images', image, image.name);
      });
    }

    const response = await put(`/marketplace/marketplace/${id}`, {
      ...form,
      removedImages: images,
    });
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      if (uploadFiles.length > 0) {
        images.forEach((image) => {
          formData.append('existingImages', image);
        });
        await upload(`/marketplace/marketplace-image/${id}`, formData);
      }
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        setIsSubmitting(false);
        setForm({
          title: '',
          category: '',
          productCondition: '',
          condition: '',
          price: '',
          city: '',
          phoneNumber: '',
          email: '',
          fullName: '',
          description: '',
        });
        setUploadFiles([]);
        navigate('/marketplace');
      }, 3000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: '100px',
        margin: '0 auto',
        maxWidth: '416px',
        width: '100%',
        maxHeight: '980px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={{ xs: 'none', sm: 'flex' }}
        alignItems={'center'}
        onClick={() => navigate('/marketplace')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
        maxWidth={'116px'}
        width={'100%'}
      >
        <Box
          component={'img'}
          width={'20px'}
          height={'20px'}
          src={chevronLeft}
          marginRight={'12px'}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('editProduct')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('addProductSubtitle')}
      </Typography>
      <Box
        maxWidth={'416px'}
        marginTop={'32px'}
        marginBottom={'25px'}
        display={'flex'}
        border={`1px solid ${theme.palette.darkGrey[300]}`}
        borderRadius={'8px'}
      >
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'step 1'}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'step 2'}
        />
      </Box>
      {activeTab === 'step 1' && (
        <Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
          >
            <TextInput
              label={`${t('productTitle')}*`}
              id="title"
              name="title"
              type="text"
              placeholder={t('productTitle')}
              required
              handleInputChange={handleChange}
              value={form.title}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {`${t('category')}*`}
            </Typography>
            <SelectInput
              name="category"
              id="category"
              placeholder={t('selectCategory')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              options={Categories.filter((category) => category.value !== '')}
              value={form.category}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('condition')}
            </Typography>
            <SelectInput
              name="productCondition"
              id="productCondition"
              placeholder={t('selectCondition')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              options={ProductCondition.filter(
                (condition) => condition.value !== '',
              )}
              value={form.productCondition}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <TextInput
              label={`${t('productPrice')}*`}
              id="price"
              name="price"
              type="text"
              placeholder={t('productPrice')}
              required
              handleInputChange={handleChange}
              value={form.price}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {`${t('city')}*`}
            </Typography>
            <SelectInput
              name="city"
              id="city"
              placeholder={t('selectCity')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              options={Cities.filter((city) => city.value !== '')}
              value={form.city}
            />
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype="orange"
              onClick={() => setActiveTab('step 2')}
            >
              {t('next')}{' '}
              <Box component={'img'} src={chevronRight} alt="chevron-right" />
            </Button>
          </Box>
        </Box>
      )}
      {activeTab === 'step 2' && (
        <Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
          >
            <TextInput
              label={`${t('phoneNumber')}*`}
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              placeholder={t('phoneNumber')}
              disabled
              autoComplete="tel"
              handleInputChange={handleChange}
              value={form.phoneNumber}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <TextInput
              label={`${t('email')}`}
              id="email"
              name="email"
              type="email"
              placeholder={t('email')}
              autoComplete="email"
              handleInputChange={handleChange}
              value={form.email}
              disabled
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', ssm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('productDescription')}*
            </Typography>
            <TextField
              hiddenLabel
              margin="normal"
              fullWidth
              id="description"
              name="description"
              multiline
              rows={3}
              placeholder={t('writeDescription')}
              onChange={handleChange}
              value={form.description}
              inputProps={{
                sx: {
                  fontSize: '16px',
                  lineHeight: 1.5,
                  color: theme.palette.darkGrey[500],
                  '&::placeholder': {
                    color: theme.palette.darkGrey[500],
                    opacity: 1,
                    fontSize: '16px',
                  },
                },
              }}
              sx={{
                margin: '6px 0 0 0',
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
              marginBottom={'6px'}
            >
              {t('photos')}
            </Typography>
            <DropZonefile
              content={
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  padding={'16px 24px'}
                  sx={{
                    cursor: 'pointer',
                    height: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '8px',
                      border: `1px solid ${theme.palette.darkGrey[200]}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '12px',
                    }}
                  >
                    <Box component={'img'} src={uploadIcon} alt="upload icon" />
                  </Box>
                  <Box
                    display={'flex'}
                    sx={{
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {uploadFiles &&
                      uploadFiles.map((file, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              marginBottom: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '12px',
                            }}
                          >
                            <Box
                              component={'img'}
                              src={
                                file.path
                                  ? URL.createObjectURL(file)
                                  : `https://backend.tenisopartneris.lt/public/shop/${file}`
                              }
                              alt="upload icon"
                              sx={{
                                height: '44px',
                                width: '44px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                              }}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                  {uploadFiles && uploadFiles.length === 0 && (
                    <>
                      <Box display={'flex'}>
                        <Typography
                          variant="body4"
                          color={theme.palette.orange[700]}
                          fontWeight={600}
                        >
                          Click to upload&nbsp;
                        </Typography>
                        <Typography
                          variant="body4"
                          color={theme.palette.darkGrey[600]}
                        >
                          or drag and drop{' '}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body4"
                        color={theme.palette.darkGrey[600]}
                      >
                        SVG, PNG, JPG
                      </Typography>
                    </>
                  )}
                </Box>
              }
              onDrop={(acceptedFiles) =>
                handleDataChange('uploadFiles', acceptedFiles)
              }
              uploadFiles={uploadFiles}
            />
            {images && images.length > 0 && (
              <Box marginTop={'16px'}>
                <Typography
                  variant="body4"
                  color={theme.palette.darkGrey[600]}
                  fontWeight={600}
                >
                  {t('currentPhotos')}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: '1px', justifyContent: 'flex-start' }}
                >
                  {images.map((image, i) => (
                    <Grid item xs={3} sm={2} key={i}>
                      <Box position="relative" height="60px" width="60px">
                        <Box
                          component={'img'}
                          src={`https://backend.tenisopartneris.lt/public/shop/${image}`}
                          height="100%"
                          width="100%"
                          alt="product"
                          sx={{
                            borderRadius: '4px',
                            objectFit: 'cover',
                          }}
                        />
                        <Box
                          position="absolute"
                          top={0}
                          right={0}
                          onClick={() => handleRemove(image)}
                          sx={{
                            cursor: 'pointer',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            borderRadius: '50%',
                            height: '20px',
                            width: '20px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CloseIcon
                            sx={{
                              height: '20px',
                              width: '20px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype="white"
              bordered="true"
              onClick={() => setActiveTab('step 1')}
            >
              <Box component={'img'} src={chevronLeft} alt="chevron-left" />{' '}
              {t('previous')}
            </Button>
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype="orange"
              marginTop={{ xs: '16px', sm: '0px' }}
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
            >
              {isSubmitting ? <SpinningBox /> : t('submit')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyProductsEdit;
