import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import tournament from 'assets/images/tournament-hero.png';
import bar from 'assets/icons/bar.svg';
import locationIcon from 'assets/icons/location.svg';
import calendar from 'assets/icons/calendar.svg';
import Button from 'components/Button';
import moment from 'moment';
import { get } from 'api';

const Hero = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const [tournamentInfo, setTournamentInfo] = useState([]);

  useEffect(() => {
    const fetchTournamentInfo = async () => {
      const response = await get(`/tournaments/tournament/${id}`);
      const data = await response.json();
      if (data.length === 0) {
        navigate('/turnyras');
        return;
      }
      const tournamentInfo = {
        id: data[0].id,
        title: data[0].tournament_name,
        date: `${moment(data[0].start_date).format('YYYY-MM-DD')} - ${moment(
          data[0].final_date,
        ).format('YYYY-MM-DD')}`,
        location: data[0].city,
        price: data[0].price,
        divisions: data[0].divisions,
        earlyBird: data[0].early_bird,
        earlyBirdDate: data[0].early_bird_date,
        registrationDate: data[0].registration_date,
        startDate: data[0].start_date,
        groupsDate: data[0].groups_date,
        eliminationsDate: data[0].eliminations_date,
        finalsDate: data[0].final_date,
        isRegOn: data[0].isRegON,
      };
      setTournamentInfo(tournamentInfo);
    };
    fetchTournamentInfo();
  }, [id]);

  return (
    <Grid container direction={isSm ? 'row' : 'column-reverse'}>
      <Grid item container alignItems={'center'} xs={12} sm={6} md={8}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'} maxWidth={489}>
          <Box marginBottom={'4px'}>
            <Typography
              variant={isMd ? 'h4' : 'h5'}
              color={theme.palette.base.black}
              fontWeight={700}
            >
              {tournamentInfo.title}
            </Typography>
          </Box>
          <Box marginTop={'20px'} flexDirection={'column'}>
            <Box display={'flex'} alignItems={'center'}>
              <Box
                component={'img'}
                src={calendar}
                height={1}
                borderRadius={'50%'}
                alt="calendar"
              />
              <Typography
                variant={'body3'}
                fontWeight={600}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[700]}
              >
                {t('date')}:
              </Typography>
              <Typography
                variant={'body3'}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[600]}
              >
                {tournamentInfo.date}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginTop={'12px'}>
              <Box
                component={'img'}
                src={locationIcon}
                height={1}
                borderRadius={'50%'}
                alt="location"
              />
              <Typography
                variant={'body3'}
                fontWeight={600}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[700]}
              >
                {t('city')}:
              </Typography>
              <Typography
                variant={'body3'}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[600]}
              >
                {tournamentInfo.location}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginTop={'12px'}>
              <Box
                component={'img'}
                src={bar}
                height={1}
                borderRadius={'50%'}
                alt="bar"
              />
              <Typography
                variant={'body3'}
                fontWeight={600}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[700]}
              >
                {t('divisions')}:
              </Typography>
              <Typography
                variant={'body3'}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[600]}
              >
                {tournamentInfo.divisions}
              </Typography>
            </Box>
          </Box>
          {tournamentInfo.isRegOn ? (
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
              marginTop={'32px'}
            >
              <Button
                buttontype="orange"
                onClick={() => navigate(`/turnyras/registracija/${id}`)}
              >
                {t('regToTournament')}
              </Button>
              <Button
                buttontype="white"
                bordered="true"
                ml={{ xs: 0, sm: 2 }}
                mt={{ xs: '20px', sm: 0 }}
                onClick={() =>
                  navigate(`/turnyrai/${id}`, {
                    state: { from: 'tournament-registration' },
                  })
                }
              >
                {t('tournamentParticipants')}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            height={1}
            width={1}
            sx={{
              maxWidth: { xs: '257px', sm: 'auto', md: 'auto' },
            }}
          >
            <Box
              component={'img'}
              src={tournament}
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
              alt="tournament"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
