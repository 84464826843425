import React, { useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import TournamentsList from './TournamentsList';
import NewTournament from './NewTournament';

const Tournament = () => {
  const theme = useTheme();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [currentView, setCurrentView] = useState('list');

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '100%', md: '516px' },
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'end'}
        marginBottom={'24px'}
      >
        {currentView === 'create' && (
          <Button
            buttontype={'white'}
            bordered={'true'}
            size={'small'}
            onClick={() => setCurrentView('list')}
          >
            Turnyrų sąrašas
          </Button>
        )}
        {currentView === 'list' && (
          <Button
            buttontype={'white'}
            bordered={'true'}
            size={'small'}
            onClick={() => setCurrentView('create')}
          >
            Sukurti turnyrą
          </Button>
        )}
      </Box>
      {currentView === 'list' && <TournamentsList />}
      {currentView === 'create' && (
        <NewTournament setCurrentView={setCurrentView} />
      )}
    </Box>
  );
};

export default Tournament;
