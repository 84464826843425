import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { chartOptions } from '../Chart/options';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import award from 'assets/icons/award-04.svg';
import helpCircle from 'assets/icons/help-circle.svg';
import { ArrowDown, ArrowUp } from 'iconsax-react';
import { PieChart } from '../Chart';
import { get, getUserIdFromToken } from 'api';
import RankingsHistory from './components/RankingsHistory';
import { CalendarSearch } from 'iconsax-react';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    open={props.open}
    onOpen={props.onOpen}
    onClose={props.onClose}
    classes={{ popper: className }}
    placement="top"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.base.white,
    color: theme.palette.darkGrey[700],
    boxShadow:
      '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    fontSize: 14,
    fontFamily: 'Inter, sans-serif',
    padding: '8px 12px',
    borderRadius: '8px',
  },
}));

const Statistics = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { t } = useTranslation();
  const [mySinglesStats, setMySinglesStats] = useState([]);
  const [myDoublesStats, setMyDoublesStats] = useState([]);
  const [myMixedStats, setMyMixedStats] = useState([]);
  const [myRanking, setMyRanking] = useState();
  const [myRankingChange, setMyRankingChange] = useState();
  const [userSinglesStats, setUserSinglesStats] = useState([]);
  const [userDoublesStats, setUserDoublesStats] = useState([]);
  const [userMixedStats, setUserMixedStats] = useState([]);
  const [userRanking, setUserRanking] = useState();
  const [userRankingChange, setUserRankingChange] = useState();
  const [userTotalPoints, setUserTotalPoints] = useState();
  const [goldTrophies, setGoldTrophies] = useState([]);
  const [silverTrophies, setSilverTrophies] = useState([]);
  const [bronzeTrophies, setBronzeTrophies] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [rankingsShowTooltip, setRankingsShowTooltip] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rankingsHistory, setRankingsHistory] = useState([]);
  const [showGoldTooltip, setShowGoldTooltip] = useState(
    new Array(goldTrophies.length).fill(false),
  );
  const [showSilverTooltip, setShowSilverTooltip] = useState(
    new Array(silverTrophies.length).fill(false),
  );
  const [showBronzeTooltip, setShowBronzeTooltip] = useState(
    new Array(bronzeTrophies.length).fill(false),
  );
  const userId = id ? id : getUserIdFromToken();

  const infoText = t('ratingsExplained');
  const rankingsText = t('rankingsHistory');

  useEffect(() => {
    const fetchGoldTrophies = async () => {
      try {
        const response = await get(`/statistics/gold-trophie/${userId}`);
        const data = await response.json();
        setGoldTrophies(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchSilverTrophies = async () => {
      try {
        const response = await get(`/statistics/silver-trophie/${userId}`);
        const data = await response.json();
        setSilverTrophies(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchBronzeTrophies = async () => {
      try {
        const response = await get(`/statistics/bronze-trophie/${userId}`);
        const data = await response.json();
        setBronzeTrophies(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRankingsHistory = async () => {
      try {
        const response = await get(`/ratings/user-rankings-history/${userId}`);
        const data = await response.json();
        setRankingsHistory(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchGoldTrophies();
    fetchSilverTrophies();
    fetchBronzeTrophies();
    fetchRankingsHistory();
  }, [userId]);

  const fetchData = async (endpoint, setData, id = '') => {
    try {
      const response = await get(endpoint + id);
      const data = await response.json();
      setData(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const endpoint = id
      ? `/statistics/singlestotalstatsusers/`
      : `/statistics/singlestotalstats`;
    fetchData(endpoint, id ? setUserSinglesStats : setMySinglesStats, id);
  }, [id]);

  useEffect(() => {
    const endpoint = id
      ? `/statistics/doublestotalstatsusers/`
      : `/statistics/doublestotalstats`;
    fetchData(endpoint, id ? setUserDoublesStats : setMyDoublesStats, id);
  }, [id]);

  useEffect(() => {
    const endpoint = id
      ? `/statistics/mixdoublestotalstatsusers/`
      : `/statistics/mixdoublestotalstats`;
    fetchData(endpoint, id ? setUserMixedStats : setMyMixedStats, id);
  }, [id]);

  useEffect(() => {
    const endpoint = id
      ? `/statistics/mixdoublestotalstatsusers/`
      : `/statistics/mixdoublestotalstats`;
    fetchData(endpoint, id ? setUserMixedStats : setMyMixedStats, id);
  }, [id]);

  useEffect(() => {
    const fetchPoints = async (
      ratingEndpoint,
      setRanking,
      setRankingChange,
      setPoints,
    ) => {
      try {
        const resMyPoints = await get(ratingEndpoint);
        const data = await resMyPoints.json();
        setRanking(data.currentRanking);
        setRankingChange(data.rankingChange);
        setPoints(data.total_points);
      } catch (error) {
        console.log(error);
      }
    };

    if (id) {
      fetchPoints(
        `/ratings/user-ranking/${id}`,
        setUserRanking,
        setUserRankingChange,
        setUserTotalPoints,
      );
    } else {
      fetchPoints(
        `/ratings/my-ranking`,
        setMyRanking,
        setMyRankingChange,
        setUserTotalPoints,
      );
    }
  }, [id]);

  const sumStats = (statsArray, property) => {
    return statsArray.reduce(
      (sum, stats) => sum + (parseInt(stats[property]) || 0),
      0,
    );
  };

  const calculateStats = (statsArray) => {
    const totalMatches = sumStats(statsArray, 'matches_total');
    const matchesWon = sumStats(statsArray, 'matches_won');
    const matchesLost = sumStats(statsArray, 'matches_lost');
    const matchesWonLost = [matchesWon, matchesLost];
    const totalMatchesPlayed = statsArray.map(
      (stats) => parseInt(stats.matches_total) || 0,
    );

    // Calculate win percentage
    let winPercentage = 0;
    if (totalMatches > 0) {
      winPercentage = (matchesWon / totalMatches) * 100;
    }

    // Calculate the percentage of singles matches out of total matches
    const singlesMatches = parseInt(statsArray[0].matches_total) || 0;
    let singlesPercentage = 0;
    if (totalMatches > 0) {
      singlesPercentage = (singlesMatches / totalMatches) * 100;
    }

    return {
      totalMatches,
      matchesWon,
      matchesLost,
      matchesWonLost,
      totalMatchesPlayed,
      winPercentage: winPercentage.toFixed(2), // Format to two decimal places
      singlesPercentage: singlesPercentage.toFixed(2), // Format to two decimal places
    };
  };

  const myStatsArray = [mySinglesStats, myDoublesStats, myMixedStats];
  const myStats = calculateStats(myStatsArray);

  const userStatsArray = [userSinglesStats, userDoublesStats, userMixedStats];
  const userStats = calculateStats(userStatsArray);

  const winPercentage = id ? userStats.winPercentage : myStats.winPercentage;
  const singlesPercentage = id
    ? userStats.singlesPercentage
    : myStats.singlesPercentage;

  const wonLostOptions = {
    ...chartOptions,
    labels: [t('won'), t('lost')],
    colors: ['#EF8257', '#D3DDDE'],
  };

  const totalMatchesPlayedOptions = {
    ...chartOptions,
    labels: [t('singles'), t('doubles'), t('mixed')],
    colors: ['#D45019', '#EF8257', '#D3DDDE'],
  };

  return (
    <Box>
      <RankingsHistory
        data={rankingsHistory}
        totalPoints={userTotalPoints}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          color: theme.palette.darkGrey[700],
          marginBottom: '32px',
        }}
      >
        {t('stats')}
      </Typography>
      <Box
        display={'flex'}
        gap={'32px'}
        flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'nowrap' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          maxWidth={{ xs: '100%', sm: '363px' }}
          width={'100%'}
          minHeight={'264px'}
          height={'auto'}
          sx={{
            padding: '32px',
            backgroundColor: theme.palette.base.white,
            borderRadius: '16px',
            boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
          }}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography
                variant="body3"
                sx={{
                  fontWeight: 700,
                  color: theme.palette.darkGrey[700],
                  marginBottom: '12px',
                }}
              >
                {t('rankings')}
                <LightTooltip
                  title={infoText}
                  placement={'top-start'}
                  open={showTooltip}
                  onOpen={() => setShowTooltip(true)}
                  onClose={() => setShowTooltip(false)}
                >
                  <Box
                    component={'img'}
                    src={helpCircle}
                    marginLeft={'8px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setShowTooltip(!showTooltip)}
                    alt="help circle"
                  />
                </LightTooltip>
              </Typography>
              <LightTooltip
                title={rankingsText}
                placement={'top-start'}
                open={rankingsShowTooltip}
                onOpen={() => setRankingsShowTooltip(true)}
                onClose={() => setRankingsShowTooltip(false)}
              >
                <CalendarSearch
                  style={{ cursor: 'pointer' }}
                  onClick={() => setModalOpen(true)}
                />
              </LightTooltip>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  border: `1px solid ${theme.palette.darkGrey[200]}`,
                  borderRadius: '8px',
                  maxWidth: '89px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.darkGrey[700],
                    backgroundColor: theme.palette.darkGrey[100],
                    padding: '8px 12px',
                    borderRadius: '8px 8px 0 0',
                    height: '36px',
                    textAlign: 'center',
                  }}
                >
                  {t('total')}
                </Typography>
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.darkGrey[700],
                    backgroundColor: theme.palette.base.white,
                    padding: '8px 0px',
                    borderRadius: '0 0 8px 8px',
                    height: '36px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {id ? userRanking : myRanking} |
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '4px',
                      color:
                        (id ? userRankingChange : myRankingChange) > 0
                          ? theme.palette.success.main
                          : (id ? userRankingChange : myRankingChange) < 0
                          ? theme.palette.error.main
                          : theme.palette.darkGrey[700],
                    }}
                  >
                    {(id ? userRankingChange : myRankingChange) > 0 ? '+' : ''}
                    {id ? userRankingChange : myRankingChange}
                    {(id ? userRankingChange : myRankingChange) > 0 && (
                      <ArrowUp size={14} color={theme.palette.success[600]} />
                    )}
                    {(id ? userRankingChange : myRankingChange) < 0 && (
                      <ArrowDown size={14} color={theme.palette.error[600]} />
                    )}
                  </span>
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  border: `1px solid ${theme.palette.darkGrey[200]}`,
                  borderRadius: '8px',
                  maxWidth: '89px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.darkGrey[700],
                    backgroundColor: theme.palette.darkGrey[100],
                    padding: '8px 12px',
                    borderRadius: '8px 8px 0 0',
                    height: '36px',
                    textAlign: 'center',
                  }}
                >
                  {t('singles')}
                </Typography>
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.darkGrey[700],
                    backgroundColor: theme.palette.base.white,
                    padding: '8px 0px',
                    borderRadius: '0 0 8px 8px',
                    height: '36px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {id ? userRanking : myRanking} |
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '4px',
                      color:
                        (id ? userRankingChange : myRankingChange) > 0
                          ? theme.palette.success.main
                          : (id ? userRankingChange : myRankingChange) < 0
                          ? theme.palette.error.main
                          : theme.palette.darkGrey[700],
                    }}
                  >
                    {(id ? userRankingChange : myRankingChange) > 0 ? '+' : ''}
                    {id ? userRankingChange : myRankingChange}
                    {(id ? userRankingChange : myRankingChange) > 0 && (
                      <ArrowUp size={14} color={theme.palette.success[600]} />
                    )}
                    {(id ? userRankingChange : myRankingChange) < 0 && (
                      <ArrowDown size={14} color={theme.palette.error[600]} />
                    )}
                  </span>
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  border: `1px solid ${theme.palette.darkGrey[200]}`,
                  borderRadius: '8px',
                  maxWidth: '89px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.darkGrey[700],
                    backgroundColor: theme.palette.darkGrey[100],
                    padding: '8px 12px',
                    borderRadius: '8px 8px 0 0',
                    height: '36px',
                    textAlign: 'center',
                  }}
                >
                  {t('doubles')}
                </Typography>
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.darkGrey[700],
                    backgroundColor: theme.palette.base.white,
                    padding: '8px 12px',
                    borderRadius: '0 0 8px 8px',
                    height: '36px',
                    textAlign: 'center',
                  }}
                >
                  -
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} marginTop={'20px'}>
            <Typography
              variant="body3"
              sx={{
                fontWeight: 700,
                color: theme.palette.darkGrey[700],
                marginBottom: '12px',
              }}
            >
              {t('trophies')}
            </Typography>
            {goldTrophies.length === 0 &&
            silverTrophies.length === 0 &&
            bronzeTrophies.length === 0 ? (
              <Typography
                variant="body4"
                sx={{
                  fontWeight: 500,
                  color: theme.palette.darkGrey[500],
                }}
              >
                {t('noTrophies')}
              </Typography>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '8px',
                }}
              >
                {goldTrophies.map((trophy, index) => (
                  <LightTooltip
                    key={index}
                    title={`${trophy.tournament_name} (${trophy.tournament_level})`}
                    open={showGoldTooltip[index] || false}
                    onOpen={() => {
                      const newShowTooltip = [...showGoldTooltip];
                      newShowTooltip[index] = true;
                      setShowGoldTooltip(newShowTooltip);
                    }}
                    onClose={() => {
                      const newShowTooltip = [...showGoldTooltip];
                      newShowTooltip[index] = false;
                      setShowGoldTooltip(newShowTooltip);
                    }}
                  >
                    <Box
                      display={'flex'}
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        width: { xs: '135px', sm: '145px' },
                        padding: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const newShowTooltip = [...showGoldTooltip];
                        newShowTooltip[index] = !newShowTooltip[index];
                        setShowGoldTooltip(newShowTooltip);
                      }}
                    >
                      <Typography
                        variant="body4"
                        sx={{
                          fontWeight: 700,
                          color: theme.palette.darkGrey[700],
                          marginRight: '8px',
                        }}
                      >
                        {t('firstPlace')}
                      </Typography>
                      <Box component={'img'} src={award} alt="award" />
                    </Box>
                  </LightTooltip>
                ))}
                {silverTrophies.map((trophy, index) => (
                  <LightTooltip
                    key={index}
                    title={`${trophy.tournament_name} (${trophy.tournament_level})`}
                    open={showSilverTooltip[index] || false}
                    onOpen={() => {
                      const newShowTooltip = [...showSilverTooltip];
                      newShowTooltip[index] = true;
                      setShowSilverTooltip(newShowTooltip);
                    }}
                    onClose={() => {
                      const newShowTooltip = [...showSilverTooltip];
                      newShowTooltip[index] = false;
                      setShowSilverTooltip(newShowTooltip);
                    }}
                  >
                    <Box
                      display={'flex'}
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        width: { xs: '135px', sm: '145px' },
                        padding: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const newShowTooltip = [...showSilverTooltip];
                        newShowTooltip[index] = !newShowTooltip[index];
                        setShowSilverTooltip(newShowTooltip);
                      }}
                    >
                      <Typography
                        variant="body4"
                        sx={{
                          fontWeight: 700,
                          color: theme.palette.darkGrey[700],
                          marginRight: '8px',
                        }}
                      >
                        {t('secondPlace')}
                      </Typography>
                      <Box component={'img'} src={award} alt="award" />
                    </Box>
                  </LightTooltip>
                ))}
                {bronzeTrophies.map((trophy, index) => (
                  <LightTooltip
                    key={index}
                    title={`${trophy.tournament_name} (${trophy.tournament_level})`}
                    open={showBronzeTooltip[index] || false}
                    onOpen={() => {
                      const newShowTooltip = [...showBronzeTooltip];
                      newShowTooltip[index] = true;
                      setShowBronzeTooltip(newShowTooltip);
                    }}
                    onClose={() => {
                      const newShowTooltip = [...showBronzeTooltip];
                      newShowTooltip[index] = false;
                      setShowBronzeTooltip(newShowTooltip);
                    }}
                  >
                    <Box
                      display={'flex'}
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        width: { xs: '135px', sm: '145px' },
                        padding: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const newShowTooltip = [...showBronzeTooltip];
                        newShowTooltip[index] = !newShowTooltip[index];
                        setShowBronzeTooltip(newShowTooltip);
                      }}
                    >
                      <Typography
                        variant="body4"
                        sx={{
                          fontWeight: 700,
                          color: theme.palette.darkGrey[700],
                          marginRight: '8px',
                        }}
                      >
                        {t('thirdPlace')}
                      </Typography>
                      <Box component={'img'} src={award} alt="award" />
                    </Box>
                  </LightTooltip>
                ))}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          maxWidth={{ xs: '100%', sm: '363px' }}
          width={'100%'}
          height={'264px'}
          sx={{
            backgroundColor: theme.palette.base.white,
            borderRadius: '16px',
            boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            variant="body3"
            sx={{
              padding: '32px',
              paddingBottom: '0',
              fontWeight: 700,
              color: theme.palette.darkGrey[700],
              marginBottom: '16px',
            }}
          >
            {t('totalMatchesPlayed')}:{' '}
            {id ? userStats.totalMatches : myStats.totalMatches} <br />
            {t('won') + ': ' + winPercentage + '%'}
          </Typography>
          <PieChart
            series={id ? userStats.matchesWonLost : myStats.matchesWonLost}
            options={wonLostOptions}
          />
        </Box>
        <Box
          maxWidth={{ xs: '100%', sm: '363px', md: '330px' }}
          width={'100%'}
          height={'264px'}
          sx={{
            backgroundColor: theme.palette.base.white,
            borderRadius: '16px',
            boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            variant="body3"
            sx={{
              padding: '32px',
              paddingBottom: '0',
              fontWeight: 700,
              color: theme.palette.darkGrey[700],
              marginBottom: '16px',
            }}
          >
            {t('totalMatchesPlayed')}:{' '}
            {id ? userStats.totalMatches : myStats.totalMatches}
            <br />
            {t('singles') + ': ' + singlesPercentage + '%'}
          </Typography>
          <PieChart
            series={
              id ? userStats.totalMatchesPlayed : myStats.totalMatchesPlayed
            }
            options={totalMatchesPlayedOptions}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Statistics;
