import React from 'react';
import { useTheme } from '@mui/material/styles';
import { MenuItem, Select, Typography, Box } from '@mui/material';
import chevronDown from 'assets/icons/select-chevron-down.svg';
import chevronUp from 'assets/icons/select-chevron-up.svg';

const SelectInput = ({
  id,
  placeholder,
  open,
  handleOpen,
  handleClose,
  handleChange,
  topMargin,
  options,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Select
      fullWidth
      labelId={id}
      id={id}
      onOpen={() => handleOpen(id)}
      onClose={() => handleClose(id)}
      onChange={handleChange}
      displayEmpty
      renderValue={(value) => {
        const selectedOption = options.find((option) => option.value === value);
        return selectedOption ? (
          <Typography variant="body3" color={theme.palette.darkGrey[500]}>
            {selectedOption.label}
          </Typography>
        ) : (
          <Typography variant="body3" color={theme.palette.darkGrey[500]}>
            {placeholder}
          </Typography>
        );
      }}
      IconComponent={() => (
        <Box
          component={'img'}
          src={open[id] ? chevronUp : chevronDown}
          sx={{ mr: '14px' }}
          alt="select arrow"
        />
      )}
      size="small"
      sx={{
        height: '44px',
        mt: topMargin ? topMargin : '0px',
      }}
      aria-label={id}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Typography
            variant="body3"
            color={theme.palette.darkGrey[900]}
            fontWeight={500}
          >
            {option.label}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectInput;
