import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const Button = ({ children, ...props }) => {
  const theme = useTheme();
  const { size, buttontype, bordered } = props;

  return (
    <Box
      as="button"
      width="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor={
        buttontype === 'white'
          ? theme.palette.base.white
          : theme.palette.orange[600]
      }
      color={
        buttontype === 'white'
          ? theme.palette.darkGrey[700]
          : theme.palette.base.white
      }
      padding={
        size === 'small'
          ? '10px 18px'
          : size === 'large'
          ? '16px 24px'
          : '12px 20px'
      }
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        height: size === 'small' ? '44px' : size === 'large' ? '60px' : '48px',
        gap: size === 'small' ? '8px' : '12px',
        fontSize:
          size === 'small' ? '16px' : size === 'large' ? '18px' : '16px',
        lineHeight:
          size === 'small' ? '24px' : size === 'large' ? '28px' : '24px',
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        boxShadow:
          bordered === 'true'
            ? '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
            : '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        borderRadius: '8px',
        border:
          bordered === 'true'
            ? `1px solid ${theme.palette.darkGrey[300]}`
            : 'none',
        '&:hover': {
          backgroundColor:
            buttontype === 'white'
              ? theme.palette.darkGrey[100]
              : theme.palette.orange[700],
        },
        '&:focus': {
          border:
            buttontype === 'white' && bordered === 'true'
              ? `2px solid ${theme.palette.darkGrey[300]}`
              : buttontype === 'orange'
              ? `2px solid ${theme.palette.orange[300]}`
              : 'none',
          backgroundColor:
            buttontype === 'white'
              ? theme.palette.base.white
              : theme.palette.orange[600],
        },
        '&:disabled': {
          cursor: 'not-allowed',
          backgroundColor:
            buttontype === 'white'
              ? theme.palette.base.white
              : theme.palette.orange[100],
          color:
            buttontype === 'white'
              ? theme.palette.darkGrey[300]
              : theme.palette.base.white,
          border:
            bordered === 'true'
              ? `1px solid ${theme.palette.darkGrey[300]}`
              : 'none',
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Button;
