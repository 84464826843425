import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import notification from 'assets/icons/Notification.svg';
import { Badge, Button, Menu, MenuItem, Typography } from '@mui/material';
import { get, getUserIdFromToken, post } from 'api';

const Notification = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const userId = getUserIdFromToken();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    notifications.forEach((item) => {
      if (!item.readAt) {
        setTimeout(() => {
          handleClickNotification(item);
        }, 1500);
      }
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotifications = async () => {
    const response = await get(`/notifications/${userId}`);
    const data = await response.json();
    // setNotifications(data.slice(0, 1));
    setNotifications(data);
    setUnreadNotifications(data.filter((item) => !item.readAt).length);
  };

  useEffect(() => {
    getNotifications();
  }, [userId]);

  const handleClickNotification = async (item) => {
    const response = await post(`/notifications/${item.id}/read`, { userId });
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      getNotifications();
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Badge
        badgeContent={unreadNotifications}
        variant="dot"
        color="error"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            minWidth: 'auto',
            padding: 0,
            '&:hover': {
              opacity: 0.7,
            },
          }}
        >
          <Box
            component={'img'}
            src={notification}
            height={1}
            width={1}
            alt="notification"
          />
        </Button>
      </Badge>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.base.white,
            boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
            borderRadius: '12px',
            top: '60px !important',
            width: '400px',
            height: 'auto',
            padding: '16px !important',
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
          },
        }}
      >
        {notifications.length === 0 && (
          <MenuItem
            onClick={handleClose}
            sx={{
              backgroundColor: theme.palette.base.white,
              borderRadius: '12px',
              width: '100%',
              padding: '16px !important',
              boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
              '&:not(:last-child)': {
                marginBottom: '16px',
              },
              cursor: 'default',
              '&:hover': {
                backgroundColor: theme.palette.base.white,
              },
            }}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              flexDirection={'column'}
              width={'100%'}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: '4px',
                }}
              >
                <Typography
                  variant={'body4'}
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.darkGrey[700],
                  }}
                >
                  {t('noNotifications')}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        )}
        {notifications.slice(0, 1).map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
            sx={{
              backgroundColor: theme.palette.base.white,
              borderRadius: '12px',
              width: '100%',
              padding: '16px !important',
              boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
              '&:not(:last-child)': {
                marginBottom: '16px',
              },
              cursor: 'default',
              '&:hover': {
                backgroundColor: theme.palette.base.white,
              },
            }}
          >
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              flexDirection={'column'}
              width={'100%'}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: '4px',
                }}
              >
                <Typography
                  variant={'body4'}
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.darkGrey[700],
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
              <Typography
                variant={'body4'}
                sx={{
                  fontWeight: item.readAt ? 400 : 600,
                  color: theme.palette.darkGrey[600],
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  width: '100%',
                }}
              >
                {item.body}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Notification;
