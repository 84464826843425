import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { get, getUserIdFromToken } from 'api';
import CryptoJS from 'crypto-js';

const TournamentMatches = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [upcomingMatches, setUpcomingMatches] = useState([]);

  const isChallenger = (item) => {
    return item.challenger_id === getUserIdFromToken();
  };

  const fetchUpcomingMatches = async () => {
    try {
      const response = await get('/tournaments/my-active-tournament-matches');
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUpcomingMatches(decryptedData);
    } catch (error) {
      console.error('Failed to fetch matches:', error);
    }
  };

  useEffect(() => {
    fetchUpcomingMatches();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: upcomingMatches.length > 0 ? 'flex' : 'none',
        flexDirection: 'column',
        marginBottom: '16px',
      }}
    >
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[500]}
        fontWeight={600}
        marginBottom={'8px'}
        marginTop={'16px'}
      >
        {t('tournaments')}:
      </Typography>
      <Box
        sx={{
          maxHeight: '456px',
          overflowY: 'auto',
          overflowX: 'hidden',
          marginRight: '-8px',
          '::-webkit-scrollbar': {
            width: '4px',
            borderRadius: '8px',
            background: theme.palette.darkGrey[200],
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.darkGrey[300],
            borderRadius: '8px',
            minHeight: '24px',
          },
        }}
      >
        {upcomingMatches.length > 0 &&
          upcomingMatches.map((match, index) => (
            <Box
              key={index}
              sx={{
                width: '224px',
                padding: '16px',
                backgroundColor: theme.palette.base.white,
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                '&:not(:last-child)': {
                  marginBottom: '8px',
                },
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                fontWeight={600}
              >
                {t('playUntil')} {match.play_until}
              </Typography>
              <Typography
                variant="body4"
                color={theme.palette.darkGrey[600]}
                fontWeight={400}
              >
                {isChallenger(match)
                  ? match.opponent_full_name
                  : match.challenger_full_name}
              </Typography>
              <Box
                sx={{
                  marginTop: '16px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/siusti-zinute`, {
                    state: {
                      isTournament: true,
                      isMulti: false,
                      mailTo: isChallenger(match)
                        ? match.opponent_email
                        : match.challenger_email,
                      mailSubject: `${match.tournament_name} (${match.city})`,
                      location: match.city,
                      avatar: isChallenger(match)
                        ? match.opponent_image
                        : match.challenger_image,
                      name: isChallenger(match)
                        ? match.opponent_full_name
                        : match.challenger_full_name,
                    },
                  });
                }}
              >
                <Typography
                  variant="body4"
                  color={theme.palette.darkGrey[700]}
                  fontWeight={600}
                >
                  {t('sendMessage')}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default TournamentMatches;
