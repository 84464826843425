import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from 'components/Container';
import Footer from 'components/Footer';

import { Topbar, Sidebar } from './components';

import pages from '../navigation';

const Main = ({ children }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Box>
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          borderRadius: 0,
          backgroundColor: theme.palette.blue[500],
          boxShadow: '0px 4px 18px 0px rgba(51, 69, 79, 0.24)',
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container
          paddingY={0}
          paddingLeft={{ xs: '16px', sm: '32px', md: '64px' }}
          paddingRight={{ xs: '16px', sm: '32px', md: '64px' }}
          paddingTop="20px"
          paddingBottom="20px"
          height={'84px'}
        >
          <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        {children}
        <Divider />
      </main>
      <Box bgcolor={theme.palette.blue[500]}>
        <Container
          paddingTop={{ xs: '48px', md: '64px' }}
          paddingBottom="52px"
          paddingY={0}
        >
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;
