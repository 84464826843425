import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { ContactCards, ContactForm } from './components';

const ContactPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box bgcolor={theme.palette.darkGrey[50]}>
        <Container
          sx={{
            paddingBottom: {
              sm: '80px !important',
              md: '96px !important',
            },
            paddingTop: {
              sm: '80px !important',
              md: '96px !important',
            },
          }}
        >
          <ContactForm />
        </Container>
      </Box>
      <Container
        sx={{
          paddingBottom: {
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '80px !important',
            md: '96px !important',
          },
        }}
      >
        <ContactCards />
      </Container>
    </Main>
  );
};

export default ContactPage;
