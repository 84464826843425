export const chartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: true,
    horizontalAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    offsetY: 0,
    offsetX: 0,
    markers: {
      width: 8,
      height: 8,
      radius: 5,
    },
    itemMargin: {
      horizontal: 0,
      vertical: 2,
    },
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  dataLabels: {
    enabled: false,
    formatter: (val) => {
      return val.toFixed(1) + '%';
    },
    style: {
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 600,
      colors: ['#98A2B3'],
    },
  },
  labels: [],
  colors: [],
  stroke: {
    show: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: '50%',
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 700,
            color: '#475467',
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 700,
            color: '#475467',
            offsetY: 10,
          },
          total: {
            show: false,
            fontSize: '24px',
            fontFamily: 'Roboto',
            fontWeight: 700,
            color: '#4caf50',
            label: 'Total',
            formatter: (w) => {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },
};
