import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DropZonefile = (props) => {
  const theme = useTheme();
  const { content, onDrop } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
    },
  });
  return (
    <Box
      height={'126px'}
      maxWidth={'416px'}
      width={'100%'}
      sx={{
        border: `1px solid ${theme.palette.darkGrey[300]}`,
        borderRadius: '12px',
      }}
      {...getRootProps({ className: 'dropzone' })}
    >
      <input {...getInputProps()} />
      <Box>{content}</Box>
    </Box>
  );
};

export default DropZonefile;
