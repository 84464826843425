import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Button as ButtonMui,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import logo from 'assets/logo/logo.svg';
import NavItem from './components/NavItem';
import Button from 'components/Button';
import globe02 from 'assets/icons/globe02.svg';
import searchLg from 'assets/icons/search-lg.svg';
import chevronDown from 'assets/icons/chevronDown.svg';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import settings from 'assets/icons/settings-01-white.svg';
import { get } from 'api';
import { useTheme } from '@mui/material/styles';
import Notification from 'system/layouts/Main/components/Sidebar/components/Notifications/Notification';

const dropdownNav = [
  {
    title: 'profile',
    href: '/info',
  },
  {
    title: 'myAccount',
    href: '/redaguoti-profili',
  },
  {
    title: 'logout',
  },
];

const dropdownLng = [
  {
    title: 'LT',
    lng: 'lt',
  },
  {
    title: 'EN',
    lng: 'en',
  },
];

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [lngAnchorEl, setLngAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const lngOpen = Boolean(lngAnchorEl);
  const [currentUser, setCurrentUser] = useState([]);
  const token = localStorage.getItem('token');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLngClick = (event) => {
    setLngAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLngClose = () => {
    setLngAnchorEl(null);
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await get(`/accounts/myavatar`);
        const data = await response.json();
        setCurrentUser(data);
      } catch (err) {
        console.error(err.message);
      }
    };
    getUser();
  }, []);

  return (
    <Box
      sx={{
        padding: '20px 16px',
      }}
    >
      <Box width={1}>
        <Box
          display={'flex'}
          onClick={() => navigate('/')}
          width={{ xs: 55, md: 120 }}
          sx={{ cursor: 'pointer' }}
        >
          <Box
            component={'img'}
            src={logo}
            height={1}
            width={1}
            alt="Teniso Partneris logo"
          />
        </Box>
      </Box>
      <Box marginTop={'32px'}>
        <Box sx={{ marginBottom: '12px' }}>
          {pages
            .filter((page) => page.topBar)
            .map((page, i) => (
              <NavItem key={i} id={page.title} page={page} />
            ))}
        </Box>
        <Box marginBottom={'20px'} display={'flex'} alignItems={'center'}>
          <ButtonMui
            id="basic-button"
            aria-controls={lngOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={lngOpen ? 'true' : undefined}
            onClick={handleLngClick}
            sx={{
              minWidth: 'auto',
              padding: 0,
              '&:hover': {
                opacity: 0.7,
              },
            }}
          >
            <Box component={'img'} src={globe02} />
          </ButtonMui>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={lngOpen}
            onClose={handleLngClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiMenu-paper': {
                backgroundColor: theme.palette.base.white,
                boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
                borderRadius: '8px',
                top: 'auto !important',
                bottom: '220px !important',
                left: '50px !important',
                width: '55px',
                height: 'auto',
                padding: '16px !important',
                paddingTop: '0px !important',
                paddingBottom: '0px !important',
              },
            }}
          >
            {dropdownLng.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleChangeLanguage(item.lng);
                  handleClose();
                }}
                sx={{
                  padding: '0px !important',
                  '&:not(:last-child)': {
                    marginBottom: '12px !important',
                  },
                }}
              >
                <Typography
                  variant={'body3'}
                  fontWeight={600}
                  fontStyle={'normal'}
                  color={theme.palette.darkGrey[700]}
                >
                  {item.title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {!token ? (
          <>
            <Box marginTop={2}>
              <Button
                size="small"
                bordered="true"
                buttontype="white"
                onClick={() => navigate('/prisijungimas')}
                width={1}
              >
                {t('login')}
              </Button>
            </Box>
            <Box marginTop={'20px'}>
              <Button
                onClick={() => navigate('/registracija')}
                buttontype="orange"
                size="small"
                width={1}
              >
                {t('signup')}
              </Button>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              onClick={() => navigate('/info')}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
                cursor: 'pointer',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  component={'img'}
                  src={
                    currentUser.image
                      ? `https://backend.tenisopartneris.lt/public/images/${currentUser.image}`
                      : avatarWhite
                  }
                  alt="avatar"
                  width={'24px'}
                  height={'24px'}
                  borderRadius={'50%'}
                  sx={{
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box
                marginLeft={'12px'}
                display={'flex'}
                alignItems={'center'}
                onClick={() => navigate('/info')}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Typography
                  variant={'body3'}
                  fontWeight={600}
                  fontStyle={'normal'}
                  color={theme.palette.base.white}
                >
                  {currentUser.first_name}
                </Typography>
              </Box>
              <Box marginLeft={'12px'} display={'flex'} alignItems={'center'}>
                <ButtonMui
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{
                    minWidth: 'auto',
                    padding: '0px !important',
                    '&:hover': {
                      opacity: 0.7,
                    },
                  }}
                >
                  <Box component={'img'} src={chevronDown} alt="chevron down" />
                </ButtonMui>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    '& .MuiMenu-paper': {
                      backgroundColor: theme.palette.base.white,
                      boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
                      borderRadius: '8px',
                      width: '160px',
                      height: 'auto !important',
                      padding: '16px !important',
                      paddingTop: '0px !important',
                      paddingBottom: '0px !important',
                      left: '16px !important',
                    },
                  }}
                >
                  {dropdownNav.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        if (item.title === 'logout') {
                          handleLogout();
                          setAnchorEl(null);
                        } else {
                          navigate(item.href);
                        }
                      }}
                      sx={{
                        padding: '0px !important',
                      }}
                    >
                      <Typography
                        variant={'body3'}
                        fontWeight={600}
                        fontStyle={'normal'}
                        color={theme.palette.darkGrey[700]}
                      >
                        {t(item.title)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Notification />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
