import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Footer from 'components/Footer';
import { Sidebar } from './components';
import pages from '../navigation';
import SideMenu from './components/SideMenu';

const Main = ({ children }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <Box>
      <SideMenu handleSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <Box
        component="main"
        sx={{
          marginLeft: isMd ? '256px' : 0,
          marginTop: '84px',
        }}
      >
        {children}
      </Box>
      <Box
        bgcolor={theme.palette.blue[500]}
        sx={{ zIndex: 200, position: 'relative' }}
      >
        <Container paddingTop="64px" paddingBottom="48px" paddingY={0}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;
