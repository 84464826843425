import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import userCheck from 'assets/icons/userCheckWhite.svg';
import racquet from 'assets/icons/racquetWhite.svg';
import awardMedal from 'assets/icons/awardMedal.svg';
import heartCheck from 'assets/icons/heartCheck.svg';
import { Typography, useMediaQuery } from '@mui/material';
import iphone from 'assets/images/iphone.png';

const Impact = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const impactsList1 = [
    {
      title: t('impactOneTitle'),
      subtitle: t('impactOneText'),
      icon: userCheck,
    },
    {
      title: t('impactTwoTitle'),
      subtitle: t('impactTwoText'),
      icon: racquet,
    },
  ];

  const impactsList2 = [
    {
      title: t('impactThreeTitle'),
      subtitle: t('impactThreeText'),
      icon: awardMedal,
    },
    {
      title: t('impactFourTitle'),
      subtitle: t('impactFourText'),
      icon: heartCheck,
    },
  ];

  const impactsList3 = [
    {
      title: t('impactOneTitle'),
      subtitle: t('impactOneText'),
      icon: userCheck,
    },
    {
      title: t('impactTwoTitle'),
      subtitle: t('impactTwoText'),
      icon: racquet,
    },
    {
      title: t('impactThreeTitle'),
      subtitle: t('impactThreeText'),
      icon: awardMedal,
    },
    {
      title: t('impactFourTitle'),
      subtitle: t('impactFourText'),
      icon: heartCheck,
    },
  ];

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '629px', md: '768px' },
          width: '100%',
          marginBottom: { xs: '0', md: '64px' },
        }}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={'20px'}
          textAlign={'center'}
        >
          {t('impactTitle')}
        </Typography>
        <Typography
          variant={isMd ? 'body1' : 'body2'}
          color={theme.palette.darkGrey[600]}
          textAlign={'center'}
        >
          {t('impactSubtitle')}
        </Typography>
      </Box>
      <Box
        display={{ xs: 'none', md: 'flex' }}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <Box display={'flex'} flexDirection={'column'}>
          {impactsList1.map((item, i) => (
            <Box
              key={i}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              maxWidth={303}
              maxHeight={178}
              width={'100%'}
              height={'100%'}
              sx={{ '&:not(:last-of-type)': { marginBottom: '48px' } }}
            >
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: theme.palette.orange[600],
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px',
                  padding: '12px',
                }}
              >
                <Box
                  component={'img'}
                  alt="icon"
                  src={item.icon}
                  height={1}
                  width={1}
                  maxWidth={24}
                  maxHeight={24}
                />
              </Box>
              <Typography
                variant={'body1'}
                fontWeight={600}
                color={theme.palette.darkGrey[700]}
                marginBottom={'8px'}
              >
                {item.title}
              </Typography>
              <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
                {item.subtitle}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Box sx={{ position: 'relative', zIndex: 1, paddingTop: '90px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="533"
              height="480"
              viewBox="0 0 533 480"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M182.534 461.691C75.4901 428.768 1.82278 329.846 0.512178 217.408C-0.658168 117.003 82.6936 43.2414 177.277 10.7273C260.57 -17.9056 346.827 12.9156 406.643 77.7959C485.413 163.236 571.843 274.645 513.202 375.097C449.503 484.212 302.948 498.727 182.534 461.691Z"
                fill="url(#paint0_linear_1670_39978)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M182.534 461.691C75.4901 428.768 1.82278 329.846 0.512178 217.408C-0.658168 117.003 82.6936 43.2414 177.277 10.7273C260.57 -17.9056 346.827 12.9156 406.643 77.7959C485.413 163.236 571.843 274.645 513.202 375.097C449.503 484.212 302.948 498.727 182.534 461.691Z"
                fill="url(#paint1_linear_1670_39978)"
                fillOpacity="0.73"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1670_39978"
                  x1="0.5"
                  y1="240"
                  x2="532.5"
                  y2="240"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#141E30" />
                  <stop offset="1" stopColor="#243B55" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1670_39978"
                  x1="241"
                  y1="293.5"
                  x2="378"
                  y2="-35.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#475467" />
                  <stop offset="1" stopColor="#94A3B1" />
                </linearGradient>
              </defs>
            </svg>
          </Box>
          <Box
            position={'relative'}
            zIndex={1}
            margin={'-578px auto 0 auto'}
            width={'100%'}
            maxWidth={301}
            height={616}
            maxHeight={616}
          >
            <Box
              component={'img'}
              src={iphone}
              height={1}
              width={1}
              alt="phone image"
            />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          {impactsList2.map((item, i) => (
            <Box
              key={i}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              maxWidth={304}
              maxHeight={178}
              width={'100%'}
              height={'100%'}
              sx={{ '&:not(:last-of-type)': { marginBottom: '48px' } }}
            >
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: theme.palette.orange[600],
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px',
                  padding: '12px',
                }}
              >
                <Box
                  component={'img'}
                  alt="icon"
                  src={item.icon}
                  height={1}
                  width={1}
                  maxWidth={24}
                  maxHeight={24}
                />
              </Box>
              <Typography
                variant={'body1'}
                fontWeight={600}
                color={theme.palette.darkGrey[700]}
                marginBottom={'8px'}
              >
                {item.title}
              </Typography>
              <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
                {item.subtitle}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        marginTop="48px"
      >
        <Box marginTop="48px">
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="356"
              height="322"
              viewBox="0 0 356 322"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M121.688 308.849C50.1832 286.856 0.973457 220.776 0.0979789 145.668C-0.683811 78.5979 54.9951 29.3252 118.177 7.60573C173.816 -11.521 231.436 9.06755 271.393 52.4075C324.011 109.481 381.746 183.903 342.574 251.004C300.023 323.893 202.125 333.589 121.688 308.849Z"
                fill="url(#paint0_linear_1833_64653)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M121.688 308.849C50.1832 286.856 0.973457 220.776 0.0979789 145.668C-0.683811 78.5979 54.9951 29.3252 118.177 7.60573C173.816 -11.521 231.436 9.06755 271.393 52.4075C324.011 109.481 381.746 183.903 342.574 251.004C300.023 323.893 202.125 333.589 121.688 308.849Z"
                fill="url(#paint1_linear_1833_64653)"
                fillOpacity="0.73"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1833_64653"
                  x1="0.0898438"
                  y1="160.76"
                  x2="355.465"
                  y2="160.76"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#141E30" />
                  <stop offset="1" stopColor="#243B55" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1833_64653"
                  x1="160.744"
                  y1="196.498"
                  x2="252.259"
                  y2="-23.274"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#475467" />
                  <stop offset="1" stopColor="#94A3B1" />
                </linearGradient>
              </defs>
            </svg>
          </Box>
          <Box
            position={'relative'}
            zIndex={1}
            margin={'-370px auto 0 auto'}
            width={'100%'}
            maxWidth={209}
            height={427}
          >
            <Box component={'img'} src={iphone} height={1} width={1} />
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          width={'100%'}
          flexWrap={'wrap'}
          marginTop={{ xs: '32px', sm: '64px' }}
        >
          {impactsList3.map((item, i) => (
            <Box
              key={i}
              display={'flex'}
              flexDirection={'column'}
              alignItems={{ xs: 'center', sm: 'flex-start' }}
              maxWidth={{ xs: '100%', sm: '462px' }}
              maxHeight={178}
              width={'100%'}
              height={'100%'}
              sx={{ '&:not(:last-of-type)': { marginBottom: '48px' } }}
            >
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: theme.palette.orange[600],
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px',
                  padding: '12px',
                }}
              >
                <Box
                  component={'img'}
                  alt="icon"
                  src={item.icon}
                  height={1}
                  width={1}
                  maxWidth={24}
                  maxHeight={24}
                />
              </Box>
              <Typography
                variant={'body1'}
                fontWeight={600}
                color={theme.palette.darkGrey[700]}
                marginBottom={'8px'}
              >
                {item.title}
              </Typography>
              <Typography
                variant={'body3'}
                color={theme.palette.darkGrey[600]}
                textAlign={{ xs: 'center', sm: 'left' }}
              >
                {item.subtitle}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Impact;
