import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Link } from '@mui/material';
import PrimaryAccordion from '../PrimaryAccordion';
import SecondaryAccordion from '../SecondaryAccordion';

const FAQAccordion = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box display={'flex'} width={1} flexDirection={{ xs: 'column', md: 'row' }}>
      <Box
        sx={{
          maxWidth: '415px',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h4"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600, marginBottom: '20px' }}
        >
          FAQs
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.darkGrey[600],
            fontWeight: 400,
            marginBottom: '12px',
          }}
        >
          {t('faqText')}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.darkGrey[600], fontWeight: 400 }}
        >
          {t('faqTextTwo')}&nbsp;
          <Link href={'/kontaktai'}>
            <Typography
              variant="body16"
              sx={{
                color: theme.palette.darkGrey[600],
                fontWeight: 400,
              }}
            >
              {t('contactForm')}
            </Typography>
          </Link>
        </Typography>
      </Box>
      <Box
        sx={{
          marginLeft: { xs: '0px', md: '32px' },
          marginTop: { xs: '32px', md: '0px' },
          display: 'flex',
          flexDirection: 'column',
          maxWidth: { xs: '100%', sm: '961px', md: '863px' },
          width: '100%',
        }}
      >
        <PrimaryAccordion />
        <SecondaryAccordion />
      </Box>
    </Box>
  );
};

export default FAQAccordion;
