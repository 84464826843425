import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Bracket } from 'react-brackets';
import { useTheme } from '@mui/material/styles';
import CustomSeed from '../CustomSeed/CustomSeed';
import { Box, Typography } from '@mui/material';
import { PrimaryTab } from 'components/Tabs';
import { getUserIdFromToken, get } from 'api';
import { TournamentPlayerCard } from 'components/Cards';
import { mainTableDates } from 'constants/Tournament';

const MainTable = ({ level, filter }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const token = getUserIdFromToken();
  const { id } = useParams();
  const [bracketRounds, setBracketRounds] = useState([]);
  const [originalBracketRounds, setOriginalBracketRounds] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [activeLevel, setActiveLevel] = useState('light');
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const resetFilter = () => {
    setSelectedFilter(1);
  };

  useEffect(() => {
    const fetchMainTable = async () => {
      const response = await get(`/tournaments/tournament-pagrindas/${id}`);
      const data = await response.json();
      const filtered = data.filter(
        (item) => item.tournament_gender === filter.gender,
      );
      setAllData(filtered);

      const levelsOrder = ['super light', 'light', 'middle', 'power', 'hard'];
      const availableLevels = filtered
        .map((item) => item.tournament_level.toLowerCase())
        .filter((level) => levelsOrder.includes(level));

      const fetchedLevel =
        availableLevels.length > 0
          ? levelsOrder.find((level) => availableLevels.includes(level))
          : 'light';

      setActiveLevel(level ? level.toLowerCase() : fetchedLevel);
    };
    fetchMainTable();
  }, [id, token, filter, level]);

  useEffect(() => {
    if (level) {
      setActiveLevel(level.toLowerCase());
    }
  }, [level]);

  useEffect(() => {
    const filteredGroups = allData.filter(
      (item) => item.tournament_level.toLowerCase() === activeLevel,
    );
    setFilteredData(filteredGroups);
  }, [activeLevel, allData]);

  const groupP = (property, group) =>
    filteredData
      .filter((item) => item[property + '_group'] === group)
      .map((item, index) => {
        const isChallenger = item[property + '_group'] % 2 !== 0;
        return (
          <TournamentPlayerCard
            key={index}
            pRez={item[property + '_rez']}
            first_name={item.first_name}
            last_name={item.last_name}
            winner={item[property + '_winner']}
            user_id={item.user_id}
            doubles_partner={item.doubles_partner}
            partner_first_name={item.partner_first_name}
            partner_last_name={item.partner_last_name}
            isChallenger={isChallenger}
            avatar={item.image}
          />
        );
      });

  const createSeeds = (
    groupFunc,
    groupNamesPairs,
    startId,
    defaultName = '',
    stage,
    step,
  ) => {
    return groupNamesPairs.map(([groupName1, groupName2], index) => {
      return {
        id: startId + index,
        date: mainTableDates[stage][step],
        isActive: allData[0]?.isActive,
        isContinued: allData[0]?.isContinued,
        stage: 'winner',
        teams: [
          {
            name:
              groupFunc(groupName1).length > 0
                ? groupFunc(groupName1)
                : defaultName,
          },
          {
            name:
              groupFunc(groupName2).length > 0
                ? groupFunc(groupName2)
                : defaultName,
          },
        ],
      };
    });
  };

  const TOP64 = [
    {
      title: 'TOP 64',
      seeds: createSeeds(
        (groupName) => groupP('P1', groupName),
        [
          ['T1', 'T2'],
          ['T3', 'T4'],
          ['T5', 'T6'],
          ['T7', 'T8'],
          ['T9', 'T10'],
          ['T11', 'T12'],
          ['T13', 'T14'],
          ['T15', 'T16'],
          ['T17', 'T18'],
          ['T19', 'T20'],
          ['T21', 'T22'],
          ['T23', 'T24'],
          ['T25', 'T26'],
          ['T27', 'T28'],
          ['T29', 'T30'],
          ['T31', 'T32'],
          ['T33', 'T34'],
          ['T35', 'T36'],
          ['T37', 'T38'],
          ['T39', 'T40'],
          ['T41', 'T42'],
          ['T43', 'T44'],
          ['T45', 'T46'],
          ['T47', 'T48'],
          ['T49', 'T50'],
          ['T51', 'T52'],
          ['T53', 'T54'],
          ['T55', 'T56'],
          ['T57', 'T58'],
          ['T59', 'T60'],
          ['T61', 'T62'],
          ['T63', 'T64'],
        ],
        1,
        <Box sx={{ marginLeft: '12px' }}>
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            BYE..
          </Typography>
        </Box>,
        'TOP64',
        'TOP64',
      ),
    },
    {
      title: 'TOP 32',
      seeds: createSeeds(
        (groupName) => groupP('P2', groupName),
        [
          ['S1', 'S2'],
          ['S3', 'S4'],
          ['S5', 'S6'],
          ['S7', 'S8'],
          ['S9', 'S10'],
          ['S11', 'S12'],
          ['S13', 'S14'],
          ['S15', 'S16'],
          ['S17', 'S18'],
          ['S19', 'S20'],
          ['S21', 'S22'],
          ['S23', 'S24'],
          ['S25', 'S26'],
          ['S27', 'S28'],
          ['S29', 'S30'],
          ['S31', 'S32'],
        ],
        1,
        '',
        'TOP64',
        'TOP32',
      ),
    },
    {
      title: 'TOP 16',
      seeds: createSeeds(
        (groupName) => groupP('P3', groupName),
        [
          ['A1', 'A2'],
          ['A3', 'A4'],
          ['A5', 'A6'],
          ['A7', 'A8'],
          ['A9', 'A10'],
          ['A11', 'A12'],
          ['A13', 'A14'],
          ['A15', 'A16'],
        ],
        17,
        '',
        'TOP64',
        'TOP16',
      ),
    },
    {
      title: 'TOP 8',
      seeds: createSeeds(
        (groupName) => groupP('P4', groupName),
        [
          ['K1', 'K2'],
          ['K3', 'K4'],
          ['K5', 'K6'],
          ['K7', 'K8'],
        ],
        25,
        '',
        'TOP64',
        'TOP8',
      ),
    },
    {
      title: t('semiFinal'),
      seeds: createSeeds(
        (groupName) => groupP('P5', groupName),
        [
          ['PF1', 'PF2'],
          ['PF3', 'PF4'],
        ],
        29,
        '',
        'TOP64',
        'TOP4',
      ),
    },
    {
      title: t('final'),
      seeds: createSeeds(
        (groupName) => groupP('P6', groupName),
        [['F1', 'F2']],
        31,
        '',
        'TOP64',
        'FINAL',
      ),
    },
    {
      title: t('thirdPlace'),
      seeds: createSeeds(
        (groupName) => groupP('P7', groupName),
        [['F3', 'F4']],
        32,
        '',
        'TOP64',
        'FINAL',
      ),
    },
  ];

  const TOP32 = [
    {
      title: 'TOP 32',
      seeds: createSeeds(
        (groupName) => groupP('P2', groupName),
        [
          ['S1', 'S2'],
          ['S3', 'S4'],
          ['S5', 'S6'],
          ['S7', 'S8'],
          ['S9', 'S10'],
          ['S11', 'S12'],
          ['S13', 'S14'],
          ['S15', 'S16'],
          ['S17', 'S18'],
          ['S19', 'S20'],
          ['S21', 'S22'],
          ['S23', 'S24'],
          ['S25', 'S26'],
          ['S27', 'S28'],
          ['S29', 'S30'],
          ['S31', 'S32'],
        ],
        1,
        <Box sx={{ marginLeft: '12px' }}>
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            BYE..
          </Typography>
        </Box>,
        'TOP32',
        'TOP32',
      ),
    },
    {
      title: 'TOP 16',
      seeds: createSeeds(
        (groupName) => groupP('P3', groupName),
        [
          ['A1', 'A2'],
          ['A3', 'A4'],
          ['A5', 'A6'],
          ['A7', 'A8'],
          ['A9', 'A10'],
          ['A11', 'A12'],
          ['A13', 'A14'],
          ['A15', 'A16'],
        ],
        17,
        '',
        'TOP32',
        'TOP16',
      ),
    },
    {
      title: 'TOP 8',
      seeds: createSeeds(
        (groupName) => groupP('P4', groupName),
        [
          ['K1', 'K2'],
          ['K3', 'K4'],
          ['K5', 'K6'],
          ['K7', 'K8'],
        ],
        25,
        '',
        'TOP32',
        'TOP8',
      ),
    },
    {
      title: t('semiFinal'),
      seeds: createSeeds(
        (groupName) => groupP('P5', groupName),
        [
          ['PF1', 'PF2'],
          ['PF3', 'PF4'],
        ],
        29,
        '',
        'TOP32',
        'TOP4',
      ),
    },
    {
      title: t('final'),
      seeds: createSeeds(
        (groupName) => groupP('P6', groupName),
        [['F1', 'F2']],
        31,
        '',
        'TOP32',
        'FINAL',
      ),
    },
    {
      title: t('thirdPlace'),
      seeds: createSeeds(
        (groupName) => groupP('P7', groupName),
        [['F3', 'F4']],
        32,
        '',
        'TOP32',
        'FINAL',
      ),
    },
  ];

  const TOP16 = [
    {
      title: 'TOP 16',
      seeds: createSeeds(
        (groupName) => groupP('P3', groupName),
        [
          ['A1', 'A2'],
          ['A3', 'A4'],
          ['A5', 'A6'],
          ['A7', 'A8'],
          ['A9', 'A10'],
          ['A11', 'A12'],
          ['A13', 'A14'],
          ['A15', 'A16'],
        ],
        1,
        <Box sx={{ marginLeft: '12px' }}>
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            BYE..
          </Typography>
        </Box>,
        'TOP16',
        'TOP16',
      ),
    },
    {
      title: 'TOP 8',
      seeds: createSeeds(
        (groupName) => groupP('P4', groupName),
        [
          ['K1', 'K2'],
          ['K3', 'K4'],
          ['K5', 'K6'],
          ['K7', 'K8'],
        ],
        9,
        '',
        'TOP16',
        'TOP8',
      ),
    },
    {
      title: t('semiFinal'),
      seeds: createSeeds(
        (groupName) => groupP('P5', groupName),
        [
          ['PF1', 'PF2'],
          ['PF3', 'PF4'],
        ],
        13,
        '',
        'TOP16',
        'TOP4',
      ),
    },
    {
      title: t('final'),
      seeds: createSeeds(
        (groupName) => groupP('P6', groupName),
        [['F1', 'F2']],
        15,
        '',
        'TOP16',
        'FINAL',
      ),
    },
    {
      title: t('thirdPlace'),
      seeds: createSeeds(
        (groupName) => groupP('P7', groupName),
        [['F3', 'F4']],
        16,
        '',
        'TOP16',
        'FINAL',
      ),
    },
  ];

  const TOP8 = [
    {
      title: 'TOP 8',
      seeds: createSeeds(
        (groupName) => groupP('P4', groupName),
        [
          ['K1', 'K2'],
          ['K3', 'K4'],
          ['K5', 'K6'],
          ['K7', 'K8'],
        ],
        1,
        <Box sx={{ marginLeft: '12px' }}>
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            BYE..
          </Typography>
        </Box>,
        'TOP8',
        'TOP8',
      ),
    },
    {
      title: t('semiFinal'),
      seeds: createSeeds(
        (groupName) => groupP('P5', groupName),
        [
          ['PF1', 'PF2'],
          ['PF3', 'PF4'],
        ],
        9,
        '',
        'TOP8',
        'TOP4',
      ),
    },
    {
      title: t('final'),
      seeds: createSeeds(
        (groupName) => groupP('P6', groupName),
        [['F1', 'F2']],
        13,
        '',
        'TOP8',
        'FINAL',
      ),
    },
    {
      title: t('thirdPlace'),
      seeds: createSeeds(
        (groupName) => groupP('P7', groupName),
        [['F3', 'F4']],
        14,
        '',
        'TOP8',
        'FINAL',
      ),
    },
  ];

  const pusfinalis = [
    {
      title: t('semiFinal'),
      seeds: createSeeds(
        (groupName) => groupP('P5', groupName),
        [
          ['PF1', 'PF2'],
          ['PF3', 'PF4'],
        ],
        1,
        '',
        'TOP4',
        'TOP4',
      ),
    },
    {
      title: t('final'),
      seeds: createSeeds(
        (groupName) => groupP('P6', groupName),
        [['F1', 'F2']],
        5,
        '',
        'TOP4',
        'FINAL',
      ),
    },
    {
      title: t('thirdPlace'),
      seeds: createSeeds(
        (groupName) => groupP('P7', groupName),
        [['F3', 'F4']],
        6,
        '',
        'TOP4',
        'FINAL',
      ),
    },
  ];

  const generateRounds = (
    TOP64,
    TOP32,
    astuntfinalis,
    ketvirtfinalis,
    pusfinalis,
  ) => {
    if (filteredData.length <= 4) {
      return pusfinalis;
    } else if (filteredData.length <= 8) {
      return ketvirtfinalis;
    } else if (filteredData.length <= 16) {
      return astuntfinalis;
    } else if (filteredData.length <= 32) {
      return TOP32;
    } else if (filteredData.length <= 64) {
      return TOP64;
    } else {
      // Handle the case when middleLength > 32
      return null;
    }
  };

  useEffect(() => {
    if (filteredData.length > 0) {
      setLoaded(true);
      const bracketRounds = generateRounds(
        TOP64,
        TOP32,
        TOP16,
        TOP8,
        pusfinalis,
      );
      setBracketRounds(bracketRounds);
      setOriginalBracketRounds(bracketRounds);
    }
  }, [filteredData]);

  useEffect(() => {
    const newBracketRounds = originalBracketRounds.slice(selectedFilter - 1);
    setBracketRounds(newBracketRounds);
  }, [selectedFilter, originalBracketRounds]);

  const handleSelectFilter = (tabName) => {
    const index = originalBracketRounds.findIndex(
      (round) => round.title === tabName,
    );
    setSelectedFilter(index + 1);
  };

  if (!loaded) {
    return null;
  }

  return (
    <Box>
      <Box
        maxWidth={'700px'}
        marginBottom={'24px'}
        display={{ xs: 'flex', sm: 'inline-flex' }}
        justifyContent={'space-between'}
        border={`1px solid ${theme.palette.darkGrey[300]}`}
        borderRadius={'8px'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {['super light', 'light', 'middle', 'power', 'hard'].map((level) => {
          const hasLevel = allData.some(
            (item) => item.tournament_level.toLowerCase() === level,
          );

          return (
            hasLevel && (
              <PrimaryTab
                key={level}
                activeTab={activeLevel}
                setActiveTab={setActiveLevel}
                tabName={level}
                resetFilter={resetFilter}
                pageName={'mainTable'}
              />
            )
          );
        })}
      </Box>
      <Box>
        <Box
          maxWidth={'840px'}
          marginBottom={'24px'}
          display={{ xs: 'flex', sm: 'inline-flex' }}
          justifyContent={'space-between'}
          border={`1px solid ${theme.palette.darkGrey[300]}`}
          borderRadius={'8px'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          {originalBracketRounds.map((round, index) => {
            return (
              <PrimaryTab
                key={index}
                tabName={round.title}
                activeTab={originalBracketRounds[selectedFilter - 1].title}
                setActiveTab={handleSelectFilter}
                pageName={'mainTable'}
              />
            );
          })}
        </Box>
      </Box>
      <Bracket
        rounds={bracketRounds}
        roundTitleComponent={(title, roundIndex) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: `2px solid ${theme.palette.darkGrey[700]}`,
                padding: '12px',
                width: '90%',
                margin: '0 auto',
              }}
            >
              <Typography
                variant={'body3'}
                fontWeight={600}
                color={theme.palette.darkGrey[700]}
              >
                {title}
              </Typography>
            </Box>
          );
        }}
        renderSeedComponent={CustomSeed}
        swipeableProps={{
          disabled: true,
        }}
      />
    </Box>
  );
};

export default MainTable;
