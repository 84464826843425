import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { get, post } from 'api';
import { Levels, MenLevels, WomenLevels } from 'constants/Selections';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import SpinningBox from 'components/SpinningBox';

const Losers = () => {
  const theme = useTheme();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [open, setOpen] = useState({});
  const [tournaments, setTournaments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    tournament: '',
    gender: '',
    level: '',
    P2: '',
    P3: '',
    P4: '',
    P5: '',
    P6: '',
    P7: '',
  });

  useEffect(() => {
    const getTournaments = async () => {
      const response = await get('/tournaments/is-active');
      const data = await response.json();
      const options = data.map((tournament) => ({
        value: tournament.id,
        label: `${tournament.tournament_name} (${tournament.city})`,
      }));
      setTournaments(options);
    };
    getTournaments();
  }, []);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const getOptions = () => {
    if (!form.tournament) return;

    const levelsMap = {
      Moterys: WomenLevels,
      Vyrai: MenLevels,
    };

    const cities = ['Vilnius', 'Kaunas'];

    for (let gender in levelsMap) {
      if (form.gender.includes(gender)) {
        for (let city of cities) {
          const selectedTournament = tournaments.find(
            (tournament) => tournament.value === form.tournament,
          );
          if (selectedTournament.label.includes(city)) {
            return levelsMap[gender].filter(
              (level) => level[`${city.toLowerCase()}On`],
            );
          }
        }
      }
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const body = {
      tournament_id: form.tournament,
      tournament_level: form.level,
      tournament_gender: form.gender,
      P2_date: form.P2,
      P3_date: form.P3,
      P4_date: form.P4,
      P5_date: form.P5,
      P6_date: form.P6,
      P7_date: form.P7,
    };
    const response = await post('/loserstage/scheduleTournament', body);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setIsSubmitting(false);
      setForm({
        tournament: '',
        level: '',
        gender: '',
        P2: '',
        P3: '',
        P4: '',
        P5: '',
        P6: '',
        P7: '',
      });
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '100%', md: '516px' },
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Turnyras:
          </Typography>
          <SelectInput
            name="tournament"
            id="tournament"
            placeholder="Pasirinkite turnyrą"
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.tournament}
            options={tournaments}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Lytis:
          </Typography>
          <SelectInput
            name="gender"
            id="gender"
            placeholder="Pasirinkite lytį"
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.gender}
            options={[
              {
                value: 'Vyrai',
                label: 'Vyrai',
              },
              {
                value: 'Moterys',
                label: 'Moterys',
              },
            ]}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Lygis:
          </Typography>
          <SelectInput
            name="level"
            id="level"
            placeholder="Pasirinkite lygį"
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.level}
            options={getOptions() || Levels}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Sužaisti iki (TOP 32):
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                P2: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.P2)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Sužaisti iki (TOP 16):
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                P3: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.P3)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Sužaisti iki (TOP 8):
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                P4: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.P4)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Sužaisti iki (TOP 4):
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                P5: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.P5)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Sužaisti iki (FINALAS):
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                P6: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.P6)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Sužaisti iki (3 VIETA):
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                P7: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.P7)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          maxWidth={{ xs: '100%', md: '100%' }}
          width={'100%'}
          buttontype={'orange'}
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
};

export default Losers;
