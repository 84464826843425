import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const AuthorCard = ({ name, avatar, jobTitle }) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} alignItems={'center'} width={1} maxWidth={304}>
      <Box
        component={'img'}
        src={avatar}
        height={52}
        width={52}
        borderRadius={'50%'}
        alt="Author avatar"
        sx={{
          objectFit: 'cover',
        }}
      />
      <Box display={'flex'} flexDirection={'column'} marginLeft={'16px'}>
        <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
          {name}
        </Typography>
        <Typography variant={'body3'} sx={{ fontWeight: 400 }}>
          {jobTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthorCard;
