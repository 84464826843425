import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import perfectmatch from 'assets/images/perfectmatch.jpg';
import Button from 'components/Button';

const PerfectMatchCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      maxHeight={{ xs: 'auto', sm: 397 }}
      height={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          padding: { xs: '64px 24px 48px 24px', sm: '80px 64px' },
          maxWidth: '785px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box display={'flex'} flexDirection={'column'} marginBottom={'32px'}>
          <Typography
            variant={isMd ? 'h4' : 'h5'}
            color={theme.palette.darkGrey[700]}
            sx={{
              fontWeight: 700,
              letterSpacing: '-0.72px',
              marginBottom: '20px',
            }}
          >
            {t('perfectMatchTitle')}
          </Typography>
          <Typography
            variant={isMd ? 'body2' : 'body3'}
            color={theme.palette.darkGrey[600]}
            sx={{
              fontWeight: 400,
              textAlign: 'left',
              maxWidth: '576px',
              width: '100%',
            }}
          >
            {t('perfectMatchText')}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'flex-start' }}
          maxWidth={{ xs: '100%', sm: '230px' }}
          width={'100%'}
        >
          <Button
            buttontype="orange"
            onClick={() =>
              navigate('/partnerio-paieska/skelbti', {
                state: { to: '/partnerio-paieska/skelbti' },
              })
            }
            width={{ xs: '100%' }}
          >
            {t('postAnnouncement')}
          </Button>
        </Box>
      </Box>
      <Box
        display={'flex'}
        maxWidth={{ xs: '100%', sm: '464px', md: '525px' }}
        width={'100%'}
      >
        <Box
          component={'img'}
          src={perfectmatch}
          alt="Perfect match"
          maxWidth={'100%'}
          maxHeight={397}
          sx={{
            borderTopRightRadius: { xs: '0', sm: '16px' },
            borderBottomRightRadius: '16px',
            borderBottomLeftRadius: { xs: '16px', sm: '0' },
            height: '397px',
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
  );
};

export default PerfectMatchCard;
