import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const NavItem = ({ page }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const p = page;

  const linkColor = theme.palette.base.white;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      sx={{
        cursor: 'pointer',
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '1px',
          backgroundColor: 'currentcolor',
          transform: 'scaleX(0)',
          transition: 'transform 0.3s ease',
        },
        '&:hover:after': {
          transform: 'scaleX(1)',
        },
      }}
    >
      <Button
        component={'a'}
        href={p.href}
        sx={{
          justifyContent: 'flex-start',
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          minWidth: 'auto',
          fontWeight: 600,
        }}
      >
        <Typography
          fontWeight={600}
          color={linkColor}
          variant={'body3'}
          padding={0}
        >
          {t(p.title)}
        </Typography>
      </Button>
    </Box>
  );
};

NavItem.propTypes = {
  id: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  colorInvert: PropTypes.bool,
};

export default NavItem;
