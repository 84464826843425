import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { TextInput } from 'components/Inputs';

const Settings = ({ settingsForm, setSettingsForm }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const {
    showEmail,
    showPhone,
    newsLettersEmail,
    newsLettersPhone,
    password,
    repeatPassword,
    scheduledEmails,
  } = settingsForm;

  const handleChange = (event) => {
    if (event.target.name === 'password') {
      setPasswordError(
        event.target.value.length < 8
          ? 'Slaptažodis turi būti bent 8 simbolių ilgio'
          : '',
      );
    }
    if (event.target.name === 'repeatPassword') {
      setRepeatPasswordError(
        password !== event.target.value ? 'Slaptažodžiai nesutampa' : '',
      );
    }
    setSettingsForm({
      ...settingsForm,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box
      component={'form'}
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '280px' }}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('changePassword')}
          </Typography>
        </Box>
        <TextInput
          id="password"
          name="password"
          type="password"
          placeholder={t('enterPassword')}
          helperText={passwordError}
          error={!!passwordError}
          handleInputChange={handleChange}
          value={password}
          inputProps={{
            autoComplete: 'new-password',
          }}
          sx={{
            margin: 0,
            maxWidth: { xs: '100%', sm: '298px', md: '304px' },
            mt: { xs: '6px', sm: '0px' },
          }}
        />
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '280px' }}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('repeatPassword')}
          </Typography>
        </Box>
        <TextInput
          id="repeatPassword"
          name="repeatPassword"
          type="password"
          placeholder={t('repeatPassword')}
          helperText={repeatPasswordError}
          error={!!repeatPasswordError}
          handleInputChange={handleChange}
          value={repeatPassword}
          inputProps={{
            autoComplete: 'new-password',
          }}
          sx={{
            margin: 0,
            maxWidth: { xs: '100%', sm: '298px', md: '304px' },
            mt: { xs: '6px', sm: '0px' },
          }}
        />
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '280px' }}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('showEmail')}
          </Typography>
        </Box>
        <RadioGroup
          row
          name="showEmail"
          value={showEmail}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('yes')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
          <FormControlLabel
            value="0"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('no')}
            sx={{
              marginLeft: '12px',
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
        </RadioGroup>
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '280px' }}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('showPhone')}
          </Typography>
        </Box>
        <RadioGroup
          row
          name="showPhone"
          value={showPhone}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('yes')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
          <FormControlLabel
            value="0"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('no')}
            sx={{
              marginLeft: '12px',
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
        </RadioGroup>
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '280px' }}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('allowScheduledEmails')}
          </Typography>
        </Box>
        <RadioGroup
          row
          name="scheduledEmails"
          value={scheduledEmails}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('yes')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
          <FormControlLabel
            value="0"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('no')}
            sx={{
              marginLeft: '12px',
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
        </RadioGroup>
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '280px' }}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('allowNewsEmail')}
          </Typography>
        </Box>
        <RadioGroup
          row
          name="newsLettersEmail"
          value={newsLettersEmail}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('yes')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
          <FormControlLabel
            value="0"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('no')}
            sx={{
              marginLeft: '12px',
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
        </RadioGroup>
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '280px' }}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('allowNewsSms')}
          </Typography>
        </Box>
        <RadioGroup
          row
          name="newsLettersPhone"
          value={newsLettersPhone}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('yes')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
          <FormControlLabel
            value="0"
            control={
              <Radio
                size="small"
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            }
            label={t('no')}
            sx={{
              marginLeft: '12px',
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                color: theme.palette.darkGrey[700],
                fontWeight: 500,
              },
            }}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default Settings;
