import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import tournament from 'assets/images/tournament-hero.png';
import bar from 'assets/icons/bar.svg';
import currency from 'assets/icons/currency.svg';
import locationIcon from 'assets/icons/location.svg';
import calendar from 'assets/icons/calendar.svg';
import Button from 'components/Button';

const Hero = ({ tournamentInfo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const { title, date, location, divisions, price, isRegOn } = tournamentInfo;

  return (
    <Box
      display={'flex'}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      alignItems={'center'}
      justifyContent={'space-between'}
      height={1}
    >
      <Box
        data-aos={isMd ? 'fade-right' : 'fade-up'}
        marginLeft={{ xs: '0px', sm: '0px', md: '112px' }}
      >
        <Box marginBottom={'4px'}>
          <Typography
            variant={isMd ? 'h4' : 'h5'}
            color={theme.palette.base.black}
            fontWeight={700}
          >
            {title}
          </Typography>
        </Box>
        <Box marginTop={'20px'} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box
              component={'img'}
              src={calendar}
              height={1}
              borderRadius={'50%'}
              alt="calendar"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('date')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {date}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'12px'}>
            <Box
              component={'img'}
              src={locationIcon}
              height={1}
              borderRadius={'50%'}
              alt="location"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('location')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {location}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'12px'}>
            <Box
              component={'img'}
              src={bar}
              height={1}
              borderRadius={'50%'}
              alt="bar"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('divisions')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {divisions}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} marginTop={'12px'}>
            <Box
              component={'img'}
              src={currency}
              height={1}
              borderRadius={'50%'}
              alt="currency"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('fee')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {price}€ / {t('person')}
            </Typography>
          </Box>
        </Box>
        {isRegOn ? (
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            marginTop={'32px'}
          >
            <Button
              buttontype="orange"
              onClick={() =>
                navigate(`/turnyras/registracija/${id}`, {
                  state: { to: `/turnyras/registracija/${id}` },
                })
              }
            >
              {t('regToTournament')}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box
        height={1}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        marginBottom={{ xs: '32px', sm: '0px', md: '0px' }}
        marginRight={{ xs: '0px', sm: '0px', md: '147px' }}
      >
        <Box
          component={'img'}
          src={tournament}
          alt="Tournament"
          width={1}
          height={1}
          sx={{
            maxWidth: { xs: '280px', sm: '450px', md: '496px' },
            maxHeight: { xs: '334px', sm: '441px', md: '629px' },
            filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
