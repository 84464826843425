import React from 'react';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { ContentView } from './components';

const TopPlayersPage = () => {
  return (
    <Main>
      <ContainerSystem
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
      >
        <ContentView />
      </ContainerSystem>
    </Main>
  );
};

export default TopPlayersPage;
