import React from 'react';
import PrivateRoute from 'utils/PrivateRoute';

import {
  OverviewPage as OverviewView,
  NotFoundCover as NotFoundCoverView,
  FindPartnerPage as FindPartnerView,
  TournamentPage as TournamentPageView,
  EditProfilePage as EditProfileView,
  TopPlayersPage as TopPlayersView,
  MarketplacePage as MarketplaceView,
  MessagesPage as MessagesView,
  ResultsPage as ResultsView,
  AdminPage as AdminView,
  SchedulerPage as SchedulerView,
  CourtsViewPage as CourtsViewView,
  RatingsPage as RatingsView,
} from 'system/views';

const routes = [
  {
    path: '/admin',
    renderer: (params = {}) => (
      <PrivateRoute>
        <AdminView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/info/:id?',
    renderer: (params = {}) => (
      <PrivateRoute>
        <OverviewView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/ivesti-rezultata',
    renderer: (params = {}) => (
      <PrivateRoute>
        <ResultsView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/planuoti-susitikima',
    renderer: (params = {}) => (
      <PrivateRoute>
        <SchedulerView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/teniso-kortai',
    renderer: (params = {}) => (
      <PrivateRoute>
        <CourtsViewView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/marketplace/:id?',
    renderer: (params = {}) => (
      <PrivateRoute>
        <MarketplaceView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/marketplace/skelbti',
    renderer: (params = {}) => (
      <PrivateRoute>
        <MarketplaceView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/marketplace/mano-produktai/:id?',
    renderer: (params = {}) => (
      <PrivateRoute>
        <MarketplaceView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/redaguoti-profili',
    renderer: (params = {}) => (
      <PrivateRoute>
        <EditProfileView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/top-zaidejai',
    renderer: (params = {}) => (
      <PrivateRoute>
        <TopPlayersView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/reitingai',
    renderer: (params = {}) => (
      <PrivateRoute>
        <RatingsView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/siusti-zinute',
    renderer: (params = {}) => (
      <PrivateRoute>
        <MessagesView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/partnerio-paieska/:slug?',
    renderer: (params = {}) => (
      <PrivateRoute>
        <FindPartnerView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/turnyras/:id?',
    renderer: (params = {}) => (
      <PrivateRoute>
        <TournamentPageView {...params} />
      </PrivateRoute>
    ),
  },
  {
    path: '/turnyras/registracija/:id?',
    renderer: (params = {}) => (
      <PrivateRoute>
        <TournamentPageView {...params} />
      </PrivateRoute>
    ),
  },
];

export default routes;
