import { useLocation } from 'react-router-dom';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { ScheduleMatch } from './components';

const SchedulerPage = () => {
  const location = useLocation();
  const state = location.state;

  return (
    <Main>
      <ContainerSystem
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
      >
        <ScheduleMatch state={state} />
      </ContainerSystem>
    </Main>
  );
};

export default SchedulerPage;
