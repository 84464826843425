import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { BlogCard } from 'components/Cards';
import { SecondaryTab } from 'components/Tabs';
import CustomPagination from 'components/Pagination';
import { SelectInput } from 'components/Inputs';
import client from 'utils/client';
import { useMediaQuery } from '@mui/material';

const Blog = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(t('tabViewAll'));
  const [open, setOpen] = useState({});
  const [page, setPage] = useState(1);
  const [blogPosts, setBlogPosts] = useState([]);
  const [filters, setFilters] = useState({
    sorting: 'newest',
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const itemsPerPage = isMd ? 6 : 3;

  useEffect(() => {
    const fetchPosts = async () => {
      const result = await client.fetch(
        `*[_type == "post"] | order(publishedAt desc) {
          title,
          slug,
          mainImage,
          publishedAt,
          excerpt,
          author->{
            name,
            image,
            bio
          },
          categories[]->{
            title
          },
          body
        }`,
      );
      setBlogPosts(result);
    };
    fetchPosts();
  }, []);

  const filteredPosts = blogPosts.filter((item) => {
    if (activeTab === t('tabViewAll')) {
      return item;
    } else if (activeTab === t('tabTrainingTips')) {
      return item.categories.some(
        (category) => category.title === 'Teniso patarimai',
      );
    } else if (activeTab === t('tabGear')) {
      return item.categories.some((category) => category.title === 'Įranga');
    }
    return item.categories.some((category) => category.title === 'Turnyrai');
  });

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const sortedPosts = [...filteredPosts].sort((a, b) => {
    if (filters.sorting === 'newest') {
      return new Date(b.publishedAt) - new Date(a.publishedAt);
    } else {
      return new Date(a.publishedAt) - new Date(b.publishedAt);
    }
  });

  const paginatedPosts = sortedPosts.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={{ xs: '64px', md: '48px' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box
          maxWidth={'976px'}
          width={'100%'}
          height={{ xs: 'auto', md: '46px' }}
          sx={{
            borderBottom: {
              xs: 'none',
              md: `1px solid ${theme.palette.darkGrey[300]}`,
            },
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={{ xs: 'column', sm: 'row' }}
            maxWidth={'auto'}
            width={'100%'}
            gap={{ xs: '12px', md: '0px' }}
          >
            <SecondaryTab
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName={t('tabViewAll')}
              page={'blog'}
            />
            <SecondaryTab
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName={t('tabTrainingTips')}
              page={'blog'}
            />
            <SecondaryTab
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName={t('tabGear')}
              page={'blog'}
            />
            <SecondaryTab
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabName={t('tabTournaments')}
              page={'blog'}
            />
          </Box>
        </Box>
        <Box
          marginLeft={{ xs: '0px', md: '32px' }}
          maxWidth={{ xs: '100%', md: '304px' }}
          marginTop={{ xs: '24px', md: '0px' }}
          width={'100%'}
        >
          <SelectInput
            name="sorting"
            id="sorting"
            placeholder="Sort by"
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            value={filters.sorting}
            options={[
              { value: 'newest', label: `${t('newest')}` },
              { value: 'oldest', label: `${t('oldest')}` },
            ]}
          />
        </Box>
      </Box>
      <Grid container spacing={{ xs: '48px', sm: '32px' }}>
        {paginatedPosts.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <BlogCard data={item} />
          </Grid>
        ))}
      </Grid>
      <Box
        marginTop="20px"
        paddingTop="20px"
        maxWidth="100%"
        display="flex"
        justifyContent="center"
        borderTop={`1px solid ${theme.palette.darkGrey[200]}`}
      >
        <CustomPagination
          page={page}
          handlePaginationChange={handlePaginationChange}
          count={Math.ceil(blogPosts.length / itemsPerPage)}
        />
      </Box>
    </Box>
  );
};

export default Blog;
