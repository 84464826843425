import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import { light } from './palette';

const getTheme = (mode) =>
  createTheme({
    palette: {
      mode: light.mode,
      alternate: light.alternate,
      orange: light.orange,
      blue: light.blue,
      green: light.green,
      lightGrey: light.lightGrey,
      darkGrey: light.darkGrey,
      success: light.success,
      error: light.error,
      base: light.base,
    },
    shadows: shadows(mode),
    typography: {
      fontFamily: '"Inter", sans-serif',
      button: {
        textTransform: 'none',
        fontWeight: 'medium',
      },
      h1: {
        fontSize: '72px',
        lineHeight: 1.25,
      },
      h2: {
        fontSize: '60px',
        lineHeight: 1.2,
      },
      h3: {
        fontSize: '48px',
        lineHeight: 1.25,
      },
      h4: {
        fontSize: '36px',
        lineHeight: 1.2,
      },
      h5: {
        fontSize: '30px',
        lineHeight: 1.25,
      },
      h6: {
        fontSize: '24px',
        lineHeight: 1.33,
      },
      body1: {
        fontSize: '20px',
        lineHeight: 1.5,
      },
      body2: {
        fontSize: '18px',
        lineHeight: 1.5,
      },
      body3: {
        fontSize: '16px',
        lineHeight: 1.5,
      },
      body4: {
        fontSize: '14px',
        lineHeight: 1.5,
      },
      body5: {
        fontSize: '12px',
        lineHeight: 1.5,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1440,
        lg: 1536,
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1300,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10,
          },
          containedSecondary: mode === 'light' ? { color: 'white' } : {},
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ED6F3A',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ED6F3A',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            fontFamily: '"Inter", sans-serif',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            '&.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select': {
              minHeight: '20px',
            },
          },
        },
      },
    },
  });

export default getTheme;
