import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { ContentView } from './components';
import { getUserRoleFromToken } from 'api';

const AdminPage = () => {
  const isAdmin = getUserRoleFromToken() === 'admin';
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate('/info');
    }
  }, [isAdmin, navigate]);

  return (
    <Main>
      <ContainerSystem
        minHeight={'100vh'}
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
      >
        <ContentView />
      </ContainerSystem>
    </Main>
  );
};

export default AdminPage;
