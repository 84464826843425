import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import Hero from './components/Hero';
import Content from './components/Content';
import Blog from './components/Blog';
import { Box } from '@mui/material';
import client from 'utils/client';

const BlogArticlePage = () => {
  const theme = useTheme();
  const { slug } = useParams();
  const [articlePost, setArticlePost] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const result = await client.fetch(
        `*[_type == "post" && slug.current == "${slug}"] {
          title,
          slug,
          mainImage,
          publishedAt,
          excerpt,
          author->{
            name,
            image,
            bio
          },
          categories[]->{
            title
          },
          body
        }`,
      );
      setArticlePost(result[0]);
    };
    fetchPosts();
  }, []);

  return (
    <Main>
      <Hero
        title={articlePost.title}
        publishedAt={articlePost.publishedAt}
        excerpt={articlePost.excerpt}
        image={articlePost.mainImage}
      />
      <Container
        sx={{
          paddingBottom: {
            xs: '32px !important',
            sm: '48px !important',
            md: '64px !important',
          },
          paddingTop: {
            xs: '0px !important',
            sm: '16px !important',
            md: '64px !important',
          },
        }}
      >
        <Content data={articlePost} />
      </Container>
      <Box
        sx={{
          background: theme.palette.darkGrey[50],
        }}
      >
        <Container>
          <Blog />
        </Container>
      </Box>
    </Main>
  );
};

export default BlogArticlePage;
