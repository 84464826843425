import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Button from 'components/Button';

const ExcelExport = ({ excelData, fileName, buttonTitle, disabled }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      maxWidth={{ xs: '100%', md: '50%' }}
      width={'100%'}
      buttontype={'white'}
      bordered={'true'}
      disabled={disabled}
      size={'small'}
      onClick={() => exportToExcel()}
    >
      {buttonTitle}
    </Button>
  );
};

export default ExcelExport;
