import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import chevronLeft from 'assets/icons/chevron-left.svg';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import getStripe from 'utils/stripe';
import { get, getUserIdFromToken, post } from 'api';
import { Levels, MenLevels, WomenLevels } from 'constants/Selections';
import { checkRegistrationPoints } from 'utils/tournamentHelper';
import { SuccessToast, ErrorToast } from 'utils/Toasts';

const Registration = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [session, setSession] = useState({});
  const [profile, setProfile] = useState({});
  const [open, setOpen] = useState({});
  const [tournaments, setTournaments] = useState([]);
  const [form, setForm] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    tournament: '',
    gender: '',
    level: '',
  });
  const formData = JSON.parse(localStorage.getItem('formData'));
  const registrationPoints = localStorage.getItem('registrationPoints');
  const [checkoutData, setCheckoutData] = useState(null);
  const [isNewCustomer, setIsNewCustomer] = useState(null);
  const [levels, setLevels] = useState({ Moterys: [], Vyrai: [] });
  const userId = getUserIdFromToken();
  console.log(tournaments);

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await get('/tournaments/tournament-levels');
        const data = await response.json();
        const levelsData = data.reduce((acc, level) => {
          if (!acc[level.gender]) {
            acc[level.gender] = [];
          }
          acc[level.gender].push(level);
          return acc;
        }, {});
        setLevels(levelsData);
      } catch (error) {
        console.error('Failed to fetch levels', error);
      }
    };

    fetchLevels();
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      const response = await get('/accounts/myprofile');
      const data = await response.json();
      setProfile(data[0]);
      setForm({
        ...form,
        userId: data[0].id,
        firstName: data[0].first_name,
        lastName: data[0].last_name,
        email: data[0].email,
      });
    };
    const getTournaments = async () => {
      const response = await get('/tournaments/reg-open');
      const data = await response.json();
      const options = data.map((tournament) => ({
        value: tournament.id,
        label: `${tournament.tournament_name} (${tournament.city})`,
      }));
      setTournaments(options);
    };

    getUserDetails();
    getTournaments();
  }, []);

  useEffect(() => {
    const getIsNewCustomer = async () => {
      const response = await get(`/tournaments/check-new-customer/${userId}`);
      const data = await response.json();
      setIsNewCustomer(data.isNewCustomer);
    };
    getIsNewCustomer();
  }, [userId]);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const getOptions = () => {
    if (!form.tournament) return [];

    const selectedTournament = tournaments.find(
      (tournament) => tournament.value === form.tournament,
    );

    if (!selectedTournament) return [];

    const city = selectedTournament.label
      .split('(')[1]
      .split(')')[0]
      .toLowerCase();

    const gender = Object.keys(levels).find((gender) =>
      form.gender.includes(gender),
    );

    if (!gender) return [];

    const options = levels[gender]
      .filter((level) => level[`${city}On`])
      .map((level) => ({ value: level.value, label: level.label }));

    return options;
  };

  const options = useMemo(() => getOptions(), [form.tournament, form.gender]);

  const handleSubmit = async () => {
    const registrationPoints = checkRegistrationPoints(form.level);
    localStorage.setItem('formData', JSON.stringify(form));
    localStorage.setItem('registrationPoints', registrationPoints);
    if (!form.level || !form.tournament || !form.gender) {
      errorToastRef.current.showError(t('fillAllFields'));
      return;
    }
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    console.warn(error.message);
  };

  function buildQuery(data, prefix) {
    var type = Object.prototype.toString.call(data).slice(8, -1).toLowerCase();
    return Object.keys(data)
      .map(function (key, index) {
        var value = data[key];
        if (type === 'array') {
          key = prefix + '[' + index + ']';
        } else if (type === 'object') {
          key = prefix ? prefix + '[' + key + ']' : key;
        }

        if (typeof value === 'object') {
          return buildQuery(value, key);
        }

        return key + '=' + encodeURIComponent(value);
      })
      .join('&');
  }

  useEffect(() => {
    async function getStripeSession() {
      const selectedTournament = tournaments.find(
        (tournament) => tournament.value === form.tournament,
      );

      if (!selectedTournament) {
        return process.env.REACT_APP_STRIPE_PRICE_ID_NORMAL;
      }

      const isMiniTournament = selectedTournament.label
        .toLowerCase()
        .includes('mini');

      const continuedPrice = isNewCustomer
        ? process.env.REACT_APP_STRIPE_PRICE_ID_NEW_CUSTOMERS
        : isMiniTournament
        ? process.env.REACT_APP_STRIPE_PRICE_ID_MINI
        : process.env.REACT_APP_STRIPE_PRICE_ID_NORMAL;

      const allowPromotionCodes = !isNewCustomer;

      let stripeRequest = await fetch(
        'https://api.stripe.com/v1/checkout/sessions',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: buildQuery({
            mode: 'payment',
            customer_creation: 'always',
            saved_payment_method_options: {
              payment_method_save: 'enabled',
            },
            success_url: `${process.env.REACT_APP_URL}/turnyras/registracija/${id}?session_id={CHECKOUT_SESSION_ID}`,
            cancel_url: `${process.env.REACT_APP_URL}/turnyras/registracija/${id}?stripeCanceled=true`,
            customer_email: profile.email,
            client_reference_id: profile.email,
            allow_promotion_codes: allowPromotionCodes,
            line_items: [
              {
                price: continuedPrice,
                quantity: 1,
              },
            ],
          }),
        },
      );
      let stripeResponse = await stripeRequest.json();
      setSession(stripeResponse);
    }
    getStripeSession();
  }, [profile, isNewCustomer, tournaments, form.tournament, id, profile.email]);

  useEffect(() => {
    if (location.search.includes('stripeCanceled=true')) {
      errorToastRef.current.showError(t('canceledPayment'));
    }
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');

    const fetchCheckoutData = async () => {
      const response = await fetch(
        `https://api.stripe.com/v1/checkout/sessions/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        },
      );
      const data = await response.json();
      setCheckoutData(data);
    };

    fetchCheckoutData();
  }, [location]);

  async function registerForTournament() {
    const response = await post('/tournaments/registration', {
      tournament_id: formData.tournament,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      tournament_gender: formData.gender,
      tournament_level: formData.level,
      doubles_partner: '',
      registrationPoints,
    });
    const data = await response.json();
    if (data.error) {
      errorToastRef.current.showError(data.error);
    } else {
      successToastRef.current.showSuccess(t('registrationSuccess'));
      setTimeout(() => {
        window.localStorage.removeItem('registrationPoints');
        navigate(`/turnyrai/${id}`, {
          state: { from: 'tournament-registration', data: formData },
        });
      }, 3000);
    }
  }

  useEffect(() => {
    if (checkoutData && checkoutData.payment_status === 'paid') {
      registerForTournament();
    }
  }, [checkoutData]);

  return (
    <Box
      sx={{
        marginBottom: '100px',
        margin: '0 auto',
        maxWidth: '416px',
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate(`/turnyras/${id}`)}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
      >
        <Box
          component={'img'}
          src={chevronLeft}
          marginRight={'12px'}
          height={1}
          alt="chevron Left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('joinTournament')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('tournamentRegSubtitle')}
      </Typography>
      <Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={t('firstName')}
            id="firstName"
            name="firstName"
            type="text"
            placeholder={t('firstName')}
            disabled
            handleInputChange={handleChange}
            value={form.firstName}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={t('lastName')}
            disabled
            id="lastName"
            name="lastName"
            type="text"
            placeholder={t('lastName')}
            handleInputChange={handleChange}
            value={form.lastName}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={t('email')}
            id="email"
            name="email"
            type="email"
            placeholder={t('email')}
            disabled
            handleInputChange={handleChange}
            value={form.email}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('tournament')}*
          </Typography>
          <SelectInput
            name="tournament"
            id="tournament"
            placeholder={t('selectTournament')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.tournament}
            options={tournaments}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('gender')}*
          </Typography>
          <SelectInput
            name="gender"
            id="gender"
            placeholder={t('selectGender')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.gender}
            options={[
              {
                value: 'Vyrai',
                label: 'Vyrai',
              },
              {
                value: 'Moterys',
                label: 'Moterys',
              },
            ]}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            NTRP ({t('level')})*
          </Typography>
          <SelectInput
            name="level"
            id="level"
            placeholder={t('selectLevel')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.level}
            options={options}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          maxWidth={{ xs: '100%', sm: '100%' }}
          width={'100%'}
          buttontype={'orange'}
          onClick={() => handleSubmit()}
        >
          {t('checkout')}
        </Button>
      </Box>
    </Box>
  );
};

export default Registration;
