import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InputAdornment, useMediaQuery } from '@mui/material';
import searchLg from 'assets/icons/search-lg.svg';
import { TextInput } from 'components/Inputs';

const Hero = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      height={'450px'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        padding: { xs: '64px 24px', md: '64px' },
        background: theme.palette.lightGrey[200],
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={'639px'}
        width={'100%'}
        alignItems={'center'}
        marginBottom={'32px'}
      >
        <Typography
          variant="h4"
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            width: '100%',
            letterSpacing: '-0.72px',
            marginBottom: { xs: '16px', md: '20px' },
            textAlign: 'center',
          }}
        >
          {t('blogTitle')}
        </Typography>
        <Typography
          variant={isMd ? 'body2' : 'body3'}
          color={theme.palette.darkGrey[600]}
          sx={{
            fontWeight: 400,
            width: '100%',
            textAlign: 'center',
          }}
        >
          {t('blogSubtitle')}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'center'}
        maxWidth={'639px'}
        width={'100%'}
      >
        <TextInput
          type="text"
          id="search"
          name="search"
          placeholder={t('search')}
          handleInputChange={() => {}}
          icon={
            <InputAdornment position="start">
              <Box component={'img'} src={searchLg} alt="search icon" />
            </InputAdornment>
          }
          sx={{
            margin: 0,
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
