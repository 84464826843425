import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { SecondaryTab } from 'components/Tabs';
import GroupTable from '../GroupTable';
import MainTable from '../MainTable';
import LoserTable from '../LoserTable';
import { SelectInput } from 'components/Inputs';
import { Typography } from '@mui/material';
import { get } from 'api';

const ContentView = () => {
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const state = location.state;
  const [activeTab, setActiveTab] = useState(t('groups'));
  const [open, setOpen] = useState({});
  const [isGrupesActive, setIsGrupesActive] = useState(0);
  const [isPagrindasActive, setIsPagrindasActive] = useState(0);
  const [isPaguodaActive, setIsPaguodaActive] = useState(0);
  const [filter, setFilter] = useState({
    gender: 'Vyrai',
  });

  useEffect(() => {
    const fetchTournamentInfo = async () => {
      const response = await get(`/tournaments/tournaments/${id}`);
      const data = await response.json();
      setIsGrupesActive(data[0].grupes);
      setIsPagrindasActive(data[0].pagrindas);
      setIsPaguodaActive(data[0].paguoda);
    };
    fetchTournamentInfo();
  }, [id]);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (state) {
      if (state.tournament_stage === 'Grupės') {
        setActiveTab(t('groups'));
      }
      if (state.tournament_stage === 'Pagrindas') {
        setActiveTab(t('main'));
      }
      if (state.tournament_stage === 'Paguoda') {
        setActiveTab(t('consolation'));
      }
      if (state.tournament_gender === 'Vyrai') {
        setFilter({ ...filter, gender: 'Vyrai' });
      } else if (state.tournament_gender === 'Moterys') {
        setFilter({ ...filter, gender: 'Moterys' });
      }
    }
  }, [state]);

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={{ xs: '100%', sm: '304px' }}
        width={'100%'}
      >
        <Typography
          variant="body4"
          fontWeight={500}
          color={theme.palette.darkGrey[700]}
        >
          {t('selectGender')}
        </Typography>
        <SelectInput
          name="gender"
          id="gender"
          placeholder={t('selectGender')}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleChange={handleChange}
          topMargin={'6px'}
          options={[
            {
              value: 'Vyrai',
              label: 'Vyrai',
            },
            {
              value: 'Moterys',
              label: 'Moterys',
            },
          ]}
          value={filter.gender}
        />
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        maxWidth={'864px'}
        sx={{
          marginTop: { xs: '32px', sm: '48px' },
          marginBottom: { xs: '32px', sm: '64px' },
          borderBottom: {
            xs: 'none',
            sm: `1px solid ${theme.palette.darkGrey[300]}`,
          },
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: '12px', sm: '0px' },
        }}
      >
        <SecondaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={t('groups')}
        />
        <SecondaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={t('main')}
        />
        <SecondaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={t('consolation')}
        />
      </Box>
      {isGrupesActive === 1 && (
        <Box sx={{ display: activeTab === t('groups') ? 'block' : 'none' }}>
          <GroupTable level={state?.tournament_level} filter={filter} />
        </Box>
      )}
      {isPagrindasActive === 1 && (
        <Box sx={{ display: activeTab === t('main') ? 'block' : 'none' }}>
          <MainTable level={state?.tournament_level} filter={filter} />
        </Box>
      )}
      {isPaguodaActive === 1 && (
        <Box
          sx={{ display: activeTab === t('consolation') ? 'block' : 'none' }}
        >
          <LoserTable level={state?.tournament_level} filter={filter} />
        </Box>
      )}
    </Box>
  );
};

export default ContentView;
