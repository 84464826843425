export const courtNames = [
  {
    courtID: 1,
    courtName: 'SEB 01',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 7,
    courtName: 'SEB 02',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 8,
    courtName: 'SEB 03',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 10,
    courtName: 'SEB 04',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 11,
    courtName: 'SEB 05',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 12,
    courtName: 'SEB 06',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 13,
    courtName: 'SEB 07',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 14,
    courtName: 'SEB 08',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 15,
    courtName: 'SEB 09',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 16,
    courtName: 'SEB 10',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 17,
    courtName: 'SEB 11',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 18,
    courtName: 'SEB 12',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 19,
    courtName: 'SEB 13',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 20,
    courtName: 'SEB 14',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 21,
    courtName: 'SEB 15',
    place: 2,
    tennisCourt: 'SEB',
  },
  {
    courtID: 135,
    courtName: 'SEB 16',
    place: 18,
    tennisCourt: 'SEB',
  },
  {
    courtID: 137,
    courtName: 'SEB 17',
    place: 18,
    tennisCourt: 'SEB',
  },
  {
    courtID: 139,
    courtName: 'SEB 18',
    place: 18,
    tennisCourt: 'SEB',
  },
  {
    courtID: 141,
    courtName: 'SEB 19',
    place: 18,
    tennisCourt: 'SEB',
  },
  {
    courtID: 143,
    courtName: 'SEB 20',
    place: 18,
    tennisCourt: 'SEB',
  },
  {
    courtID: 145,
    courtName: 'SEB 21',
    place: 18,
    tennisCourt: 'SEB',
  },
  {
    courtID: 147,
    courtName: 'Centrinis',
    place: 18,
    tennisCourt: 'SEB',
  },
  {
    courtID: 59,
    courtName: 'K1',
    place: 8,
    tennisCourt: 'SEB',
  },
  {
    courtID: 61,
    courtName: 'K2',
    place: 8,
    tennisCourt: 'SEB',
  },
  {
    courtID: 63,
    courtName: 'K3',
    place: 8,
    tennisCourt: 'SEB',
  },
  {
    courtID: 65,
    courtName: 'K4',
    place: 8,
    tennisCourt: 'SEB',
  },
  {
    courtID: 67,
    courtName: 'K5',
    place: 8,
    tennisCourt: 'SEB',
  },
  {
    courtID: 69,
    courtName: 'K6',
    place: 8,
    tennisCourt: 'SEB',
  },
  {
    courtID: 7,
    courtName: 'MegaTenis 1',
    place: 2,
    tennisCourt: 'megaTennis',
  },
  {
    courtID: 8,
    courtName: 'MegaTenis 2',
    place: 2,
    tennisCourt: 'megaTennis',
  },
  {
    courtID: 9,
    courtName: 'MegaTenis 3',
    place: 2,
    tennisCourt: 'megaTennis',
  },
  {
    courtID: 10,
    courtName: 'MegaTenis 4',
    place: 2,
    tennisCourt: 'megaTennis',
  },
  {
    courtID: 44,
    courtName: 'Bernardinai 1',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 45,
    courtName: 'Bernardinai 2',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 46,
    courtName: 'Bernardinai 3',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 47,
    courtName: 'Bernardinai 4',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 48,
    courtName: 'Bernardinai 5',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 49,
    courtName: 'Bernardinai 6',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 50,
    courtName: 'Bernardinai 7',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 51,
    courtName: 'Bernardinai 8',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 52,
    courtName: 'Bernardinai 9',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 53,
    courtName: 'Bernardinai 10',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 54,
    courtName: 'Bernardinai 11',
    place: 5,
    tennisCourt: 'SEB',
  },
  {
    courtID: 55,
    courtName: 'Bernardinai 12',
    place: 5,
    tennisCourt: 'SEB',
  },
];

export const surfaces = [
  {
    value: 'hard',
    label: 'Hard',
  },
  {
    value: 'kilimas',
    label: 'Kilimas',
  },
  {
    value: 'gruntas',
    label: 'Gruntas',
  },
  {
    value: 'sintetineZole',
    label: 'Sintetinė žolė',
  },
  {
    value: 'any',
    label: 'Nesvarbu',
  },
];

export const cities = [
  {
    value: 'vilnius',
    label: 'Vilnius',
  },
  {
    value: 'kaunas',
    label: 'Kaunas',
  },
];
