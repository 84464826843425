import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import calendar from 'assets/icons/calendar-check-02.svg';

const TournamentPlayerCard = ({
  pRez,
  first_name,
  last_name,
  winner,
  user_id,
  doubles_partner,
  partner_first_name,
  partner_last_name,
  isChallenger,
  avatar,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const rez = pRez ? pRez : '';
  const firstName = first_name !== null ? first_name : '';
  const lastName = last_name !== null ? last_name : '';
  const partnerName = partner_first_name !== null ? partner_first_name : '';
  const partnerLastName = partner_last_name !== null ? partner_last_name : '';
  const fullName = `${firstName} ${lastName}`;
  const partnerFullName = `${partnerName} ${partnerLastName}`;
  const isWinnerWithPartner = winner && winner.includes(',');
  const winners = isWinnerWithPartner && winner ? winner.split(',') : [winner];
  const isWinner = winners.includes(user_id);
  const [showTooltip, setShowTooltip] = useState({});

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.base.white,
      color: theme.palette.darkGrey[700],
      boxShadow:
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      fontSize: 12,
      fontFamily: 'Inter, sans-serif',
      padding: '8px 12px',
      borderRadius: '8px',
    },
  }));

  const splitMatchResult = (result, isChallenger) => {
    const sets = result.split(' ');
    const scores = sets.map((set) => set.split(':'));

    const playerScores = scores.map((score) => {
      const playerScore = isChallenger ? score[0] : score[1];
      return playerScore;
    });

    return playerScores;
  };

  const updatedRez = (rez) => {
    const parts = rez.split(' ');
    const regex = /\((\d+)\)/g;
    const superscriptParts = parts.map((part) =>
      part.replace(regex, '<sup>$1</sup>'),
    );
    const superscriptRezultatas = superscriptParts.join(' ');
    return superscriptRezultatas;
  };

  const playerScores = splitMatchResult(rez, isChallenger);

  return (
    <Box
      sx={{
        width: '304px',
        height: '48px',
        padding: '8px 12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: isWinner ? '8px' : '0px',
        backgroundColor: isWinner ? theme.palette.darkGrey[100] : 'transparent',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          component={'img'}
          src={
            avatar
              ? `https://backend.tenisopartneris.lt/public/images/${avatar}`
              : isWinner
              ? avatarWhite
              : avatarGrey
          }
          alt="avatar"
          sx={{
            width: '32px',
            height: '32px',
            objectFit: 'cover',
            borderRadius: '50%',
            marginRight: '8px',
          }}
        />
        <Box
          component={'a'}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              textDecorationColor: theme.palette.darkGrey[700],
            },
          }}
          href={`${window.location.origin}/info/${user_id}`}
        >
          <Typography
            variant={'body4'}
            fontWeight={600}
            textAlign={'left'}
            color={theme.palette.darkGrey[700]}
          >
            {fullName}
          </Typography>
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => navigate(`/info/${doubles_partner}`)}
        >
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            {partnerFullName}
          </Typography>
        </Box>
      </Box>
      <Box>
        {playerScores[0].includes('202') ? (
          <LightTooltip
            title={rez}
            placement={'top'}
            open={showTooltip[`${user_id}`] || false}
            onOpen={() =>
              setShowTooltip((prevState) => ({
                ...prevState,
                [`${user_id}`]: true,
              }))
            }
            onClose={() =>
              setShowTooltip((prevState) => ({
                ...prevState,
                [`${user_id}`]: false,
              }))
            }
          >
            <Box
              component={'img'}
              src={calendar}
              marginLeft={'8px'}
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setShowTooltip((prevState) => ({
                  ...prevState,
                  [`${user_id}`]: !prevState[`${user_id}`],
                }))
              }
              alt="calendar icon"
            />
          </LightTooltip>
        ) : (
          playerScores
            .filter((score) => score.trim() !== '')
            .map((score, index) => {
              return (
                <Typography
                  key={index}
                  variant={'body4'}
                  fontWeight={600}
                  color={
                    isWinner
                      ? theme.palette.darkGrey[700]
                      : theme.palette.darkGrey[600]
                  }
                  dangerouslySetInnerHTML={{
                    __html: updatedRez(score),
                  }}
                  sx={{
                    '&:not(:last-child)': {
                      marginRight: '20px',
                    },
                  }}
                />
              );
            })
        )}
      </Box>
    </Box>
  );
};

export default TournamentPlayerCard;
