import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import arrowUp from 'assets/icons/arrow-up-right.svg';
import { useMediaQuery } from '@mui/material';
import urlFor from 'utils/image';

export default function BlogCard({ data }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const { title, excerpt, mainImage, slug } = data;

  return (
    <Card
      sx={{
        maxWidth: { xs: '100%', sm: '464px', md: '416px' },
        bgcolor: 'inherit',
        boxShadow: 'none',
        borderRadius: '0px',
      }}
    >
      <Box
        component={'a'}
        href={`/naujienos/${slug.current}`}
        height={1}
        sx={{
          textDecoration: 'none',
          display: 'block',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        <Box
          component="img"
          width="100%"
          src={urlFor(mainImage)}
          alt="tennis player"
          sx={{
            borderRadius: '16px',
            objectFit: 'cover',
            objectPosition: '50% 70%',
            height: { xs: '208px', sm: '268px', md: '240px' },
          }}
        />
        <CardContent sx={{ padding: 0, marginTop: { xs: '12px', sm: '16px' } }}>
          <Box display={'flex'}>
            <Typography
              gutterBottom
              variant={isSm ? 'h6' : 'body1'}
              fontWeight={600}
              color={theme.palette.darkGrey[700]}
              sx={{
                marginBottom: { xs: '12px', sm: '12px', md: '16px' },
                marginRight: '15px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title}
            </Typography>
            <Box display={'flex'} height={1}>
              <Box component={'img'} src={arrowUp} alt="arrow up" />
            </Box>
          </Box>
          <Typography
            variant={isSm ? 'body2' : 'body3'}
            color={theme.palette.darkGrey[600]}
          >
            {excerpt}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}
