export const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  orange: {
    100: '#F5BBAE',
    200: '#F3A891',
    300: '#F19574',
    400: '#EF8257',
    500: '#ED6F3A',
    600: '#EB5C1D',
    700: '#D45019',
    800: '#BD4415',
    900: '#A63811',
  },
  blue: {
    100: '#637E8F',
    200: '#567182',
    300: '#4D6675',
    400: '#435966',
    500: '#34454F',
    600: '#233038',
    700: '#1B262F',
    800: '#131C27',
    900: '#0B121F',
  },
  green: {
    100: '#65B49C',
    200: '#52A08E',
    300: '#3F8C80',
    400: '#2C7872',
    500: '#196464',
    600: '#065056',
    700: '#043C44',
    800: '#022832',
    900: '#011420',
  },
  lightGrey: {
    100: '#F9FCFC',
    200: '#F7FCFC',
    300: '#F1FBFC',
    400: '#E7F1F2',
    500: '#DDE7E8',
    600: '#D3DDDE',
    700: '#BEC8C9',
    800: '#A9B3B4',
    900: '#949E9F',
  },
  darkGrey: {
    25: '#FAFBFC',
    50: '#F5F6F7',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  success: {
    100: '#ECFDF3',
    200: '#DBFAE6',
    300: '#A9EFC5',
    400: '#75E0A7',
    500: '#47CD89',
    600: '#17B26A',
    700: '#079455',
    800: '#067647',
    900: '#085D3A',
  },
  error: {
    100: '#FEF3F2',
    200: '#FEE4E2',
    300: '#FECDC9',
    400: '#FDA19B',
    500: '#F97066',
    600: '#F04438',
    700: '#D92D20',
    800: '#B32318',
    900: '#912018',
  },
  base: {
    black: '#25313C',
    white: '#FFFFFF',
  },
};
