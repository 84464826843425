import styled, { keyframes } from 'styled-components';
import { Box } from '@mui/system';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinningBox = styled(Box)`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #767676;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
`;

export default SpinningBox;
