import Chart from 'react-apexcharts';

const PieChart = (props) => {
  const { series, options } = props;

  return (
    <Chart
      options={options}
      type="donut"
      width="320px"
      height="170px"
      series={series}
    />
  );
};

export default PieChart;
