import React from 'react';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { PanelView, UserInfoCard } from './components';

const OverviewPage = () => {
  return (
    <Main>
      <ContainerSystem>
        <UserInfoCard />
      </ContainerSystem>
      <ContainerSystem
        paddingTop={{ xs: '0px !important' }}
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
      >
        <PanelView />
      </ContainerSystem>
    </Main>
  );
};

export default OverviewPage;
