import React from 'react';
import { TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TextInput = ({
  label,
  id,
  name,
  type,
  required,
  autoFocus,
  placeholder,
  handleInputChange,
  icon,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <>
      {label && (
        <Typography
          variant="body4"
          fontWeight={500}
          color={theme.palette.darkGrey[700]}
        >
          {label}
        </Typography>
      )}
      <TextField
        hiddenLabel
        margin="normal"
        fullWidth
        id={id}
        name={name}
        type={type}
        size="small"
        placeholder={placeholder}
        onChange={handleInputChange}
        InputProps={{
          startAdornment: icon ? icon : null,
          style: {
            height: '44px',
            fontSize: '16px',
            lineHeight: 1.5,
            color: theme.palette.darkGrey[500],
          },
        }}
        inputProps={{
          sx: {
            '&::placeholder': {
              color: theme.palette.darkGrey[500],
              opacity: 1,
              fontSize: '16px',
            },
          },
        }}
        sx={{
          height: '44px',
          margin: '6px 0 0 0',
        }}
        {...rest}
      />
    </>
  );
};

export default TextInput;
