import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import heroImg from 'assets/images/homeHero.png';
import Button from 'components/Button';

const Hero = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <Box
      display={'flex'}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      alignItems={'center'}
      justifyContent={'space-between'}
      height={1}
    >
      <Box
        data-aos={isMd ? 'fade-right' : 'fade-up'}
        maxWidth={416}
        marginLeft={{ xs: '0px', sm: '0px', md: '112px' }}
      >
        <Typography
          variant={isMd ? 'h2' : isSm ? 'h3' : 'h4'}
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          sx={{
            lineHeight: { xs: '48px', sm: '60px', md: 1.2 },
            letterSpacing: { xs: '-0.72px', sm: '-0.96px', md: '-1.2px' },
            marginBottom: { xs: '16px', sm: '20px', md: '20px' },
          }}
        >
          {t('homeHeroTitle')}
        </Typography>
        <Typography
          variant={isMd ? 'body1' : 'body2'}
          color={theme.palette.darkGrey[600]}
          sx={{ fontWeight: { xs: 400, md: 500 } }}
        >
          {t('homeHeroSubtitle')}
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'flex-start' }}
          marginTop={'32px'}
        >
          <Button
            buttontype="orange"
            onClick={() => navigate('/teniso-partneris')}
          >
            {t('findPartner')}
          </Button>
          <Box marginTop={{ xs: 2, sm: 0 }} marginLeft={{ sm: 2 }}>
            <Button
              bordered="true"
              buttontype="white"
              width={{ xs: '100%', md: 'auto' }}
              onClick={() => navigate('/turnyrai')}
            >
              {t('joinTournament')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        height={1}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        marginBottom={{ xs: '32px', sm: '0px', md: '0px' }}
        marginRight={{ xs: '0px', sm: '0px', md: '147px' }}
      >
        <Box
          component={'img'}
          src={heroImg}
          alt="Hero image"
          sx={{
            maxWidth: { xs: '280px', sm: '450px', md: '496px' },
            maxHeight: { xs: '334px', sm: '441px', md: '629px' },
            filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
