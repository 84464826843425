import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import Signup from './components/Signup';

const SignupPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          background: theme.palette.darkGrey[25],
        }}
      >
        <Container>
          <Signup />
        </Container>
      </Box>
    </Main>
  );
};

export default SignupPage;
