import React from 'react';
import { useTheme } from '@mui/material/styles';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { Blog } from './components';
import Hero from './components/Hero';

const BlogNewsPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Hero />
      <Container
        sx={{
          paddingBottom: {
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '80px !important',
            md: '96px !important',
          },
        }}
      >
        <Blog />
      </Container>
    </Main>
  );
};

export default BlogNewsPage;
