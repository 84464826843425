import { useParams } from 'react-router-dom';
import TournamentViewPage from './tournamentView/TournamentViewPage';
import TournamentsPage from './tournaments/TournamentsPage';

const TournamentPage = () => {
  const params = useParams();

  return <>{params.id ? <TournamentViewPage /> : <TournamentsPage />}</>;
};

export default TournamentPage;
