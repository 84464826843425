import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const ContainerSystem = ({ children, ...rest }) => (
  <Box
    maxWidth={{ sm: 1024, md: 1184 }}
    margin={{ xs: '0 auto', sm: '0 auto', md: 0 }}
    width={1}
    padding={{ xs: '48px 16px', sm: '64px 32px', md: '48px 64px 48px 32px' }}
    {...rest}
  >
    {children}
  </Box>
);

ContainerSystem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContainerSystem;
