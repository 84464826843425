import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import location from 'assets/icons/location.svg';
import currency from 'assets/icons/currency.svg';
import info from 'assets/icons/annotation-info-dark.svg';
import stars from 'assets/icons/stars-02.svg';
import noImageImg from 'assets/images/no-image.jpg';
import Button from 'components/Button';
import { Grid, useMediaQuery } from '@mui/material';

const ItemCard = ({ product }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageChange = (index) => {
    setSelectedImage(index);
  };

  return (
    <Box
      width={'100%'}
      maxHeight={{ xs: 'auto', sm: 500 }}
      height={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
    >
      <Box
        sx={{
          display: 'flex',
          maxWidth: { xs: '100%', sm: '528px' },
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Box
          display={'flex'}
          maxWidth={{ xs: '100%', sm: '528px' }}
          maxHeight={400}
          width={'100%'}
          marginTop={{ xs: '32px', sm: 0 }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box
            component={'img'}
            src={
              product.images.length > 0
                ? `https://backend.tenisopartneris.lt/public/shop/${product.images[selectedImage]}`
                : noImageImg
            }
            alt="product"
            maxWidth={'100%'}
            width={'100%'}
            height={{ xs: '250px', sm: '400px' }}
            sx={{
              borderRadius: '16px',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '1px', justifyContent: 'center' }}
        >
          {product.images.map((image, i) => (
            <Grid item xs={3} md={2} key={i}>
              <Box
                component={'img'}
                src={
                  image
                    ? `https://backend.tenisopartneris.lt/public/shop/${image}`
                    : noImageImg
                }
                alt="product"
                height="60px"
                width="60px"
                sx={{
                  borderRadius: '4px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
                onClick={() => handleImageChange(i)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          padding: { xs: '0px', sm: '0 32px 32px 32px' },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            letterSpacing: '-0.72px',
            marginBottom: '20px',
          }}
        >
          {product.product_title}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{ marginBottom: '12px' }}
          >
            <Box
              component={'img'}
              src={currency}
              width={20}
              height={20}
              sx={{ marginRight: '12px' }}
              alt="currency"
            />
            <Typography
              variant={'body3'}
              color={theme.palette.darkGrey[700]}
              fontWeight={600}
            >
              {t('price')}:&nbsp;
            </Typography>
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {product.product_price} Eur
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{ marginBottom: '12px' }}
          >
            <Box
              component={'img'}
              src={stars}
              width={20}
              height={20}
              sx={{ marginRight: '12px' }}
              alt="stars"
            />
            <Typography
              variant={'body3'}
              color={theme.palette.darkGrey[700]}
              fontWeight={600}
            >
              {t('condition')}:&nbsp;
            </Typography>
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {product.product_usednew
                .split('_')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                )
                .join(' ')}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{ marginBottom: '12px' }}
          >
            <Box
              component={'img'}
              src={location}
              width={20}
              height={20}
              sx={{ marginRight: '12px' }}
              alt="location"
            />
            <Typography
              variant={'body3'}
              color={theme.palette.darkGrey[700]}
              fontWeight={600}
            >
              {t('city')}:&nbsp;
            </Typography>
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {product.city}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems="flex-start"
            flexDirection={'column'}
          >
            <Box display={'flex'} alignItems={'center'} mb={'4px'}>
              <Box
                component={'img'}
                src={info}
                width={20}
                height={20}
                sx={{ marginRight: '12px' }}
                alt="info"
              />
              <Typography
                variant={'body3'}
                color={theme.palette.darkGrey[700]}
                fontWeight={600}
              >
                {t('productDescription')}:&nbsp;
              </Typography>
            </Box>
            <Typography
              variant={'body3'}
              color={theme.palette.darkGrey[600]}
              marginTop={{ xs: '4px', sm: '0px' }}
            >
              {product.description}
            </Typography>
          </Box>
        </Box>
        <Box
          marginTop={{ xs: '24px', sm: '32px' }}
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Button
            buttontype="orange"
            width={{ xs: '100%', sm: 'auto' }}
            onClick={() =>
              navigate('/siusti-zinute', {
                state: {
                  userId: product.user_id,
                  name: product.full_name,
                  location: product.city,
                  mailTo: product.email,
                  mailSubject: `Teniso Partneris - ${product.product_title}`,
                  isProduct: true,
                },
              })
            }
          >
            {t('sendMessage')}
          </Button>
          <Button
            buttontype="orange"
            width={{ xs: '100%', sm: 'auto' }}
            marginLeft={{ xs: '0px', sm: '16px' }}
            marginTop={{ xs: '8px', sm: '0px' }}
            onClick={() => {
              window.location.href = `tel:${product.phone_number}`;
            }}
          >
            {t('contactSeller')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemCard;
