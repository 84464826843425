import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import chevronLeft from 'assets/icons/chevron-left.svg';
import { PrimaryTab } from 'components/Tabs';
import { TextField } from '@mui/material';
import DropZonefile from '../Dropzone/Dropzone';
import uploadIcon from 'assets/icons/upload-cloud-02.svg';
import chevronRight from 'assets/icons/chevron-right-white.svg';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { Categories, Cities, ProductCondition } from 'constants/Selections';
import SpinningBox from 'components/SpinningBox';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import { get, post, upload } from 'api';

const MarketplacePost = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('step 1');
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState({});
  const [myProfile, setMyProfile] = useState([]);
  const [form, setForm] = useState({
    title: '',
    category: '',
    productCondition: '',
    condition: '',
    price: '',
    city: '',
    phoneNumber: '',
    email: '',
    description: '',
    fullName: '',
  });
  const successToastRef = useRef();
  const errorToastRef = useRef();

  useEffect(() => {
    const getMyProfile = async () => {
      const response = await get(`/accounts/myinfo`);
      const data = await response.json();
      setMyProfile(data);
      setForm({
        ...form,
        email: data.email,
        phoneNumber: data.phone_number,
        fullName: data.first_name + ' ' + data.last_name,
      });
    };
    getMyProfile();
  }, []);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleDataChange = (key, value) => {
    setUploadFiles(value);
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (
      !form.title ||
      !form.category ||
      !form.productCondition ||
      !form.price ||
      !form.city ||
      !form.description
    ) {
      errorToastRef.current.showError(t('fillAllFields'));
      setIsSubmitting(false);
      return;
    }
    const response = await post(`/marketplace/marketplace`, form);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      const formData = new FormData();
      uploadFiles.forEach((image) => {
        formData.append('images', image, image.name);
      });
      await upload(`/marketplace/marketplace-image/${data.data}`, formData);
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        setIsSubmitting(false);
        setForm({
          title: '',
          category: '',
          productCondition: '',
          condition: '',
          price: '',
          city: '',
          phoneNumber: '',
          email: '',
          description: '',
        });
        setUploadFiles([]);
        navigate('/marketplace');
      }, 3000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: '100px',
        margin: '0 auto',
        maxWidth: '416px',
        width: '100%',
        height: '805px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate('/marketplace')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
        maxWidth={'116px'}
        width={'100%'}
      >
        <Box
          component={'img'}
          width={'20px'}
          height={'20px'}
          src={chevronLeft}
          marginRight={'12px'}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('addProduct')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('addProductSubtitle')}
      </Typography>
      <Box
        maxWidth={'416px'}
        marginTop={'32px'}
        marginBottom={'25px'}
        display={'flex'}
        border={`1px solid ${theme.palette.darkGrey[300]}`}
        borderRadius={'8px'}
      >
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'step 1'}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'step 2'}
        />
      </Box>
      {activeTab === 'step 1' && (
        <Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
          >
            <TextInput
              label={`${t('productTitle')}*`}
              id="title"
              name="title"
              type="text"
              placeholder={t('productTitle')}
              required
              handleInputChange={handleChange}
              value={form.title}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {`${t('category')}*`}
            </Typography>
            <SelectInput
              name="category"
              id="category"
              placeholder={t('selectCategory')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              options={Categories.filter((category) => category.value !== '')}
              value={form.category}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('condition')}
            </Typography>
            <SelectInput
              name="productCondition"
              id="productCondition"
              placeholder={t('selectCondition')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              options={ProductCondition.filter(
                (condition) => condition.value !== '',
              )}
              value={form.productCondition}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <TextInput
              label={`${t('productPrice')}*`}
              id="price"
              name="price"
              type="text"
              placeholder={t('productPrice')}
              required
              handleInputChange={handleChange}
              value={form.price}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {`${t('city')}*`}
            </Typography>
            <SelectInput
              name="city"
              id="city"
              placeholder={t('selectCity')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              options={Cities.filter((city) => city.value !== '')}
              value={form.city}
            />
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype="orange"
              onClick={() => setActiveTab('step 2')}
            >
              {t('next')}{' '}
              <Box component={'img'} src={chevronRight} alt="chevron-right" />
            </Button>
          </Box>
        </Box>
      )}
      {activeTab === 'step 2' && (
        <Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
          >
            <TextInput
              label={`${t('phoneNumber')}`}
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              placeholder={t('phoneNumber')}
              autoComplete="tel"
              handleInputChange={handleChange}
              value={form.phoneNumber}
              disabled={myProfile.phone_number ? true : false}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <TextInput
              label={`${t('email')}`}
              id="email"
              name="email"
              type="email"
              placeholder={t('email')}
              autoComplete="email"
              handleInputChange={handleChange}
              value={form.email}
              disabled={myProfile.email ? true : false}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('productDescription')}*
            </Typography>
            <TextField
              hiddenLabel
              margin="normal"
              fullWidth
              id="description"
              name="description"
              multiline
              rows={3}
              placeholder={t('writeDescription')}
              onChange={handleChange}
              value={form.description}
              inputProps={{
                sx: {
                  fontSize: '16px',
                  lineHeight: 1.5,
                  color: theme.palette.darkGrey[500],
                  '&::placeholder': {
                    color: theme.palette.darkGrey[500],
                    opacity: 1,
                    fontSize: '16px',
                  },
                },
              }}
              sx={{
                margin: '6px 0 0 0',
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
              marginBottom={'6px'}
            >
              {t('photos')}
            </Typography>
            <DropZonefile
              content={
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  padding={'16px 24px'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '8px',
                      border: `1px solid ${theme.palette.darkGrey[200]}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '12px',
                    }}
                  >
                    <Box component={'img'} src={uploadIcon} alt="upload icon" />
                  </Box>
                  <Box
                    display={'flex'}
                    sx={{
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {uploadFiles &&
                      uploadFiles.map((file, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              marginBottom: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '12px',
                            }}
                          >
                            <Box
                              component={'img'}
                              src={
                                file.path
                                  ? URL.createObjectURL(file)
                                  : `https://backend.tenisopartneris.lt/public/shop/${file}`
                              }
                              alt="upload file"
                              sx={{
                                height: '44px',
                                width: '44px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                              }}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                  {uploadFiles && uploadFiles.length === 0 && (
                    <>
                      <Box display={'flex'}>
                        <Typography
                          variant="body4"
                          color={theme.palette.orange[700]}
                          fontWeight={600}
                        >
                          Click to upload&nbsp;
                        </Typography>
                        <Typography
                          variant="body4"
                          color={theme.palette.darkGrey[600]}
                        >
                          or drag and drop{' '}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body4"
                        color={theme.palette.darkGrey[600]}
                      >
                        SVG, PNG, JPG
                      </Typography>
                    </>
                  )}
                </Box>
              }
              onDrop={(acceptedFiles) =>
                handleDataChange('uploadFiles', acceptedFiles)
              }
              uploadFiles={uploadFiles}
            />
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype="white"
              bordered="true"
              onClick={() => setActiveTab('step 1')}
            >
              <Box component={'img'} src={chevronLeft} alt="chevron-left" />{' '}
              {t('previous')}
            </Button>
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype="orange"
              marginTop={{ xs: '16px', sm: '0px' }}
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
            >
              {isSubmitting ? <SpinningBox /> : t('submit')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MarketplacePost;
