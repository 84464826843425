import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import arrowUp from 'assets/icons/arrow-up-right.svg';
import calendar from 'assets/icons/calendar.svg';
import locationIcon from 'assets/icons/location.svg';
import { useMediaQuery } from '@mui/material';

export default function TournamentCard({
  id,
  type,
  tournament,
  image,
  title,
  date,
  location,
  brief,
  view,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <Card
      onClick={() => {
        if (view === 'system') {
          navigate(`/turnyras/${id}`);
        } else {
          navigate(`/turnyrai/${id}`);
        }
      }}
      sx={{
        maxWidth: { xs: '100%', sm: '467px', md: '416px' },
        bgcolor: 'inherit',
        boxShadow: 'none',
        cursor: 'pointer',
        borderRadius: '0px',
      }}
    >
      <Box>
        <Box
          width={1}
          sx={{
            position: 'relative',
            borderRadius: { xs: '13.885px', sm: '17.846px', md: '16px' },
            overflow: 'hidden',
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            height: { xs: '208.269px', sm: '267px', md: '240px' },
            alignSelf: 'stretch',
          }}
        >
          {!isSm && !isMd && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="263"
              height="209"
              viewBox="0 0 263 209"
              fill="none"
            >
              <path
                opacity="0.91"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M261.573 0H-1.14453V208.269H262.899C224.885 188.201 199.062 148.935 199.062 103.779C199.062 59.1512 224.285 20.2751 261.573 0Z"
                fill="#F5F6F7"
              />
            </svg>
          )}
          {isSm && !isMd && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="338"
              height="268"
              viewBox="0 0 338 268"
              fill="none"
            >
              <path
                opacity="0.91"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M336.212 0H-1.4668V267.692H337.916C289.056 241.898 255.865 191.428 255.865 133.39C255.865 76.0281 288.286 26.0599 336.212 0Z"
                fill="#F5F6F7"
              />
            </svg>
          )}
          {isMd && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="303"
              height="240"
              viewBox="0 0 303 240"
              fill="none"
            >
              <path
                opacity="0.91"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M301.427 0H-1.31641V240H302.956C259.15 216.874 229.393 171.625 229.393 119.59C229.393 68.1631 258.459 23.3641 301.427 0Z"
                fill="#F5F6F7"
              />
            </svg>
          )}
          <Box
            sx={{
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '4px',
              paddingBottom: '4px',
              left: { xs: '12px', sm: '19px', md: '16px' },
              top: { xs: '12px', sm: '22px', md: '16px' },
              position: 'absolute',
              backgroundColor: theme.palette.base.white,
              borderRadius: '40px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              display: 'inline-flex',
            }}
          >
            <Typography
              variant={isSm ? 'body3' : 'body4'}
              fontWeight={600}
              sx={{ color: theme.palette.orange[700] }}
            >
              {type}
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              width: '224px',
              left: { xs: '12px', sm: '19px', md: '16px' },
              top: { xs: '74px', sm: '98px', md: '88px' },
            }}
          >
            <Typography
              variant={isSm ? 'h6' : 'body1'}
              fontWeight={700}
              sx={{
                color: theme.palette.darkGrey[700],
                wordWrap: 'break-word',
                maxWidth: { xs: '172px', md: '224px' },
              }}
            >
              {tournament}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: 0, marginTop: '16px' }}>
          <Box display={'flex'}>
            <Typography
              gutterBottom
              variant={isSm ? 'h6' : 'body1'}
              fontWeight={600}
              sx={{
                marginBottom: { xs: '12px', sm: '16px' },
                marginRight: { xs: '0', sm: '15px' },
              }}
            >
              {title}
            </Typography>
            <Box
              display={'flex'}
              onClick={() => navigate(`/turnyrai/${id}`)}
              height={1}
            >
              <Box component={'img'} src={arrowUp} alt="arrow up" />
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{ marginBottom: { xs: '12px', md: '16px' } }}
          >
            <Box display={'flex'}>
              <Box component={'img'} src={calendar} alt="calendar" />
              <Typography
                variant={isSm ? 'body2' : 'body3'}
                color={theme.palette.darkGrey[700]}
                sx={{ marginLeft: '8px', fontWeight: 600 }}
              >
                {date}
              </Typography>
            </Box>
            <Box display={'flex'} marginLeft={'16px'}>
              <Box component={'img'} src={locationIcon} alt="location" />
              <Typography
                variant={isSm ? 'body2' : 'body3'}
                color={theme.palette.darkGrey[700]}
                sx={{ marginLeft: '8px', fontWeight: 600 }}
              >
                {location}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant={isSm ? 'body2' : 'body3'}
            color={theme.palette.darkGrey[600]}
          >
            {brief}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
