import * as React from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  '& > li': {
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    margin: '0 4px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    '& > button': {
      backgroundColor: 'inherit',
      border: 'none',
      cursor: 'pointer',
      fontSize: '14px',
      fontFamily: 'inherit',
      fontWeight: 500,
      color: '#475467',
    },
  },
});

export default function UsePagination({ page, handlePaginationChange, count }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { items } = usePagination({
    count,
    siblingCount: 0,
    boundaryCount: isMd ? 2 : 0,
    page,
    onChange: handlePaginationChange,
  });

  const firstItem = items.shift();
  const lastItem = items.pop();

  return (
    <nav
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {firstItem && (
        <Box
          component={'button'}
          {...firstItem}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'inherit',
            border: 'none',
            cursor: 'pointer',
            color: theme.palette.darkGrey[600],
            fontWeight: 600,
            fontFamily: 'inherit',
            fontSize: '14px',
            paddingInline: '0px !important',
            '& > svg': {
              marginRight: '8px',
            },
          }}
        >
          <ArrowLeft />
          {t('previous')}
        </Box>
      )}
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                  backgroundColor: selected ? '#e0e0e0' : undefined,
                  borderRadius: selected ? '50%' : undefined,
                  padding: selected ? '12px' : undefined,
                  width: selected ? '40px' : undefined,
                }}
                {...item}
              >
                {page}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
      {lastItem && (
        <Box
          component={'button'}
          {...lastItem}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'inherit',
            border: 'none',
            cursor: 'pointer',
            color: theme.palette.darkGrey[600],
            fontWeight: 600,
            fontFamily: 'inherit',
            fontSize: '14px',
            '& > svg': {
              marginLeft: '8px',
            },
          }}
        >
          {t('next')}
          <ArrowRight />
        </Box>
      )}
    </nav>
  );
}
