import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { Hero, Intro, Mission, Values, Vision, Impact } from './components';

const AboutPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Hero />
      <Container
        sx={{
          paddingBottom: {
            sm: '0px !important',
            md: '0px !important',
          },
          paddingTop: { sm: '80px !important', md: '78px !important' },
        }}
      >
        <Intro />
      </Container>
      <Container
        sx={{
          paddingBottom: {
            xs: '0px !important',
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            xs: '0px !important',
            sm: '48px !important',
            md: '64px !important',
          },
        }}
      >
        <Vision />
      </Container>
      <Container
        sx={{
          paddingBottom: { sm: '80px !important', md: '96px !important' },
          paddingTop: { sm: '0px !important', md: '0px !important' },
        }}
      >
        <Mission />
      </Container>
      <Box bgcolor={theme.palette.darkGrey[50]}>
        <Container
          sx={{
            paddingBottom: {
              sm: '80px !important',
              md: '96px !important',
            },
            paddingTop: {
              sm: '80px !important',
              md: '96px !important',
            },
          }}
        >
          <Values />
        </Container>
      </Box>
      <Container
        sx={{
          paddingBottom: {
            sm: '64px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '80px !important',
            md: '96px !important',
          },
        }}
      >
        <Impact />
      </Container>
    </Main>
  );
};

export default AboutPage;
