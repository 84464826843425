import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { post, get } from 'api';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import SpinningBox from 'components/SpinningBox';

const Login = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const { id } = useParams();

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (id) {
      const verifyEmail = async () => {
        const response = await get(`/verifyemail/verify-email/${id}`);
        const data = await response.json();
        if (data.status === 'SUCCESS') {
          successToastRef.current.showSuccess(data.msg);
        } else {
          errorToastRef.current.showError(data.err);
        }
      };
      verifyEmail();
    }
  }, [id]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form.email)) {
      errorToastRef.current.showError('Please enter a valid email address');
      setIsSubmitting(false);
      return;
    }
    const response = await post('/auth/login', form);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        localStorage.setItem('token', data.token);
        setIsSubmitting(false);
        let { to } = location.state || { to: { pathname: '/info' } };
        navigate(to.pathname);
      }, 2000);
    } else {
      errorToastRef.current.showError(data.msg);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '415px',
        width: '100%',
        mx: 'auto',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        sx={{
          mb: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          mb={'12px'}
        >
          {t('loginTitle')}
        </Typography>
        <Typography variant="body3" color={theme.palette.darkGrey[600]}>
          {t('loginSubtitle')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TextInput
          label={t('email')}
          id="email"
          name="email"
          type="email"
          placeholder={t('enterEmail')}
          required
          inputProps={{
            autoComplete: 'email',
          }}
          handleInputChange={handleChange}
          value={form.email || ''}
        />
        <Box sx={{ mt: '16px' }} />
        <TextInput
          label={t('password')}
          id="password"
          name="password"
          type="password"
          placeholder={t('enterPassword')}
          required
          handleInputChange={handleChange}
          value={form.password || ''}
          inputProps={{
            autoComplete: 'current-password',
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: '24px',
            mb: '24px',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/pamirsau-slaptazodi')}
        >
          <Typography
            variant="body4"
            color={theme.palette.orange[800]}
            fontWeight={600}
          >
            {t('forgotPassword?')}
          </Typography>
        </Box>
        <Button
          buttontype="orange"
          width="100%"
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : t('login')}
        </Button>
        <Box
          sx={{
            mt: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body4" color={theme.palette.darkGrey[600]}>
            {t('dontHaveAccount?')}
          </Typography>
          <Box
            onClick={() => navigate('/registracija')}
            fontWeight={600}
            color={theme.palette.orange[800]}
            display="flex"
            alignItems="center"
            marginLeft={'8px'}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="body4"
              color={theme.palette.orange[800]}
              fontWeight={600}
            >
              {t('signup')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Login;
