import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import forma from 'assets/images/forma.png';
import Button from 'components/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '800px',
  width: { xs: '90%', md: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
  borderRadius: '16px',
  p: 4,
};

const InstructionsModal = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        bordered="true"
        buttontype="white"
        width={{ xs: '100%', md: 'auto' }}
        onClick={handleOpen}
      >
        {t('instructions')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h6"
            id="modal-modal-title"
            color={theme.palette.darkGrey[700]}
            fontWeight={700}
          >
            Kaip skirti 1,2% paramą?
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            color={theme.palette.darkGrey[600]}
            marginTop={'16px'}
          >
            &#8226; Prisijunkite prie EDS sistemos:{' '}
            <a
              href="https://deklaravimas.vmi.lt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://deklaravimas.vmi.lt/
            </a>
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            color={theme.palette.darkGrey[600]}
            marginTop={'12px'}
          >
            &#8226; Spauskite <strong>Pildyti formą;</strong>
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            color={theme.palette.darkGrey[600]}
            marginTop={'12px'}
          >
            &#8226; Pasirinkite <strong>Prašymas skirti paramą;</strong>
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            color={theme.palette.darkGrey[600]}
            marginTop={'12px'}
          >
            &#8226; Spauskite ant <strong>gaublio</strong> (Pildyti formą vedlio
            principu);
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            color={theme.palette.darkGrey[600]}
            marginTop={'12px'}
          >
            &#8226; Gavėjo kodas: <strong>305710256</strong>
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            color={theme.palette.darkGrey[600]}
            marginTop={'12px'}
          >
            &#8226; Mokesčio dalies paskirtis:{' '}
            <strong>VŠĮ TENISO PARTNERIS</strong>
          </Typography>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}
          >
            <Typography
              variant="body1"
              color={theme.palette.darkGrey[700]}
              fontWeight={700}
            >
              Užpildytos formos pavyzdys:
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Box
                component={'img'}
                src={forma}
                alt="forma"
                sx={{ width: '100%', height: 'auto' }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default InstructionsModal;
