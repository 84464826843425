import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from 'locales/en/translation.json';
import translationLT from 'locales/lt/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  lt: {
    translation: translationLT,
  },
};

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'lt',
    supportedLngs: ['lt', 'en'],
  });

export default i18next;
