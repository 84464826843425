import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { Hero, PracticeCard, PriceCard, SpecsCard } from './components';

const RentPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          background: theme.palette.lightGrey[200],
          height: { xs: 'auto', sm: 450 },
        }}
      >
        <Container
          sx={{
            paddingBottom: {
              sm: '80px !important',
              md: '96px !important',
            },
            paddingTop: {
              sm: '0px !important',
              md: '0px !important',
            },
          }}
        >
          <Hero />
        </Container>
      </Box>
      <Container
        sx={{
          paddingBottom: {
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '80px !important',
            md: '96px !important',
          },
        }}
      >
        <SpecsCard />
      </Container>
      <Container
        sx={{
          paddingBottom: {
            sm: '64px !important',
            md: '96px !important',
          },
          paddingTop: {
            xs: '0px !important',
            sm: '0px !important',
            md: '0px !important',
          },
        }}
      >
        <PriceCard />
      </Container>
      <Container
        sx={{
          paddingBottom: {
            sm: '64px !important',
            md: '96px !important',
          },
          paddingTop: {
            xs: '0px !important',
            sm: '0px !important',
            md: '0px !important',
          },
        }}
      >
        <PracticeCard />
      </Container>
    </Main>
  );
};

export default RentPage;
