import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { ContentView, Hero, Registration } from '../components';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TournamentViewPage = () => {
  const { id } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const isRegistration = location.pathname.includes('registracija');

  return (
    <Main>
      {isRegistration ? (
        <ContainerSystem>
          <Registration />
        </ContainerSystem>
      ) : (
        <>
          <Box
            sx={{
              background: theme.palette.lightGrey[200],
            }}
          >
            <ContainerSystem paddingLeft={{ md: '144px !important' }}>
              <Hero />
            </ContainerSystem>
          </Box>
          <ContainerSystem
            maxWidth={{ sm: 1024, md: 1440 }}
            paddingTop={'64px !important'}
            paddingBottom={{ sm: '80px !important', md: '96px !important' }}
          >
            <ContentView />
          </ContainerSystem>
        </>
      )}
    </Main>
  );
};

export default TournamentViewPage;
