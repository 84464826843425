import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import { SecondaryTab } from 'components/Tabs';
import Vilnius from '../Vilnius';
import Kaunas from '../Kaunas/Kaunas';

const ContentView = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [activeTab, setActiveTab] = useState(t('vilnius'));

  useEffect(() => {
    if (isFirstRender && queryParams.get('city')) {
      setActiveTab(queryParams.get('city'));
    }
    setIsFirstRender(false);
  }, [queryParams, isFirstRender]);

  const successToastRef = useRef();
  const errorToastRef = useRef();

  return (
    <Box>
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={{ xs: '32px', sm: '48px' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box width={'100%'} maxWidth={600}>
          <Typography
            variant="h4"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 700, marginBottom: '16px' }}
          >
            {t('courtsTitle')}
          </Typography>
          <Typography variant="body1" color={theme.palette.darkGrey[600]}>
            {t('courtsSubtitle')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'24px'}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          maxWidth={'500px'}
          sx={{
            marginTop: { xs: '32px', sm: '48px' },
            marginBottom: { xs: '32px', sm: '64px' },
            borderBottom: {
              xs: 'none',
              sm: `1px solid ${theme.palette.darkGrey[300]}`,
            },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '12px', sm: '0px' },
          }}
        >
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('vilnius')}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('kaunas')}
          />
        </Box>
      </Box>
      <Box marginTop={'24px'}>
        {activeTab === t('vilnius') && <Vilnius city="vilnius" />}
        {activeTab === t('kaunas') && <Kaunas city="kaunas" />}
      </Box>
    </Box>
  );
};

export default ContentView;
