import { useMemo, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import { ArrowDown, ArrowUp } from 'iconsax-react';

// material-ui
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// third-party
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  DebouncedInput,
  HeaderSort,
  SelectColumnSorting,
  TablePagination,
} from 'components/react-table';
import ScrollX from 'components/ScrollX';
import { useTranslation } from 'react-i18next';

// ==============================|| REACT TABLE - LIST ||============================== //

function ReactTable({ data, columns }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [sorting, setSorting] = useState([
    { id: 'currentRanking', desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting,
      globalFilter,
    },
    enableRowSelection: false,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getRowCanExpand: () => true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: false,
  });

  let headers = [];
  columns.map(
    (columns) =>
      // @ts-ignore
      columns.accessorKey &&
      headers.push({
        label: typeof columns.header === 'string' ? columns.header : '#',
        // @ts-ignore
        key: columns.accessorKey,
      }),
  );

  return (
    <Box sx={{ width: { xs: '100%', sm: '75%' }, mt: 2 }}>
      <Stack
        direction={{ xs: 'column-reverse', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ mt: { xs: 1, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
        >
          <DebouncedInput
            value={globalFilter ?? ''}
            onFilterChange={(value) => setGlobalFilter(String(value))}
            placeholder={`${t('search')} ${t('player')}, ${t('city')}`}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          <SelectColumnSorting
            {...{
              getState: table.getState,
              getAllColumns: table.getAllColumns,
              setSorting,
            }}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: { xs: '100%', sm: 'auto' }, mt: '8px', mb: '8px' }}
      >
        <Typography
          variant="body5"
          fontWeight={600}
          color={theme.palette.darkGrey[600]}
        >
          {t('lastUpdated')}: {process.env.REACT_APP_RANKINGS_UPDATED_AT}
        </Typography>
      </Stack>
      <ScrollX>
        <Stack>
          <TableContainer>
            <Table>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => {
                      if (
                        header.column.columnDef.meta !== undefined &&
                        header.column.getCanSort()
                      ) {
                        Object.assign(header.column.columnDef.meta, {
                          className:
                            header.column.columnDef.meta.className +
                            ' cursor-pointer prevent-select',
                        });
                      }

                      return (
                        <TableCell
                          key={header.id}
                          {...header.column.columnDef.meta}
                          onClick={header.column.getToggleSortingHandler()}
                          {...(header.column.getCanSort() &&
                            header.column.columnDef.meta === undefined && {
                              className: 'cursor-pointer prevent-select',
                            })}
                          sx={{
                            width:
                              index === 0
                                ? '100px'
                                : index === 1
                                ? '100px'
                                : index === 2
                                ? 'auto'
                                : index === 3
                                ? '120px'
                                : '120px',
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Box>
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color={theme.palette.darkGrey[600]}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </Typography>
                              </Box>
                              {header.column.getCanSort() && (
                                <HeaderSort column={header.column} />
                              )}
                            </Stack>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          {...cell.column.columnDef.meta}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <>
            <Divider />
            <Box sx={{ p: 2 }}>
              <TablePagination
                {...{
                  setPageSize: table.setPageSize,
                  setPageIndex: table.setPageIndex,
                  getState: table.getState,
                  getPageCount: table.getPageCount,
                  initialPageSize: 100,
                }}
              />
            </Box>
          </>
        </Stack>
      </ScrollX>
    </Box>
  );
}
// ==============================|| CUSTOMER LIST ||============================== //

export default function RatingsTable({ data }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: t('rating'),
        accessorKey: 'currentRanking',
        meta: {
          className: 'cell-center',
        },
        cell: ({ getValue }) => (
          <Typography
            variant={'body2'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            #{getValue('currentRanking')}
          </Typography>
        ),
      },
      {
        header: t('rankingChange'),
        accessorKey: 'rankingChange',
        cell: ({ getValue }) => {
          const rankingChange = getValue('rankingChange');
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {rankingChange > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    gap: 0.25,
                  }}
                >
                  <Typography
                    variant="body3"
                    color={theme.palette.success[600]}
                    fontWeight={600}
                  >
                    +{rankingChange}
                  </Typography>
                  <ArrowUp size={14} color={theme.palette.success[600]} />
                </Box>
              ) : rankingChange < 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px',
                    gap: 0.25,
                  }}
                >
                  <Typography
                    variant="body3"
                    color={theme.palette.error[600]}
                    fontWeight={600}
                  >
                    {rankingChange}
                  </Typography>
                  <ArrowDown size={14} color={theme.palette.error[600]} />
                </Box>
              ) : (
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color={theme.palette.darkGrey[700]}
                >
                  {rankingChange}
                </Typography>
              )}
            </Box>
          );
        },
      },
      {
        header: t('player'),
        accessorKey: 'fullName',
        cell: ({ row, getValue }) => (
          <Stack
            direction="row"
            spacing={1.5}
            alignItems="center"
            onClick={() => navigate(`/info/${row.original.user_id}`)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Box
              component={'img'}
              src={
                row.original.image
                  ? `https://backend.tenisopartneris.lt/public/images/${row.original.image}`
                  : avatarGrey
              }
              height={52}
              width={52}
              borderRadius={'50%'}
              alt="Author avatar"
              sx={{
                objectFit: 'cover',
              }}
            />
            <Stack spacing={0}>
              <Typography
                variant={'body4'}
                fontWeight={600}
                color={theme.palette.darkGrey[700]}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {getValue('fullName')}
              </Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        header: t('points'),
        accessorKey: 'total_points',
        meta: {
          className: 'cell-right',
        },
        cell: ({ getValue }) => (
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            {getValue('total_points')}
          </Typography>
        ),
      },
      {
        header: t('city'),
        accessorKey: 'city',
        meta: {
          className: 'cell-center',
        },
        cell: ({ getValue }) => (
          <Typography
            variant={'body4'}
            fontWeight={500}
            color={theme.palette.darkGrey[600]}
          >
            {getValue('city')}
          </Typography>
        ),
      },
    ], // eslint-disable-next-line
    [theme],
  );

  return (
    <ReactTable
      {...{
        data: data,
        columns,
      }}
    />
  );
}
