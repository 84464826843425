import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SecondaryTab } from 'components/Tabs';
import TournamentsTop from '../Tournaments/Tournaments';
import PartnerTop from '../Partner/Partner';
import AllTop from '../All/All';

const ContentView = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(t('tournamentsTop30'));

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box marginTop={{ sm: '40px' }} marginBottom={{ xs: '48px', sm: '40px' }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          maxWidth={'864px'}
          width={'100%'}
          sx={{
            borderBottom: {
              xs: 'none',
              sm: `1px solid ${theme.palette.darkGrey[300]}`,
            },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '12px', md: '0px' },
          }}
        >
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('tournamentsTop30')}
            page={'top players'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('partnerMatchesTop30')}
            page={'top players'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('combinedTop30')}
            page={'top players'}
          />
        </Box>
      </Box>
      <Box>
        {activeTab === t('tournamentsTop30') && <TournamentsTop />}
        {activeTab === t('partnerMatchesTop30') && <PartnerTop />}
        {activeTab === t('combinedTop30') && <AllTop />}
      </Box>
    </Box>
  );
};

export default ContentView;
