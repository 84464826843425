import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import {
  Marketplace,
  MarketplaceItem,
  MarketplacePost,
  MyProducts,
} from './components';
import MyProductsEdit from './components/MyProductsEdit';

const MarketplacePage = () => {
  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();

  return (
    <Main>
      <ContainerSystem
        paddingTop={'72px !important'}
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
        minHeight={'100vh'}
      >
        {pathname === '/marketplace/skelbti' ? (
          <MarketplacePost />
        ) : pathname === '/marketplace' ? (
          <Marketplace />
        ) : pathname === '/marketplace/mano-produktai' ? (
          <MyProducts />
        ) : pathname === `/marketplace/${id}` ? (
          <MarketplaceItem />
        ) : pathname === `/marketplace/mano-produktai/${id}` ? (
          <MyProductsEdit />
        ) : null}
      </ContainerSystem>
    </Main>
  );
};

export default MarketplacePage;
