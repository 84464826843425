import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { PrimaryTab } from 'components/Tabs';
import { ParticipantCard } from 'components/Cards';

const WomensDivision = ({ womenDivision, viewData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [activeTab, setActiveTab] = useState('Super Light');
  const [participantsCount, setParticipantsCount] = useState({});

  useEffect(() => {
    if (viewData && viewData.gender === 'Moterys') {
      setActiveTab(viewData.level);
      setTimeout(() => {
        window.localStorage.removeItem('formData');
      }, 1000);
    }
  }, [viewData]);

  useEffect(() => {
    const superLight = womenDivision.filter(
      (item) => item.tournament_level === 'Super Light',
    );
    const light = womenDivision.filter(
      (item) => item.tournament_level === 'Light',
    );
    const middle = womenDivision.filter(
      (item) => item.tournament_level === 'Middle',
    );
    const power = womenDivision.filter(
      (item) => item.tournament_level === 'Power',
    );
    const hard = womenDivision.filter(
      (item) => item.tournament_level === 'Hard',
    );
    setParticipantsCount({
      superLight: superLight.length || '',
      light: light.length || '',
      middle: middle.length || '',
      power: power.length || '',
      hard: hard.length || '',
    });
  }, [womenDivision]);

  return (
    <Box width={'100%'}>
      <Typography
        variant={isMd ? 'h4' : 'h5'}
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
        marginBottom={'32px'}
      >
        {t('womensDivision')}{' '}
        {womenDivision.length > 0 && `(${womenDivision.length})`}
      </Typography>
      <Box
        maxWidth={'820px'}
        marginBottom={'48px'}
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={'space-between'}
        border={`1px solid ${theme.palette.darkGrey[300]}`}
        borderRadius={'8px'}
      >
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'Super Light'}
          participants={participantsCount.superLight}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'Light'}
          participants={participantsCount.light}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'Middle'}
          participants={participantsCount.middle}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'Power'}
          participants={participantsCount.power}
        />
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'Hard'}
          participants={participantsCount.hard}
        />
      </Box>
      <Grid container spacing={{ xs: '24px', sm: '32px' }}>
        {womenDivision
          .filter((item) => item.tournament_level === activeTab)
          .map((item) => (
            <Grid item xs={12} sm={4} md={3} key={item.user_id}>
              <ParticipantCard
                name={item.name}
                surname={item.surname}
                avatar={item.image}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default WomensDivision;
