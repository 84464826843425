import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme, styled } from '@mui/material/styles';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import chevronLeft from 'assets/icons/chevron-left.svg';
import helpCircle from 'assets/icons/help-circle.svg';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import Button from 'components/Button';
import SpinningBox from 'components/SpinningBox';
import { get, getUserFullNameFromToken, post } from 'api';
import { Cities } from 'constants/Selections';
import { SelectInput } from 'components/Inputs';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import {
  CurrentLosersPosition,
  CurrentWinnersPosition,
  NextLoserPosition,
  NextWinnerPosition,
  nextGroup,
  playoffsWinner,
  upcomingMatch,
} from 'utils/tournamentHelper';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    open={props.open}
    onOpen={props.onOpen}
    onClose={props.onClose}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.base.white,
    color: theme.palette.darkGrey[700],
    boxShadow:
      '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    fontSize: 12,
    fontFamily: 'Inter, sans-serif',
    padding: '8px 12px',
    borderRadius: '8px',
  },
}));

const TournamentResult = ({ state }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    chSet1: '',
    opSet1: '',
    chSet2: '',
    opSet2: '',
    chSet3: '',
    opSet3: '',
    tb1: '',
    tb2: '',
    tb3: '',
    chTb1: '',
    chTb2: '',
    chTb3: '',
    opTb1: '',
    opTb2: '',
    opTb3: '',
    winner: '',
    loser: '',
    techWinner: '',
    techLoser: '',
    technicalLoss: false,
    city: '',
    court: '',
    date: '',
    withoutPlay: false,
  });
  const [open, setOpen] = useState({});
  const [showTooltip, setShowTooltip] = useState({
    tieBreak: false,
    technical: false,
    withoutPlay: false,
  });
  const [tennisCourts, setTennisCourts] = useState([]);

  const technicalLossText = t('technicalLossExplanation');
  const withoutPlayText = t('withoutPlayExplanation');

  const tieBreakText = t('tieBreakExplanation');
  const matchWinner = form.techWinner ? form.techWinner : form.winner;

  const { matchData } = state;
  const {
    challenger_full_name,
    challenger_id,
    challenger_image,
    opponent_full_name,
    opponent_id,
    opponent_image,
    city,
    match_city,
    match_court,
    match_date,
    match_time,
    tournament_stage,
  } = matchData;

  useEffect(() => {
    const fetchCourts = async () => {
      const response = await get('/matches/courts');
      const data = await response.json();
      setTennisCourts(data);
    };
    fetchCourts();
  }, []);

  useEffect(() => {
    let chScore = 0;
    let opScore = 0;

    if (parseInt(form.chSet1) > parseInt(form.opSet1)) chScore++;
    else if (parseInt(form.chSet1) < parseInt(form.opSet1)) opScore++;

    if (parseInt(form.chSet2) > parseInt(form.opSet2)) chScore++;
    else if (parseInt(form.chSet2) < parseInt(form.opSet2)) opScore++;

    if (parseInt(form.chSet3) > parseInt(form.opSet3)) chScore++;
    else if (parseInt(form.chSet3) < parseInt(form.opSet3)) opScore++;

    if (chScore > opScore && chScore >= 2)
      setForm({ ...form, winner: challenger_id, loser: opponent_id });
    else if (chScore < opScore && opScore >= 2)
      setForm({ ...form, winner: opponent_id, loser: challenger_id });
    else if (form.technicalLoss && chScore === 1 && opScore === 0)
      setForm({ ...form, winner: opponent_id, loser: challenger_id });
    else if (form.technicalLoss && chScore === 0 && opScore === 1)
      setForm({ ...form, winner: challenger_id, loser: opponent_id });
    else setForm({ ...form, winner: '' });
  }, [
    form.chSet1,
    form.opSet1,
    form.chSet2,
    form.opSet2,
    form.chSet3,
    form.opSet3,
    form.techWinner,
    form.techLoser,
    form.technicalLoss,
    challenger_id,
    opponent_id,
  ]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let validatedValue = value;

    if (['chSet1', 'opSet1', 'chSet2', 'opSet2'].includes(name)) {
      validatedValue =
        value === '' || isNaN(value)
          ? ''
          : Math.min(Math.max(parseInt(value), 0), 7).toString();
    }

    setForm((prev) => ({ ...prev, [name]: validatedValue }));
  };

  const handleTieBreak = (score, id) => {
    switch (id) {
      case 'tb1':
        if (form.chSet1 === '6' && form.opSet1 === '7') {
          if (parseInt(score) <= 5) {
            setForm({ ...form, chTb1: score, opTb1: '7', tb1: score });
          } else {
            const newScore = parseInt(score) + 2;
            setForm({
              ...form,
              chTb1: score,
              opTb1: newScore.toString(),
              tb1: score,
            });
          }
        } else if (form.chSet1 === '7' && form.opSet1 === '6') {
          if (parseInt(score) <= 5) {
            setForm({ ...form, chTb1: '7', opTb1: score, tb1: score });
          } else {
            const newScore = parseInt(score) + 2;
            setForm({ ...form, chTb1: newScore, opTb1: score, tb1: score });
          }
        }
        break;
      case 'tb2':
        if (form.chSet2 === '6' && form.opSet2 === '7') {
          if (parseInt(score) <= 5) {
            setForm({ ...form, chTb2: score, opTb2: '7', tb2: score });
          } else {
            const newScore = parseInt(score) + 2;
            setForm({
              ...form,
              chTb2: score,
              opTb2: newScore.toString(),
              tb2: score,
            });
          }
        } else if (form.chSet2 === '7' && form.opSet2 === '6') {
          if (parseInt(score) <= 5) {
            setForm({ ...form, chTb2: '7', opTb2: score, tb2: score });
          } else {
            const newScore = parseInt(score) + 2;
            setForm({
              ...form,
              chTb2: newScore.toString(),
              opTb2: score,
              tb2: score,
            });
          }
        }
        break;
      case 'tb3':
        if (form.chSet3 === '6' && form.opSet3 === '7') {
          if (parseInt(score) <= 5) {
            setForm({ ...form, chTb3: score, opTb3: '7', tb3: score });
          } else {
            const newScore = parseInt(score) + 2;
            setForm({
              ...form,
              chTb3: score,
              opTb3: newScore.toString(),
              tb3: score,
            });
          }
        } else if (form.chSet3 === '7' && form.opSet3 === '6') {
          if (parseInt(score) <= 5) {
            setForm({ ...form, chTb3: '7', opTb3: score, tb3: score });
          } else {
            const newScore = parseInt(score) + 2;
            setForm({
              ...form,
              chTb3: newScore.toString(),
              opTb3: score,
              tb3: score,
            });
          }
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isNumber = /^\d+$/.test(value);

    if ((isNumber && value.length <= 2) || value === '') {
      setForm({ ...form, [name]: value });
    }
  };

  const handleSelectChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    const isTieBreakSet1 =
      (form.chSet1 === '6' && form.opSet1 === '7') ||
      (form.chSet1 === '7' && form.opSet1 === '6');
    const isTieBreakSet2 =
      (form.chSet2 === '6' && form.opSet2 === '7') ||
      (form.chSet2 === '7' && form.opSet2 === '6');

    if (!form.withoutPlay) {
      if ((isTieBreakSet1 && !form.tb1) || (isTieBreakSet2 && !form.tb2)) {
        errorToastRef.current.showError(t('enterTBPoints'));
        setIsSubmitting(false);
        return;
      } else if (!form.chSet1 && !form.opSet1) {
        errorToastRef.current.showError(t('enter1Set'));
        setIsSubmitting(false);
        return;
      } else if (!form.chSet2 && !form.opSet2 && !form.technicalLoss) {
        errorToastRef.current.showError(t('enter2Set'));
        setIsSubmitting(false);
        return;
      } else if (
        (!form.city || !form.court || !form.date) &&
        !form.technicalLoss
      ) {
        errorToastRef.current.showError(t('selectCityDateCourt'));
        setIsSubmitting(false);
        return;
      }
    }

    const {
      opponent_id,
      challenger_id,
      ch_doubles_partner_id,
      op_doubles_partner_id,
      match_id,
      tournament_type,
      tournament_id,
      match_number,
      tournament_stage,
      challenger_position,
      opponent_position,
      tournament_level,
      tournament_gender,
      P1,
      P2,
      P3,
      P4,
    } = matchData;

    const getWinnersCurrentPosition = CurrentWinnersPosition(
      matchWinner,
      challenger_id,
      challenger_position,
      opponent_id,
      opponent_position,
    );
    const getLosersCurrentPosition = CurrentLosersPosition(
      form.techLoser ? form.techLoser : form.loser,
      challenger_id,
      challenger_position,
      opponent_id,
      opponent_position,
    );
    const getNextWinnerPosition = NextWinnerPosition(getWinnersCurrentPosition);
    const getNextLoserPosition =
      match_number === 'P5_rez'
        ? NextLoserPosition(getLosersCurrentPosition)
        : '';
    const tempWinner = matchWinner;
    const tempLoser =
      match_number === 'P5_rez'
        ? tempWinner === challenger_id
          ? opponent_id
          : challenger_id
        : '';
    const paguodaPagrindasWinner = playoffsWinner(match_number);
    const nextMatchNumber = upcomingMatch(match_number);
    const nextGroupNumber = nextGroup(match_number);
    let chResult = '';
    let opResult = '';

    for (let i = 1; i <= 3; i++) {
      const chTb = form[`chTb${i}`] ? `(${form[`chTb${i}`]})` : '';
      const opTb = form[`opTb${i}`] ? `(${form[`opTb${i}`]})` : '';
      const chSet =
        form[`chSet${i}`] && form[`opSet${i}`]
          ? `${form[`chSet${i}`]}${chTb}:${form[`opSet${i}`]}${opTb}`
          : '';
      const opSet =
        form[`chSet${i}`] && form[`opSet${i}`]
          ? `${form[`opSet${i}`]}${opTb}:${form[`chSet${i}`]}${chTb}`
          : '';

      chResult = `${chResult} ${chSet}`.trim();
      opResult = `${opResult} ${opSet}`.trim();
    }
    const data = {
      match_id,
      challenger_id,
      opponent_id,
      ch_set1: form.chSet1,
      op_set1: form.opSet1,
      ch_set2: form.chSet2,
      op_set2: form.opSet2,
      ch_set3: form.chSet3,
      op_set3: form.opSet3,
      ch_tb1: form.chTb1,
      op_tb1: form.opTb1,
      ch_tb2: form.chTb2,
      op_tb2: form.opTb2,
      ch_tb3: form.chTb3,
      op_tb3: form.opTb3,
      match_winner: matchWinner,
      loserID: form.techLoser ? form.techLoser : form.loser,
      match_type: tournament_type,
      ch_dp_partner_id: ch_doubles_partner_id,
      op_dp_partner_id: op_doubles_partner_id,
      match_date: form.date,
      city: form.city,
      place: tennisCourts.find((court) => court.id === form.court)?.court_name,
      tournament_id,
      match_number,
      tournament_stage,
      tournament_level,
      tournament_gender,
      chResult: form.withoutPlay ? 'B/Ž' : chResult,
      opResult: form.withoutPlay ? 'B/Ž' : opResult,
      paguodaPagrindasWinner,
      getNextWinnerPosition,
      getNextLoserPosition,
      nextMatchNumber,
      nextGroupNumber,
      loser: tempLoser,
      P1,
      P2,
      P3,
      P4,
    };

    try {
      const res = await get(`/tournaments/checkmatch/${match_id}`);
      const check = await res.json();
      if (check.length > 0) {
        errorToastRef.current.showError(t('matchAlreadyExists'));
        setIsSubmitting(false);
        return;
      }
      const response = await post('/tournaments/challengervsopponent', data);
      const result = await response.json();
      if (result.status === 'SUCCESS') {
        successToastRef.current.showSuccess(result.msg);
        setTimeout(() => {
          setForm({
            chSet1: '',
            opSet1: '',
            chSet2: '',
            opSet2: '',
            chSet3: '',
            opSet3: '',
            tb1: '',
            tb2: '',
            tb3: '',
            chTb1: '',
            chTb2: '',
            chTb3: '',
            opTb1: '',
            opTb2: '',
            opTb3: '',
            winner: '',
            loser: '',
            techWinner: '',
            techLoser: '',
            technicalLoss: false,
            city: '',
            court: '',
            date: '',
          });
          setIsSubmitting(false);
          navigate('/info');
        }, 1500);
      } else {
        errorToastRef.current.showError(result.err);
        setIsSubmitting(false);
      }
    } catch (err) {
      errorToastRef.current.showError(err);
      setIsSubmitting(false);
    }
  };

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  useEffect(() => {
    if (tennisCourts.length > 0) {
      setForm({
        ...form,
        challenger_id,
        opponent_id,
        city: match_city || city,
        court:
          tennisCourts.find((court) => court.court_name === match_court)?.id ||
          '',
        date:
          (match_date && moment(match_date).format('YYYY-MM-DD')) ||
          moment().format('YYYY-MM-DD'),
        time: match_time || '',
      });
    }
  }, [matchData, tennisCourts]);

  return (
    <Box
      sx={{
        marginBottom: '100px',
        maxWidth: tournament_stage === 'Grupės' ? '1009px' : '864px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate('/info')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
      >
        <Box
          component={'img'}
          src={chevronLeft}
          marginRight={'12px'}
          height={1}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('enterScore')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('enterScoreText')}
      </Typography>
      <Box
        sx={{
          marginTop: '32px',
          maxWidth: tournament_stage === 'Grupės' ? '1009px' : '864px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '276px',
            display: 'flex',
            border: `1px solid ${theme.palette.darkGrey[300]}`,
            borderRadius: '12px',
            overflow: 'hidden',
            overflowX: { xs: 'auto', sm: 'hidden' },
            boxSizing: 'border-box',
            '::-webkit-scrollbar': {
              height: '4px',
              borderRadius: '8px',
              background: theme.palette.darkGrey[200],
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.darkGrey[300],
              borderRadius: '8px',
              height: '4px',
            },
          }}
        >
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '278px',
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                {t('player')}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: '28px',
                display: 'flex',
                width: '278px',
                alignItems: 'center',
                borderRadius: '0px 8px 8px 0px',
                padding: '8px 12px',
                backgroundColor:
                  matchWinner === challenger_id
                    ? theme.palette.darkGrey[100]
                    : 'transparent',
                borderTop:
                  matchWinner === challenger_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderRight:
                  matchWinner === challenger_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderBottom:
                  matchWinner === challenger_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                boxSizing: 'border-box',
                height: '48px',
              }}
            >
              <Box
                component={'img'}
                src={
                  challenger_image
                    ? `https://backend.tenisopartneris.lt/public/images/${challenger_image}`
                    : matchWinner === challenger_id
                    ? avatarWhite
                    : avatarGrey
                }
                alt="avatar"
                sx={{
                  width: '32px',
                  height: '32px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  marginRight: '6px',
                }}
              />
              <Typography
                variant="body4"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 600 }}
              >
                {challenger_full_name}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: '28px',
                display: 'flex',
                width: '278px',
                alignItems: 'center',
                borderRadius: '0px 8px 8px 0px',
                padding: '8px 12px',
                backgroundColor:
                  matchWinner === opponent_id
                    ? theme.palette.darkGrey[100]
                    : 'transparent',
                borderTop:
                  matchWinner === opponent_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderRight:
                  matchWinner === opponent_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderBottom:
                  matchWinner === opponent_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                boxSizing: 'border-box',
                height: '48px',
              }}
            >
              <Box
                component={'img'}
                src={
                  opponent_image
                    ? `https://backend.tenisopartneris.lt/public/images/${opponent_image}`
                    : matchWinner === opponent_id
                    ? avatarWhite
                    : avatarGrey
                }
                alt="avatar"
                sx={{
                  width: '32px',
                  height: '32px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  marginRight: '6px',
                }}
              />
              <Typography
                variant="body4"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 600 }}
              >
                {opponent_full_name}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '0px 0px 0px 12px',
                marginTop: '28px',
                width: '278px',
                borderTop: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700, display: 'flex', alignItems: 'center' }}
              >
                Tie break
                <LightTooltip
                  title={tieBreakText}
                  placement={'top-start'}
                  open={showTooltip.tieBreak}
                  onOpen={() =>
                    setShowTooltip({ ...showTooltip, tieBreak: true })
                  }
                  onClose={() =>
                    setShowTooltip({ ...showTooltip, tieBreak: false })
                  }
                >
                  <Box
                    component={'img'}
                    src={helpCircle}
                    marginLeft={'8px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setShowTooltip({
                        ...showTooltip,
                        tieBreak: !showTooltip.tieBreak,
                      })
                    }
                    alt="help-circle"
                  />
                </LightTooltip>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '120px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                1 set
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="chSet1"
                id="chSet1"
                onChange={handleInputChange}
                value={form.chSet1}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="opSet1"
                id="opSet1"
                onChange={handleInputChange}
                value={form.opSet1}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                borderTop: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Box
                component={'input'}
                name="tb1"
                id="tb1"
                onChange={(e) => handleTieBreak(e.target.value, 'tb1')}
                value={form.tb1}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '120px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                2 set
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="chSet2"
                id="chSet2"
                onChange={handleInputChange}
                value={form.chSet2}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="opSet2"
                id="opSet2"
                onChange={handleInputChange}
                value={form.opSet2}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                borderTop: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Box
                component={'input'}
                name="tb2"
                id="tb2"
                onChange={(e) => handleTieBreak(e.target.value, 'tb2')}
                value={form.tb2}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '120px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                3 set
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="chSet3"
                id="chSet3"
                onChange={handleChange}
                value={form.chSet3}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="opSet3"
                id="opSet3"
                onChange={handleChange}
                value={form.opSet3}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                borderTop: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Box
                component={'input'}
                name="tb3"
                id="tb3"
                onChange={(e) => handleTieBreak(e.target.value, 'tb3')}
                value={form.tb3}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '230px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{
                  fontWeight: 700,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('technicalLoss')}
                <LightTooltip
                  title={technicalLossText}
                  placement={'top-start'}
                  open={showTooltip.technical}
                  onOpen={() =>
                    setShowTooltip({ ...showTooltip, technical: true })
                  }
                  onClose={() =>
                    setShowTooltip({ ...showTooltip, technical: false })
                  }
                >
                  <Box
                    component={'img'}
                    src={helpCircle}
                    marginLeft={'8px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setShowTooltip({
                        ...showTooltip,
                        technical: !showTooltip.technical,
                      })
                    }
                    alt="help-circle"
                  />
                </LightTooltip>
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Checkbox
                onChange={(e) =>
                  setForm({
                    ...form,
                    techWinner: e.target.checked ? opponent_id : '',
                    techLoser: e.target.checked ? challenger_id : '',
                    technicalLoss: e.target.checked,
                    chSet1: e.target.checked ? '0' : '',
                    opSet1: e.target.checked ? '1' : '',
                    chSet2: '',
                    opSet2: '',
                    chSet3: '',
                    opSet3: '',
                    chTb1: '',
                    chTb2: '',
                    chTb3: '',
                    opTb1: '',
                    opTb2: '',
                    opTb3: '',
                    tb1: '',
                    tb2: '',
                    tb3: '',
                    winner: '',
                    loser: '',
                    withoutPlay: false,
                  })
                }
                checked={form.techWinner === opponent_id}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  width: '20px',
                  height: '20px',
                  color: 'transparent',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.grey[300]}`,
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Checkbox
                onChange={(e) =>
                  setForm({
                    ...form,
                    techWinner: e.target.checked ? challenger_id : '',
                    techLoser: e.target.checked ? opponent_id : '',
                    technicalLoss: e.target.checked,
                    chSet1: e.target.checked ? '1' : '',
                    opSet1: e.target.checked ? '0' : '',
                    chSet2: '',
                    opSet2: '',
                    chSet3: '',
                    opSet3: '',
                    chTb1: '',
                    chTb2: '',
                    chTb3: '',
                    opTb1: '',
                    opTb2: '',
                    opTb3: '',
                    tb1: '',
                    tb2: '',
                    tb3: '',
                    winner: '',
                    loser: '',
                    withoutPlay: false,
                  })
                }
                checked={form.techWinner === challenger_id}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  width: '20px',
                  height: '20px',
                  color: 'transparent',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.grey[300]}`,
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                borderTop: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            />
          </Box>
          {tournament_stage === 'Grupės' && (
            <Box>
              <Box
                sx={{
                  backgroundColor: theme.palette.darkGrey[100],
                  padding: '12px 16px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '145px',
                  borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
                }}
              >
                <Typography
                  variant="body3"
                  color={theme.palette.darkGrey[700]}
                  sx={{
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {t('withoutPlay')}
                  <LightTooltip
                    title={withoutPlayText}
                    placement={'top-start'}
                    open={showTooltip.withoutPlay}
                    onOpen={() =>
                      setShowTooltip({ ...showTooltip, withoutPlay: true })
                    }
                    onClose={() =>
                      setShowTooltip({ ...showTooltip, withoutPlay: false })
                    }
                  >
                    <Box
                      component={'img'}
                      src={helpCircle}
                      marginLeft={'8px'}
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        setShowTooltip({
                          ...showTooltip,
                          withoutPlay: !showTooltip.withoutPlay,
                        })
                      }
                      alt="help-circle"
                    />
                  </LightTooltip>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '28px',
                  height: '48px',
                }}
              >
                <Checkbox
                  onChange={(e) =>
                    setForm({
                      ...form,
                      techWinner: '',
                      techLoser: '',
                      technicalLoss: '',
                      chSet1: e.target.checked ? 'B/Ž' : '',
                      opSet1: e.target.checked ? 'B/Ž' : '',
                      chSet2: '',
                      opSet2: '',
                      chSet3: '',
                      opSet3: '',
                      chTb1: '',
                      chTb2: '',
                      chTb3: '',
                      opTb1: '',
                      opTb2: '',
                      opTb3: '',
                      tb1: '',
                      tb2: '',
                      tb3: '',
                      winner: '',
                      loser: '',
                      withoutPlay: e.target.checked,
                    })
                  }
                  checked={
                    form.withoutPlay && !form.techLoser && !form.techWinner
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: 'transparent',
                    borderRadius: '4px',
                    border: `1px solid ${theme.palette.grey[300]}`,
                    '&.Mui-checked': {
                      color: theme.palette.orange[600],
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '28px',
                  height: '48px',
                }}
              >
                <Checkbox
                  onChange={(e) =>
                    setForm({
                      ...form,
                      techWinner: '',
                      techLoser: '',
                      technicalLoss: '',
                      chSet1: e.target.checked ? 'B/Ž' : '',
                      opSet1: e.target.checked ? 'B/Ž' : '',
                      chSet2: '',
                      opSet2: '',
                      chSet3: '',
                      opSet3: '',
                      chTb1: '',
                      chTb2: '',
                      chTb3: '',
                      opTb1: '',
                      opTb2: '',
                      opTb3: '',
                      tb1: '',
                      tb2: '',
                      tb3: '',
                      winner: '',
                      loser: '',
                      withoutPlay: e.target.checked,
                    })
                  }
                  checked={
                    form.withoutPlay && !form.techLoser && !form.techWinner
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: 'transparent',
                    borderRadius: '4px',
                    border: `1px solid ${theme.palette.grey[300]}`,
                    '&.Mui-checked': {
                      color: theme.palette.orange[600],
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  backgroundColor: theme.palette.darkGrey[100],
                  padding: '12px 16px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '28px',
                  borderTop: `1px solid ${theme.palette.darkGrey[300]}`,
                }}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            marginTop: '28px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'100%'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('selectCity')}*
            </Typography>
            <SelectInput
              name="city"
              id="city"
              placeholder={t('selectCity')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleSelectChange}
              topMargin={'6px'}
              options={Cities.filter((city) => city.value !== '')}
              value={form.city}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'416px'}
            marginTop={{ xs: '20px', sm: '0px' }}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('selectDate')}*
            </Typography>
            <DatePicker
              slotProps={{
                textField: {
                  sx: {
                    width: '100%',
                    height: '48px',
                    borderRadius: '8px',
                    marginTop: '6px',
                  },
                  size: 'small',
                  inputProps: {
                    style: {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[500],
                      display: 'flex',
                      height: '28.75px',
                    },
                  },
                  InputLabelProps: {
                    style: {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[500],
                    },
                  },
                },
              }}
              format="YYYY-MM-DD"
              onChange={(newValue) =>
                setForm({
                  ...form,
                  date: moment(newValue).format('YYYY-MM-DD'),
                })
              }
              value={form.date ? moment(form.date) : null}
              maxDate={moment().endOf('day')}
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: { xs: '20px', sm: '28px' },
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'416px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('tennisCourt')}*
            </Typography>
            <SelectInput
              name="court"
              id="court"
              placeholder={t('selectCourt')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleSelectChange}
              topMargin={'6px'}
              value={form.court}
              options={tennisCourts
                .filter((court) => court.city === form.city)
                .map((court) => {
                  return { value: court.id, label: court.court_name };
                })}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              maxWidth: '416px',
              width: '100%',
              marginTop: { xs: '32px', sm: '24px' },
            }}
          >
            <Button
              width="100%"
              buttontype={'orange'}
              onClick={(e) => handleSubmit(e)}
              disabled={isSubmitting}
            >
              {isSubmitting ? <SpinningBox /> : t('submitResult')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TournamentResult;
