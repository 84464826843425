import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ResultsCard, TournamentPartnerCard } from 'components/Cards';
import { get, getUserIdFromToken } from 'api';
import Button from 'components/Button';
import CustomPagination from 'components/Pagination';
import CryptoJS from 'crypto-js';
import { Levels } from 'constants/Selections';

const MyTournamentMatches = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [pastMatches, setPastMatches] = useState([]);
  const [hideButtons, setHideButtons] = useState(false);
  const [isGroupStage, setIsGroupStage] = useState(false);
  const [isPagrindasStage, setIsPagrindasStage] = useState(false);
  const [isPaguodaStage, setIsPaguodaStage] = useState(false);
  const [tournamentLevels, setTournamentLevels] = useState([]);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [loading, setLoading] = useState(true);

  const itemsPerPage = isMd ? 6 : 3;

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const isChallenger = (item) => {
    const challengerId = id ? parseInt(id) : getUserIdFromToken();
    return item.challenger_id === challengerId;
  };

  const fetchUpcomingMatches = async (userId) => {
    try {
      const endpoint = userId
        ? `/tournaments/user-active-tournament-matches/${userId}`
        : '/tournaments/my-active-tournament-matches';
      const response = await get(endpoint);
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      const groupStage = decryptedData.filter(
        (match) => match.tournament_stage === 'Grupės',
      );
      const pagrindasStage = decryptedData.filter(
        (match) => match.tournament_stage === 'Pagrindas',
      );
      const paguodaStage = decryptedData.filter(
        (match) => match.tournament_stage === 'Paguoda',
      );
      const hideWaitingMatches = decryptedData.filter(
        (match) => match.challenger_id !== 0 && match.opponent_id !== 0,
      );
      setIsGroupStage(groupStage.length > 1);
      setIsPagrindasStage(pagrindasStage.length > 1);
      setIsPaguodaStage(paguodaStage.length > 1);
      const filteredLevels = decryptedData
        .filter((match) => {
          const desiredLevels = Levels.map((level) => level.value);
          return desiredLevels.includes(match.tournament_level);
        })
        .map((match) => ({
          tournament_level: match.tournament_level,
          tournament_gender: match.tournament_gender,
          tournament_id: match.tournament_id,
          tournament_stage: match.tournament_stage,
          city: match.city,
        }));

      const distinctFilteredLevels = filteredLevels.filter(
        (level, index, self) =>
          index ===
          self.findIndex(
            (l) =>
              l.tournament_level === level.tournament_level &&
              l.tournament_gender === level.tournament_gender &&
              l.tournament_id === level.tournament_id &&
              l.tournament_stage === level.tournament_stage &&
              l.city === level.city,
          ),
      );

      setTournamentLevels(distinctFilteredLevels);
      setUpcomingMatches(decryptedData);
    } catch (error) {
      console.error('Failed to fetch matches:', error);
    }
  };

  const fetchPastMatches = async (userId) => {
    try {
      const endpoint = userId
        ? `/statistics/tournamentmatches/${userId}`
        : '/tournaments/tournamentmatches';
      const response = await get(endpoint);
      const data = await response.json();
      const playedMatches = data.filter((match) => match.match_winner !== null);
      setPastMatches(playedMatches);
    } catch (error) {
      console.error('Failed to fetch matches:', error);
    }
  };

  useEffect(() => {
    if (id) {
      setHideButtons(true);
      Promise.all([fetchPastMatches(id), fetchUpcomingMatches(id)]).then(() =>
        setLoading(false),
      );
    } else {
      setHideButtons(false);
      Promise.all([fetchPastMatches(null), fetchUpcomingMatches(null)]).then(
        () => setLoading(false),
      );
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const paginatedMatches = pastMatches.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  return (
    <Box>
      <Box marginBottom={'48px'}>
        <Box>
          {upcomingMatches.length > 0 && !hideButtons && (
            <>
              <Typography
                variant={'h4'}
                fontWeight={700}
                color={theme.palette.darkGrey[700]}
                marginBottom={'32px'}
              >
                {t('myTournamentBrackets')}
              </Typography>
              <Box
                marginBottom={'32px'}
                display={'flex'}
                flexDirection={{ xs: 'column', sm: 'row' }}
              >
                {tournamentLevels.map((level, index) => {
                  return (
                    <Button
                      key={index}
                      buttontype="white"
                      bordered="true"
                      width={{ xs: '100%', sm: 'auto' }}
                      marginRight={{ xs: '0px', sm: '32px' }}
                      marginBottom={{ xs: '20px', sm: 0 }}
                      onClick={() =>
                        navigate(`/turnyras/${level.tournament_id}`, {
                          state: {
                            tournament_stage: level.tournament_stage,
                            tournament_level: level.tournament_level,
                            tournament_gender: level.tournament_gender,
                          },
                        })
                      }
                    >
                      <Typography
                        variant={'body3'}
                        color={theme.palette.darkGrey[600]}
                        fontWeight={600}
                      >
                        {t('tournamentBrackets')} - {level.tournament_level} (
                        {level.city})
                      </Typography>
                    </Button>
                  );
                })}
              </Box>
            </>
          )}
        </Box>
        <Typography
          variant={'h4'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={'32px'}
        >
          {t('upcomingTournamentMatches')}
        </Typography>
        <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }}>
          {isGroupStage && !hideButtons && (
            <Box
              marginBottom={{ xs: '20px', sm: '32px' }}
              marginRight={{ sm: '32px' }}
            >
              <Button
                buttontype="white"
                bordered="true"
                width={{ xs: '100%', sm: 'auto' }}
                onClick={() =>
                  navigate('/siusti-zinute', {
                    state: {
                      isTournament: true,
                      isMulti: true,
                      upcomingMatches,
                    },
                  })
                }
              >
                <Typography
                  variant={'body3'}
                  color={theme.palette.darkGrey[600]}
                  fontWeight={600}
                >
                  {t('sendBulkMessage')}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        {upcomingMatches.length === 0 ? (
          <Box
            sx={{
              maxWidth: { xs: '100%', sm: '464px', md: '416px' },
              width: '100%',
            }}
          >
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {t('noUpcomingTournamentMatches')}
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={'32px'}>
            {upcomingMatches.map((item, i) => {
              const isCH = isChallenger(item);
              return (
                <Grid item xs={12} sm={6} md={5} key={i}>
                  <TournamentPartnerCard
                    matchId={item.id}
                    avatar={isCH ? item.opponent_image : item.challenger_image}
                    name={
                      isCH ? item.opponent_full_name : item.challenger_full_name
                    }
                    ntrp={isCH ? item.opponent_ntrp : item.challenger_ntrp}
                    playUntil={item.play_until}
                    location={item.city}
                    tournament={item.tournament_name}
                    phoneNumber={
                      isCH ? item.opponent_phone : item.challenger_phone
                    }
                    userId={isCH ? item.opponent_id : item.challenger_id}
                    mailTo={isCH ? item.opponent_email : item.challenger_email}
                    hideButtons={hideButtons}
                    matchData={item}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <Box>
        <Typography
          variant={'h4'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={'32px'}
        >
          {t('pastTournamentMatches')}
        </Typography>
        {pastMatches.length === 0 ? (
          <Box
            sx={{
              maxWidth: { xs: '100%', sm: '464px', md: '416px' },
              width: '100%',
            }}
          >
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {t('noPastTournamentMatches')}
            </Typography>
          </Box>
        ) : (
          <>
            <Grid container spacing={'32px'}>
              {pastMatches.length > 0 &&
                paginatedMatches.map((item, i) => {
                  return (
                    <Grid item xs={12} sm={4} key={i}>
                      <ResultsCard data={item} page={'tournaments'} />
                    </Grid>
                  );
                })}
            </Grid>
            <Box
              marginTop="20px"
              paddingTop="20px"
              maxWidth="100%"
              display="flex"
              justifyContent="center"
              borderTop={`1px solid ${theme.palette.darkGrey[200]}`}
            >
              <CustomPagination
                page={page}
                handlePaginationChange={handlePaginationChange}
                count={Math.ceil(pastMatches.length / itemsPerPage)}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default MyTournamentMatches;
