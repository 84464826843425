import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PlayerCard from './PlayerCard';
import { tournamentStageMap } from 'constants/Tournament';

const ResultsCard = ({ data, page }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    type,
    match_date: date,
    challenger_id,
    opponent_id,
    ch_set1,
    ch_set2,
    ch_set3,
    op_set1,
    op_set2,
    op_set3,
    ch_tb1,
    op_tb1,
    ch_tb2,
    op_tb2,
    ch_tb3,
    op_tb3,
    ch_doubles_partner_id,
    op_doubles_partner_id,
    ch_doubles_partner_name,
    op_doubles_partner_name,
    challenger_full_name,
    opponent_full_name,
    match_winner,
    opponent_image,
    challenger_image,
    place,
    tournament_stage,
    tournament_gender,
    match_number,
    tournament_name,
    tournament_id,
    tournament_level,
  } = data;

  const tournamentStage = (tournamentStage, matchNumber) => {
    const stage = tournamentStageMap[tournamentStage];
    if (typeof stage === 'string') {
      return stage;
    } else {
      return stage ? stage[matchNumber] : undefined;
    }
  };

  const isWinner = (id) => {
    let winner;
    if (match_winner === null) {
      return false;
    } else if (typeof match_winner === 'number') {
      winner = [match_winner];
    } else {
      winner = match_winner.split(',').map(Number);
    }

    return winner.includes(id);
  };

  const players = [
    {
      userId: challenger_id,
      fullName: challenger_full_name,
      avatar: challenger_image,
      partnerId: ch_doubles_partner_id,
      partnerFullName: ch_doubles_partner_name,
      set1: ch_set1,
      set2: ch_set2,
      set3: ch_set3,
      tb1: ch_tb1,
      tb2: ch_tb2,
      tb3: ch_tb3,
      isWinner: isWinner(challenger_id),
    },
    {
      userId: opponent_id,
      fullName: opponent_full_name,
      avatar: opponent_image,
      partnerId: op_doubles_partner_id,
      partnerFullName: op_doubles_partner_name,
      set1: op_set1,
      set2: op_set2,
      set3: op_set3,
      tb1: op_tb1,
      tb2: op_tb2,
      tb3: op_tb3,
      isWinner: isWinner(opponent_id),
    },
  ];

  return (
    <Box
      sx={{
        width: { xs: '100%', md: '340px' },
        height: { xs: '100%', md: '200px' },
        backgroundColor: theme.palette.base.white,
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body5"
          fontWeight={600}
          color={theme.palette.darkGrey[400]}
        >
          {page === 'tournaments'
            ? tournamentStage(tournament_stage, match_number)
            : type}
        </Typography>
        <Typography
          variant="body5"
          fontWeight={600}
          color={theme.palette.darkGrey[400]}
        >
          {date}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: '12px',
          flexDirection: 'column',
        }}
      >
        {players.map((player, index) => (
          <PlayerCard key={index} {...player} />
        ))}
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: page === 'tournaments' ? 'pointer' : 'default',
        }}
        onClick={() => {
          if (page === 'tournaments') {
            navigate(`/turnyras/${tournament_id}`, {
              state: {
                tournament_id,
                tournament_name,
                tournament_level,
                tournament_stage,
                tournament_gender,
              },
            });
          }
        }}
      >
        <Typography
          variant="body5"
          fontWeight={600}
          color={theme.palette.darkGrey[400]}
        >
          {page === 'tournaments' ? tournament_name : place}
        </Typography>
        <Typography
          variant="body5"
          fontWeight={600}
          color={theme.palette.darkGrey[400]}
        >
          {page === 'tournaments' ? tournament_level : ''}
        </Typography>
      </Box>
    </Box>
  );
};

export default ResultsCard;
