import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

// ==============================|| TABLE PAGINATION ||============================== //

export default function TablePagination({
  getPageCount,
  setPageIndex,
  setPageSize,
  getState,
  initialPageSize,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  let options = [25, 50, 100, 250];

  if (initialPageSize) {
    options = [...options, initialPageSize]
      .filter(
        (item, index) => [...options, initialPageSize].indexOf(item) === index,
      )
      .sort(function (a, b) {
        return a - b;
      });
  }

  // eslint-disable-next-line
  useEffect(() => setPageSize(initialPageSize || 100), []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePagination = (event, value) => {
    setPageIndex(value - 1);
  };

  const handleChange = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Grid
      spacing={1}
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: 'auto' }}
    >
      <Grid item xs={12} sm={5}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          width={1}
          justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="body3"
              color={theme.palette.primary[600]}
              fontWeight={500}
            >
              {t('showing')}:
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={getState().pagination.pageSize}
                onChange={handleChange}
                size="small"
                sx={{
                  '& .MuiSelect-select': { py: 0.75, px: 1.25 },
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="body3"
              color={theme.palette.primary[600]}
              fontWeight={500}
            >
              {t('goTo')}
            </Typography>
            <TextField
              size="small"
              type="number"
              value={getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                setPageIndex(page);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  py: 0.75,
                  px: 1.25,
                  width: 36,
                },
              }}
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ mt: { xs: 1, sm: 0 } }}>
        <Pagination
          sx={{ '& .MuiPaginationItem-root': { my: 0.5 } }}
          count={getPageCount()}
          page={getState().pagination.pageIndex + 1}
          onChange={handleChangePagination}
          color={theme.palette.primary[500]}
          variant="body3"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
}

TablePagination.propTypes = {
  getPageCount: PropTypes.func,
  setPageIndex: PropTypes.func,
  setPageSize: PropTypes.func,
  getState: PropTypes.func,
  initialPageSize: PropTypes.number,
};
