import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');
  let { to } = location.state || { to: { pathname: '/prisijungimas' } };

  useEffect(() => {
    if (!token) {
      navigate('/prisijungimas', { state: { to: location } });
    }
  }, [navigate, token]);

  if (!token) {
    return null;
  }

  return <>{children}</>;
};

export default PrivateRoute;
