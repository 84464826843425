import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import chevronRight from 'assets/icons/chevron-right.svg';
import { PartnerCard } from 'components/Cards';
import searchLg from 'assets/icons/search-lg.svg';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { SelectInput, TextInput } from 'components/Inputs';
import { get } from 'api';
import { NTRP, Cities } from 'constants/Selections';

const Partners = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const [open, setOpen] = useState({});
  const [matches, setMatches] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [filters, setFilters] = useState({
    city: '',
    ntrp: '',
    search: '',
  });

  useEffect(() => {
    const fetchMatches = async () => {
      const response = await get('/matches/matches');
      const data = await response.json();
      setMatches(data);
    };
    fetchMatches();
  }, []);

  useEffect(() => {
    const filtered = matches.filter((match) => {
      const city = filters.city
        ? match.city.toLowerCase().includes(filters.city.toLowerCase())
        : true;
      const ntrp = filters.ntrp
        ? match.ntrp.toLowerCase().includes(filters.ntrp.toLowerCase())
        : true;
      const search = filters.search
        ? match.first_name
            .toLowerCase()
            .includes(filters.search.toLowerCase()) ||
          match.ntrp.toLowerCase().includes(filters.search.toLowerCase()) ||
          match.city.toLowerCase().includes(filters.search.toLowerCase()) ||
          match.type.toLowerCase().includes(filters.search.toLowerCase()) ||
          match.match_date
            .toLowerCase()
            .includes(filters.search.toLowerCase()) ||
          match.place.toLowerCase().includes(filters.search.toLowerCase())
        : true;
      return city && ntrp && search;
    });
    setFilteredMatches(filtered);
  }, [filters, matches]);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={'48px'}
      >
        <Box>
          <Typography
            variant={isMd ? 'h4' : 'h5'}
            color={theme.palette.darkGrey[700]}
            sx={{
              fontWeight: 700,
              marginBottom: { xs: '16px', sm: '20px', md: '20px' },
            }}
          >
            {t('homePartnersTitle')}
          </Typography>
          <Typography
            variant={isMd ? 'body1' : isSm ? 'body2' : 'body3'}
            color={theme.palette.darkGrey[600]}
          >
            {t('homePartnersSubtitle')}
          </Typography>
        </Box>
        <Box
          display={{ xs: 'none', sm: 'flex' }}
          alignItems={'center'}
          sx={{
            cursor: 'pointer',
            marginBottom: '32px',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'translateX(3px)',
            },
          }}
          onClick={() => navigate('/teniso-partneris')}
        >
          <Typography
            variant="body3"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 600 }}
          >
            {t('seeAll')}
          </Typography>
          <Box component={'img'} src={chevronRight} alt="chevron right" />
        </Box>
      </Box>
      <Box
        marginBottom={{ xs: '48px', sm: '40px', md: '64px' }}
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        gap={{ xs: '24px', sm: '0px', md: '0px' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '298px', md: '416px' }}
          width={'100%'}
        >
          <TextInput
            label={t('search')}
            type="text"
            id="search"
            name="search"
            placeholder={t('search')}
            handleInputChange={handleChange}
            value={filters.search}
            icon={
              <InputAdornment position="start">
                <Box component={'img'} src={searchLg} alt="search icon" />
              </InputAdornment>
            }
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '298px', md: '416px' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectCity')}
          </Typography>
          <SelectInput
            name="city"
            id="city"
            placeholder={t('selectCity')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={Cities}
            value={filters.city}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '298px', md: '416px' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectLevel')}
          </Typography>
          <SelectInput
            name="ntrp"
            id="ntrp"
            placeholder={t('selectLevel')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={NTRP}
            value={filters.ntrp}
          />
        </Box>
      </Box>
      <Box>
        {filteredMatches.length === 0 && (
          <Typography
            variant="body1"
            color={theme.palette.darkGrey[600]}
            sx={{ marginBottom: '32px' }}
          >
            {t('noPartnerMatches')}
          </Typography>
        )}
      </Box>
      <Grid container spacing={'32px'}>
        {filteredMatches.slice(0, 3).map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <PartnerCard
              avatar={item.image}
              name={item.first_name}
              ntrp={item.ntrp}
              date={item.match_date}
              time={item.match_time}
              location={item.city}
              tennisCourt={item.place}
              details={item.comment}
              userId={item.user_id}
              matchData={item}
              type={item.type}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        display={{ xs: 'flex', sm: 'none' }}
        marginTop={'48px'}
        alignItems={'center'}
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate('/teniso-partneris')}
      >
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('seeAll')}
        </Typography>
        <Box component={'img'} src={chevronRight} alt="chevron right" />
      </Box>
    </Box>
  );
};

export default Partners;
