import React from 'react';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { PrivacyPolicy } from './components';

const PrivacyPage = () => {
  return (
    <Main>
      <Container>
        <PrivacyPolicy />
      </Container>
    </Main>
  );
};

export default PrivacyPage;
