import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ruta from 'assets/sponsors/ruta.png';
import snackbox from 'assets/sponsors/snackbox.png';
import olearys from 'assets/sponsors/olearys.png';
import gym from 'assets/sponsors/gym.png';
import neodenta from 'assets/sponsors/neodenta.png';
import sun365 from 'assets/sponsors/sun365.png';
import redbull from 'assets/sponsors/redbull.png';
import birzuduona from 'assets/sponsors/birzuduona.png';
import smarthit from 'assets/sponsors/smarthit.png';
import perskindol from 'assets/sponsors/perskindol.png';
import avene from 'assets/sponsors/avene.png';
import slicesushi from 'assets/sponsors/slicesushi.png';
import skinlovers from 'assets/sponsors/skinlovers.png';
import verde from 'assets/sponsors/verde.png';
import equador from 'assets/sponsors/equador.png';
import orivego from 'assets/sponsors/orivego.png';
import goldentree from 'assets/sponsors/goldentree.png';
import biyoma from 'assets/sponsors/biyoma.png';
import harkontrol from 'assets/sponsors/harkontrol.png';
import kdesign from 'assets/sponsors/kdesign.png';
import tenniszone from 'assets/sponsors/tenniszone.png';
import livol from 'assets/sponsors/livol.png';
import raido from 'assets/sponsors/raido.png';
import burtai from 'assets/sponsors/burtai.png';
import akvile from 'assets/sponsors/akvile.png';
import odapro from 'assets/sponsors/odapro.png';
import oxyklinika from 'assets/sponsors/oxyklinika.png';
import apivita from 'assets/sponsors/apivita.png';
import sushilovers from 'assets/sponsors/sushilovers.png';
import meikithabit from 'assets/sponsors/meikithabit.webp';
import iconfit from 'assets/sponsors/iconfit.png';

const sponsors = [
  // { name: 'ruta', image: ruta, url: 'https://www.facebook.com/ruta1913' },
  {
    name: 'snackbox',
    image: snackbox,
    url: 'https://www.snackbox.lt',
  },
  {
    name: 'olearys',
    image: olearys,
    url: 'https://lt.olearys.club',
  },
  { name: 'gym', image: gym, url: 'https://gymplius.lt' },
  // {
  //   name: 'neodenta',
  //   image: neodenta,
  //   url: 'https://www.facebook.com/Neodenta',
  // },
  // {
  //   name: 'sun365',
  //   image: sun365,
  //   url: 'https://www.facebook.com/SUN365.today',
  // },
  { name: 'redbull', image: redbull, url: 'https://www.facebook.com/RedBull' },
  {
    name: 'birzuduona',
    image: birzuduona,
    url: 'https://www.birzuduona.lt',
  },
  {
    name: 'smarthit',
    image: smarthit,
    url: 'https://smarthit.lt',
  },
  // {
  //   name: 'perskindol',
  //   image: perskindol,
  //   url: 'https://stipresnisuzskausma.lt',
  // },
  // {
  //   name: 'avene',
  //   image: avene,
  //   url: 'https://www.eau-thermale-avene.lt/',
  // },
  // {
  //   name: 'slicesushi',
  //   image: slicesushi,
  //   url: 'https://www.facebook.com/slicesushivilnius',
  // },
  // {
  //   name: 'skinlovers',
  //   image: skinlovers,
  //   url: 'https://www.facebook.com/yourskinlovers',
  // },
  {
    name: 'verde',
    image: verde,
    url: 'https://www.pizzaverde.lt',
  },
  {
    name: 'equador',
    image: equador,
    url: 'https://www.equador.lt',
  },
  // { name: 'orivego', image: orivego, url: 'https://www.facebook.com/Orivego' },
  {
    name: 'goldentree',
    image: goldentree,
    url: 'https://goldentree.lt',
  },
  {
    name: 'biyoma',
    image: biyoma,
    url: 'https://www.facebook.com/biyomavitaminai',
  },
  // {
  //   name: 'harkontrol',
  //   image: harkontrol,
  //   url: 'https://www.facebook.com/harkontrolllietuva',
  // },
  { name: 'kdesign', image: kdesign, url: 'https://k-design.io' },
  {
    name: 'tenniszone',
    image: tenniszone,
    url: 'https://tennis-zone.lt',
  },
  // {
  //   name: 'livol',
  //   image: livol,
  //   url: 'https://www.livol.lt',
  // },
  {
    name: 'raido',
    image: raido,
    url: 'https://raido.lt',
  },
  // {
  //   name: 'burtai',
  //   image: burtai,
  //   url: 'https://burtai.eu',
  // },
  {
    name: 'akvile',
    image: akvile,
    url: 'https://akvile.lt',
  },
  // {
  //   name: 'odapro',
  //   image: odapro,
  //   url: 'https://odapro.eu',
  // },
  {
    name: 'oxyklinika',
    image: oxyklinika,
    url: 'https://oxyklinika.lt',
  },
  {
    name: 'apivita',
    image: apivita,
    url: 'https://www.facebook.com/apivitaLT',
  },
  {
    name: 'sushilovers',
    image: sushilovers,
    url: 'https://sushilovers.lt/',
  },
  {
    name: 'meikithabit',
    image: meikithabit,
    url: 'https://meikithabit.lt/',
  },
  {
    name: 'iconfit',
    image: iconfit,
    url: 'https://iconfit.lt/',
  },
];

const SponsorsCard = ({ page }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      width={'100%'}
      maxHeight={{ xs: '100%', md: 'auto' }}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={'1060px'}
        width={'100%'}
        alignItems={'center'}
        marginBottom={'32px'}
      >
        <Typography
          variant={page === 'sponsorship' ? 'h3' : 'h4'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            width: '100%',
            letterSpacing: '-0.72px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          {t('sponsorsCardTitle')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={'32px'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {sponsors.map((sponsor) => (
          <Grid item xs={3} sm={1.2} key={sponsor.name}>
            <Box
              sx={{
                height: { xs: '60px', sm: '93px' },
                width: { xs: '60px', sm: '93px' },
              }}
            >
              <Box
                width="100%"
                height="100%"
                component={'img'}
                src={sponsor.image}
                alt={sponsor.name}
                onClick={() => window.open(sponsor.url, '_blank')}
                sx={{
                  objectFit: 'contain',
                  cursor: 'pointer',
                  borderRadius: '9.643px',
                  boxShadow:
                    '0px 4.822px 7.233px -2.411px rgba(16, 24, 40, 0.03), 0px 14.465px 19.287px -4.822px rgba(16, 24, 40, 0.08)',
                  '&:hover': {
                    filter: 'grayscale(75%)',
                  },
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SponsorsCard;
