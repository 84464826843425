import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import annotationInfo from 'assets/icons/annotation-info.svg';
import messageChat from 'assets/icons/message-chat-circle.svg';
import messageHeart from 'assets/icons/message-heart-square.svg';
import fbDark from 'assets/icons/fbDark2.svg';
import igDark from 'assets/icons/igDark.svg';
import youtubeDark from 'assets/icons/youtubeDark.svg';

const ContactCards = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const companyInfo = [
    {
      title: t('credentials'),
      text1: 'Všį Teniso partneris',
      text2: 'Įm. kodas 305710256',
      icon: annotationInfo,
    },
    {
      title: t('Kontaktai'),
      text1: '+370 600 00000',
      text2: 'info@tenisopartneris.lt',
      icon: messageChat,
    },
  ];

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      {companyInfo.map((item, i) => (
        <Box
          key={i}
          sx={{
            maxWidth: { xs: '100%', sm: '298px', md: '416px' },
            width: '100%',
            maxHeight: '246px',
            height: '246px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '32px',
            backgroundColor: theme.palette.darkGrey[50],
            borderRadius: '16px',
            '&:not(:first-of-type)': {
              marginTop: { xs: '32px', sm: 0 },
            },
          }}
        >
          <Box
            sx={{
              width: '48px',
              height: '48px',
              backgroundColor: theme.palette.orange[600],
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '32px',
              padding: '12px',
            }}
          >
            <Box
              component={'img'}
              alt="icon"
              src={item.icon}
              height={1}
              width={1}
              maxWidth={24}
              maxHeight={24}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              variant={'body1'}
              sx={{
                fontWeight: 600,
                color: theme.palette.darkGrey[700],
                marginBottom: '8px',
              }}
            >
              {item.title}
            </Typography>
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {item.text1}
            </Typography>
            {item.text2.includes('@') ? (
              <a
                href={`mailto:${item.text2}`}
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  variant={'body3'}
                  sx={{
                    color: theme.palette.darkGrey[600],
                    marginTop: '12px',
                    textDecoration: 'none',
                  }}
                >
                  {item.text2}
                </Typography>
              </a>
            ) : (
              <Typography
                variant={'body3'}
                sx={{ color: theme.palette.darkGrey[600], marginTop: '12px' }}
              >
                {item.text2}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '298px', md: '416px' },
          width: '100%',
          maxHeight: '246px',
          height: '246px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '32px',
          backgroundColor: theme.palette.darkGrey[50],
          borderRadius: '16px',
          marginTop: { xs: '32px', sm: 0 },
        }}
      >
        <Box
          sx={{
            width: '48px',
            height: '48px',
            backgroundColor: theme.palette.orange[600],
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '32px',
            padding: '12px',
          }}
        >
          <Box
            component={'img'}
            src={messageHeart}
            alt="icon"
            height={1}
            width={1}
            maxWidth={24}
            maxHeight={24}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            variant={'body1'}
            color={theme.palette.darkGrey[700]}
            sx={{
              fontWeight: 600,
              marginBottom: '16px',
            }}
          >
            {t('followUs')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              component={'a'}
              href={'https://www.facebook.com/groups/tenisopartneris'}
              target="_blank"
              width={'24px'}
              height={'24px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box component={'img'} src={fbDark} alt="facebook" />
            </Box>
            <Box
              component={'a'}
              href={'https://www.instagram.com/tenisopartneris/'}
              target="_blank"
              width={'24px'}
              height={'24px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              marginLeft={'20px'}
            >
              <Box component={'img'} src={igDark} alt="instagram" />
            </Box>
            <Box
              component={'a'}
              href={'https://www.youtube.com/@tenisopartneris1446'}
              target="_blank"
              width={'24px'}
              height={'24px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              marginLeft={'20px'}
            >
              <Box component={'img'} src={youtubeDark} alt="youtube" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactCards;
