import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';

const Testinis = ({ tournamentInfo }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const {
    earlyBird,
    earlyBirdDate,
    registrationDate,
    startDate,
    finalsDate,
    groupsDate,
    eliminationsDate,
  } = tournamentInfo;

  return (
    <Box maxWidth={'752px'} width={'100%'} margin={'0 auto'}>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('tournamentRegStructure')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; {t('registrationDeadline')}:</strong>{' '}
            {moment(registrationDate).format('LL')} 19:00.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; {t('groupStage')}:</strong>{' '}
            {moment(startDate).format('LL')} - {moment(groupsDate).format('LL')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; {t('eliminationStage')}:</strong>{' '}
            {moment(groupsDate).format('LL')} -{' '}
            {moment(eliminationsDate).format('LL')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; {t('championshipDay')}:</strong> iki{' '}
            {moment(finalsDate).format('LL')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('registrationRequirements')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={700}
            color={theme.palette.darkGrey[600]}
          >
            {t('registrationRequirementsText')}:
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            1. {t('regTextOne')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            2. {t('regTextTwo')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            3. {t('regTextThree')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            4. {t('regTextFour')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('skillLevels')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; Super Light:</strong> {t('superlightLevel')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; Light:</strong> {t('lightLevel')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; Middle:</strong> {t('middleLevel')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; Power:</strong> {t('powerLevel')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; Hard:</strong> {t('hardLevel')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('tournamentStructure')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; {t('groupStage')}:</strong>{' '}
            {t('groupMiniStageText')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; {t('knockoutStage')}:</strong>{' '}
            {t('knockoutMiniStageText')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>&#8226; {t('consolationStage')}:</strong>{' '}
            {t('consolationMiniStageText')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('groupStageMatches')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('groupMiniStageMatchesText')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('groupStageMatchesTextTwo')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('groupStageMatchesTextThree')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('groupStageMatchesTextFour')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={700}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextFive')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextSix')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextSeven')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextEight')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={600}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextNine')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextTen')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextEleven')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={600}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextTwelve')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextThirteen')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextFourteen')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextFiftheen')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('groupStageMatchesTextSixteen')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('eliminationStageMatches')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('eliminationStageMatchesText')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            marginTop={'8px'}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('eliminationStageMatchesTextTwo')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('eliminationStageMatchesTextThree')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('consolidationStageMatches')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('consolidationMiniStageMatchesText')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            marginTop={'8px'}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('consolidationStageMatchesTextTwo')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            marginTop={'8px'}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('consolidationStageMatchesTextThree')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('finalsAwards')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('finalsAwardsTextTwo')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('finalsAwardsTextThree')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('finalsAwardsTextTestinisFour')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('matchScheduling')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('matchSchedulingText')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            marginTop={'8px'}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('matchSchedulingSummer')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            marginTop={'8px'}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('matchSchedulingWinter')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            marginTop={'8px'}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('matchSchedulingOther')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('matchSchedulingTextTwo')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('matchSchedulingTextThree')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            &#8226; {t('matchSchedulingTextFour')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('matchDelaying')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'body3'}
            fontWeight={600}
            color={theme.palette.darkGrey[600]}
          >
            {t('matchDelayingText')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('matchDelayingTextTwo')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('matchDelayingTextThree')}
          </Typography>

          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
            marginTop={'8px'}
          >
            {t('matchDelayingTextFour')}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('technicalWinLoss')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('technicalWinLossText')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('technicalWinLossTextTwo')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('technicalWinLossTextThree')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('technicalWinLossTextFour')}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8xp', md: '12px' }}
        >
          {t('contactInformations')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('contactInformationsText')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('contactInformationsTextTwo')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('contactInformationsTextThree')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            &#8226; {t('contactInformationsTextFour')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Testinis;
