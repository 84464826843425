import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import vision from 'assets/images/vision.png';
import visionMobile from 'assets/images/vision-mobile.jpg';

const VisionCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      sx={{
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
        maxHeight: { xs: 'auto', sm: 330, md: 400 },
      }}
    >
      <Box
        display={'flex'}
        maxWidth={{ xs: '100%', sm: '528px' }}
        width={'100%'}
        sx={{
          height: { xs: 'auto', sm: 330, md: 400 },
        }}
      >
        <Box
          component={'img'}
          src={isMd ? vision : visionMobile}
          alt="vision image"
          height={'100%'}
          maxWidth={'100%'}
          sx={{
            borderBottomRightRadius: { xs: '16px', sm: 0 },
            borderBottomLeftRadius: { xs: '16px', sm: '16px', md: 0 },
            borderTopLeftRadius: { xs: '0px', sm: '16px' },
            borderTopRightRadius: { xs: '0px', sm: 0, md: 0 },
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: { xs: '64px 24px 48px 24px', md: '0 64px' },
        }}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            letterSpacing: '-0.72px',
            marginBottom: { xs: '16px', md: '20px' },
          }}
        >
          {t('visionTitle')}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.darkGrey[600]}
          sx={{
            fontWeight: 400,
            textAlign: 'left',
            maxWidth: '607px',
            width: '100%',
          }}
        >
          {t('visionText')}
        </Typography>
      </Box>
    </Box>
  );
};

export default VisionCard;
