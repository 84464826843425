import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import partnership from 'assets/images/partnership.png';
import Button from 'components/Button';

const PartnershipCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      maxHeight={{ xs: 'auto', md: 400 }}
      height={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      sx={{
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
      }}
    >
      <Box
        display={'flex'}
        maxWidth={{ xs: '100%', sm: '525px' }}
        width={'100%'}
      >
        <Box
          component={'img'}
          src={partnership}
          alt="partnership"
          height={{ xs: 'auto', sm: '520px', md: '400px' }}
          maxWidth={'100%'}
          sx={{
            borderTopLeftRadius: { xs: '0px', sm: '16px' },
            borderBottomLeftRadius: '16px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: { xs: '16px', sm: '0px' },
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box
        sx={{
          padding: {
            xs: '48px 24px',
            sm: '64px 32px ',
            md: '64px',
            maxWidth: '785px',
            width: '100%',
          },
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginBottom={{ xs: '24px', sm: '32px' }}
        >
          <Typography
            variant={isMd ? 'h4' : 'h5'}
            color={theme.palette.darkGrey[700]}
            sx={{
              fontWeight: 700,
              letterSpacing: '-0.72px',
              marginBottom: '20px',
            }}
          >
            {t('partnershipCardTitle')}
          </Typography>
          <Typography
            variant="body3"
            color={theme.palette.darkGrey[600]}
            sx={{
              fontWeight: 400,
              textAlign: 'left',
              maxWidth: '607px',
              width: '100%',
            }}
          >
            {t('partnershipCardText')}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'flex-start' }}
          maxWidth={{ xs: '100%', sm: '300px' }}
          width={'100%'}
        >
          <Button
            buttontype="orange"
            onClick={() => navigate('/kontaktai')}
            width={{ xs: '100%', sm: 'auto' }}
          >
            {t('joinPartnership')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PartnershipCard;
