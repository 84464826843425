import React from 'react';
import Main from 'landing/layouts/Main';
import { Partners } from './components';
import Container from 'components/Container';

const FindPartnerPage = () => {
  return (
    <Main>
      <Container
        sx={{
          paddingBottom: {
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '80px !important',
            md: '96px !important',
          },
          minHeight: '55vh',
        }}
      >
        <Partners />
      </Container>
    </Main>
  );
};

export default FindPartnerPage;
