import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, styled } from '@mui/material/styles';
import { Box, TextField, Typography } from '@mui/material';
import DropZonefile from '../Dropzone/Dropzone';
import uploadIcon from 'assets/icons/upload-cloud-02.svg';
import helpCircle from 'assets/icons/help-circle.svg';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { SelectInput, TextInput } from 'components/Inputs';
import { Cities, NTRP } from 'constants/Selections';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    open={props.open}
    onOpen={props.onOpen}
    onClose={props.onClose}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.base.white,
    color: theme.palette.darkGrey[700],
    boxShadow:
      '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    fontSize: 12,
    fontFamily: 'Inter, sans-serif',
    padding: '8px 12px',
    borderRadius: '8px',
  },
}));

const PersonalInfo = ({
  personalInfoForm,
  setPersonalInfoForm,
  avatarUpload,
  setAvatarUpload,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState({});
  const [phoneError, setPhoneError] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    messenger,
    city,
    ntrp,
    image,
    description,
  } = personalInfoForm;

  const infoText = t('ntrpExplanation');

  const photoText = t('photoExplanation');

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleDataChange = (key, value) => {
    setAvatarUpload(value);
  };

  const handleChange = (event) => {
    if (event.target.name === 'phone') {
      const phoneNumber = parsePhoneNumberFromString(
        `+370${event.target.value}`,
      );

      if (
        !phoneNumber ||
        !phoneNumber.isValid() ||
        !event.target.value.startsWith('6')
      ) {
        setPhoneError(
          'Įveskite teisingo formato telefono numerį, pvz: (612 34567)',
        );
      } else {
        setPhoneError('');
      }
    }
    if (event.target.name === 'messenger') {
      let username = event.target.value;
      try {
        const url = new URL(event.target.value);
        username = url.pathname.substring(1); // Remove the leading slash
      } catch (_) {
        // Ignore
      }
      event.target.value = 'https://m.me/' + username;
    }
    setPersonalInfoForm({
      ...personalInfoForm,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box
      component={'form'}
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        maxHeight={{ xs: 'auto', sm: '126px' }}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('yourPhoto')}
            <LightTooltip
              title={photoText}
              placement={'top-start'}
              open={showTooltip}
              onOpen={() => setShowTooltip(true)}
              onClose={() => setShowTooltip(false)}
            >
              <Box
                component={'img'}
                src={helpCircle}
                marginLeft={'8px'}
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowTooltip(!showTooltip)}
                alt="help circle"
              />
            </LightTooltip>
          </Typography>
          <Typography variant="body4" color={theme.palette.darkGrey[600]}>
            {t('photoText')}
          </Typography>
        </Box>
        <Box
          height={'64px'}
          width={'64px'}
          margin={{ xs: '24px auto', md: '0 47px 0 0' }}
        >
          <Box
            component={'img'}
            width={'100%'}
            height={'100%'}
            src={
              image
                ? `https://backend.tenisopartneris.lt/public/images/${image}`
                : avatarGrey
            }
            alt="avatar"
            sx={{
              objectFit: 'cover',
              borderRadius: '50%',
            }}
          />
        </Box>
        <DropZonefile
          content={
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              padding={'16px 24px'}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  border: `1px solid ${theme.palette.darkGrey[200]}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '12px',
                }}
              >
                <Box component={'img'} src={uploadIcon} alt="upload icon" />
              </Box>
              <Box
                display={'flex'}
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {avatarUpload &&
                  avatarUpload.map((file, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          component={'img'}
                          src={
                            file.path
                              ? URL.createObjectURL(file)
                              : `https://backend.tenisopartneris.lt/public/shop/${file}`
                          }
                          alt="avatar"
                          sx={{
                            height: '48px',
                            width: '48px',
                            objectFit: 'cover',
                            borderRadius: '50%',
                          }}
                        />
                      </Box>
                    );
                  })}
              </Box>
              {avatarUpload && avatarUpload.length === 0 && (
                <>
                  <Box display={'flex'}>
                    <Typography
                      variant="body4"
                      color={theme.palette.orange[700]}
                      fontWeight={600}
                    >
                      Click to upload&nbsp;
                    </Typography>
                    <Typography
                      variant="body4"
                      color={theme.palette.darkGrey[600]}
                    >
                      or drag and drop{' '}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body4"
                    color={theme.palette.darkGrey[600]}
                  >
                    SVG, PNG, JPG
                  </Typography>
                </>
              )}
            </Box>
          }
          onDrop={(acceptedFiles) =>
            handleDataChange('uploadFiles', acceptedFiles)
          }
          uploadFiles={avatarUpload}
        />
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('nameSurname')}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          width={'100%'}
          marginTop={{ xs: '6px', sm: '0px' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <TextInput
            id="firstName"
            name="firstName"
            type="text"
            placeholder="First name"
            disabled
            autoComplete="first name"
            value={firstName || ''}
            sx={{
              margin: 0,
              maxWidth: { xs: '100%', sm: '298px', md: '304px' },
            }}
          />
          <TextInput
            required
            id="lastName"
            name="lastName"
            type="text"
            disabled
            autoComplete="last name"
            placeholder="Last name"
            value={lastName || ''}
            sx={{
              margin: 0,
              marginLeft: { xs: '0px', sm: '32px' },
              maxWidth: { xs: '100%', sm: '298px', md: '304px' },
              marginTop: { xs: '16px', sm: '0px' },
            }}
          />
        </Box>
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('emailAddress')}
          </Typography>
        </Box>
        <TextInput
          id="email"
          name="email"
          type="email"
          placeholder="Enter your email address"
          disabled
          value={email || ''}
          sx={{
            margin: 0,
            maxWidth: { xs: '100%', sm: '298px', md: '304px' },
            mt: { xs: '6px', sm: '0px' },
          }}
        />
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('mobileNumber')} (+370)
          </Typography>
        </Box>
        <TextInput
          id="phoneNumber"
          name="phoneNumber"
          type="text"
          placeholder={t('enterPhone')}
          required
          autoComplete="tel"
          handleInputChange={handleChange}
          value={phoneNumber || ''}
          inputProps={{ maxLength: 8 }}
          helperText={phoneError}
          error={!!phoneError}
          sx={{
            margin: 0,
            maxWidth: { xs: '100%', sm: '298px', md: '304px' },
            mt: { xs: '6px', sm: '0px' },
          }}
        />
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('city')}
          </Typography>
        </Box>
        <Box
          width={'100%'}
          sx={{
            mt: { xs: '6px', sm: '0px' },
            maxWidth: { xs: '100%', sm: '298px', md: '304px' },
          }}
        >
          <SelectInput
            name="city"
            id="city"
            placeholder={t('selectCity')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            value={city || ''}
            options={Cities}
          />
        </Box>
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            Facebook messenger
          </Typography>
        </Box>
        <TextInput
          id="messenger"
          name="messenger"
          type="text"
          placeholder="Pvz. Jonas.Jonaitis"
          required
          autoComplete="messenger"
          handleInputChange={handleChange}
          value={messenger || ''}
          sx={{
            margin: 0,
            maxWidth: { xs: '100%', sm: '298px', md: '304px' },
            mt: { xs: '6px', sm: '0px' },
          }}
        />
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('skillLevel')} (NTRP)
            <LightTooltip
              title={infoText}
              placement={'top-start'}
              open={showTooltip}
              onOpen={() => setShowTooltip(true)}
              onClose={() => setShowTooltip(false)}
            >
              <Box
                component={'img'}
                src={helpCircle}
                marginLeft={'8px'}
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowTooltip(!showTooltip)}
                alt="help circle"
              />
            </LightTooltip>
          </Typography>
        </Box>
        <Box
          width={'100%'}
          sx={{
            mt: { xs: '6px', sm: '0px' },
            maxWidth: { xs: '100%', sm: '298px', md: '304px' },
          }}
        >
          <SelectInput
            name="ntrp"
            id="ntrp"
            placeholder={t('selectLevel')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            options={NTRP}
            value={ntrp || ''}
          />
        </Box>
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box
        display={'flex'}
        maxHeight={'126px'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginRight={'57px'}
          width={'100%'}
          maxWidth={'280px'}
        >
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[700]}
            fontWeight={600}
          >
            {t('bio')}
          </Typography>
          <Typography variant="body4" color={theme.palette.darkGrey[600]}>
            {t('bioText')}
          </Typography>
        </Box>
        <TextField
          hiddenLabel
          margin="normal"
          fullWidth
          id="description"
          name="description"
          multiline
          rows={3}
          placeholder="Description.."
          value={description || ''}
          onChange={handleChange}
          inputProps={{
            sx: {
              fontSize: '16px',
              lineHeight: 1.5,
              color: theme.palette.darkGrey[500],
              '&::placeholder': {
                color: theme.palette.darkGrey[500],
                opacity: 1,
                fontSize: '16px',
              },
            },
          }}
          sx={{
            margin: 0,
            maxWidth: '528px',
            mt: { xs: '6px', sm: '0px' },
          }}
        />
      </Box>
    </Box>
  );
};

export default PersonalInfo;
