import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import chevronLeft from 'assets/icons/chevron-left.svg';
import { MarketplaceCard } from 'components/Cards';
import ItemCard from '../ItemCard';
import { useMediaQuery } from '@mui/material';
import { get } from 'api';
import CryptoJS from 'crypto-js';

const MarketplaceItem = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [product, setProduct] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getMarketplace = async () => {
      const response = await get(`/marketplace/marketplace/${id}`);
      if (!response.ok) {
        navigate('/marketplace');
        return;
      }
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (!decryptedData[0]) {
        navigate('/marketplace');
        return;
      }
      setProduct(decryptedData[0]);
    };
    getMarketplace();
  }, [id]);

  useEffect(() => {
    const getMarketplace = async () => {
      const response = await get(
        `/marketplace/similar/${product.product_category}/${product.id}`,
      );
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setSimilarProducts(decryptedData);
    };
    if (product.id) {
      getMarketplace();
      setLoaded(true);
    }
  }, [product]);

  if (!loaded) {
    return null;
  }

  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} marginBottom={'64px'}>
        <Box
          onClick={() => navigate('/marketplace')}
          marginRight={'12px'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            cursor: 'pointer',
            maxWidth: '106px',
          }}
        >
          <Box component={'img'} src={chevronLeft} alt="chevron-left" />
          <Typography
            variant="body3"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 600 }}
          >
            {t('goBack')}
          </Typography>
        </Box>
        <ItemCard product={product} />
      </Box>
      {similarProducts.length > 0 && (
        <>
          <Typography
            variant={isMd ? 'h4' : 'h5'}
            color={theme.palette.darkGrey[700]}
            fontWeight={700}
            marginBottom={'40px'}
          >
            {t('seeSimilarItems')}
          </Typography>
          <Grid container spacing={'32px'}>
            {similarProducts.slice(0, 3).map((item) => (
              <Grid item xs={12} sm={4} key={item.id}>
                <MarketplaceCard
                  id={item.id}
                  image={item.images[0]}
                  title={item.product_title}
                  price={item.product_price}
                  city={item.city}
                  condition={item.product_usednew
                    .split('_')
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase(),
                    )
                    .join(' ')}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default MarketplaceItem;
