import { useLocation } from 'react-router-dom';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { MultiMessage, SingleMessage } from './components';

const MessagesPage = () => {
  const location = useLocation();
  const state = location.state;

  return (
    <Main>
      <ContainerSystem
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
      >
        {state?.isMulti ? (
          <MultiMessage state={state} />
        ) : (
          <SingleMessage state={state} />
        )}
      </ContainerSystem>
    </Main>
  );
};

export default MessagesPage;
