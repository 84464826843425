import React from 'react';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { SponsorsCard } from 'components/Cards';
import { PartnershipCard } from './components';

const SponsorshipPage = () => {
  return (
    <Main>
      <Container
        sx={{
          paddingBottom: {
            sm: '64px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '64px !important',
            md: '96px !important',
          },
        }}
      >
        <SponsorsCard page="sponsorship" />
      </Container>
      <Container
        sx={{
          paddingBottom: {
            sm: '64px !important',
            md: '96px !important',
          },
          paddingTop: {
            xs: '0px !important',
            sm: '0px !important',
            md: '0px !important',
          },
        }}
      >
        <PartnershipCard />
      </Container>
    </Main>
  );
};

export default SponsorshipPage;
