import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const SecondaryTab = ({ activeTab, setActiveTab, tabName, page }) => {
  const theme = useTheme();

  return (
    <Box
      onClick={() => setActiveTab(tabName)}
      maxWidth={{ xs: '100%', sm: '287px' }}
      width={page === 'blog' ? { xs: '100%', sm: 'auto' } : '100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={'10px 18px'}
      borderBottom={
        activeTab === tabName
          ? `2px solid ${theme.palette.orange[600]}`
          : `none`
      }
      backgroundColor={activeTab === tabName ? '#FCEEEB' : 'inherit'}
      borderRadius={activeTab === tabName ? '8px 8px 0 0' : '0'}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Typography
        variant={'body3'}
        align={'center'}
        fontWeight={600}
        color={
          activeTab === tabName
            ? theme.palette.orange[800]
            : theme.palette.darkGrey[500]
        }
      >
        {tabName.charAt(0).toUpperCase() + tabName.slice(1)}
      </Typography>
    </Box>
  );
};

export default SecondaryTab;
