import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import ResetPassword from './components/ResetPassword';

const ResetPasswordPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          background: theme.palette.darkGrey[25],
        }}
      >
        <Container
          paddingTop={{ xs: '64px !important', md: '143px !important' }}
          paddingBottom={{ xs: '64px !important', md: '143px !important' }}
        >
          <ResetPassword />
        </Container>
      </Box>
    </Main>
  );
};

export default ResetPasswordPage;
