import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

const Hero = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      height={{ xs: 'auto', sm: '450px' }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        padding: { xs: '64px 24px', md: '64px' },
        background: theme.palette.lightGrey[200],
        maxHeight: { xs: '212px', sm: '450px' },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={'768px'}
        width={'100%'}
        alignItems={'center'}
      >
        <Typography
          variant={isMd ? 'h3' : 'h4'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            width: '100%',
            letterSpacing: '-0.72px',
            marginBottom: { xs: '12px', md: '20px' },
            textAlign: 'center',
          }}
        >
          {t('aboutUs')}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.darkGrey[600]}
          sx={{
            fontWeight: 400,
            maxWidth: '501px',
            width: '100%',
            textAlign: 'center',
            fontStyle: 'italic',
          }}
        >
          {t('aboutSubtitle')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
