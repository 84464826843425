import {
  loserPositionMap,
  losersPointsMap,
  matchNumberMapping,
  registrationPointsMap,
  tournamentStageMap,
  winnerPoints,
  winnerPositionMap,
} from 'constants/Tournament';

export const CurrentWinnersPosition = (
  winner,
  challenger_id,
  challenger_position,
  opponent_id,
  opponent_position,
) => {
  if (winner === challenger_id) {
    return challenger_position;
  } else if (winner === opponent_id) {
    return opponent_position;
  } else {
    return null;
  }
};

export const CurrentLosersPosition = (
  loser,
  challenger_id,
  challenger_position,
  opponent_id,
  opponent_position,
) => {
  if (loser === challenger_id) {
    return challenger_position;
  } else if (loser === opponent_id) {
    return opponent_position;
  } else {
    return null;
  }
};

export const NextWinnerPosition = (position) => {
  return winnerPositionMap[position];
};

export const NextLoserPosition = (position) => {
  return loserPositionMap[position];
};

export const checkWinnersPoints = (
  match_number,
  tournament_stage,
  tournament_level,
) => {
  const stagePoints = winnerPoints[tournament_stage];
  if (!stagePoints) return 0;

  const levelPoints = stagePoints[tournament_level];
  if (!levelPoints) return 0;

  if (typeof levelPoints === 'number') {
    return levelPoints;
  } else {
    return levelPoints[match_number] || levelPoints.default || 0;
  }
};

export const checkLosersPoints = (
  match_number,
  tournament_stage,
  tournament_level,
) => {
  const stagePoints = losersPointsMap[tournament_stage];
  if (!stagePoints) return 0;

  if (typeof stagePoints === 'number') {
    return stagePoints;
  } else {
    const levelPoints = stagePoints[tournament_level];
    if (!levelPoints) return 0;

    return levelPoints[match_number] || levelPoints.default || 0;
  }
};

export const checkRegistrationPoints = (tournament_level) => {
  return registrationPointsMap[tournament_level] || 0;
};

export const getPersonRanking = (points, userId) => {
  let rank = '';
  const sortedPoints = points.sort(
    (a, b) => b.tournament_points - a.tournament_points,
  );
  const userIndex = sortedPoints.findIndex(
    (player) => player.id === Number(userId),
  );
  rank = userIndex + 1;

  return rank;
};

export const tournamentStage = (tournament_stage, match_number) => {
  const stage = tournamentStageMap[tournament_stage];
  if (typeof stage === 'string') {
    return stage;
  } else {
    return stage ? stage[match_number] : undefined;
  }
};

export const playoffsWinner = (match_number) => {
  return matchNumberMapping[match_number]?.winner || '';
};

export const upcomingMatch = (match_number) => {
  return matchNumberMapping[match_number]?.nextMatch;
};

export const nextGroup = (match_number) => {
  return matchNumberMapping[match_number]?.nextGroup;
};
