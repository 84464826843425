import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/lt';
import { useTheme } from '@mui/material/styles';
import { times, duration } from '../Variables/times';
import { cities, surfaces } from '../Variables/courtNames';
import CourtsTable from '../Table/Table';
import Button from 'components/Button';
import { SelectInput, TextInput } from 'components/Inputs';
import {
  get,
  getUserEmailFromToken,
  getUserIdFromToken,
  getUserPhoneFromToken,
  post,
  remove,
} from 'api';
import SpinningBox from 'components/SpinningBox';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import chevronRight from 'assets/icons/chevronRight.svg';
import chevronLeft from 'assets/icons/chevronLeft.svg';

const Kaunas = ({ city }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [courtsData, setCourtsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [selectedStartTime, setSelectedStartTime] = useState('07:00:00');
  const [selectedEndTime, setSelectedEndTime] = useState('23:00:00');
  const [selectedDuration, setSelectedDuration] = useState(90);
  const [selectedSurface, setSelectedSurface] = useState('any');
  const [selectedCity, setSelectedCity] = useState('kaunas');
  const [loaded, setLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [subscriptionsOpen, setSubscriptionsOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubOpen = () => setSubscriptionsOpen(true);
  const handleSubClose = () => setSubscriptionsOpen(false);
  const userEmail = getUserEmailFromToken();
  const userId = getUserIdFromToken();
  const userPhone = getUserPhoneFromToken();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [mySubcriptions, setMySubcriptions] = useState([]);

  const usersWithMobile = [1, 2, 3, 1656];

  useEffect(() => {
    if (
      isFirstRender &&
      queryParams.get('date') &&
      queryParams.get('start') &&
      queryParams.get('end') &&
      queryParams.get('duration') &&
      queryParams.get('surface') &&
      queryParams.get('city')
    ) {
      setSelectedDate(queryParams.get('date'));
      setSelectedStartTime(queryParams.get('start'));
      setSelectedEndTime(queryParams.get('end'));
      const duration = queryParams.get('duration');
      setSelectedDuration(parseInt(duration));
      setSelectedSurface(queryParams.get('surface'));
      setSelectedCity(queryParams.get('city'));
      handleOpen();

      setTimeout(() => {
        let url = new URL(window.location);
        url.searchParams.delete('date');
        url.searchParams.delete('start');
        url.searchParams.delete('end');
        url.searchParams.delete('duration');
        url.searchParams.delete('surface');
        url.searchParams.delete('city');
        window.history.replaceState({}, '', url);
      }, 0);
    }
    setIsFirstRender(false);
  }, [queryParams, isFirstRender]);

  const successToastRef = useRef();
  const errorToastRef = useRef();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMd ? '20%' : '85%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    border: 'none',
    p: 4,
  };

  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMd ? 'auto' : '85%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    border: 'none',
    p: 4,
  };

  const findCourtsByDate = (date) => {
    return courtsData.find((courts) => courts[0].date === date) || [];
  };

  const getMySubscriptions = async () => {
    const response = await get(`/courts/my-notifications/kaunas/${userId}`);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      setMySubcriptions(data.data);
    }
  };

  useEffect(() => {
    const fetchMegaTennis = async () => {
      const formData = new FormData();
      formData.append('date', selectedDate);
      formData.append('game_type_id', 2);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/proxy`,
        {
          method: 'POST',
          body: formData,
        },
      );
      const data = await response.json();

      // First, flatten the data to an array of objects, each containing courtID, date, and a single time slot
      const flattenedData = data.tracks.flatMap((current) =>
        current.tracks.map((track) => ({
          courtID: track.game_track_id,
          date: current.date,
          timeSlot: {
            [track.hour]: {
              from: `${track.hour}:00`,
              to: `${track.hour_to}:00`,
              status:
                Array.isArray(track.reservation) &&
                track.reservation.length === 0
                  ? 'free'
                  : 'full',
            },
          },
        })),
      );

      // Then, group the flattened data by courtID and date, and reduce each group to a single object with a timetable
      const groupedData = flattenedData.reduce((accumulator, current) => {
        const key = `${current.date}-${current.courtID}`;
        if (!accumulator[key]) {
          accumulator[key] = {
            courtID: current.courtID,
            date: current.date,
            tennisCourt: 'megaTennis',
            timetable: {},
          };
        }
        accumulator[key].timetable = {
          ...accumulator[key].timetable,
          ...current.timeSlot,
        };
        return accumulator;
      }, {});

      // Finally, transform the grouped data to the desired format
      const transformedData = Object.values(groupedData).reduce(
        (accumulator, current) => {
          const date = current.date;
          if (!accumulator[date]) {
            accumulator[date] = [];
          }
          accumulator[date].push(current);
          return accumulator;
        },
        {},
      );

      setCourtsData(Object.values(transformedData));
      setLoaded(true);
    };
    fetchMegaTennis();
  }, [selectedDate]);

  const handleSelectOpen = (id) => {
    setOpenSelect({ ...open, [id]: true });
  };

  const handleSelectClose = (id) => {
    setOpenSelect({ ...open, [id]: false });
  };

  const handleSelectChange = (event) => {
    if (event.target.name === 'startTime') {
      setSelectedStartTime(event.target.value);
    }
    if (event.target.name === 'endTime') {
      setSelectedEndTime(event.target.value);
    }
    if (event.target.name === 'duration') {
      setSelectedDuration(event.target.value);
    }
    if (event.target.name === 'surface') {
      setSelectedSurface(event.target.value);
    }
    if (event.target.name === 'city') {
      setSelectedCity(event.target.value);
    }
  };

  const handleSubscribe = async () => {
    setIsSubmitting(true);
    if (
      !selectedDate ||
      !selectedStartTime ||
      !selectedEndTime ||
      !selectedDuration ||
      !selectedSurface ||
      !selectedCity
    ) {
      setIsSubmitting(false);
      errorToastRef.current.showError(t('fillAllFields'));
      return;
    }
    const body = {
      userId,
      userEmail,
      userPhone: usersWithMobile.includes(userId) ? userPhone : null,
      date: selectedDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      duration: selectedDuration,
      surface: selectedSurface,
      city: selectedCity,
    };
    const response = await post('/courts/subscribe', body);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      setIsSubmitting(false);
      handleClose();
      successToastRef.current.showSuccess(data.msg);
    } else {
      setIsSubmitting(false);
      errorToastRef.current.showError(data.err);
    }
  };

  const handleDelete = async (id) => {
    const response = await remove(`/courts/unsubscribe/${id}`);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('notificationDeleted'));
      setTimeout(() => {
        getMySubscriptions();
      }, 1500);
    } else {
      errorToastRef.current.showError(data.err);
    }
  };

  const handleSubscriptionsOpen = () => {
    getMySubscriptions();
    handleSubOpen();
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box marginBottom={'24px'}>
        <Button
          bordered="true"
          buttontype="white"
          onClick={handleSubscriptionsOpen}
          width={{ xs: '100%', sm: 'auto' }}
        >
          {t('mySubcriptions')}
        </Button>
        <Modal
          open={subscriptionsOpen}
          onClose={handleSubClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <Box sx={style2}>
            <CardContent
              sx={{
                maxHeight: '80vh',
                overflow: 'auto',
                padding: '0px',
                '&::-webkit-scrollbar': {
                  backgroundColor: theme.palette.darkGrey[100],
                  width: '2px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.darkGrey[300],
                  borderRadius: '20px',
                },
              }}
            >
              <Typography
                variant={isMd ? 'h5' : 'h6'}
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
                marginBottom={'16px'}
              >
                {t('myActiveSubcriptions')}
              </Typography>
              {mySubcriptions.length > 0 ? (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{t('date')}</TableCell>
                        <TableCell align="center">{t('from')}</TableCell>
                        <TableCell align="center">{t('to')}</TableCell>
                        <TableCell align="center">{t('duration')}</TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {t('surface')}
                        </TableCell>
                        <TableCell align="center">{t('city')}</TableCell>
                        <TableCell align="center">{t('cancel')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mySubcriptions.map((sub) => (
                        <TableRow key={sub.id}>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {moment(sub.date).format('YYYY-MM-DD')} <br />(
                            {moment(sub.date).locale('lt').format('dddd')})
                          </TableCell>
                          <TableCell align="center">{sub.startTime}</TableCell>
                          <TableCell align="center">{sub.endTime}</TableCell>
                          <TableCell align="center">
                            {sub.duration} min
                          </TableCell>
                          <TableCell align="center">
                            {sub.surface === 'any'
                              ? 'Nesvarbu'
                              : sub.surface === 'hard'
                              ? 'Hard'
                              : 'Kilimas'}
                          </TableCell>
                          <TableCell align="center">
                            {sub.city.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            <Button
                              buttontype="orange"
                              onClick={() => handleDelete(sub.id)}
                            >
                              {t('cancel')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('noSubscriptions')}
                </Typography>
              )}
            </CardContent>
          </Box>
        </Modal>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width={{ xs: '100%', sm: 'auto' }}
        mx="auto"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          width={{ xs: '100%', sm: '75%' }}
          justifyContent="space-between"
        >
          <DatePicker
            slotProps={{
              textField: {
                size: 'large',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    display: 'flex',
                    height: '16px',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            value={moment(selectedDate)}
            onChange={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
            minDate={moment().endOf('day')}
            maxDate={moment().add(14, 'days')}
            sx={{ height: '48px', width: { xs: '100%', sm: 'auto' } }}
          />
          <Box
            display="flex"
            alignItems="center"
            marginBottom={{ xs: '24px', sm: 0 }}
          >
            <Box
              sx={{
                width: { xs: 'auto', sm: '50px' },
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: { xs: '8px', sm: '16px' },
              }}
              onClick={() => {
                const newDate = moment(selectedDate).subtract(1, 'days');
                if (newDate.isSameOrAfter(moment(), 'day')) {
                  setSelectedDate(newDate.format('YYYY-MM-DD'));
                }
              }}
            >
              <Box
                component={'img'}
                src={chevronLeft}
                alt="chevron-left"
                sx={{
                  height: '20px',
                  width: '20px',
                  '&:hover': {
                    opacity: 0.5,
                  },
                }}
              />
            </Box>
            <Typography
              variant="body1"
              fontWeight={600}
              color={theme.palette.darkGrey[700]}
              width={{ xs: '100%', sm: '310px' }}
              textAlign="center"
            >
              {moment(selectedDate).locale('lt').format('dddd').toUpperCase()} (
              {moment(selectedDate).format('YYYY-MM-DD')})
            </Typography>
            <Box
              sx={{
                width: { xs: 'auto', sm: '50px' },
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginLeft: { xs: '8px', sm: '16px' },
              }}
              onClick={() =>
                setSelectedDate(
                  moment(selectedDate).add(1, 'days').format('YYYY-MM-DD'),
                )
              }
            >
              <Box
                component={'img'}
                src={chevronRight}
                alt="chevron-right"
                sx={{
                  height: '20px',
                  width: '20px',

                  '&:hover': {
                    opacity: 0.5,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Button
          buttontype="orange"
          onClick={handleOpen}
          marginTop={{ xs: '24px', sm: '0px' }}
          width={{ xs: '100%', sm: 'auto' }}
        >
          {t('subscribe')}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <Box sx={style}>
            <CardContent
              sx={{ maxHeight: '80vh', overflow: 'auto', padding: '0px' }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('date')}*
                </Typography>
                <DatePicker
                  slotProps={{
                    textField: {
                      size: 'large',
                      inputProps: {
                        style: {
                          fontSize: '16px',
                          display: 'flex',
                          height: '16px',
                        },
                      },
                      InputLabelProps: {
                        style: {
                          fontSize: '16px',
                        },
                      },
                    },
                  }}
                  format="YYYY-MM-DD"
                  value={moment(selectedDate)}
                  onChange={(date) =>
                    setSelectedDate(date.format('YYYY-MM-DD'))
                  }
                  minDate={moment().endOf('day')}
                  maxDate={moment().add(14, 'days')}
                  sx={{ height: '48px' }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('startTime')}*
                </Typography>
                <SelectInput
                  name="startTime"
                  id="startTime"
                  placeholder={t('startTime')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={times}
                  value={selectedStartTime}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('endTime')}*
                </Typography>
                <SelectInput
                  name="endTime"
                  id="endTime"
                  placeholder={t('endTime')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={times}
                  value={selectedEndTime}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('duration')}*
                </Typography>
                <SelectInput
                  name="duration"
                  id="duration"
                  placeholder={t('duration')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={duration}
                  value={selectedDuration}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('surface')}*
                </Typography>
                <SelectInput
                  name="surface"
                  id="surface"
                  placeholder={t('surface')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={surfaces}
                  value={selectedSurface}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('city')}*
                </Typography>
                <SelectInput
                  name="city"
                  id="city"
                  placeholder={t('city')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={cities}
                  value={selectedCity}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', sm: '100%' }}
                marginTop={'16px'}
              >
                <TextInput
                  label={t('email')}
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t('email')}
                  disabled
                  value={userEmail}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Button
                  buttontype="orange"
                  onClick={handleSubscribe}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <SpinningBox /> : t('subscribe')}
                </Button>
              </Box>
            </CardContent>
          </Box>
        </Modal>
      </Box>
      <Box mt={'32px'} style={{ backgroundColor: 'white' }}>
        <CourtsTable courtsData={findCourtsByDate(selectedDate)} city={city} />
      </Box>
    </Box>
  );
};

export default Kaunas;
