import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import 'moment/locale/lt';

moment.locale('lt');

const ServeCard = ({ id, tournamentInfo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      maxHeight={'330px'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        padding: { xs: '64px 24px', sm: '40px 64px', md: '64px' },
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={'768px'}
        width={'100%'}
        alignItems={'center'}
        marginBottom={'32px'}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            width: '100%',
            letterSpacing: '-0.72px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          {t('serveCardTitle')}
        </Typography>
        <Typography
          variant={isMd ? 'body2' : 'body3'}
          color={theme.palette.darkGrey[600]}
          sx={{
            fontWeight: 400,
            maxWidth: '501px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          Registruokitės į {tournamentInfo.title} turnyrą iki{' '}
          {moment(tournamentInfo.registrationDate).format('LL')}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Button
          buttontype={'orange'}
          onClick={() => navigate(`/turnyras/registracija/${id}`)}
          width={{ xs: '100%', sm: 'auto' }}
        >
          {t('registerNow')}
        </Button>
      </Box>
    </Box>
  );
};

export default ServeCard;
