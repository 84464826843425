import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { TextInput } from 'components/Inputs';
import { post, get } from 'api';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import SpinningBox from 'components/SpinningBox';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [form, setForm] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      navigate('/prisijungimas');
      return;
    }
    const verifyEmail = async () => {
      const response = await get(`/resetpass/verify-email/${id}`);
      const data = await response.json();
      if (data.length === 0) {
        navigate('/prisijungimas');
        return;
      }
      setUserEmail(data[0].email);
      setForm({
        ...form,
        email: data[0].email,
      });
      setLoaded(true);
    };
    verifyEmail();
  }, [id]);

  const handleChange = (event) => {
    if (event.target.name === 'password') {
      setPasswordError(
        event.target.value.length < 8
          ? 'Slaptažodis turi būti bent 8 simbolių ilgio'
          : '',
      );
    }
    if (event.target.name === 'repeatPassword') {
      setRepeatPasswordError(
        form.password !== event.target.value ? 'Slaptažodžiai nesutampa' : '',
      );
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form.email)) {
      errorToastRef.current.showError('Įveskite teisingo formato el. paštą');
      setIsSubmitting(false);
      return;
    }

    const response = await post(`/resetpass/change-password/${id}`, form);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('passwordChanged'));
      setTimeout(() => {
        setIsSubmitting(false);
        navigate('/prisijungimas');
      }, 3000);
    } else {
      errorToastRef.current.showError(data.msg);
      setIsSubmitting(false);
    }
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '415px',
        width: '100%',
        mx: 'auto',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        sx={{
          mb: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          mb={'12px'}
        >
          {t('resetPasswordTitle')}
        </Typography>
        <Typography variant="body3" color={theme.palette.darkGrey[600]}>
          {t('resetPasswordSubtitle')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TextInput
          label={`${t('email')}*`}
          id="email"
          name="email"
          type="email"
          placeholder={t('enterEmail')}
          inputProps={{
            autoComplete: 'email',
          }}
          disabled
          value={userEmail || ''}
        />
        <Box sx={{ mt: '16px' }} />
        <TextInput
          label={`${t('password')}*`}
          id="password"
          name="password"
          type="password"
          placeholder={t('enterPassword')}
          inputProps={{
            autoComplete: 'new-password',
          }}
          helperText={passwordError}
          error={!!passwordError}
          required
          handleInputChange={handleChange}
          value={form.password || ''}
        />
        <Box sx={{ mt: '32px' }} />
        <TextInput
          label={`${t('repeatPassword')}*`}
          id="repeatPassword"
          name="repeatPassword"
          type="password"
          placeholder={t('repeatPassword')}
          helperText={repeatPasswordError}
          error={!!repeatPasswordError}
          required
          handleInputChange={handleChange}
          value={form.repeatPassword || ''}
        />
        <Button
          buttontype="orange"
          width="100%"
          marginTop="40px"
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : t('resetPassword')}
        </Button>
        <Box
          sx={{
            mt: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body4" color={theme.palette.darkGrey[600]}>
            {t('rememberedPassword?')}
          </Typography>
          <Box
            onClick={() => navigate('/prisijungimas')}
            fontWeight={600}
            color={theme.palette.orange[800]}
            display="flex"
            alignItems="center"
            marginLeft={'8px'}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="body4"
              color={theme.palette.orange[800]}
              fontWeight={600}
            >
              {t('login')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ResetPassword;
