import eyeIcon from 'assets/icons/eye-white.svg';
import racquetIcon from 'assets/icons/racquetWhite.svg';
import userIcon from 'assets/icons/userCheckWhite.svg';
import cartIcon from 'assets/icons/shopping-cart-white.svg';
import usersIcon from 'assets/icons/usersWhite.svg';
import searchIcon from 'assets/icons/search-lg-white.svg';
import podiumIcon from 'assets/icons/podiumWhite.svg';

const pages = [
  {
    title: 'overview',
    href: '/info',
    sideBar: true,
    icon: eyeIcon,
  },
  {
    title: 'tournaments',
    href: '/turnyras',
    sideBar: true,
    icon: racquetIcon,
  },
  {
    title: 'findAPartner',
    href: '/partnerio-paieska',
    sideBar: true,
    icon: userIcon,
  },
  {
    title: 'marketplace',
    href: '/marketplace',
    sideBar: true,
    icon: cartIcon,
  },
  // {
  //   title: 'topPlayers',
  //   href: '/top-zaidejai',
  //   sideBar: true,
  //   icon: usersIcon,
  // },
  {
    title: 'ratings',
    href: '/reitingai',
    sideBar: true,
    icon: podiumIcon,
  },
  {
    title: 'tennisCourts',
    href: '/teniso-kortai',
    sideBar: true,
    icon: searchIcon,
  },
  {
    title: 'editProfile',
    href: '/redaguoti-profili',
    sideBar: false,
  },
];

export default pages;
