import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import chevronLeft from 'assets/icons/chevron-left.svg';
import chevronRight from 'assets/icons/chevron-right-white.svg';
import { PrimaryTab } from 'components/Tabs';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { SelectInput } from 'components/Inputs';
import Button from 'components/Button';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import SpinningBox from 'components/SpinningBox';
import { get, getTokenFromStorage, post } from 'api';
import moment from 'moment';
import {
  Cities,
  MatchLength,
  NTRP,
  CourtNr,
  MatchType,
  Times,
} from 'constants/Selections';

const PartnerPost = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = getTokenFromStorage();
  const [activeTab, setActiveTab] = useState('step 1');
  const [open, setOpen] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tennisCourts, setTennisCourts] = useState([]);
  const [form, setForm] = useState({
    date: '',
    court: '',
    courtNumber: '',
    time: '',
    duration: '',
    city: '',
    ntrp: '',
    type: '',
    comment: '',
    courtBooked: '1',
  });
  const successToastRef = useRef();
  const errorToastRef = useRef();

  useEffect(() => {
    const fetchCourts = async () => {
      const response = await get('/matches/courts');
      const data = await response.json();
      setTennisCourts(data);
    };
    fetchCourts();
  }, []);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (
      !form.date ||
      !form.time ||
      !form.duration ||
      !form.city ||
      !form.ntrp ||
      !form.type
    ) {
      errorToastRef.current.showError(t('fillAllFields'));
      setIsSubmitting(false);
      return;
    } else if (form.courtBooked === '1' && (!form.court || !form.courtNumber)) {
      errorToastRef.current.showError(t('fillAllFields'));
      setIsSubmitting(false);
      return;
    }
    const body = {
      match_date: form.date,
      place:
        form.courtBooked === '0'
          ? ''
          : tennisCourts.find((court) => court.id === form.court).court_name,
      court_nr: form.courtNumber,
      match_time: form.time,
      match_length: form.duration,
      city: form.city,
      ntrp: form.ntrp,
      type: form.type,
      comment: form.comment,
      courtBooked: Number(form.courtBooked),
    };
    const response = await post('/matches/matches', body, token);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        setIsSubmitting(false);
        navigate('/partnerio-paieska');
      }, 3000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: '100px',
        margin: '0 auto',
        maxWidth: '416px',
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate('/partnerio-paieska')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
      >
        <Box
          component={'img'}
          src={chevronLeft}
          marginRight={'12px'}
          height={1}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('partnerPostTitle')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('partnerPostSubtitle')}
      </Typography>
      <Box
        maxWidth={'505px'}
        marginTop={'32px'}
        marginBottom={'25px'}
        display={'flex'}
        justifyContent={'space-between'}
        border={`1px solid ${theme.palette.darkGrey[300]}`}
        borderRadius={'8px'}
      >
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'step 1'}
          pageName={'partnerPost'}
        />
        {form.courtBooked === '1' && (
          <PrimaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'step 2'}
            pageName={'partnerPost'}
          />
        )}
        <PrimaryTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={'step 3'}
          pageName={'partnerPost'}
        />
      </Box>
      {activeTab === 'step 1' && (
        <Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('date')}*
            </Typography>
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  inputProps: {
                    style: {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[500],
                      display: 'flex',
                    },
                  },
                  InputLabelProps: {
                    style: {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[500],
                    },
                  },
                },
              }}
              minDate={moment()}
              format="YYYY-MM-DD"
              onChange={(newValue) =>
                setForm({
                  ...form,
                  date: moment(newValue).format('YYYY-MM-DD'),
                })
              }
              value={moment(form.date)}
              sx={{
                mt: '6px',
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('courtBookedText')}*
            </Typography>
            <FormControl>
              <RadioGroup
                name="courtBooked"
                row
                value={form.courtBooked}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.orange[600],
                        },
                      }}
                    />
                  }
                  label={t('yes')}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[700],
                      fontWeight: 500,
                    },
                  }}
                />
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.orange[600],
                        },
                      }}
                    />
                  }
                  label={t('no')}
                  sx={{
                    marginLeft: '12px',
                    '& .MuiFormControlLabel-label': {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[700],
                      fontWeight: 500,
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('time')}*
            </Typography>
            <SelectInput
              name="time"
              id="time"
              placeholder={t('selectTime')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              value={form.time}
              options={Times}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('duration')}*
            </Typography>
            <SelectInput
              name="duration"
              id="duration"
              placeholder={t('selectDuration')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              value={form.duration}
              options={MatchLength}
            />
          </Box>
          {form.courtBooked === '0' && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'25px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                {t('city')}*
              </Typography>
              <SelectInput
                name="city"
                id="city"
                placeholder={t('selectCity')}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleChange={handleChange}
                topMargin={'6px'}
                value={form.city}
                options={Cities.filter((city) => city.value !== '')}
              />
            </Box>
          )}
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype={'orange'}
              onClick={() =>
                setActiveTab(form.courtBooked === '1' ? 'step 2' : 'step 3')
              }
            >
              {t('next')}{' '}
              <Box component={'img'} src={chevronRight} alt="chevron-right" />
            </Button>
          </Box>
        </Box>
      )}
      {activeTab === 'step 2' && form.courtBooked === '1' && (
        <Box>
          {form.courtBooked === '1' && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                {t('city')}*
              </Typography>
              <SelectInput
                name="city"
                id="city"
                placeholder={t('selectCity')}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleChange={handleChange}
                topMargin={'6px'}
                value={form.city}
                options={Cities.filter((city) => city.value !== '')}
              />
            </Box>
          )}
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('tennisCourt')}*
            </Typography>
            <SelectInput
              name="court"
              id="court"
              placeholder={t('selectCourt')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              value={form.court}
              options={tennisCourts
                .filter((court) => court.city === form.city)
                .map((court) => {
                  return { value: court.id, label: court.court_name };
                })}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('courtNumber')}*
            </Typography>
            <SelectInput
              name="courtNumber"
              id="courtNumber"
              placeholder={t('selectCourtNumber')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              value={form.courtNumber}
              options={CourtNr}
            />
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype={'white'}
              bordered="true"
              onClick={() => setActiveTab('step 1')}
            >
              <Box component={'img'} src={chevronLeft} alt="chevron-left" />{' '}
              {t('previous')}
            </Button>
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype={'orange'}
              onClick={() => setActiveTab('step 3')}
              marginTop={{ xs: '12px', sm: '0px' }}
            >
              {t('next')}{' '}
              <Box component={'img'} src={chevronRight} alt="chevron-right" />
            </Button>
          </Box>
        </Box>
      )}
      {activeTab === 'step 3' && (
        <Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              NTRP {t('level')}*
            </Typography>
            <SelectInput
              name="ntrp"
              id="ntrp"
              placeholder={t('selectLevel')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              value={form.ntrp}
              options={NTRP.filter((level) => level.value !== '')}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('matchType')}*
            </Typography>
            <SelectInput
              name="type"
              id="type"
              placeholder={t('selectMatchType')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleChange}
              topMargin={'6px'}
              value={form.type}
              options={MatchType}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
            marginTop={'25px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('comment')}
            </Typography>
            <TextField
              hiddenLabel
              margin="normal"
              fullWidth
              id="comment"
              name="comment"
              multiline
              rows={2}
              placeholder={t('commentPlaceholder')}
              value={form.comment}
              onChange={handleChange}
              inputProps={{
                sx: {
                  fontSize: '16px',
                  lineHeight: 1.5,
                  color: theme.palette.darkGrey[500],
                  '&::placeholder': {
                    color: theme.palette.darkGrey[500],
                    opacity: 1,
                    fontSize: '16px',
                  },
                },
              }}
              sx={{
                mt: '6px',
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype={'white'}
              bordered="true"
              onClick={() =>
                setActiveTab(form.courtBooked === '1' ? 'step 2' : 'step 1')
              }
            >
              <Box component={'img'} src={chevronLeft} alt="chevron-left" />{' '}
              {t('previous')}
            </Button>
            <Button
              maxWidth={{ xs: '100%', sm: '109px' }}
              width={'100%'}
              buttontype={'orange'}
              onClick={() => handleSubmit()}
              marginTop={{ xs: '12px', sm: '0px' }}
              disabled={isSubmitting}
            >
              {isSubmitting ? <SpinningBox /> : t('submit')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PartnerPost;
