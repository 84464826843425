import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import arrowUp from 'assets/icons/arrow-up-right.svg';
import location from 'assets/icons/location.svg';
import currency from 'assets/icons/currency.svg';
import stars from 'assets/icons/stars-02.svg';
import Button from 'components/Button';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import { remove } from 'api';
import noImageImg from 'assets/images/no-image.jpg';

export default function MarketplaceCard({
  id,
  image,
  title,
  price,
  city,
  condition,
  page,
  fetchData,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const successToastRef = useRef();
  const errorToastRef = useRef();

  const handleDelete = async () => {
    const response = await remove(`/marketplace/marketplace/${id}`);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('productDeleted'));
      setTimeout(() => {
        fetchData();
      }, 1500);
    } else {
      errorToastRef.current.showError(data.err);
    }
  };

  const confirmDelete = () => {
    confirmAlert({
      title: '',
      message: t('confirmDeleteProduct'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => handleDelete(),
        },
        {
          label: t('no'),
        },
      ],
    });
  };

  return (
    <Card
      sx={{
        maxWidth: { xs: '100%', sm: 'auto' },
        width: '100%',
        minHeight: 302,
        height: '100%',
        bgcolor: 'inherit',
        boxShadow: 'none',
        cursor: 'pointer',
        borderRadius: '0px',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box onClick={() => navigate(`/marketplace/${id}`)}>
        <Box maxWidth={{ xs: '100%', sm: 'auto' }} width={'100%'}>
          <Box
            component="img"
            src={
              image
                ? `https://backend.tenisopartneris.lt/public/shop/${image}`
                : noImageImg
            }
            alt="product"
            height={{ xs: '200px', sm: '175px' }}
            width="100%"
            sx={{
              borderRadius: '12px',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography
            gutterBottom
            variant="body2"
            fontWeight={700}
            color={theme.palette.darkGrey[700]}
            sx={{
              marginRight: '15px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          <Box
            display={'flex'}
            component="a"
            href={`/marketplace/${id}`}
            title="TP"
            height={1}
          >
            <Box component={'img'} src={arrowUp} alt="arrow up" />
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'flex-start'} gap={'12px'}>
          <Box
            sx={{
              backgroundColor: theme.palette.darkGrey[100],
              padding: '4px 12px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box
              component={'img'}
              src={currency}
              width={14}
              height={14}
              alt="currency"
            />
            <Typography
              variant="body5"
              color={theme.palette.darkGrey[600]}
              fontWeight={600}
              sx={{ marginLeft: '8px' }}
            >
              {price}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: theme.palette.darkGrey[100],
              padding: '4px 12px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box
              component={'img'}
              src={location}
              width={14}
              height={14}
              alt="location"
            />
            <Typography
              variant="body5"
              color={theme.palette.darkGrey[600]}
              fontWeight={600}
              sx={{ marginLeft: '8px' }}
            >
              {city}
            </Typography>
          </Box>
          {condition && (
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '4px 12px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box
                component={'img'}
                src={stars}
                width={14}
                height={14}
                alt="stars"
              />
              <Typography
                variant="body5"
                color={theme.palette.darkGrey[600]}
                fontWeight={600}
                sx={{ marginLeft: '8px' }}
              >
                {condition}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {page === 'my-products' && (
        <Box
          marginTop="12px"
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Button
            size={'small'}
            buttontype={'orange'}
            marginRight={'16px'}
            onClick={() => navigate(`/marketplace/mano-produktai/${id}`)}
            width="100%"
          >
            {t('edit')}
          </Button>
          <Button
            bordered="true"
            buttontype={'white'}
            size={'small'}
            width="100%"
            onClick={() => confirmDelete()}
          >
            {t('delete')}
          </Button>
        </Box>
      )}
    </Card>
  );
}
