import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import chevronLeft from 'assets/icons/chevron-left.svg';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import Button from 'components/Button';
import SpinningBox from 'components/SpinningBox';
import { get, put } from 'api';
import { Cities, Times, CourtNr } from 'constants/Selections';
import { SelectInput } from 'components/Inputs';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';

const ScheduleMatch = ({ state }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    city: '',
    court: '',
    date: '',
    time: '',
    courtNumber: '',
  });
  const [open, setOpen] = useState({});
  const [tennisCourts, setTennisCourts] = useState([]);

  const { matchData } = state;
  const {
    challenger_full_name,
    challenger_id,
    challenger_image,
    opponent_full_name,
    opponent_id,
    opponent_image,
    city,
    match_city,
    match_date,
    match_court,
    match_time,
    match_court_number,
  } = matchData;

  useEffect(() => {
    const fetchCourts = async () => {
      const response = await get('/matches/courts');
      const data = await response.json();
      setTennisCourts(data);
    };
    fetchCourts();
  }, []);

  useEffect(() => {
    if (tennisCourts.length > 0) {
      setForm((prevForm) => ({
        ...prevForm,
        challenger_id,
        opponent_id,
        city: match_city || city,
        court:
          tennisCourts.find((court) => court.court_name === match_court)?.id ||
          '',
        date: match_date || '',
        time: match_time || '',
        courtNumber: match_court_number || '',
      }));
    }
  }, [matchData, tennisCourts]);

  const handleSelectChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const createMatchData = () => {
    const {
      match_id,
      opponent_id,
      challenger_id,
      tournament_id,
      match_number,
      tournament_level,
      tournament_gender,
      tournament_stage,
    } = matchData;

    return {
      match_id,
      challenger_id,
      opponent_id,
      match_date: moment(form.date).format('YYYY-MM-DD'),
      city: form.city,
      place: tennisCourts.find((court) => court.id === form.court)?.court_name,
      time: form.time,
      courtNumber: form.courtNumber,
      tournament_id,
      match_number,
      tournament_level,
      tournament_gender,
      tournament_stage,
    };
  };

  const handleRequest = async (event, url, successMessage) => {
    event.preventDefault();
    setIsSubmitting(true);
    const data = createMatchData();
    try {
      const response = await put(url, data);
      const result = await response.json();
      if (result.status === 'SUCCESS') {
        successToastRef.current.showSuccess(t(successMessage));
        setTimeout(() => {
          setForm({
            city: '',
            court: '',
            date: '',
            time: '',
            courtNumber: '',
          });
          setIsSubmitting(false);
          navigate('/info');
        }, 1500);
      } else {
        errorToastRef.current.showError(result.err);
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      errorToastRef.current.showError(err.message);
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (event) =>
    handleRequest(event, '/tournaments/schedule-match', 'matchScheduled');

  const handleCancelMatch = (event) =>
    handleRequest(event, '/tournaments/cancel-match', 'matchCancelled');

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const courtName = tennisCourts.find(
    (court) => court.id === form.court,
  )?.court_name;

  return (
    <Box
      sx={{
        marginBottom: '100px',
        width: '100%',
        maxWidth: '864px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '60vh',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate('/info')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
      >
        <Box
          component={'img'}
          src={chevronLeft}
          marginRight={'12px'}
          height={1}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('planMatchTitle')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('planMatchText')}
      </Typography>
      <Box
        sx={{
          marginTop: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            border: `1px solid ${theme.palette.darkGrey[100]}`,
            borderRadius: '8px',
            boxSizing: 'border-box',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.darkGrey[100],
              padding: '12px 16px',
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: { xs: '100%', sm: '288px' },
            }}
          >
            <Typography
              variant="body3"
              color={theme.palette.darkGrey[700]}
              sx={{ fontWeight: 700 }}
            >
              {t('players')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', sm: '288px' },
              alignItems: 'center',
              justifyContent: { xs: 'flex-start', sm: 'center' },
              borderRadius: '0px 8px 8px 0px',
              padding: '8px 12px',
              backgroundColor: 'transparent',
              borderTop: 'none',
              borderRight: 'none',
              borderBottom: 'none',
              boxSizing: 'border-box',
              height: '48px',
            }}
          >
            <Box
              component={'img'}
              src={
                challenger_image
                  ? `https://backend.tenisopartneris.lt/public/images/${challenger_image}`
                  : avatarGrey
              }
              alt="avatar"
              sx={{
                width: '32px',
                height: '32px',
                objectFit: 'cover',
                borderRadius: '50%',
                marginRight: '6px',
              }}
            />
            <Typography
              variant="body4"
              color={theme.palette.darkGrey[700]}
              sx={{ fontWeight: 600 }}
            >
              {challenger_full_name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', sm: '288px' },
              alignItems: 'center',
              justifyContent: { xs: 'flex-start', sm: 'center' },
              borderRadius: '0px 8px 8px 0px',
              padding: '8px 12px',
              backgroundColor: 'transparent',
              borderTop: 'none',
              borderRight: 'none',
              borderBottom: 'none',
              boxSizing: 'border-box',
              height: '48px',
            }}
          >
            <Box
              component={'img'}
              src={
                opponent_image
                  ? `https://backend.tenisopartneris.lt/public/images/${opponent_image}`
                  : avatarGrey
              }
              alt="avatar"
              sx={{
                width: '32px',
                height: '32px',
                objectFit: 'cover',
                borderRadius: '50%',
                marginRight: '6px',
              }}
            />
            <Typography
              variant="body4"
              color={theme.palette.darkGrey[700]}
              sx={{ fontWeight: 600 }}
            >
              {opponent_full_name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'100%'}
            marginTop={{ xs: '20px', sm: '0px' }}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('selectCity')}*
            </Typography>
            <SelectInput
              name="city"
              id="city"
              placeholder={t('selectCity')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleSelectChange}
              topMargin={'6px'}
              options={Cities.filter((city) => city.value !== '')}
              value={form.city}
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: { xs: '0px', sm: '28px' },
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'416px'}
            marginTop={{ xs: '20px', sm: '0px' }}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('tennisCourt')}*
            </Typography>
            <SelectInput
              name="court"
              id="court"
              placeholder={t('selectCourt')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleSelectChange}
              topMargin={'6px'}
              value={form.court}
              options={tennisCourts
                .filter((court) => court.city === form.city)
                .map((court) => {
                  return { value: court.id, label: court.court_name };
                })}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'416px'}
            marginTop={{ xs: '20px', sm: '0px' }}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('courtNumber')}
            </Typography>
            <SelectInput
              name="courtNumber"
              id="courtNumber"
              placeholder={t('selectCourtNumber')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleSelectChange}
              topMargin={'6px'}
              value={form.courtNumber}
              options={CourtNr}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: { xs: 'column', sm: 'row' },
            marginTop: '20px',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'416px'}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('selectDate')}*
            </Typography>
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  inputProps: {
                    style: {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[500],
                      display: 'flex',
                    },
                  },
                  InputLabelProps: {
                    style: {
                      fontSize: '16px',
                      color: theme.palette.darkGrey[500],
                    },
                  },
                },
              }}
              format="YYYY-MM-DD"
              onChange={(newValue) =>
                setForm({
                  ...form,
                  date: moment(newValue).format('YYYY-MM-DD'),
                })
              }
              value={moment(form.date)}
              minDate={moment().endOf('day')}
              sx={{
                mt: '6px',
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '416px' }}
            width={'416px'}
            marginTop={{ xs: '20px', sm: '0px' }}
          >
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('time')}
            </Typography>
            <SelectInput
              name="time"
              id="time"
              placeholder={t('selectTime')}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleChange={handleSelectChange}
              topMargin={'6px'}
              value={form.time}
              options={Times}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            marginTop: '20px',
          }}
        >
          <Button
            width="100%"
            bordered="true"
            buttontype={'white'}
            onClick={(e) => handleCancelMatch(e)}
            maxWidth={{ xs: '100%', sm: '416px' }}
            marginBottom={{ xs: '20px', sm: '0px' }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <SpinningBox /> : t('cancelScheduledMatch')}
          </Button>
          <Button
            width="100%"
            buttontype={'orange'}
            onClick={(e) => handleSubmit(e)}
            disabled={isSubmitting}
            maxWidth={{ xs: '100%', sm: '416px' }}
          >
            {isSubmitting ? <SpinningBox /> : t('scheduleMatch')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleMatch;
