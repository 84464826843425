import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import winterBG from 'assets/images/tournaments-image-5.jpg';
import easterBG from 'assets/images/tournaments-image.jpg';
import summerBG from 'assets/images/tournaments-image-4.jpg';
import autumnBG from 'assets/images/tournaments-image-7.jpg';
import { TournamentCard } from 'components/Cards';
import CustomPagination from 'components/Pagination';
import { get } from 'api';
import { useMediaQuery } from '@mui/material';

const PastTournaments = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pastTournaments, setPastTournaments] = useState([]);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const itemsPerPage = isMd ? 6 : 3;

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchPastTournaments = async () => {
      const response = await get('/tournaments/past-tournaments');
      const data = await response.json();
      setPastTournaments(data);
    };
    fetchPastTournaments();
  }, []);

  const paginatedTournaments = pastTournaments.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={'48px'}
      >
        <Box>
          <Typography
            variant="h4"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 700, marginBottom: '20px' }}
          >
            {t('pastTournamentsTitle')}
          </Typography>
          <Typography variant="body1" color={theme.palette.darkGrey[600]}>
            {t('pastTournamentsSubtitle')}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={{ xs: '48px', sm: '32px' }}>
        {paginatedTournaments.map((item, i) => {
          const tournamentBG = (() => {
            if (item.tournament_name.includes('ŽIEMA')) {
              return winterBG;
            } else if (item.tournament_name.includes('PAVASARIS')) {
              return easterBG;
            } else if (item.tournament_name.includes('VASARA')) {
              return summerBG;
            } else if (item.tournament_name.includes('RUDUO')) {
              return autumnBG;
            } else {
              return winterBG;
            }
          })();
          return (
            <Grid item xs={12} sm={6} md={5} key={i}>
              <TournamentCard
                id={item.id}
                type={item.tournament_type}
                tournament={item.tournament_name}
                image={tournamentBG}
                title={item.tournament_name}
                date={item.start_day}
                location={item.city}
                brief={t('tournamentCardBrief')}
                view={'system'}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box
        marginTop="20px"
        paddingTop="20px"
        maxWidth="100%"
        display="flex"
        justifyContent="center"
        borderTop={`1px solid ${theme.palette.darkGrey[200]}`}
      >
        <CustomPagination
          page={page}
          handlePaginationChange={handlePaginationChange}
          count={Math.ceil(pastTournaments.length / itemsPerPage)}
        />
      </Box>
    </Box>
  );
};

export default PastTournaments;
