import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { MarketplaceCard } from 'components/Cards';
import { SelectInput } from 'components/Inputs';
import Button from 'components/Button';
import { Categories, Cities, ProductCondition } from 'constants/Selections';
import { get } from 'api';
import CryptoJS from 'crypto-js';

const Marketplace = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState({});
  const [marketplace, setMarketplace] = useState([]);
  const [filteredMarketplace, setFilteredMarketplace] = useState([]);
  const [filters, setFilters] = useState({
    category: '',
    city: '',
    condition: '',
  });

  useEffect(() => {
    const getMarketplace = async () => {
      const response = await get('/marketplace/marketplace');
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setMarketplace(decryptedData);
    };
    getMarketplace();
  }, []);

  useEffect(() => {
    const filtered = marketplace.filter((item) => {
      const city =
        filters.city === '' ||
        item.city.toLowerCase() === filters.city.toLowerCase();
      const category =
        filters.category === '' ||
        item.product_category.toLowerCase() === filters.category.toLowerCase();
      const condition =
        filters.condition === '' ||
        item.product_usednew.toLowerCase() === filters.condition.toLowerCase();

      return city && category && condition;
    });
    setFilteredMarketplace(filtered);
  }, [filters, marketplace]);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={{ xs: '48px', sm: '64px' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box maxWidth={500} width={'100%'}>
          <Typography
            variant="h4"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 700, marginBottom: '16px' }}
          >
            {t('marketplaceTitle')}
          </Typography>
          <Typography variant="body1" color={theme.palette.darkGrey[600]}>
            {t('marketplaceSubtitle')}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: { xs: '100%', sm: '360px' },
            width: '100%',
            display: 'flex',
            marginTop: { xs: '24px', sm: '0px' },
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Button
            bordered="true"
            buttontype="white"
            onClick={() => navigate('/marketplace/mano-produktai')}
            width={{ xs: '100%' }}
          >
            {t('myProducts')}
          </Button>
          <Button
            buttontype="orange"
            marginLeft={{ xs: '0px', sm: '20px' }}
            marginTop={{ xs: '8px', sm: '0px' }}
            onClick={() => navigate('/marketplace/skelbti')}
            width={{ xs: '100%' }}
          >
            {t('addProduct')}
          </Button>
        </Box>
      </Box>
      <Box
        marginBottom={{ xs: '48px', sm: '64px' }}
        display={'flex'}
        flexWrap={'wrap'}
        gap={{ xs: '12px', sm: 0, md: '32px' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '341px' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectCategory')}
          </Typography>
          <SelectInput
            name="category"
            id="category"
            placeholder={t('selectCategory')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={Categories}
            value={filters.category}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '341px' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectCity')}
          </Typography>
          <SelectInput
            name="city"
            id="city"
            placeholder={t('selectCity')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={Cities}
            value={filters.city}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '341px' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectCondition')}
          </Typography>
          <SelectInput
            name="condition"
            id="condition"
            placeholder={t('selectCondition')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={ProductCondition}
            value={filters.condition}
          />
        </Box>
      </Box>
      <Grid container spacing={'32px'}>
        {filteredMarketplace.map((item) => (
          <Grid item xs={12} sm={4} key={item.id}>
            <MarketplaceCard
              id={item.id}
              image={item.images[0]}
              title={item.product_title}
              price={item.product_price}
              city={item.city}
              condition={item.product_usednew
                .split('_')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                )
                .join(' ')}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Marketplace;
