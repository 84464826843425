import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { get, post } from 'api';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import SpinningBox from 'components/SpinningBox';

const NewTournament = ({ setCurrentView }) => {
  const theme = useTheme();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [open, setOpen] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    city: '',
    tournamentName: '',
    tournamentType: '',
    earlyBirdCode: '',
    earlyBirdDate: '',
    registrationDate: '',
    tournamentStartDate: '',
    tournamentStartDay: '',
    finalsDate: '',
    finalDay: '',
    groupsDate: '',
    eliminationsDate: '',
    price: '',
    divisions: '',
  });

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const body = {
      city: form.city,
      tournament_name: form.tournamentName,
      tournament_type: form.tournamentType,
      early_bird: form.earlyBirdCode,
      early_bird_date: form.earlyBirdDate,
      registration_date: form.registrationDate,
      start_date: form.tournamentStartDate,
      start_day: form.tournamentStartDay,
      final_date: form.finalsDate,
      final_day: form.finalDay,
      groups_date: form.groupsDate,
      eliminations_date: form.eliminationsDate,
      price: form.price,
      divisions: form.divisions,
    };
    const response = await post('/tournaments/newTournament', body);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        setIsSubmitting(false);
        setForm({
          city: '',
          tournamentName: '',
          tournamentType: '',
          earlyBirdCode: '',
          earlyBirdDate: '',
          registrationDate: '',
          tournamentStartDate: '',
          tournamentStartDay: '',
          finalsDate: '',
          finalDay: '',
          groupsDate: '',
          eliminationsDate: '',
          price: '',
          divisions: '',
        });
        setCurrentView('list');
        window.scrollTo(0, 0);
      }, 2000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '100%', md: '516px' },
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Miestas:
          </Typography>
          <SelectInput
            name="city"
            id="city"
            placeholder="Pasirinkite miestą"
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.city}
            options={[
              { value: 'Vilnius', label: 'Vilnius' },
              { value: 'Kaunas', label: 'Kaunas' },
            ]}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={'Turnyro pavadinimas:'}
            id="tournamentName"
            name="tournamentName"
            type="text"
            placeholder={'Turnyro pavadinimas'}
            required
            handleInputChange={handleChange}
            value={form.tournamentName}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Turnyro tipas:
          </Typography>
          <SelectInput
            name="tournamentType"
            id="tournamentType"
            placeholder="Pasirinkite turnyro tipą"
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            value={form.level}
            options={[
              { value: 'Vienetai', label: 'Vienetai' },
              { value: 'Dvejetai', label: 'Dvejetai' },
              { value: 'MixDvejetai', label: 'Mix Dvejetai' },
            ]}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={'Early bird nuolaidos kodas:'}
            id="earlyBirdCode"
            name="earlyBirdCode"
            type="text"
            placeholder={'Pvz.: ISLIK5'}
            required
            handleInputChange={handleChange}
            value={form.earlyBirdCode}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Early bird nuolaida iki:
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                earlyBirdDate: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.earlyBirdDate)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Registracija iki:
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                registrationDate: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.registrationDate)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Turnyro pradžios data:
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                tournamentStartDate: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.tournamentStartDate)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={'Turnyro pradžios diena:'}
            id="tournamentStartDay"
            name="tournamentStartDay"
            type="text"
            placeholder={'Pvz.: Kovo 1d.'}
            required
            handleInputChange={handleChange}
            value={form.tournamentStartDay}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Finalų data:
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                finalsDate: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.finalsDate)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={'Turnyro finalinė diena:'}
            id="finalDay"
            name="finalDay"
            type="text"
            placeholder={'Pvz.: Kovo 1d.'}
            required
            handleInputChange={handleChange}
            value={form.finalDay}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Grupių etapas iki:
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                groupsDate: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.groupsDate)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            Atkrintamųjų etapas iki:
          </Typography>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                    display: 'flex',
                  },
                },
                InputLabelProps: {
                  style: {
                    fontSize: '16px',
                    color: theme.palette.darkGrey[500],
                  },
                },
              },
            }}
            format="YYYY-MM-DD"
            onChange={(newValue) =>
              setForm({
                ...form,
                eliminationsDate: moment(newValue).format('YYYY-MM-DD'),
              })
            }
            value={moment(form.eliminationsDate)}
            sx={{
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={'Turnyro mokestis:'}
            id="price"
            name="price"
            type="text"
            placeholder={'Pvz.: 40€'}
            required
            handleInputChange={handleChange}
            value={form.price}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', md: '100%' }}
          marginTop={'24px'}
        >
          <TextInput
            label={'Divizijos:'}
            id="divisions"
            name="divisions"
            type="text"
            placeholder={'Pvz.: 8'}
            required
            handleInputChange={handleChange}
            value={form.divisions}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          maxWidth={{ xs: '100%', md: '100%' }}
          width={'100%'}
          buttontype={'orange'}
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
};

export default NewTournament;
