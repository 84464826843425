import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import TopTable from '../Table/Table';
import { get, getTokenFromStorage } from 'api';

const MenRatings = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const token = getTokenFromStorage();
  const [topPlayers, setTopPlayers] = useState([]);

  useEffect(() => {
    const getTopPlayers = async () => {
      try {
        const response = await get('/ratings/men');
        const data = await response.json();
        setTopPlayers(data);
      } catch (err) {
        console.error(err.message);
      }
    };
    getTopPlayers();
  }, []);

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box maxWidth={{ xs: '100%', sm: '80%' }} width={'100%'}>
        <Typography
          variant="h5"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 700, marginBottom: '20px' }}
        >
          {t('menRating')}
        </Typography>
        <Typography variant="body3" color={theme.palette.darkGrey[600]}>
          {t('menRatingText')}
        </Typography>
      </Box>
      <TopTable data={topPlayers} />
    </Box>
  );
};

export default MenRatings;
