import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { useMediaQuery } from '@mui/material';

const PracticeCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      maxHeight={{ xs: '248px', sm: '206px', md: '229px' }}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={'768px'}
        width={'100%'}
        alignItems={'center'}
        marginBottom={'32px'}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            width: '100%',
            letterSpacing: '-0.72px',
            marginBottom: '20px',
            textAlign: { xs: 'left', sm: 'center' },
          }}
        >
          {t('practiceCardTitle')}
        </Typography>
        <Typography
          variant={isMd ? 'body2' : 'body3'}
          color={theme.palette.darkGrey[600]}
          sx={{
            fontWeight: 400,
            maxWidth: '501px',
            width: '100%',
            textAlign: { xs: 'left', sm: 'center' },
          }}
        >
          {t('practiceCardText')}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Button
          buttontype={'orange'}
          onClick={() => navigate('/kontaktai')}
          width={{ xs: '100%', sm: 'auto' }}
        >
          {t('bookNow')}
        </Button>
      </Box>
    </Box>
  );
};

export default PracticeCard;
