import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const Container = ({ children, ...rest }) => (
  <Box
    maxWidth={{ xs: 640, sm: 1024, md: 1440 }}
    width={1}
    marginX="auto"
    paddingX={{ xs: '16px', sm: '32px', md: '64px' }}
    paddingY={{ xs: '64px', sm: '32px', md: '96px' }}
    {...rest}
  >
    {children}
  </Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
