import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import shieldTick from 'assets/icons/shieldTick.svg';
import award from 'assets/icons/award.svg';
import perspective from 'assets/icons/perspective.svg';
import users from 'assets/icons/usersWhite.svg';
import { Typography, useMediaQuery } from '@mui/material';

const Values = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const valuesList = [
    {
      title: t('valueOneTitle'),
      subtitle: t('valueOneText'),
      icon: shieldTick,
    },
    {
      title: t('valueTwoTitle'),
      subtitle: t('valueTwoText'),
      icon: award,
    },
    {
      title: t('valueThreeTitle'),
      subtitle: t('valueThreeText'),
      icon: perspective,
    },
    {
      title: t('valueFourTitle'),
      subtitle: t('valueFourText'),
      icon: users,
    },
  ];

  return (
    <Box
      display={'flex'}
      width={'100%'}
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '464px', md: '416px' },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            letterSpacing: '-0.72px',
            marginBottom: '20px',
          }}
        >
          {t('valuesTitle')}
        </Typography>
        <Typography
          variant={isMd ? 'body1' : isSm ? 'body3' : 'body2'}
          sx={{
            fontWeight: 400,
            marginBottom: '40px',
            maxWidth: { xs: '100%', sm: '464px', md: '304px' },
          }}
        >
          {t('valuesSubtitle')}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {valuesList.map((item, i) => (
          <Grid item xs={12} sm={6} md={6} key={i}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              maxWidth={416}
              maxHeight={{ xs: 'auto', sm: 'auto' }}
              width={'100%'}
              minHeight={264}
              bgcolor={theme.palette.base.white}
              sx={{
                padding: { xs: '24px', md: '32px' },
                borderRadius: '16px',
                boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
              }}
            >
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: theme.palette.orange[600],
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '32px',
                  padding: '12px',
                }}
              >
                <Box
                  component={'img'}
                  src={item.icon}
                  alt="icon"
                  height={1}
                  width={1}
                  maxWidth={24}
                  maxHeight={24}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography
                  variant={'body1'}
                  color={theme.palette.darkGrey[700]}
                  sx={{
                    fontWeight: 600,
                    marginBottom: '8px',
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant={'body3'}
                  sx={{ color: theme.palette.darkGrey[600] }}
                >
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Values;
