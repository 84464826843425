import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import logoSmall from 'assets/logo/tp-logo.svg';
import globe02 from 'assets/icons/globe02.svg';
import chevronDown from 'assets/icons/chevronDown.svg';
import searchLg from 'assets/icons/search-lg.svg';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import hamburger from 'assets/icons/hamburger.svg';
import { Box, Typography, Button, Menu, MenuItem } from '@mui/material';
import { get } from 'api';
import Notification from './components/Notifications/Notification';

const dropdownNav = [
  {
    title: 'profile',
    href: '/info',
  },
  {
    title: 'myAccount',
    href: '/redaguoti-profili',
  },
  {
    title: 'logout',
  },
];

const dropdownLng = [
  {
    title: 'LT',
    lng: 'lt',
  },
  {
    title: 'EN',
    lng: 'en',
  },
];

const Topbar = ({ onSidebarOpen, pages }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [lngAnchorEl, setLngAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const lngOpen = Boolean(lngAnchorEl);
  const [players, setPlayers] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const { t, i18n } = useTranslation();

  const Placeholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <Box display={'flex'} alignItems={'center'}>
          <Box component={'img'} src={searchLg} alt="search" />{' '}
          <Box marginLeft={'8px'}>{props.children}</Box>
        </Box>
      </components.Placeholder>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLngClick = (event) => {
    setLngAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLngClose = () => {
    setLngAnchorEl(null);
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await get(`/accounts/myavatar`);
        const data = await response.json();
        setCurrentUser(data);
      } catch (err) {
        console.error(err.message);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    const getPlayers = async () => {
      try {
        const response = await get(`/accounts/usersearch`);
        const data = await response.json();
        const players = data.map((player) => {
          return {
            value: player.id,
            label: `${player.first_name} ${player.last_name}`,
          };
        });
        setPlayers(players);
      } catch (err) {
        console.error(err.message);
      }
    };
    getPlayers();
  }, []);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box display={'flex'} alignItems={'center'} width={'100%'}>
        <Box
          display={'flex'}
          onClick={() => navigate('/info')}
          width={{ xs: 55, md: 55 }}
          sx={{ cursor: 'pointer' }}
        >
          <Box
            component={'img'}
            alt="logo"
            src={logoSmall}
            height={1}
            width={1}
          />
        </Box>
        <Box
          sx={{
            height: '44px',
            width: '100%',
            maxWidth: '308px',
            marginLeft: '168px',
            marginTop: 0,
            marginBottom: 0,
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Select
            options={players}
            placeholder={t('findPlayer')}
            onChange={(value) => {
              navigate(`/info/${value.value}`, { replace: true });
              window.location.reload();
            }}
            components={{ Placeholder }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                boxShadow: 'none',
                borderRadius: '8px',
                border: `1px solid ${theme.palette.darkGrey[300]}`,
                backgroundColor: theme.palette.base.white,
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: 'none',
                },
              }),
              menu: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                color: theme.palette.darkGrey[600],
                backgroundColor: theme.palette.base.white,
              }),
              option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                color: theme.palette.darkGrey[600],
                backgroundColor: theme.palette.base.white,
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                fontFamily: 'Inter, sans-serif',
                '&:hover': {
                  backgroundColor: theme.palette.darkGrey[50],
                  color: theme.palette.darkGrey[600],
                },
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: theme.palette.darkGrey[600],
                backgroundColor: theme.palette.base.white,
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                fontFamily: 'Inter, sans-serif',
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: theme.palette.darkGrey[500],
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                fontFamily: 'Inter, sans-serif',
              }),
            }}
          />
        </Box>
      </Box>
      <Box
        display={{ xs: 'none', md: 'flex' }}
        width={'100%'}
        justifyContent={'flex-end'}
      >
        <Notification />
        <Box marginLeft={'32px'} display={'flex'} alignItems={'center'}>
          <Button
            id="basic-button"
            aria-controls={lngOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={lngOpen ? 'true' : undefined}
            onClick={handleLngClick}
            sx={{
              minWidth: 'auto',
              padding: 0,
              '&:hover': {
                opacity: 0.7,
              },
            }}
          >
            <Box component={'img'} src={globe02} alt="globe02" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={lngAnchorEl}
            open={lngOpen}
            onClose={handleLngClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiMenu-paper': {
                backgroundColor: theme.palette.base.white,
                boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
                borderRadius: '8px',
                top: '65px !important',
                width: '55px',
                height: 'auto',
                padding: '16px !important',
                paddingTop: '0px !important',
                paddingBottom: '0px !important',
              },
            }}
          >
            {dropdownLng.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleChangeLanguage(item.lng);
                  handleLngClose();
                }}
                sx={{
                  padding: '0px !important',
                  '&:not(:last-child)': {
                    marginBottom: '12px !important',
                  },
                }}
              >
                <Typography
                  variant={'body3'}
                  fontWeight={600}
                  fontStyle={'normal'}
                  color={theme.palette.darkGrey[700]}
                >
                  {item.title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box marginLeft={'32px'} display={'flex'} alignItems={'center'}>
          <Box
            component={'img'}
            src={
              currentUser.image
                ? `https://backend.tenisopartneris.lt/public/images/${currentUser.image}`
                : avatarWhite
            }
            alt="avatar"
            width={'24px'}
            height={'24px'}
            borderRadius={'50%'}
            sx={{
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box marginLeft={'12px'} display={'flex'} alignItems={'center'}>
          <Typography
            variant={'body3'}
            fontWeight={600}
            fontStyle={'normal'}
            color={theme.palette.base.white}
          >
            {currentUser.first_name}
          </Typography>
        </Box>
        <Box marginLeft={'12px'} display={'flex'} alignItems={'center'}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              minWidth: 'auto',
              padding: 0,
              '&:hover': {
                opacity: 0.7,
              },
            }}
          >
            <Box component={'img'} src={chevronDown} alt="chevronDown" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiMenu-paper': {
                backgroundColor: theme.palette.base.white,
                boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
                borderRadius: '8px',
                top: '60px !important',
                width: '160px',
                height: 'auto',
                padding: '16px !important',
                paddingTop: '0px !important',
                paddingBottom: '0px !important',
              },
            }}
          >
            {dropdownNav.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  if (item.title === 'logout') {
                    handleLogout();
                  } else {
                    navigate(item.href);
                  }
                }}
                sx={{
                  padding: '0px !important',
                  '&:not(:last-child)': {
                    marginBottom: '12px !important',
                  },
                }}
              >
                <Typography
                  variant={'body3'}
                  fontWeight={600}
                  fontStyle={'normal'}
                  color={theme.palette.darkGrey[700]}
                >
                  {t(item.title)}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          buttontype="white"
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          sx={{
            minWidth: 'auto',
            padding: 1,
            border: 'none',
            backgroundColor: 'inherit',
          }}
        >
          <Box
            component={'img'}
            src={hamburger}
            alt="hamburger"
            width={'29px'}
            height={'29px'}
          />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      topBar: PropTypes.bool,
    }),
  ),
  colorInvert: PropTypes.bool,
};

export default Topbar;
