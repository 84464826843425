import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import {
  Hero,
  Partners,
  Blog,
  Tournaments,
  PerfectMatchCard,
} from './components';
import { AccountCard } from 'components/Cards';

const HomePage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        position={'relative'}
        sx={{
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(180deg, #F1FBFC 0%, #F5F6F7 100%)',
          }}
        >
          <Container
            sx={{
              paddingTop: { xs: '40px !important' },
              paddingBottom: { xs: '60px !important', md: '64px !important' },
              height: { xs: '100%', sm: '700px', md: '90vh' },
            }}
          >
            <Hero />
          </Container>
        </Box>
      </Box>
      <Container>
        <Tournaments />
      </Container>
      <Box
        sx={{
          background: theme.palette.darkGrey[50],
        }}
      >
        <Container>
          <Partners />
        </Container>
      </Box>
      <Container
        sx={{
          paddingBottom: { sm: '80px !important', md: '96px !important' },
          paddingTop: { sm: '80px !important', md: '96px !important' },
        }}
      >
        <PerfectMatchCard />
      </Container>
      <Box
        sx={{
          background: theme.palette.darkGrey[50],
        }}
      >
        <Container>
          <Blog />
        </Container>
      </Box>
      <Container
        sx={{
          paddingBottom: { sm: '80px !important', md: '96px !important' },
          paddingTop: { sm: '80px !important', md: '96px !important' },
        }}
      >
        <AccountCard />
      </Container>
    </Main>
  );
};

export default HomePage;
