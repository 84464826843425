import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mission from 'assets/images/mission.png';
import orangeCheck from 'assets/icons/orangeCheck.svg';

const MissionCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const missionList = [
    {
      title: t('missionOne'),
    },
    {
      title: t('missionTwo'),
    },
    {
      title: t('missionThree'),
    },
    {
      title: t('missionFour'),
    },
  ];

  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
        maxHeight: { xs: 'auto', sm: 440, md: 400 },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: {
            xs: '64px 24px 48px 24px',
            sm: '32px 64px 32px 32px',
            md: '0 64px',
          },
        }}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            letterSpacing: '-0.72px',
            marginBottom: '20px',
          }}
        >
          {t('missionTitle')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          {missionList.map((item, i) => (
            <Box
              key={i}
              display={'flex'}
              alignItems={'center'}
              sx={{ '&:not(:last-of-type)': { marginBottom: '20px' } }}
            >
              <Box
                component={'img'}
                alt="check icon"
                src={orangeCheck}
                width={28}
                height={28}
                sx={{ marginRight: '12px' }}
              />
              <Typography
                variant={'body2'}
                color={theme.palette.darkGrey[600]}
                sx={{ fontWeight: 400 }}
              >
                {item.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        display={'flex'}
        maxWidth={{ xs: '100%', md: '528px' }}
        width={'100%'}
        sx={{
          height: { xs: 'auto', sm: 440, md: 400 },
        }}
      >
        <Box
          component={'img'}
          src={mission}
          alt="mission image"
          height={'100%'}
          maxWidth={'100%'}
          sx={{
            objectFit: 'cover',
            objectPosition: { xs: '10% 50%', md: '50% 50%' },
            borderBottomRightRadius: '16px',
            borderBottomLeftRadius: { xs: '16px', sm: 0 },
            borderTopRightRadius: { xs: 0, sm: '16px' },
          }}
        />
      </Box>
    </Box>
  );
};

export default MissionCard;
