import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from 'components/Button';
import { get, post } from 'api';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import ExcelExport from 'utils/ExcelExport';

const Files = () => {
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [allUsers, setAllUsers] = useState();
  const [tournamentUsers, setTournamentUsers] = useState();
  const [dataLoaded, setDataLoaded] = useState(true);

  const handleUsersClick = async () => {
    try {
      const response = await get('/accounts/users');
      const users = await response.json();
      setAllUsers(users.data);
      if (users.data) setDataLoaded(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTournamentUsersClick = async () => {
    try {
      const response = await get('/tournaments/tournament-users');
      const tournamentUsers = await response.json();
      setTournamentUsers(tournamentUsers.data);
      if (tournamentUsers.data) setDataLoaded(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataForExcels = () => {
    handleUsersClick();
    handleTournamentUsersClick();
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '100%', md: '516px' },
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Button
          maxWidth={{ xs: '100%', md: '50%' }}
          width={'100%'}
          buttontype={'orange'}
          size={'small'}
          onClick={() => getDataForExcels()}
        >
          Užkrauti duomenis
        </Button>
        <ExcelExport
          buttonTitle="Vartotojų sąrašas"
          excelData={allUsers}
          fileName="Users"
          disabled={dataLoaded}
        />
        <ExcelExport
          buttonTitle="Turnyro dalyviai"
          excelData={tournamentUsers}
          fileName="Tournament users"
          disabled={dataLoaded}
        />
      </Box>
    </Box>
  );
};

export default Files;
