import React from 'react';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { Hero, InfoCards } from './components';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SupportPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Hero />
      <Container
        sx={{
          paddingBottom: {
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '80px !important',
            md: '96px !important',
          },
        }}
      >
        <InfoCards />
      </Container>
    </Main>
  );
};

export default SupportPage;
