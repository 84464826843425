import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import chevronRight from 'assets/icons/chevron-right.svg';
import winterBG from 'assets/images/tournaments-image-5.jpg';
import easterBG from 'assets/images/tournaments-image.jpg';
import summerBG from 'assets/images/tournaments-image-4.jpg';
import autumnBG from 'assets/images/tournaments-image-7.jpg';
import { TournamentCard } from 'components/Cards';
import { useMediaQuery } from '@mui/material';
import { get } from 'api';

const Tournaments = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const [activeTournaments, setActiveTournaments] = useState([]);

  useEffect(() => {
    const fetchActiveTournaments = async () => {
      const response = await get('/tournaments/active-tournaments');
      const data = await response.json();
      const sortedData = data.sort((a, b) => {
        return moment(b.start_date).diff(moment(a.start_date));
      });
      setActiveTournaments(sortedData);
    };
    fetchActiveTournaments();
  }, []);

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={'48px'}
      >
        <Box>
          <Typography
            variant={isMd ? 'h4' : 'h5'}
            color={theme.palette.darkGrey[700]}
            sx={{
              fontWeight: 700,
              marginBottom: { xs: '16px', sm: '20px' },
            }}
          >
            {t('homeTournamentsTitle')}
          </Typography>
          <Typography
            variant={isMd ? 'body1' : isSm ? 'body2' : 'body3'}
            color={theme.palette.darkGrey[600]}
          >
            {t('homeTournamentsSubtitle')}
          </Typography>
        </Box>
        <Box
          display={{ xs: 'none', sm: 'flex' }}
          alignItems={'center'}
          sx={{
            cursor: 'pointer',
            marginBottom: '32px',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'translateX(3px)',
            },
          }}
          onClick={() => navigate('/turnyrai')}
        >
          <Typography
            variant="body3"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 600 }}
          >
            {t('seeAllTournaments')}
          </Typography>
          <Box component={'img'} src={chevronRight} alt="chevron right" />
        </Box>
      </Box>
      <Grid container spacing={{ xs: '48px', sm: '32px' }}>
        {activeTournaments.slice(0, 3).map((item, i) => {
          const tournamentBG = (() => {
            if (item.tournament_name.includes('ŽIEMA')) {
              return winterBG;
            } else if (item.tournament_name.includes('PAVASARIS')) {
              return easterBG;
            } else if (item.tournament_name.includes('VASARA')) {
              return summerBG;
            } else if (item.tournament_name.includes('RUDUO')) {
              return autumnBG;
            } else {
              return winterBG;
            }
          })();
          return (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <TournamentCard
                id={item.id}
                type={item.tournament_type}
                tournament={item.tournament_name}
                image={tournamentBG}
                title={item.tournament_name}
                date={item.start_day}
                location={item.city}
                brief={t('tournamentCardBrief')}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box
        display={{ xs: 'flex', sm: 'none' }}
        alignItems={'center'}
        sx={{ cursor: 'pointer', marginTop: '48px' }}
        onClick={() => navigate('/turnyrai')}
      >
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('seeAllTournaments')}
        </Typography>
        <Box component={'img'} src={chevronRight} alt="chevron right" />
      </Box>
    </Box>
  );
};

export default Tournaments;
