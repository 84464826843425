import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import plusIcon from 'assets/icons/plus-circle.svg';
import minusIcon from 'assets/icons/minus-circle.svg';
import { Box, Typography } from '@mui/material';
import { FAQS } from '../PrimaryAccordion/FAQlist';

const SecondaryAccordion = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ marginTop: '16px' }}>
      {FAQS.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
          sx={{
            backgroundColor:
              expanded === index
                ? theme.palette.darkGrey[50]
                : theme.palette.base.white,
            borderRadius: '16px !important',
            boxShadow: 'none',
            padding: { xs: '24px !important', md: '32px !important' },
            margin: '0 !important',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img src={expanded === index ? minusIcon : plusIcon} />}
            aria-controls="panel1d-content"
            alt="plus icon"
            id="panel1d-header"
            sx={{
              padding: '0 !important',
              minHeight: '0 !important',
              '& > .MuiAccordionSummary-content': {
                margin: '0 !important',
              },
            }}
          >
            <Typography
              variant="body2"
              color={theme.palette.darkGrey[800]}
              fontWeight={500}
            >
              {t(item.question)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0 !important', marginTop: '8px' }}>
            <Box sx={{ maxWidth: '751px', width: '100%' }}>
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[600]}
                fontWeight={500}
              >
                {t(item.answer)}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default SecondaryAccordion;
