import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import chevronLeft from 'assets/icons/chevron-left.svg';
import locationIcon from 'assets/icons/location.svg';
import subjectIcon from 'assets/icons/pencil-line.svg';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import Button from 'components/Button';
import SpinningBox from 'components/SpinningBox';
import {
  getUserEmailFromToken,
  getUserFullNameFromToken,
  getUserIdFromToken,
  post,
} from 'api';
import moment from 'moment';

const MultiMessageCard = ({ state }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tournamentPlayers, setTournamentPlayers] = useState([]);
  const [form, setForm] = useState({
    mailText: '',
    mailTo: [],
    mailFrom: getUserEmailFromToken(),
    mailFromName: getUserFullNameFromToken(),
  });

  const { upcomingMatches } = state;
  const location = upcomingMatches[0].city;

  const isChallenger = (item) => {
    const challengerId = id ? parseInt(id) : getUserIdFromToken();
    return item.challenger_id === challengerId;
  };

  useEffect(() => {
    const mailSubject = `${upcomingMatches[0].tournament_name} (${upcomingMatches[0].city})`;
    const mailTo = tournamentPlayers.map((player) => player.email);
    setForm({ ...form, mailSubject, mailTo });
  }, [tournamentPlayers, upcomingMatches]);

  useEffect(() => {
    const opponents = upcomingMatches.reduce((acc, match) => {
      if (isChallenger(match)) {
        acc.push({
          id: match.opponent_id,
          fullName: match.opponent_full_name,
          image: match.opponent_image,
          email: match.opponent_email,
          match_city: match.match_city,
          match_court: match.match_court,
          match_date: match.match_date,
          match_time: match.match_time,
        });
      } else {
        acc.push({
          id: match.challenger_id,
          fullName: match.challenger_full_name,
          image: match.challenger_image,
          email: match.challenger_email,
          match_city: match.city,
          match_court: match.court,
          match_date: match.date,
          match_time: match.time,
        });
      }
      return acc;
    }, []);

    setTournamentPlayers(opponents);
  }, [upcomingMatches]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSend = async () => {
    setIsSubmitting(true);
    const mailText = form.mailText.replace(/\n/g, '<br>');
    const pageFrom = 'tournament';
    const response = await post('/tournaments/sendmail', {
      ...form,
      mailText,
      pageFrom,
    });
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setIsSubmitting(false);
      setForm({
        ...form,
        mailText: '',
      });
      setTimeout(() => {
        navigate('/info');
      }, 2000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: '100px',
        margin: '0 auto',
        maxWidth: '416px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate('/info')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
      >
        <Box
          component={'img'}
          src={chevronLeft}
          marginRight={'12px'}
          height={1}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('sendMessage')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('sendMessageTournament')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '32px',
        }}
      >
        <Grid container spacing={'16px'}>
          {tournamentPlayers.map((player, index) => {
            const { match_city, match_court, match_date, match_time } = player;
            const matchDate =
              match_date && moment(match_date).format('YYYY-MM-DD');
            const matchTime = match_time ? ` ${match_time}` : '';
            const haveMatchInfo = match_city && match_court && matchDate;
            const matchInfoString = haveMatchInfo
              ? `(${matchDate}${matchTime})`
              : '';
            return (
              <Grid item xs={12} md={12} key={index}>
                <Box display={'flex'} alignItems={'center'}>
                  <Checkbox
                    onChange={(e) =>
                      setForm({
                        ...form,
                        mailTo: e.target.checked
                          ? [...form.mailTo, player.email]
                          : form.mailTo.filter((item) => item !== player.email),
                      })
                    }
                    checked={form.mailTo.includes(player.email)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      width: '20px',
                      height: '20px',
                      color: 'transparent',
                      borderRadius: '4px',
                      border: `1px solid ${theme.palette.grey[300]}`,
                      '&.Mui-checked': {
                        color: theme.palette.orange[600],
                      },
                    }}
                  />
                  <Box
                    display={'flex'}
                    width="20px"
                    height="20px"
                    marginLeft={'8px'}
                  >
                    <Box
                      component={'img'}
                      src={
                        player.image
                          ? `https://backend.tenisopartneris.lt/public/images/${player.image}`
                          : avatarGrey
                      }
                      alt="avatar"
                      height={1}
                      width={1}
                      borderRadius={'50%'}
                      sx={{
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                  <Typography
                    variant={'body4'}
                    fontWeight={600}
                    marginLeft={'8px'}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {player.fullName} {matchInfoString}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box display={'flex'} alignItems={'center'} marginTop={'20px'}>
        <Box component={'img'} src={locationIcon} height={1} alt="location" />
        <Typography
          variant={'body3'}
          fontWeight={600}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[700]}
        >
          {t('city')}:
        </Typography>
        <Typography
          variant={'body3'}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[600]}
        >
          {location}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} marginTop={'8px'}>
        <Box component={'img'} src={subjectIcon} height={1} alt="subject" />
        <Typography
          variant={'body3'}
          fontWeight={600}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[700]}
        >
          {t('subject')}:
        </Typography>
        <Typography
          variant={'body3'}
          marginLeft={'8px'}
          color={theme.palette.darkGrey[600]}
        >
          {form.mailSubject}
        </Typography>
      </Box>
      <Box marginTop={'20px'}>
        <Typography
          variant="body4"
          fontWeight={500}
          color={theme.palette.darkGrey[700]}
        >
          {t('message')}:
        </Typography>
        <TextField
          hiddenLabel
          margin="normal"
          fullWidth
          id="mailText"
          name="mailText"
          multiline
          rows={4}
          placeholder={t('typeMessage')}
          inputProps={{
            sx: {
              fontSize: '16px',
              lineHeight: 1.5,
              color: theme.palette.darkGrey[500],
              '&::placeholder': {
                color: theme.palette.darkGrey[500],
                opacity: 1,
                fontSize: '16px',
              },
            },
          }}
          sx={{
            margin: 0,
            mt: '6px',
          }}
          onChange={handleChange}
          value={form.mailText}
        />
      </Box>
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          maxWidth={{ xs: '100%', md: '100%' }}
          width={'100%'}
          buttontype={'orange'}
          onClick={() => handleSend()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : t('sendMessage')}
        </Button>
      </Box>
    </Box>
  );
};

export default MultiMessageCard;
