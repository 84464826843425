import { useLocation } from 'react-router-dom';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { PartnerResult, TournamentResult } from './components';

const ResultsPage = () => {
  const location = useLocation();
  const state = location.state;

  return (
    <Main>
      <ContainerSystem
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
      >
        {state?.isTournament ? (
          <TournamentResult state={state} />
        ) : (
          <PartnerResult state={state} />
        )}
      </ContainerSystem>
    </Main>
  );
};

export default ResultsPage;
