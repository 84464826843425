import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import calendar from 'assets/icons/calendar.svg';
import clock from 'assets/icons/clock.svg';
import locationIcon from 'assets/icons/location.svg';
import racquet from 'assets/icons/racquet.svg';
import infoSquare from 'assets/icons/infoSquare.svg';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import Button from 'components/Button';
import { post, getUserIdFromToken, remove } from 'api';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';

export default function PartnerCard({
  matchId,
  avatar,
  name,
  ntrp,
  date,
  time,
  location,
  tennisCourt,
  details,
  page,
  fetchMatches,
  userId,
  mailTo,
  hideButtons,
  matchData,
  type,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const currentUserId = getUserIdFromToken();
  const isMyMatch = currentUserId === userId;
  const dateMatch = moment(date, 'YYYY-MM-DD');
  const timeMatch = moment(time, 'HH:mm:ss');
  const dateTimeMatch = moment(
    `${dateMatch.format('YYYY-MM-DD')}T${timeMatch.format('HH:mm:ss')}`,
  );
  const isDateTimeAfter = moment().isAfter(dateTimeMatch);

  const handleConnect = async () => {
    const body = {
      user_id: currentUserId,
    };
    const response = await post(`/accounts/opponent/${matchId}`, body);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('matchConfirmed'));
      fetchMatches();
      setTimeout(() => {
        navigate('/info');
      }, 2000);
    } else {
      errorToastRef.current.showError(data.err);
    }
  };

  const confirmConnection = () => {
    confirmAlert({
      title: '',
      message: t('confirmConnection'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => handleConnect(),
        },
        {
          label: t('no'),
        },
      ],
    });
  };

  const handleDelete = async () => {
    const response = await remove(`/matches/matches/${matchId}`);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('matchDeleted'));
      setTimeout(() => {
        fetchMatches();
      }, 1500);
    } else {
      errorToastRef.current.showError(data.err);
    }
  };

  const confirmDelete = () => {
    confirmAlert({
      title: '',
      message: t('confirmDeleteMatch'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => handleDelete(),
        },
        {
          label: t('no'),
        },
      ],
    });
  };

  const handleCancelation = async () => {
    const response = await post(`/matchinfo/cancelmatch`, { id: matchId });
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('matchCanceled'));
      setTimeout(() => {
        fetchMatches();
      }, 1500);
    } else {
      errorToastRef.current.showError(data.err);
    }
  };

  const confirmCancelation = () => {
    confirmAlert({
      title: '',
      message: t('confirmCancelMatch'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => handleCancelation(),
        },
        {
          label: t('no'),
        },
      ],
    });
  };

  return (
    <Card
      sx={{
        maxWidth: { xs: '100%', sm: '416px' },
        minHeight: { xs: '100%', sm: hideButtons ? 'auto' : '432px' },
        maxHeight: 'auto',
        width: '100%',
        borderRadius: '16px',
        padding: { xs: '24px', sm: '32px' },
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <CardContent
        sx={{
          padding: 0,
          minHeight: { xs: '100%', sm: hideButtons ? 'auto' : '288px' },
          '&:last-child': { paddingBottom: 0 },
        }}
        height={'100%'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'} height={60} width={60}>
              <Box
                component={'img'}
                src={
                  avatar
                    ? `https://backend.tenisopartneris.lt/public/images/${avatar}`
                    : avatarGrey
                }
                alt="Author avatar"
                height={60}
                width={60}
                borderRadius={'50%'}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box
              component={'a'}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  textDecorationColor: theme.palette.darkGrey[700],
                },
              }}
              href={`${window.location.origin}/info/${userId}`}
            >
              <Typography
                variant={'body3'}
                fontWeight={600}
                marginLeft={'16px'}
                color={theme.palette.darkGrey[700]}
              >
                {name}
              </Typography>
            </Box>
          </Box>
          <Box
            height={'28px'}
            backgroundColor={theme.palette.darkGrey[100]}
            borderRadius={'40px'}
            padding={'4px 20px'}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography
              variant={'body5'}
              fontWeight={600}
              color={theme.palette.orange[700]}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {type === 'Vienetai'
                ? t('singles')
                : type === 'Dvejetai'
                ? t('doubles')
                : t('mixed')}
              &nbsp;
            </Typography>
            <Typography
              variant={'body5'}
              fontWeight={600}
              color={theme.palette.orange[700]}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {ntrp} NTRP
            </Typography>
          </Box>
        </Box>
        <Box marginTop={'20px'} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box
              component={'img'}
              src={calendar}
              height={1}
              borderRadius={'50%'}
              alt="calendar"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('date')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'4px'}
              color={theme.palette.darkGrey[600]}
            >
              {date}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'10px'}>
            <Box
              component={'img'}
              src={clock}
              height={1}
              borderRadius={'50%'}
              alt="clock"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('time')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'4px'}
              color={theme.palette.darkGrey[600]}
            >
              {time === '23:59:00'
                ? t('anyTime')
                : moment(time, 'HH:mm:ss').format('HH:mm')}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={'10px'}>
            <Box
              component={'img'}
              src={locationIcon}
              height={1}
              borderRadius={'50%'}
              alt="location"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('location')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'4px'}
              color={theme.palette.darkGrey[600]}
            >
              {location}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} marginTop={'10px'}>
            <Box
              component={'img'}
              src={racquet}
              height={1}
              borderRadius={'50%'}
              alt="racquet"
            />
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('court')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'4px'}
              color={theme.palette.darkGrey[600]}
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {!tennisCourt ? t('notSelected') : tennisCourt}
            </Typography>
          </Box>
          {details && (
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              marginTop={'10px'}
              flexDirection={'column'}
            >
              <Box display={'flex'} alignItems={'center'} mb={'4px'}>
                <Box
                  component={'img'}
                  src={infoSquare}
                  height={1}
                  borderRadius={'50%'}
                  alt="info"
                />
                <Typography
                  variant={'body3'}
                  fontWeight={600}
                  marginLeft={'8px'}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('details')}:
                </Typography>
              </Box>
              <Typography
                variant={'body3'}
                marginLeft={'4px'}
                color={theme.palette.darkGrey[600]}
              >
                {details}
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
      {(!hideButtons || hideButtons === 'undefined') && (
        <>
          {page === 'overview' && (
            <Box marginTop="32px">
              {!isDateTimeAfter && (
                <Button
                  width={'100%'}
                  buttontype="orange"
                  onClick={() =>
                    navigate('/siusti-zinute', {
                      state: {
                        userId,
                        date,
                        time,
                        tennisCourt,
                        name,
                        avatar,
                        ntrp,
                        location,
                        mailTo,
                        mailSubject: `Teniso Partneris - susitikimas ${date}`,
                        isTournament: false,
                      },
                    })
                  }
                >
                  {t('sendMessage')}
                </Button>
              )}
              {isDateTimeAfter ? (
                <Button
                  marginTop={'12px'}
                  width={'100%'}
                  buttontype="orange"
                  onClick={() =>
                    navigate('/ivesti-rezultata', {
                      state: { matchData, isTournament: false },
                    })
                  }
                >
                  {t('enterResult')}
                </Button>
              ) : (
                <Button
                  marginTop={'12px'}
                  width={'100%'}
                  buttontype="white"
                  bordered="true"
                  onClick={() => confirmCancelation()}
                >
                  {t('cancelConnection')}
                </Button>
              )}
            </Box>
          )}
          {page === 'find-partner' && (
            <Box marginTop="32px">
              {isMyMatch && (
                <Button
                  width={'100%'}
                  buttontype="orange"
                  onClick={() => confirmDelete()}
                >
                  {t('delete')}
                </Button>
              )}
              {!isMyMatch && (
                <Button
                  width={'100%'}
                  buttontype="orange"
                  onClick={() => confirmConnection()}
                >
                  {t('connect')}
                </Button>
              )}
              {!isMyMatch && (
                <Button
                  marginTop={'12px'}
                  width={'100%'}
                  buttontype="white"
                  bordered="true"
                  onClick={() =>
                    navigate('/siusti-zinute', {
                      state: {
                        userId,
                        date,
                        time,
                        tennisCourt,
                        name,
                        avatar,
                        ntrp,
                        location,
                        mailTo,
                        mailSubject: `Teniso Partneris - susitikimas ${date}`,
                        isTournament: false,
                      },
                    })
                  }
                >
                  {t('sendMessage')}
                </Button>
              )}
            </Box>
          )}
          {page !== 'overview' && page !== 'find-partner' && (
            <Box marginTop="32px">
              <Button
                width={'100%'}
                buttontype="orange"
                onClick={() =>
                  navigate('/partnerio-paieska', {
                    state: { to: '/partnerio-paieska' },
                  })
                }
              >
                {t('connect')}
              </Button>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}
