import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';

const ParticipantCard = ({ name, surname, avatar }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      width={1}
      maxWidth={{ xs: '100%', md: '304px' }}
      height={'52px'}
    >
      <Box
        component={'img'}
        src={
          avatar
            ? `https://backend.tenisopartneris.lt/public/images/${avatar}`
            : avatarGrey
        }
        height={52}
        width={52}
        borderRadius={'50%'}
        alt="Author avatar"
        sx={{
          objectFit: 'cover',
        }}
      />
      <Typography
        variant={'body3'}
        sx={{ fontWeight: 600, marginLeft: '16px' }}
      >
        {name} {surname}
      </Typography>
    </Box>
  );
};

export default ParticipantCard;
