import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import InstructionsModal from '../Modal/Modal';
import Button from 'components/Button';

const InfoCards = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '299px', md: '416px' },
          width: '100%',
          height: { xs: '100%', sm: '358px', md: '270px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: { xs: '24px', sm: '32px' },
          borderRadius: '16px',
          boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            variant={'body1'}
            sx={{
              fontWeight: 700,
              color: theme.palette.darkGrey[700],
              marginBottom: '16px',
            }}
          >
            1,2% {t('support')}
          </Typography>
          <Typography
            variant={'body3'}
            color={theme.palette.darkGrey[600]}
            marginBottom={'8px'}
          >
            <strong>Paramos gavėjo kodas (numeris): </strong>305710256
          </Typography>
          <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
            <strong>Paramos gavėjo pavadinimas: </strong>Všį Teniso partneris
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '32px',
            height: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            width: '100%',
          }}
        >
          <Button
            buttontype={'orange'}
            width={{ xs: '100%', sm: 'auto' }}
            onClick={() => window.open('https://deklaravimas.vmi.lt/')}
            marginBottom={{ xs: '16px', sm: '16px', md: '0px' }}
            marginRight={{ xs: '0px', sm: '0px', md: '16px' }}
          >
            {t('dedicate')}
          </Button>
          <InstructionsModal />
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '299px', md: '416px' },
          width: '100%',
          height: { xs: '100%', sm: '358px', md: '270px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '32px',
          borderRadius: '16px',
          boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
          marginTop: { xs: '32px', sm: '0px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            variant={'body1'}
            sx={{
              fontWeight: 700,
              color: theme.palette.darkGrey[700],
              marginBottom: '16px',
            }}
          >
            {t('bankInfo')}
          </Typography>
          <Typography
            variant={'body3'}
            fontWeight={700}
            color={theme.palette.darkGrey[600]}
            marginBottom={'8px'}
          >
            VŠĮ TENISO PARTNERIS
          </Typography>
          <Typography
            variant={'body3'}
            color={theme.palette.darkGrey[600]}
            marginBottom={'8px'}
          >
            <strong>Įmonės kodas: </strong>305710256
          </Typography>
          <Typography
            variant={'body3'}
            color={theme.palette.darkGrey[600]}
            marginBottom={'8px'}
          >
            <strong>Sąskaita: </strong>LT247044090101632918
          </Typography>
          <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
            <strong>Bankas: </strong>AB SEB bankas
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '299px', md: '416px' },
          width: '100%',
          height: { xs: '100%', sm: '358px', md: '270px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '32px',
          borderRadius: '16px',
          boxShadow: '0px 2px 13px 0px rgba(0, 0, 0, 0.08)',
          marginTop: { xs: '32px', sm: '0px' },
        }}
      >
        <Typography
          variant={'body1'}
          sx={{
            fontWeight: 700,
            color: theme.palette.darkGrey[700],
            textAlign: 'center',
          }}
        >
          {t('contribeeInfo')}
        </Typography>
        <Box sx={{ marginTop: '32px', display: 'flex' }}>
          <Button
            buttontype={'orange'}
            width={{ xs: '100%', sm: 'auto' }}
            onClick={() =>
              window.open('https://contribee.com/teniso-partneris')
            }
          >
            {t('goToContribee')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoCards;
