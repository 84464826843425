import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { post } from 'api';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import SpinningBox from 'components/SpinningBox';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [form, setForm] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const successToastRef = useRef();
  const errorToastRef = useRef();

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form.email)) {
      errorToastRef.current.showError('Please enter a valid email address');
      setIsSubmitting(false);
      return;
    }
    const response = await post('/resetpass/password-reset', form);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        setIsSubmitting(false);
      }, 3000);
    } else {
      errorToastRef.current.showError(data.msg);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '415px',
        width: '100%',
        mx: 'auto',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        sx={{
          mb: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          mb={'12px'}
        >
          {t('forgotPassword?')}
        </Typography>
        <Typography variant="body3" color={theme.palette.darkGrey[600]}>
          {t('forgotPasswordSubtitle')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TextInput
          label={`${t('email')}*`}
          id="email"
          name="email"
          type="email"
          placeholder={t('enterEmail')}
          required
          inputProps={{
            autoComplete: 'email',
          }}
          handleInputChange={handleChange}
          value={form.email || ''}
        />
        <Button
          buttontype="orange"
          width="100%"
          marginTop="24px"
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : t('resetPassword')}
        </Button>
        <Box
          sx={{
            mt: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body4" color={theme.palette.darkGrey[600]}>
            {t('rememberedPassword?')}
          </Typography>
          <Box
            onClick={() => navigate('/prisijungimas')}
            fontWeight={600}
            color={theme.palette.orange[800]}
            display="flex"
            alignItems="center"
            marginLeft={'8px'}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="body4"
              color={theme.palette.orange[800]}
              fontWeight={600}
            >
              {t('login')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ForgotPassword;
