import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';

const PlayerCard = ({
  userId,
  fullName,
  avatar,
  partnerId,
  partnerFullName,
  set1,
  set2,
  set3,
  tb1,
  tb2,
  tb3,
  isWinner,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const sets = [
    { set: set1, tb: tb1 },
    { set: set2, tb: tb2 },
    { set: set3, tb: tb3 },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        height: '48px',
        padding: '8px 12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: isWinner ? '8px' : '0px',
        backgroundColor: isWinner ? theme.palette.darkGrey[100] : 'transparent',
        marginBottom: '12px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          component={'img'}
          src={
            avatar
              ? `https://backend.tenisopartneris.lt/public/images/${avatar}`
              : isWinner
              ? avatarWhite
              : avatarGrey
          }
          alt="avatar"
          sx={{
            width: '32px',
            height: '32px',
            objectFit: 'cover',
            borderRadius: '50%',
            marginRight: '8px',
          }}
        />
        <Box
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => navigate(`/info/${userId}`)}
        >
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {fullName}
          </Typography>
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => navigate(`/info/${partnerId}`)}
        >
          <Typography
            variant={'body4'}
            fontWeight={600}
            color={theme.palette.darkGrey[700]}
          >
            {partnerFullName}
          </Typography>
        </Box>
      </Box>
      <Box>
        {sets
          .filter(({ set }) => set !== null)
          .map(({ set, tb }, index) => (
            <Typography
              key={index}
              variant={'body4'}
              fontWeight={600}
              color={
                isWinner
                  ? theme.palette.darkGrey[700]
                  : theme.palette.darkGrey[600]
              }
              sx={{
                '&:not(:last-child)': {
                  marginRight: '20px',
                },
              }}
            >
              {set}
              {tb ? <sup>{tb}</sup> : null}
            </Typography>
          ))}
      </Box>
    </Box>
  );
};

export default PlayerCard;
