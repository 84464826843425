import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { get, post, put } from 'api';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import SpinningBox from 'components/SpinningBox';
import pencilIcon from 'assets/icons/pencil-line.svg';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const TournamentsList = () => {
  const theme = useTheme();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [open, setOpen] = useState({});
  const [currentView, setCurrentView] = useState('list');
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    id: '',
    city: '',
    tournamentName: '',
    tournamentType: '',
    earlyBirdCode: '',
    earlyBirdDate: '',
    registrationDate: '',
    tournamentStartDate: '',
    tournamentStartDay: '',
    finalsDate: '',
    finalDay: '',
    groupsDate: '',
    eliminationsDate: '',
    price: '',
    divisions: '',
    isActive: null,
    grupes: null,
    pagrindas: null,
    paguoda: null,
    isRegON: null,
  });

  const handleSelectedTournament = (tournament) => {
    setSelectedTournament(tournament);
    setCurrentView('edit');
    setForm({
      id: tournament.id,
      city: tournament.city,
      tournamentName: tournament.tournament_name,
      tournamentType: tournament.tournament_type,
      earlyBirdCode: tournament.early_bird,
      earlyBirdDate: moment(tournament.early_bird_date).format('YYYY-MM-DD'),
      registrationDate: moment(tournament.registration_date).format(
        'YYYY-MM-DD',
      ),
      tournamentStartDate: moment(tournament.start_date).format('YYYY-MM-DD'),
      tournamentStartDay: tournament.start_day,
      finalsDate: moment(tournament.final_date).format('YYYY-MM-DD'),
      finalDay: tournament.final_day,
      groupsDate: moment(tournament.groups_date).format('YYYY-MM-DD'),
      eliminationsDate: moment(tournament.eliminations_date).format(
        'YYYY-MM-DD',
      ),
      price: tournament.price,
      divisions: tournament.divisions,
      isActive: tournament.isActive,
      grupes: tournament.grupes,
      pagrindas: tournament.pagrindas,
      paguoda: tournament.paguoda,
      isRegON: tournament.isRegON,
    });
  };

  const handleGoBack = () => {
    setCurrentView('list');
    setSelectedTournament(null);
    setForm({
      id: '',
      city: '',
      tournamentName: '',
      tournamentType: '',
      earlyBirdCode: '',
      earlyBirdDate: '',
      registrationDate: '',
      tournamentStartDate: '',
      tournamentStartDay: '',
      finalsDate: '',
      finalDay: '',
      groupsDate: '',
      eliminationsDate: '',
      price: '',
      divisions: '',
      isActive: null,
      grupes: null,
      pagrindas: null,
      paguoda: null,
      isRegON: null,
    });
  };

  const getTournaments = async () => {
    const response = await get('/tournaments/tournaments');
    const data = await response.json();
    setTournaments(data);
  };

  useEffect(() => {
    getTournaments();
  }, []);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const body = {
      id: form.id,
      city: form.city,
      tournament_name: form.tournamentName,
      tournament_type: form.tournamentType,
      early_bird: form.earlyBirdCode,
      early_bird_date: form.earlyBirdDate,
      registration_date: form.registrationDate,
      start_date: form.tournamentStartDate,
      start_day: form.tournamentStartDay,
      final_date: form.finalsDate,
      final_day: form.finalDay,
      groups_date: form.groupsDate,
      eliminations_date: form.eliminationsDate,
      price: form.price,
      divisions: form.divisions,
      isActive: parseInt(form.isActive),
      grupes: parseInt(form.grupes),
      pagrindas: parseInt(form.pagrindas),
      paguoda: parseInt(form.paguoda),
      isRegON: parseInt(form.isRegON),
    };
    const response = await put('/tournaments/updateTournament', body);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setIsSubmitting(false);
      setTimeout(() => {
        getTournaments();
        setCurrentView('list');
        setForm({
          city: '',
          tournamentName: '',
          tournamentType: '',
          earlyBirdCode: '',
          earlyBirdDate: '',
          registrationDate: '',
          tournamentStartDate: '',
          tournamentStartDay: '',
          finalsDate: '',
          finalDay: '',
          groupsDate: '',
          eliminationsDate: '',
          price: '',
          divisions: '',
          isActive: null,
          grupes: null,
          pagrindas: null,
          paguoda: null,
          isRegON: null,
        });
        window.scrollTo(0, 0);
      }, 1500);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '100%', md: '516px' },
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      {currentView === 'list' && (
        <Box display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxWidth={{ xs: '100%', sm: '100%' }}
          >
            {tournaments.map((tournament) => (
              <Box
                key={tournament.id}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginBottom={'12px'}
              >
                <Typography
                  variant="body3"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {tournament.id}. {tournament.tournament_name} (
                  {tournament.tournament_type})
                </Typography>
                <Button
                  buttontype={'white'}
                  bordered={'true'}
                  size={'small'}
                  width={'44px'}
                  onClick={() => handleSelectedTournament(tournament)}
                >
                  <Box component={'img'} src={pencilIcon} alt="pencil icon" />
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {currentView === 'edit' && (
        <>
          <Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>
              <Button
                buttontype={'white'}
                bordered={'true'}
                size={'small'}
                onClick={() => handleGoBack()}
              >
                Atgal į turnyrų sąrašą
              </Button>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Miestas:
              </Typography>
              <SelectInput
                name="city"
                id="city"
                placeholder="Pasirinkite miestą"
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleChange={handleChange}
                topMargin={'6px'}
                value={form.city || ''}
                options={[
                  { value: 'Vilnius', label: 'Vilnius' },
                  { value: 'Kaunas', label: 'Kaunas' },
                ]}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <TextInput
                label={'Turnyro pavadinimas:'}
                id="tournamentName"
                name="tournamentName"
                type="text"
                placeholder={'Turnyro pavadinimas'}
                required
                handleInputChange={handleChange}
                value={form.tournamentName || ''}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Turnyro tipas:
              </Typography>
              <SelectInput
                name="tournamentType"
                id="tournamentType"
                placeholder="Pasirinkite turnyro tipą"
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleChange={handleChange}
                topMargin={'6px'}
                value={form.tournamentType || ''}
                options={[
                  { value: 'Vienetai', label: 'Vienetai' },
                  { value: 'Dvejetai', label: 'Dvejetai' },
                  { value: 'MixDvejetai', label: 'Mix Dvejetai' },
                ]}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <TextInput
                label={'Early bird nuolaidos kodas:'}
                id="earlyBirdCode"
                name="earlyBirdCode"
                type="text"
                placeholder={'Pvz.: ISLIK5'}
                required
                handleInputChange={handleChange}
                value={form.earlyBirdCode || ''}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Early bird nuolaida iki:
              </Typography>
              <DatePicker
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                        display: 'flex',
                      },
                    },
                    InputLabelProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                      },
                    },
                  },
                }}
                format="YYYY-MM-DD"
                onChange={(newValue) =>
                  setForm({
                    ...form,
                    earlyBirdDate: moment(newValue).format('YYYY-MM-DD'),
                  })
                }
                value={moment(form.earlyBirdDate)}
                sx={{
                  mt: '6px',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Registracija iki:
              </Typography>
              <DatePicker
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                        display: 'flex',
                      },
                    },
                    InputLabelProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                      },
                    },
                  },
                }}
                format="YYYY-MM-DD"
                onChange={(newValue) =>
                  setForm({
                    ...form,
                    registrationDate: moment(newValue).format('YYYY-MM-DD'),
                  })
                }
                value={moment(form.registrationDate)}
                sx={{
                  mt: '6px',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Turnyro pradžios data:
              </Typography>
              <DatePicker
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                        display: 'flex',
                      },
                    },
                    InputLabelProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                      },
                    },
                  },
                }}
                format="YYYY-MM-DD"
                onChange={(newValue) =>
                  setForm({
                    ...form,
                    tournamentStartDate: moment(newValue).format('YYYY-MM-DD'),
                  })
                }
                value={moment(form.tournamentStartDate)}
                sx={{
                  mt: '6px',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <TextInput
                label={'Turnyro pradžios diena:'}
                id="tournamentStartDay"
                name="tournamentStartDay"
                type="text"
                placeholder={'Pvz.: Kovo 1d.'}
                required
                handleInputChange={handleChange}
                value={form.tournamentStartDay || ''}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Finalų data:
              </Typography>
              <DatePicker
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                        display: 'flex',
                      },
                    },
                    InputLabelProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                      },
                    },
                  },
                }}
                format="YYYY-MM-DD"
                onChange={(newValue) =>
                  setForm({
                    ...form,
                    finalsDate: moment(newValue).format('YYYY-MM-DD'),
                  })
                }
                value={moment(form.finalsDate)}
                sx={{
                  mt: '6px',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <TextInput
                label={'Turnyro finalinė diena:'}
                id="finalDay"
                name="finalDay"
                type="text"
                placeholder={'Pvz.: Kovo 1d.'}
                required
                handleInputChange={handleChange}
                value={form.finalDay || ''}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Grupių etapas iki:
              </Typography>
              <DatePicker
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                        display: 'flex',
                      },
                    },
                    InputLabelProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                      },
                    },
                  },
                }}
                format="YYYY-MM-DD"
                onChange={(newValue) =>
                  setForm({
                    ...form,
                    groupsDate: moment(newValue).format('YYYY-MM-DD'),
                  })
                }
                value={moment(form.groupsDate)}
                sx={{
                  mt: '6px',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Atkrintamųjų etapas iki:
              </Typography>
              <DatePicker
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                        display: 'flex',
                      },
                    },
                    InputLabelProps: {
                      style: {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[500],
                      },
                    },
                  },
                }}
                format="YYYY-MM-DD"
                onChange={(newValue) =>
                  setForm({
                    ...form,
                    eliminationsDate: moment(newValue).format('YYYY-MM-DD'),
                  })
                }
                value={moment(form.eliminationsDate)}
                sx={{
                  mt: '6px',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <TextInput
                label={'Turnyro mokestis:'}
                id="price"
                name="price"
                type="text"
                placeholder={'Pvz.: 40€'}
                required
                handleInputChange={handleChange}
                value={form.price || ''}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'24px'}
            >
              <TextInput
                label={'Divizijos:'}
                id="divisions"
                name="divisions"
                type="text"
                placeholder={'Pvz.: 8'}
                required
                handleInputChange={handleChange}
                value={form.divisions || ''}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'25px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Ar turnyras aktyvus?
              </Typography>
              <FormControl>
                <RadioGroup
                  name="isActive"
                  row
                  value={form.isActive}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'Yes'}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'No'}
                    sx={{
                      marginLeft: '12px',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'25px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Ar grupės aktyvios?
              </Typography>
              <FormControl>
                <RadioGroup
                  name="grupes"
                  row
                  value={form.grupes}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'Yes'}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'No'}
                    sx={{
                      marginLeft: '12px',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'25px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Ar pagrindas aktyvus?
              </Typography>
              <FormControl>
                <RadioGroup
                  name="pagrindas"
                  row
                  value={form.pagrindas}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'Yes'}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'No'}
                    sx={{
                      marginLeft: '12px',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'25px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Ar paguoda aktyvi?
              </Typography>
              <FormControl>
                <RadioGroup
                  name="paguoda"
                  row
                  value={form.paguoda}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'Yes'}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'No'}
                    sx={{
                      marginLeft: '12px',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              maxWidth={{ xs: '100%', sm: '100%' }}
              marginTop={'25px'}
            >
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                Ar turnyro registracija aktyvi?
              </Typography>
              <FormControl>
                <RadioGroup
                  name="isRegON"
                  row
                  value={form.isRegON}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'Yes'}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.orange[600],
                          },
                        }}
                      />
                    }
                    label={'No'}
                    sx={{
                      marginLeft: '12px',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        color: theme.palette.darkGrey[700],
                        fontWeight: 500,
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              maxWidth={{ xs: '100%', sm: '100%' }}
              width={'100%'}
              buttontype={'orange'}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? <SpinningBox /> : 'Submit'}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TournamentsList;
