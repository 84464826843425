import React from 'react';
import { useTable } from 'react-table';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { courtNames } from '../Variables/courtNames';
import { Box, Typography } from '@mui/material';

const CourtsTable = ({ courtsData, city }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const courtNameMap = courtNames.reduce((map, court) => {
    map[`${court.courtID}-${court.tennisCourt}`] = court.courtName;
    return map;
  }, {});

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: t('court'),
        accessor: 'courtID',
        Cell: ({ row: { original } }) => {
          const key = `${original.courtID}-${original.tennisCourt}`;
          return (
            <Typography
              variant="body2"
              color={theme.palette.darkGrey[600]}
              fontWeight={500}
              textAlign={'center'}
            >
              {courtNameMap[key]}
            </Typography>
          );
        },
        sticky: 'left',
      },
    ];

    const times = courtsData.flatMap((court) =>
      Object.keys(court.timetable).map((time) => time),
    );

    const minTime = Math.min(
      ...times.map((time) => parseInt(time.replace(/:/g, ''))),
    );
    const maxTime = Math.max(
      ...times.map((time) => parseInt(time.replace(/:/g, ''))),
    );

    const minHour =
      city === 'vilnius'
        ? Math.floor(minTime / 10000)
        : Math.floor(minTime / 100);
    const maxHour =
      city === 'vilnius'
        ? Math.floor(maxTime / 10000)
        : Math.floor(maxTime / 100);

    for (let i = minHour; i <= maxHour; i++) {
      const vilniusTime = `${i.toString().padStart(2, '0')}:00:00`;
      const vilniusNextTime = `${i.toString().padStart(2, '0')}:30:00`;
      const kaunasTime = `${i.toString().padStart(2, '0')}:00`;
      const kaunasNextTime = `${i.toString().padStart(2, '0')}:30`;
      const time = city === 'vilnius' ? vilniusTime : kaunasTime;
      const nextTime = city === 'vilnius' ? vilniusNextTime : kaunasNextTime;
      cols.push({
        Header: time.slice(0, 5),
        accessor: (row) => ({
          firstHalf: row.timetable[time]?.status,
          secondHalf: row.timetable[nextTime]?.status,
        }),
        Cell: ({ value }) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  width: '48%',
                  height: '20px',
                  backgroundColor:
                    value.firstHalf === 'full'
                      ? theme.palette.darkGrey[200]
                      : theme.palette.success[400],
                }}
              ></Box>
              <Box
                sx={{
                  width: '48%',
                  height: '20px',
                  backgroundColor:
                    value.secondHalf === 'full'
                      ? theme.palette.darkGrey[200]
                      : theme.palette.success[400],
                }}
              ></Box>
            </Box>
          );
        },
      });
    }

    return cols;
  }, [courtsData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: courtsData });

  return (
    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'auto',
        maxHeight: { xs: '500px', sm: '100%' },
      }}
    >
      <Box mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: '50px',
              height: '20px',
              backgroundColor: theme.palette.darkGrey[200],
            }}
          />
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[600]}
            fontWeight={600}
            textAlign={'center'}
            marginLeft={1}
          >
            {t('bookedTime')}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} marginLeft={2}>
          <Box
            sx={{
              width: '50px',
              height: '20px',
              backgroundColor: theme.palette.success[400],
            }}
          />
          <Typography
            variant="body4"
            color={theme.palette.darkGrey[600]}
            fontWeight={600}
            textAlign={'center'}
            marginLeft={1}
          >
            {t('freeTime')}
          </Typography>
        </Box>
      </Box>

      <table {...getTableProps()} style={{ width: '100%', minWidth: '600px' }}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#fff',
                    zIndex: j === 0 ? 2 : 1,
                    ...(column.sticky === 'left' ? { left: 0 } : {}),
                  }}
                >
                  <Typography
                    variant="body3"
                    color={theme.palette.darkGrey[600]}
                    fontWeight={600}
                  >
                    {column.render('Header')}
                  </Typography>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={
                      cell.column.sticky === 'left'
                        ? {
                            position: 'sticky',
                            left: 0,
                            backgroundColor: '#fff',
                          }
                        : {}
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default CourtsTable;
