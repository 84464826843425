import React from 'react';
import { useTheme } from '@mui/material/styles';
import Main from 'system/layouts/Main';
import ContainerSystem from 'components/ContainerSystem';
import { PastTournaments, UpcomingTournaments } from '../components';

const TournamentsPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <ContainerSystem paddingTop={{ sm: '72px !important' }}>
        <UpcomingTournaments />
      </ContainerSystem>
      <ContainerSystem
        paddingBottom={{ sm: '80px !important', md: '96px !important' }}
      >
        <PastTournaments />
      </ContainerSystem>
    </Main>
  );
};

export default TournamentsPage;
