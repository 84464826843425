import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Grid,
  InputAdornment,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/lt';
import { PartnerCard } from 'components/Cards';
import searchLg from 'assets/icons/search-lg.svg';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import {
  get,
  remove,
  post,
  getUserEmailFromToken,
  getUserIdFromToken,
  getUserPhoneFromToken,
} from 'api';
import {
  NTRP,
  Cities,
  MatchTypePartner,
  fromNTRP,
  toNTRP,
} from 'constants/Selections';
import {
  times,
  duration,
} from '../../../CourtsView/components/Variables/times';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import SpinningBox from 'components/SpinningBox';

const Partners = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const token = getUserIdFromToken();
  const [open, setOpen] = useState(false);
  const [matches, setMatches] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filters, setFilters] = useState({
    city: '',
    ntrp: '',
    search: '',
  });
  const [mySubcriptions, setMySubcriptions] = useState([]);
  const [subscriptionsOpen, setSubscriptionsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [selectedStartTime, setSelectedStartTime] = useState('07:00:00');
  const [selectedDuration, setSelectedDuration] = useState(90);
  const [selectedFromNTRP, setSelectedFromNTRP] = useState('');
  const [selectedToNTRP, setSelectedToNTRP] = useState('');
  const [selectedType, setSelectedType] = useState('any');
  const [selectedCity, setSelectedCity] = useState('Vilnius');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const handleSubOpen = () => setSubscriptionsOpen(true);
  const handleSubClose = () => setSubscriptionsOpen(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const userEmail = getUserEmailFromToken();
  const userId = getUserIdFromToken();
  const userPhone = getUserPhoneFromToken();

  const errorToastRef = useRef();
  const successToastRef = useRef();

  const usersWithMobile = [1, 2, 3, 1656];

  useEffect(() => {
    if (isFirstRender && queryParams.get('city')) {
      setSelectedDate(queryParams.get('date'));
      setSelectedStartTime(queryParams.get('start'));
      const duration = queryParams.get('duration');
      setSelectedDuration(parseInt(duration));
      setSelectedCity(queryParams.get('city'));
      setSelectedFromNTRP(queryParams.get('fromNTRP'));
      setSelectedToNTRP(queryParams.get('toNTRP'));
      setSelectedType(queryParams.get('type'));
      handleOpen();

      setTimeout(() => {
        let url = new URL(window.location);
        url.searchParams.delete('date');
        url.searchParams.delete('start');
        url.searchParams.delete('duration');
        url.searchParams.delete('city');
        url.searchParams.delete('fromNTRP');
        url.searchParams.delete('toNTRP');
        url.searchParams.delete('type');
        window.history.replaceState({}, '', url);
      }, 0);
    }
    setIsFirstRender(false);
  }, [queryParams, isFirstRender]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMd ? '25%' : '85%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    border: 'none',
    p: 4,
  };

  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMd ? 'auto' : '85%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    border: 'none',
    p: 4,
  };

  const fetchMatches = async () => {
    const response = await get('/matches/partner-matches', token);
    const data = await response.json();
    setMatches(data);
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  useEffect(() => {
    const filtered = matches.filter((match) => {
      const city = filters.city
        ? match.city.toLowerCase().includes(filters.city.toLowerCase())
        : true;
      const ntrp = filters.ntrp
        ? match.ntrp.toLowerCase().includes(filters.ntrp.toLowerCase())
        : true;
      const search = filters.search
        ? match.first_name
            .toLowerCase()
            .includes(filters.search.toLowerCase()) ||
          match.ntrp.toLowerCase().includes(filters.search.toLowerCase()) ||
          match.city.toLowerCase().includes(filters.search.toLowerCase()) ||
          match.type.toLowerCase().includes(filters.search.toLowerCase()) ||
          match.match_date
            .toLowerCase()
            .includes(filters.search.toLowerCase()) ||
          match.place.toLowerCase().includes(filters.search.toLowerCase())
        : true;
      return city && ntrp && search;
    });
    setFilteredMatches(filtered);
    setLoaded(true);
  }, [filters, matches]);

  const handleChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const getMySubscriptions = async () => {
    const response = await get(`/partners/my-notifications/${userId}`);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      setMySubcriptions(data.data);
    }
  };

  const handleSelectOpen = (id) => {
    setOpenSelect({ ...open, [id]: true });
  };

  const handleSelectClose = (id) => {
    setOpenSelect({ ...open, [id]: false });
  };

  const handleSelectChange = (event) => {
    if (event.target.name === 'startTime') {
      setSelectedStartTime(event.target.value);
    }
    if (event.target.name === 'duration') {
      setSelectedDuration(event.target.value);
    }
    if (event.target.name === 'fromNTRP') {
      setSelectedFromNTRP(event.target.value);
    }
    if (event.target.name === 'toNTRP') {
      setSelectedToNTRP(event.target.value);
    }
    if (event.target.name === 'city') {
      setSelectedCity(event.target.value);
    }
    if (event.target.name === 'type') {
      setSelectedType(event.target.value);
    }
  };

  const handleSubscribe = async () => {
    setIsSubmitting(true);
    if (
      !selectedDate ||
      !selectedStartTime ||
      !selectedDuration ||
      !selectedCity ||
      !selectedFromNTRP ||
      !selectedType
    ) {
      setIsSubmitting(false);
      errorToastRef.current.showError(t('fillAllFields'));
      return;
    }
    const body = {
      userId,
      userEmail,
      userPhone: usersWithMobile.includes(userId) ? userPhone : null,
      date: selectedDate,
      startTime: selectedStartTime,
      duration: selectedDuration,
      fromNTRP: selectedFromNTRP,
      toNTRP: selectedToNTRP,
      city: selectedCity,
      type: selectedType,
    };
    const response = await post('/partners/subscribe', body);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      setIsSubmitting(false);
      handleClose();
      successToastRef.current.showSuccess(data.msg);
    } else {
      setIsSubmitting(false);
      errorToastRef.current.showError(data.err);
    }
  };

  const handleDelete = async (id) => {
    const response = await remove(`/partners/unsubscribe/${id}`);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('notificationDeleted'));
      setTimeout(() => {
        getMySubscriptions();
      }, 1500);
    } else {
      errorToastRef.current.showError(data.err);
    }
  };

  const handleSubscriptionsOpen = () => {
    getMySubscriptions();
    handleSubOpen();
  };

  if (!loaded) {
    return null;
  }

  return (
    <Box>
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={{ xs: '0px', sm: '40px' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <Typography
            variant="h4"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 700, marginBottom: '16px' }}
          >
            {t('homePartnersTitle')}
          </Typography>
          <Typography variant="body1" color={theme.palette.darkGrey[600]}>
            {t('homePartnersSubtitle')}
          </Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: '24px', sm: 0 }}>
          <Button
            width={'100%'}
            buttontype="orange"
            onClick={() => navigate('/partnerio-paieska/skelbti')}
          >
            {t('postAnnouncement')}
          </Button>
        </Box>
      </Box>
      <Box
        marginBottom={{ xs: '20px', sm: '40px' }}
        display="flex"
        justifyContent="flex-start"
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      >
        <Button
          bordered="true"
          buttontype="white"
          onClick={handleSubscriptionsOpen}
          width={{ xs: '100%', sm: 'auto' }}
          marginRight={{ xs: 0, sm: '16px' }}
        >
          {t('mySubcriptions')}
        </Button>
        <Modal
          open={subscriptionsOpen}
          onClose={handleSubClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <Box sx={style2}>
            <CardContent
              sx={{
                maxHeight: '85vh',
                overflow: 'auto',
                padding: '0px',
                '&::-webkit-scrollbar': {
                  backgroundColor: theme.palette.darkGrey[100],
                  width: '2px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.darkGrey[300],
                  borderRadius: '20px',
                },
              }}
            >
              <Typography
                variant={isMd ? 'h5' : 'h6'}
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
                marginBottom={'16px'}
              >
                {t('myActiveSubcriptions')}
              </Typography>
              {mySubcriptions.length > 0 ? (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{t('date')}</TableCell>
                        <TableCell align="center">{t('from')}</TableCell>
                        <TableCell align="center">{t('duration')}</TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {t('level')} (NTRP)
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                          {t('matchType')}
                        </TableCell>
                        <TableCell align="center">{t('city')}</TableCell>
                        <TableCell align="center">{t('cancel')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mySubcriptions.map((sub) => (
                        <TableRow key={sub.id}>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {moment(sub.date).format('YYYY-MM-DD')} <br />(
                            {moment(sub.date).locale('lt').format('dddd')})
                          </TableCell>
                          <TableCell align="center">{sub.startTime}</TableCell>
                          <TableCell align="center">
                            {sub.duration} min
                          </TableCell>
                          <TableCell align="center">
                            {sub.fromNTRP} -{' '}
                            {sub.toNTRP ? sub.toNTRP : 'Nesvarbu'}
                          </TableCell>
                          <TableCell align="center">
                            {sub.type === 'any' ? 'Nesvarbu' : sub.type}
                          </TableCell>
                          <TableCell align="center">
                            {sub.city.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            <Button
                              buttontype="orange"
                              onClick={() => handleDelete(sub.id)}
                            >
                              {t('cancel')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('noSubscriptions')}
                </Typography>
              )}
            </CardContent>
          </Box>
        </Modal>
        <Button
          buttontype="orange"
          onClick={handleOpen}
          marginTop={{ xs: '20px', sm: 0 }}
          marginBottom={{ xs: '20px', sm: 0 }}
          width={{ xs: '100%', sm: 'auto' }}
        >
          {t('subscribe')}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <Box sx={style}>
            <CardContent
              sx={{ maxHeight: '85vh', overflow: 'auto', padding: '0px' }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('date')}*
                </Typography>
                <DatePicker
                  slotProps={{
                    textField: {
                      size: 'large',
                      inputProps: {
                        style: {
                          fontSize: '16px',
                          display: 'flex',
                          height: '16px',
                        },
                      },
                      InputLabelProps: {
                        style: {
                          fontSize: '16px',
                        },
                      },
                    },
                  }}
                  format="YYYY-MM-DD"
                  value={moment(selectedDate)}
                  onChange={(date) =>
                    setSelectedDate(date.format('YYYY-MM-DD'))
                  }
                  minDate={moment().endOf('day')}
                  maxDate={moment().add(21, 'days')}
                  sx={{ height: '48px' }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('startTime')}*
                </Typography>
                <SelectInput
                  name="startTime"
                  id="startTime"
                  placeholder={t('startTime')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={times}
                  value={selectedStartTime}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('duration')}*
                </Typography>
                <SelectInput
                  name="duration"
                  id="duration"
                  placeholder={t('duration')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={duration}
                  value={selectedDuration}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('levelFromTo')}*
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={{ xs: 'column', md: 'row' }}
                  gap={'12px'}
                >
                  <SelectInput
                    name="fromNTRP"
                    id="fromNTRP"
                    placeholder={t('selectLevel')}
                    open={openSelect}
                    handleOpen={handleSelectOpen}
                    handleClose={handleSelectClose}
                    handleChange={handleSelectChange}
                    topMargin={'6px'}
                    options={fromNTRP}
                    value={selectedFromNTRP}
                  />
                  <SelectInput
                    name="toNTRP"
                    id="toNTRP"
                    placeholder={t('selectLevel')}
                    open={openSelect}
                    handleOpen={handleSelectOpen}
                    handleClose={handleSelectClose}
                    handleChange={handleSelectChange}
                    topMargin={'6px'}
                    options={toNTRP}
                    value={selectedToNTRP}
                  />
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('city')}*
                </Typography>
                <SelectInput
                  name="city"
                  id="city"
                  placeholder={t('city')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={Cities}
                  value={selectedCity}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Typography
                  variant="body4"
                  fontWeight={500}
                  color={theme.palette.darkGrey[700]}
                >
                  {t('matchType')}*
                </Typography>
                <SelectInput
                  name="type"
                  id="type"
                  placeholder={t('matchType')}
                  open={openSelect}
                  handleOpen={handleSelectOpen}
                  handleClose={handleSelectClose}
                  handleChange={handleSelectChange}
                  topMargin={'6px'}
                  options={MatchTypePartner}
                  value={selectedType}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', sm: '100%' }}
                marginTop={'16px'}
              >
                <TextInput
                  label={t('email')}
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t('email')}
                  disabled
                  value={userEmail}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                maxWidth={{ xs: '100%', md: '100%' }}
                marginTop={'16px'}
              >
                <Button
                  buttontype="orange"
                  onClick={handleSubscribe}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <SpinningBox /> : t('subscribe')}
                </Button>
              </Box>
            </CardContent>
          </Box>
        </Modal>
      </Box>
      <Box
        marginBottom={{ xs: '48px', sm: '64px' }}
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={{ xs: '24px', sm: '32px' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: 'auto' }}
          width={'100%'}
        >
          <TextInput
            label={t('search')}
            type="text"
            id="search"
            name="search"
            placeholder={t('search')}
            handleInputChange={handleChange}
            value={filters.search}
            icon={
              <InputAdornment position="start">
                <Box component={'img'} src={searchLg} alt="search icon" />
              </InputAdornment>
            }
            sx={{
              margin: 0,
              mt: '6px',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: 'auto' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectCity')}
          </Typography>
          <SelectInput
            name="city"
            id="city"
            placeholder={t('selectCity')}
            open={openSelect}
            handleOpen={handleSelectOpen}
            handleClose={handleSelectClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={Cities}
            value={filters.city}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: 'auto' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectLevel')}
          </Typography>
          <SelectInput
            name="ntrp"
            id="ntrp"
            placeholder={t('selectLevel')}
            open={openSelect}
            handleOpen={handleSelectOpen}
            handleClose={handleSelectClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={NTRP}
            value={filters.ntrp}
          />
        </Box>
      </Box>
      <Box>
        {filteredMatches.length === 0 && (
          <Typography
            variant="body1"
            color={theme.palette.darkGrey[600]}
            sx={{ marginBottom: '32px' }}
          >
            {t('noPartnerMatches')}
          </Typography>
        )}
      </Box>
      <Grid container spacing={'32px'}>
        {filteredMatches.map((item, i) => (
          <Grid item xs={12} sm={6} md={5} key={i}>
            <PartnerCard
              matchId={item.id}
              avatar={item.image}
              name={item.first_name}
              mailTo={item.email}
              ntrp={item.ntrp}
              date={item.match_date}
              time={item.match_time}
              location={item.city}
              tennisCourt={item.place}
              details={item.comment}
              userId={item.user_id}
              page={'find-partner'}
              fetchMatches={fetchMatches}
              type={item.type}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Partners;
