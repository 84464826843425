import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SecondaryTab } from 'components/Tabs';
import PersonalInfo from '../PersonalInfo/Info';
import Settings from '../Settings';
import Button from 'components/Button';
import { get, put, post, upload } from 'api';
import { SuccessToast, ErrorToast } from 'utils/Toasts';

const ContentView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(t('personalInfo'));
  const [userInfo, setUserInfo] = useState([]);
  const [avatarUpload, setAvatarUpload] = useState([]);
  const [personalInfoForm, setPersonalInfoForm] = useState({});
  const [settingsForm, setSettingsForm] = useState({});
  const [isUrlParamProcessed, setIsUrlParamProcessed] = useState(false);
  const successToastRef = useRef();
  const errorToastRef = useRef();

  useEffect(() => {
    if (queryParams.get('view') === 'settings' && !isUrlParamProcessed) {
      setTimeout(() => {
        setActiveTab(t('settings'));
        setIsUrlParamProcessed(true);
      }, 1000);

      setTimeout(() => {
        let url = new URL(window.location);
        url.searchParams.delete('view');
        window.history.replaceState({}, '', url);
      }, 0);
    }
  }, [isUrlParamProcessed]);

  const getUserInfo = async () => {
    try {
      const response = await get(`/accounts/myprofile`);
      const data = await response.json();
      setUserInfo(data[0]);
      setPersonalInfoForm({
        firstName: data[0].first_name,
        lastName: data[0].last_name,
        email: data[0].email,
        phoneNumber: data[0].phone_number,
        messenger: data[0].messenger,
        city: data[0].city,
        ntrp: data[0].ntrp,
        image: data[0].image,
        description: data[0].description,
      });
      setSettingsForm({
        showEmail: data[0].showEmail,
        showPhone: data[0].showPhone,
        newsLettersEmail: data[0].newsletters,
        newsLettersPhone: data[0].newsletters_sms,
        password: '',
        repeatPassword: '',
        scheduledEmails: data[0].scheduledEmails,
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleSubmit = async () => {
    if (activeTab === t('personalInfo')) {
      try {
        if (avatarUpload.length > 0) {
          const formData = new FormData();
          formData.append('image', avatarUpload[0]);
          await upload(`/singleImage`, formData);
        }
        const response = await put(`/accounts/myprofiles`, personalInfoForm);
        const data = await response.json();
        if (data.status === 'SUCCESS') {
          successToastRef.current.showSuccess(data.msg);
          getUserInfo();
          window.scrollTo(0, 0);
        } else {
          errorToastRef.current.showError(data.err);
        }
      } catch (err) {
        console.error(err.message);
      }
    } else if (activeTab === t('settings')) {
      try {
        const response = await put(`/accounts/myprofiles`, settingsForm);
        const data = await response.json();
        if (data.status === 'SUCCESS') {
          successToastRef.current.showSuccess(data.msg);
          getUserInfo();
          window.scrollTo(0, 0);
        } else {
          errorToastRef.current.showError(data.err);
        }
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              variant="h5"
              color={theme.palette.darkGrey[700]}
              fontWeight={700}
            >
              {t('personalInfoTitle')}
            </Typography>
            <Typography
              variant="body3"
              color={theme.palette.darkGrey[600]}
              marginTop={{ xs: '8px', sm: '0px' }}
            >
              {t('personalInfoSubtitle')}
            </Typography>
          </Box>
          <Box
            display={{ xs: 'none', sm: 'flex' }}
            alignItems={'center'}
            maxWidth={'220px'}
            width={'100%'}
            justifyContent={'flex-end'}
          >
            <Button
              bordered="true"
              buttontype="white"
              onClick={() => navigate('/info', { replace: true })}
              marginRight={'16px'}
            >
              {t('cancel')}
            </Button>
            <Button buttontype="orange" onClick={handleSubmit}>
              {t('save')}
            </Button>
          </Box>
        </Box>
        <Box
          backgroundColor={theme.palette.darkGrey[200]}
          height={'1px'}
          marginTop={'20px'}
        />
      </Box>
      <Box marginTop={'40px'} marginBottom={'40px'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          maxWidth={'576px'}
          width={'100%'}
          sx={{
            borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('personalInfo')}
            page={'profile'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('settings')}
            page={'profile'}
          />
        </Box>
      </Box>
      <Box>
        {activeTab === t('personalInfo') && (
          <PersonalInfo
            personalInfoForm={personalInfoForm}
            setPersonalInfoForm={setPersonalInfoForm}
            avatarUpload={avatarUpload}
            setAvatarUpload={setAvatarUpload}
          />
        )}
        {activeTab === t('settings') && (
          <Settings
            settingsForm={settingsForm}
            setSettingsForm={setSettingsForm}
          />
        )}
      </Box>
      <Box
        backgroundColor={theme.palette.darkGrey[200]}
        height={'1px'}
        marginBottom={'20px'}
        marginTop={'20px'}
      />
      <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          maxWidth={'220px'}
          width={'100%'}
          justifyContent={'flex-end'}
        >
          <Button
            bordered="true"
            buttontype="white"
            onClick={() => navigate('/info', { replace: true })}
            marginRight={'16px'}
          >
            {t('cancel')}
          </Button>
          <Button buttontype="orange" onClick={handleSubmit}>
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentView;
