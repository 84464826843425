import {
  Box,
  CardContent,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const RankingsHistory = ({ data, totalPoints, modalOpen, setModalOpen }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMd ? '50%' : '95%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    border: 'none',
    p: 4,
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: { xs: '100%', sm: 'auto' }, mt: '8px', mb: '8px' }}
        >
          <Typography
            variant="body5"
            fontWeight={600}
            color={theme.palette.darkGrey[600]}
          >
            {t('lastUpdated')}: {process.env.REACT_APP_RANKINGS_UPDATED_AT}
          </Typography>
        </Stack>
        <CardContent
          sx={{
            maxHeight: '80vh',
            overflow: 'auto',
            padding: '0px',
            '&::-webkit-scrollbar': {
              backgroundColor: theme.palette.darkGrey[100],
              width: '2px',
              height: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.darkGrey[300],
              borderRadius: '20px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: '16px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant={isMd ? 'h5' : 'h6'}
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
              >
                {t('rankingsHistory')}
              </Typography>
              <Typography
                variant={isMd ? 'body3' : 'body4'}
                fontWeight={500}
                color={theme.palette.darkGrey[500]}
              >
                {t('totalPoints')}: <strong>{totalPoints}</strong>
              </Typography>
            </Box>
            <Box
              onClick={() => setModalOpen(false)}
              sx={{
                padding: '12px 20px',
                backgroundColor: theme.palette.darkGrey[100],
                borderRadius: '8px',
                cursor: 'pointer',
                color: theme.palette.darkGrey[500],
                fontWeight: 500,
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: theme.palette.darkGrey[300],
                  color: theme.palette.darkGrey[600],
                },
              }}
            >
              X
            </Box>
          </Box>
          {data.length > 0 ? (
            <TableContainer>
              <Table
                sx={{ minWidth: { xs: 750, sm: 650 } }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        color: theme.palette.darkGrey[600],
                        fontSize: '16px',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        width: { xs: '140px', sm: 'auto' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {t('tournament')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        color: theme.palette.darkGrey[600],
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      {t('city')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        color: theme.palette.darkGrey[600],
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      {t('level')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        color: theme.palette.darkGrey[600],
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      {t('gender')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        color: theme.palette.darkGrey[600],
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      {t('stage')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        color: theme.palette.darkGrey[600],
                        fontSize: '16px',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        width: { xs: '120px', sm: 'auto' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {t('stagePoints')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: theme.palette.darkGrey[100],
                        color: theme.palette.darkGrey[600],
                        fontSize: '16px',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        width: { xs: '120px', sm: 'auto' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {t('regPoints')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell
                        align="left"
                        sx={{
                          color: theme.palette.darkGrey[500],
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        {item.tournament_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: theme.palette.darkGrey[500],
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {item.city}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: theme.palette.darkGrey[500],
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {item.tournament_level}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: theme.palette.darkGrey[500],
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {item.tournament_gender}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: theme.palette.darkGrey[500],
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {item.stage_reached}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: theme.palette.darkGrey[500],
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {item.points}{' '}
                        {item.points_won > 0 ? (
                          <span
                            style={{
                              color: theme.palette.success.main,
                            }}
                          >{`(+${item.points_won})`}</span>
                        ) : item.points_lost > 0 ? (
                          <span
                            style={{
                              color: theme.palette.error.main,
                            }}
                          >{`(-${item.points_lost})`}</span>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: theme.palette.darkGrey[500],
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {item.registration_points}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography
              variant="body3"
              color={theme.palette.darkGrey[600]}
              textAlign="center"
            >
              {t('noSubscriptions')}
            </Typography>
          )}
        </CardContent>
      </Box>
    </Modal>
  );
};

export default RankingsHistory;
