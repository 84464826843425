import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const PrimaryTab = ({
  activeTab,
  setActiveTab,
  tabName,
  resetFilter,
  pageName,
  participants,
}) => {
  const theme = useTheme();

  const handleActiveTab = () => {
    setActiveTab(tabName);
    if (resetFilter) resetFilter();
  };

  return (
    <Box
      onClick={() => handleActiveTab()}
      maxWidth={{ xs: '100%', sm: '240px' }}
      width={
        pageName === 'loserTable'
          ? { xs: '100%', sm: '140px' }
          : pageName === 'mainTable'
          ? { xs: '100%', sm: '140px' }
          : pageName === 'groupTable'
          ? { xs: '100%', sm: '140px' }
          : '100%'
      }
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={'12px 20px'}
      backgroundColor={
        activeTab === tabName ? theme.palette.darkGrey[100] : 'inherit'
      }
      sx={{
        cursor: 'pointer',
        '&:first-of-type': {
          borderTopLeftRadius: {
            xs: '8px',
            sm: '8px',
          },
          borderBottomLeftRadius: {
            xs:
              pageName === 'loserTable' ||
              pageName === 'mainTable' ||
              pageName === 'groupTable'
                ? '0px'
                : '8px',
            sm: '8px',
          },
          borderTopRightRadius: {
            xs:
              pageName === 'loserTable' ||
              pageName === 'mainTable' ||
              pageName === 'groupTable'
                ? '8px'
                : '0px',
            sm: '0px',
          },
        },
        '&:last-child': {
          borderTopRightRadius: {
            xs:
              pageName === 'loserTable' ||
              pageName === 'mainTable' ||
              pageName === 'groupTable'
                ? '0px'
                : '8px',
            sm: '8px',
          },
          borderBottomRightRadius: { xs: '8px', sm: '8px' },
          borderBottomLeftRadius: {
            xs:
              pageName === 'loserTable' ||
              pageName === 'mainTable' ||
              pageName === 'groupTable'
                ? '8px'
                : '0px',
            sm: '0px',
          },
        },
        '&:not(:last-child)': {
          borderBottom: {
            xs:
              pageName === 'loserTable' ||
              pageName === 'mainTable' ||
              pageName === 'groupTable'
                ? `1px solid ${theme.palette.darkGrey[300]}`
                : '0px',
            sm: '0px',
          },
          borderRight: {
            xs: '0px',
            sm: `1px solid ${theme.palette.darkGrey[300]}`,
          },
        },
      }}
    >
      <Typography
        variant={'body3'}
        align={'center'}
        fontWeight={600}
        color={
          activeTab === tabName
            ? theme.palette.darkGrey[600]
            : theme.palette.darkGrey[400]
        }
      >
        {tabName.charAt(0).toUpperCase() + tabName.slice(1)}{' '}
        {participants && `(${participants})`}
      </Typography>
    </Box>
  );
};

export default PrimaryTab;
