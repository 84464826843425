import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { MarketplaceCard } from 'components/Cards';
import chevronLeft from 'assets/icons/chevron-left.svg';
import Button from 'components/Button';
import { get } from 'api';
import CryptoJS from 'crypto-js';

const MyProducts = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [myProducts, setMyProducts] = useState([]);

  const getMarketplace = async () => {
    const response = await get('/marketplace/my-marketplace');
    const data = await response.json();
    const encryptedData = data.data;
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_CRYPTO_SECRET,
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setMyProducts(decryptedData);
  };

  useEffect(() => {
    getMarketplace();
  }, []);

  return (
    <Box>
      <Box
        onClick={() => navigate('/marketplace')}
        marginRight={'12px'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
          cursor: 'pointer',
          maxWidth: '106px',
        }}
      >
        <Box component={'img'} src={chevronLeft} alt="chevron-left" />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={{ xs: '48px', sm: '64px' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box maxWidth={500} width={'100%'}>
          <Typography
            variant="h4"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 700, marginBottom: '16px' }}
          >
            {t('myProducts')}
          </Typography>
          <Typography variant="body1" color={theme.palette.darkGrey[600]}>
            {t('marketplaceSubtitle')}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: { xs: '24px', sm: '0px' },
          }}
        >
          <Button
            buttontype="orange"
            onClick={() => navigate('/marketplace/skelbti')}
            width={{ xs: '100%', sm: 'auto' }}
          >
            {t('addProduct')}
          </Button>
        </Box>
      </Box>
      <Grid container spacing={'32px'}>
        {myProducts.map((item) => (
          <Grid item xs={12} sm={4} key={item.id}>
            <MarketplaceCard
              id={item.id}
              image={item.images[0]}
              title={item.product_title}
              price={item.product_price}
              city={item.city}
              condition={item.product_usednew
                .split('_')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
                )
                .join(' ')}
              page={'my-products'}
              fetchData={getMarketplace}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyProducts;
