function base64DecodeUnicode(str) {
  str =
    str.replace(/-/g, '+').replace(/_/g, '/') +
    '=='.slice(0, (3 * str.length) % 4);

  str = decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return str;
}

export function getTokenFromStorage() {
  if (window.localStorage.getItem('token')) {
    const token = window.localStorage.getItem('token');
    return token;
  } else {
    return null;
  }
}

export function getUserIdFromToken() {
  if (window.localStorage.getItem('token')) {
    const token = window.localStorage.getItem('token');
    const payload = token.split('.')[1];
    const payloadDecoded = base64DecodeUnicode(payload);
    const userId = JSON.parse(payloadDecoded).user_id;
    return userId;
  } else {
    return null;
  }
}

export function getUserRoleFromToken() {
  if (window.localStorage.getItem('token')) {
    const token = window.localStorage.getItem('token');
    const payload = token.split('.')[1];
    const payloadDecoded = base64DecodeUnicode(payload);
    const role = JSON.parse(payloadDecoded).role;
    return role;
  } else {
    return null;
  }
}

export function getUserEmailFromToken() {
  if (window.localStorage.getItem('token')) {
    const token = window.localStorage.getItem('token');
    const payload = token.split('.')[1];
    const payloadDecoded = base64DecodeUnicode(payload);
    const role = JSON.parse(payloadDecoded).email;
    return role;
  } else {
    return null;
  }
}

export function getUserPhoneFromToken() {
  if (window.localStorage.getItem('token')) {
    const token = window.localStorage.getItem('token');
    const payload = token.split('.')[1];
    const payloadDecoded = base64DecodeUnicode(payload);
    const phone = JSON.parse(payloadDecoded).phone;
    return phone;
  } else {
    return null;
  }
}

export function getUserFullNameFromToken() {
  if (window.localStorage.getItem('token')) {
    const token = window.localStorage.getItem('token');
    const payload = token.split('.')[1];
    const payloadDecoded = base64DecodeUnicode(payload);
    const fullName = JSON.parse(payloadDecoded).full_name;
    return fullName;
  } else {
    return null;
  }
}

export const get = (path) => {
  const token = window.localStorage.getItem('token');
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const post = (path, body) => {
  const token = window.localStorage.getItem('token');
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

export const put = (path, body) => {
  const token = window.localStorage.getItem('token');
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

export const remove = (path) => {
  const token = window.localStorage.getItem('token');
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const upload = (path, body) => {
  const token = window.localStorage.getItem('token');
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: body,
  });
};
