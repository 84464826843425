import React from 'react';

import {
  HomePage as HomePageView,
  NotFoundCover as NotFoundCoverView,
  LoginPage as LoginPageView,
  SignupPage as SignupPageView,
  ForgotPasswordPage as ForgotPasswordPageView,
  ResetPasswordPage as ResetPasswordPageView,
  TournamentsPage as TournamentsPageView,
  TournamentPage as TournamentPageView,
  BlogNewsPage as BlogNewsPageView,
  BlogArticlePage as BlogArticlePageView,
  AboutPage as AboutPageView,
  ContactPage as ContactPageView,
  FindPartnerPage as FindPartnerPageView,
  SponsorshipPage as SponsorshipPageView,
  SupportPage as SupportPageView,
  RentPage as RentPageView,
  FAQPage as FAQPageView,
  PrivacyPage as PrivacyPageView,
} from 'landing/views';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <HomePageView {...params} />,
  },
  {
    path: '/apie-mus',
    renderer: (params = {}) => <AboutPageView {...params} />,
  },
  {
    path: '/nuoma',
    renderer: (params = {}) => <RentPageView {...params} />,
  },
  {
    path: '/privatumo-politika',
    renderer: (params = {}) => <PrivacyPageView {...params} />,
  },
  {
    path: '/faq',
    renderer: (params = {}) => <FAQPageView {...params} />,
  },
  {
    path: '/kontaktai',
    renderer: (params = {}) => <ContactPageView {...params} />,
  },
  {
    path: '/remejai',
    renderer: (params = {}) => <SponsorshipPageView {...params} />,
  },
  {
    path: '/parama',
    renderer: (params = {}) => <SupportPageView {...params} />,
  },
  {
    path: '/teniso-partneris',
    renderer: (params = {}) => <FindPartnerPageView {...params} />,
  },
  {
    path: '/turnyrai',
    renderer: (params = {}) => <TournamentsPageView {...params} />,
  },
  {
    path: '/turnyrai/:id',
    renderer: (params = {}) => <TournamentPageView {...params} />,
  },
  {
    path: '/naujienos',
    renderer: (params = {}) => <BlogNewsPageView {...params} />,
  },
  {
    path: '/naujienos/:slug',
    renderer: (params = {}) => <BlogArticlePageView {...params} />,
  },
  {
    path: '/prisijungimas/:id?',
    renderer: (params = {}) => <LoginPageView {...params} />,
  },
  {
    path: '/registracija',
    renderer: (params = {}) => <SignupPageView {...params} />,
  },
  {
    path: '/pamirsau-slaptazodi',
    renderer: (params = {}) => <ForgotPasswordPageView {...params} />,
  },
  {
    path: '/pakeisti-slaptazodi/:id?',
    renderer: (params = {}) => <ResetPasswordPageView {...params} />,
  },
  {
    path: '/not-found-cover',
    renderer: (params = {}) => <NotFoundCoverView {...params} />,
  },
];

export default routes;
