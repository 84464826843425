import React from 'react';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { FAQAccordion } from './components';

const FAQPage = () => {
  return (
    <Main>
      <Container>
        <FAQAccordion />
      </Container>
    </Main>
  );
};

export default FAQPage;
