import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

const Intro = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        background: theme.palette.base.white,
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={{ xs: '100%', sm: '630px', md: '768px' }}
        textAlign={'center'}
        width={'100%'}
        alignItems={'center'}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            width: '100%',
            letterSpacing: '-0.72px',
            marginBottom: { xs: '16px', sm: '20px' },
            textAlign: 'center',
          }}
        >
          {t('introTitle')}
        </Typography>
        <Typography
          variant={isMd ? 'body1' : 'body3'}
          color={theme.palette.darkGrey[600]}
          sx={{
            fontWeight: 400,
            maxWidth: '768px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {t('introSubtitle')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Intro;
