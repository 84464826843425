import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SecondaryTab } from 'components/Tabs';
import Groups from '../Groups/Groups';
import Main from '../Main/Main';
import Losers from '../Losers/Losers';
import Files from '../Files/Files';
import Tournament from '../Tournament/Tournament';
import Notifications from '../Notifications/Notifications';
import MiniLosers from '../MiniLosers/MiniLosers';

const ContentView = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('notifications');

  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
    >
      <Box maxWidth={{ xs: '100%', sm: '100%', md: '516px' }} width={'100%'}>
        <Typography
          variant="h4"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 700, marginBottom: '16px' }}
        >
          Admin nustatymai
        </Typography>
        <Typography variant="body1" color={theme.palette.darkGrey[600]}>
          Čia galite valdyti turnyro grupių skirstymą, pagrindo ir paguodos
          lenteles, bei suskirstyti turnyro mačus, parsisiųsti vartotojų bei
          turnyro dalyvių sąrašus.
        </Typography>
      </Box>
      <Box marginTop={{ sm: '40px' }} marginBottom={{ xs: '24px', sm: '16px' }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          maxWidth={{ xs: '100%', sm: '100%', md: 'auto' }}
          width={'100%'}
          sx={{
            borderBottom: {
              xs: 'none',
              sm: `1px solid ${theme.palette.darkGrey[300]}`,
            },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '12px', sm: '0px' },
          }}
        >
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'notifications'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'turnyras'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'grupės'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'pagrindas'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'paguoda'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'paguoda-Mini'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={'failai'}
          />
        </Box>
      </Box>
      <Box>
        {activeTab === 'notifications' && <Notifications />}
        {activeTab === 'turnyras' && <Tournament />}
        {activeTab === 'grupės' && <Groups />}
        {activeTab === 'pagrindas' && <Main />}
        {activeTab === 'paguoda' && <Losers />}
        {activeTab === 'paguoda-Mini' && <MiniLosers />}
        {activeTab === 'failai' && <Files />}
      </Box>
    </Box>
  );
};

export default ContentView;
