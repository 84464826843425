import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        maxWidth: '864px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
      }}
    >
      <Typography
        variant="h4"
        color={theme.palette.darkGrey[700]}
        fontWeight={700}
        textAlign={'center'}
        marginBottom={'40px'}
      >
        Privatumo politika
      </Typography>
      <Typography variant="body2" color={theme.palette.darkGrey[600]}>
        Interneto svetainė tenisopartneris.lt (toliau Teniso Partneris)
        priklauso ir ją tvarko VšĮ „Teniso partneris“ (Duomenų valdytojas) ir
        asmenys sudarę darbo ar kitokio pobūdžio sutartis su ja.
      </Typography>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          1. ĮŽANGA
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          1.1. Šiame dokumente aptariama Privatumo politika, kuri yra taikoma
          visiems asmenims (toliau Lankytojams), apsilankiusiems
          tenisopartneris.lt (toliau Teniso Partneris) svetainėje ar
          besinaudojantiems jos Paslaugomis.
          <div style={{ height: '16px' }} />
          1.2. Naudodamasis Teniso Partneris Paslaugomis Lankytojas sutinka su
          šia Privatumo politika. Lankytojas neturi teisės naudotis jokiomis
          Paslaugomis, jeigu nesutinka su šia Privatumo politika.
          <div style={{ height: '16px' }} />
          1.3. Privatumo politikoje naudojamos didžiąja raide sąvokos atitinka
          Teniso Partneris svetainės taisyklėse (toliau – Taisyklės) apibrėžtas
          sąvokas ir jų prasmę.
          <div style={{ height: '16px' }} />
          1.4. Privatumo politika reglamentuoja Teniso Partneris svetainės ir
          jos Lankytojo pagrindinius asmens duomenų rinkimo, tvarkymo ir
          saugojimo principus bei tvarką.
          <div style={{ height: '16px' }} />
          1.5. Lankytojo asmens duomenys renkami ir tvarkomi – Teniso Partneris
          Paslaugų suteikimo ir rinkodaros tikslu.
        </Typography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          2. Teniso Partneris RENKAMA INFORMACIJA
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          2.1. Teniso Partneris svetainėje Prisijungimo ir Registracijos metu
          yra renkami asmens duomenys (toliau Asmens duomenys) apie Lankytojus.
          Registraciją tam, kad Lankytojas galėtų registruotis į partnerio
          paiešką ar naudotis teikiamomis Paslaugomis. Registracijos metu yra
          sukuriamas Lankytojo profilis. Lankytojas gali bet kuriuo metu
          koreguoti, pildyti ar pareikalauti panaikinti savo Registraciją
          interneto svetainėje, apie tai informuodamas raštu{' '}
          <a href="mailto:tenisopartneris@gmail.com">
            tenisopartneris@gmail.com
          </a>
          <div style={{ height: '16px' }} />
          2.2. Lankytojas neprivalo atskleisti jokių asmens duomenų, tačiau
          duomenų neatskleidimas gali apsunkinti Paslaugų teikimą ir (ar)
          naudojimąsi Svetaine, todėl gali būti, kad tokiu atveju Teniso
          Partneris negalės suteikti Paslaugų.
          <div style={{ height: '16px' }} />
          2.3. Teniso Partneris turi teisę tvarkyti ir valdyti registracijos
          metu ar vėliau naudojantis Svetaine Lankytojo pateiktus ar kitais
          būdais surinktus asmens duomenis, vadovaujantis šiose Privatumo
          politikoje nustatyta tvarka ir joje nurodytais tikslais. Teniso
          Partneris užtikrina duomenų subjektų teises pagal Asmens duomenų
          teisinės apsaugos įstatymą ir kitus Asmens duomenų tvarkymą
          reglamentuojančius norminius teisės aktus.
          <div style={{ height: '16px' }} />
          2.4. Registracija – Asmeninės Informacijos Anketos pildymas.
          Lankytojas Svetainėje pagal poreikį pateikia savo vardą, pavardę,
          telefono numerį, el. pašto adresą. Lankytojui taip pat sudaroma
          galimybė nurodyti ir kitus Asmens duomenis: žaidimo lygį, miestą,
          kuriame gyvena, amžių (viešai galima nerodyti), komentarus, tinkamus
          laikus mačui, ir pan. Lankytojas šiuos duomenis (ne)nurodo savo
          pasirinkimu ir iniciatyva. Lankytojo Asmens duomenys gaunami
          tiesiogiai iš Lankytojo.
          <div style={{ height: '16px' }} />
          2.5 Lankytojas pateikia savo Asmens duomenis patalpinimui Svetainėje
          laisva valia ir sutinka, kad jo pateikti duomenys būtų saugomi ir
          tvarkomi elektroniniu duomenų bazėje 2 (dvejus) metus nuo jo
          paskutinio prisijungimo prie sistemos ir suėjus šiam terminui,
          sunaikinami įgalioto darbuotojo.
          <div style={{ height: '16px' }} />
          2.6. Pateikdamas asmens duomenis svetainėje lankytojas sutinka ir
          pripažįsta, kad pateikia savo Asmens duomenis tam, kad tretieji
          asmenys galėtų prie šių duomenų prieiti, juos peržiūrėti ir susisiekti
          el. paštu ar nurodytu telefono numeriu.
          <div style={{ height: '16px' }} />
          2.7. Lankytojo Asmens duomenys tiesioginei rinkodarai yra renkami ir
          naudojami tik gavus Lankytojo sutikimą šiuos duomenis rinkti ir
          naudoti, kuris yra automatiškai suteikiamas Lankytojui registruojantis
          sistemoje ir pažymint sutikimo su privatumo politika langelį.
          Suteikdamas teisę tvarkyti duomenis tiesioginės rinkodaros tikslais
          Lankytojas taip pat suteikia leidimą susisiekti su juo telefonu, SMS
          žinutėmis, elektroniniu paštu, naujienlaiškiais su tiesioginės
          rinkodaros pasiūlymais.
          <div style={{ height: '16px' }} />
          2.8. Lankytojas turi teisę nesutikti, kad jo Asmens duomenys būtų
          tvarkomi tiesioginės rinkodaros tikslais, nenurodydamas nesutikimo
          motyvų. Lankytojas tiesioginės rinkodaros pasiūlymų gali atsisakyti
          tokiais būdais: paspaudęs atitinkamą nuorodą naujienlaiškyje ar el.
          pašto pranešime, informavęs apie nesutikimą Teniso Partneris el.
          laišku{' '}
          <a href="mailto:tenisopartneris@gmail.com">
            tenisopartneris@gmail.com
          </a>
          <div style={{ height: '16px' }} />
          2.9. Lankytojas yra informuotas sutinka, kad jam registruojantis
          sistemoje ir prisijungiant prie jos, sistema registruos Lankytojo
          kompiuterio IP adresą ir prisijungimo prie sistemos datą ir laiką.
          <div style={{ height: '16px' }} />
          2.10. Teniso Partneris deda visas pastangas, kad Asmens duomenys būtų
          tvarkomi tiksliai, sąžiningai ir teisėtai, tik tokiais tikslais, kurie
          buvo nustatyti prieš renkant asmens duomenis, griežtai laikantis
          teisės aktuose nustatytų asmens duomenų tvarkymo reikalavimų.
          <div style={{ height: '16px' }} />
          2.11. Svetainėse gali būti pateikiamos nuorodos į kitas interneto
          svetaines, produktus ir paslaugas, taip pat socialinių tinklų
          plėtinius. Kai kuriose iš tokių interneto svetainių gali būti kaupiama
          Lankytojo asmeninė informacija, o reklaminės interneto svetainės gali
          naudoti slapukus ir kitas panašias priemones savo reklamose, siekdami
          surinkti informaciją apie Lankytoją. Teniso Partneris Privatumo
          politika ir jose išdėstytos nuostatos neapima trečiųjų asmenų taikomos
          privatumo politikos bei nuostatų dėl informacijos rinkimo. Teniso
          Partneris nėra ir nebus laikomas atsakingu už jokius trečiųjų asmenų,
          renkančių informaciją apie Lankytoją, veiksmus.
        </Typography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          3. SLAPUKŲ NAUDOJIMAS
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          3.1. Daugelis naršyklių yra nustatytos taip, kad automatiškai priimtų
          slapukus. Jeigu Lankytojas nesutinka, kad į jo kompiuterį ar į kitą
          galinį įrenginį būtų įrašomi slapukai, jis gali pakeisti savo
          interneto naršyklės nustatymus ir išjungti visus slapukus arba įjungti
          / išjungti juos po vieną. Kad pašalintų slapukus iš mobiliojo
          įrenginio, Lankytojas turės veikti pagal mobiliojo įrenginio naudojimo
          instrukcijas. Išsamesnė nepriklausoma informacija apie slapukus, jų
          naudojimą, atsisakymo galimybes pateikia adresu
          www.AllAboutCookies.org arba www.google.com/privacy_ads.html
          <div style={{ height: '16px' }} />
          3.2. Svetainėje gali būti naudojami techniniai slapukai (būtini
          naršymui Svetainėse ir siekiant naudotis jos pagrindinėmis
          funkcijomis) ir funkciniai slapukai (leidžia įsiminti Lankytojo
          pasirinkimus (tokius, kaip jūsų prisijungimo duomenys) ir pasiūlyti
          patobulintas, Lankytojui pritaikytas funkcijas).
        </Typography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          4. LANKYTOJO INFORMACIJOS KEITIMAS
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          4.1. Lankytojas gali bet kuriuo metu koreguoti, pildyti ar
          pareikalauti panaikinti savo Registraciją interneto svetainėje, apie
          tai informuodamas raštu{' '}
          <a href="mailto:tenisopartneris@gmail.com">
            tenisopartneris@gmail.com
          </a>
        </Typography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          5. INFORMACIJOS APSAUGOS PRIEMONĖS
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          5.1. Teniso Partneris naudoja programinę įrangą bei kitokias
          priemones, kuriomis siekiama apsaugoti visų Lankytojų Prisijungimo
          duomenis. Prie Lankytojo Prisijungimo duomenų gali prieiti tik tam
          įgalioti asmenys.
          <div style={{ height: '16px' }} />
          5.2. Lankytojas ir pats yra atsakingas už visų Prisijungimo duomenų
          slaptumą ir prašo būti atsargiam naudojant ir saugant Prisijungimo
          duomenis. Lankytojas turėtų atsijungti nuo naršyklės baigęs darbą tam,
          kad būtų tikras, jog niekas neprieis prie Lankytojo elektroninio
          pašto, asmeninės informacijos, ypač tais atvejais, kai Lankytojas
          naudojasi viešai prieinamu kompiuteriu (pvz. interneto kavinėje,
          bibliotekoje ar pan.).
          <div style={{ height: '16px' }} />
          5.3. Teniso Partneris įsipareigoja stengtis apsaugoti Lankytojo asmens
          duomenis, kuriuos tvarko, tačiau atsižvelgiant į tai, kad Lankytojo
          informacija yra siunčiama interneto ryšiu, Teniso Partneris įspėja,
          kad negali garantuoti ir negarantuoja jokios Lankytojo perduodamos
          informacijos, įskaitant ir asmeninius duomenis, saugumo.
          <div style={{ height: '16px' }} />
          5.4. Teniso Partneris asmeninę Lankytojų informaciją saugo tiek kiek
          reikalinga Taisyklių ir Privatumo politikos nurodytiems tikslams
          įgyvendinti. Lankytojų duomenys yra sunaikinami praėjus 2 (dviem)
          metams nuo jo prisijungimo prie paskyros dienos arba Lankytojui
          panaikinus paskyrą. Teniso Partneris siekia nesaugoti pasenusių ir
          neaktualių Lankytojo asmens duomenų.
        </Typography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          6. NEPILNAMEČIŲ APSAUGA
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          6.1. Informacijos apie vaikų ir nepilnamečių saugojimą nuo žalingo
          interneto poveikio lankytojas gali rasti adresu
          www.draugiskasinternetas.lt
          <div style={{ height: '16px' }} />
          6.2. Teniso Partneris siekia apsaugoti nepilnamečius, besinaudojančius
          Svetaine ir jose teikiamomis Paslaugomis, tačiau neturi jokių
          galimybių apsaugoti tokių asmenų, po to kai jie palieka Svetainę.
          <div style={{ height: '16px' }} />
          6.3. Lankytojas taip pat yra atsakingas ne tik už savo, bet ir savo
          nepilnamečių vaikų ar kitų globojamų asmenų elgesį Svetainėje, jei jie
          Svetaine naudojasi Lankytojo aplinkoje, su jo leidimu arba
          naudodamiesi Lankytojo aparatine įranga.
        </Typography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          7. ATNAUJINIMAI
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          7.1. Teniso Partneris turi teisę bet kada vienašališkai pakeisti
          Privatumo politiką, o tokie pakeitimai įsigalioja po jų paskelbimo
          Svetainėje. Jei Lankytojas po Privatumo politikos pakeitimų paskelbimo
          naudojasi Svetaine ir (ar) Paslaugomis, laikoma, kad jis sutinka su
          visais pakeitimais. Lankytojui nesutinkant su pakeistu Privatumo
          politikos turiniu, jis praranda teisę naudotis Teniso Partneris
          svetaine, o taip pat privalo nedelsiant panaikinti savo registraciją
          Svetainėje.
        </Typography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          color={theme.palette.darkGrey[700]}
          fontWeight={700}
          marginBottom={'16px'}
        >
          8. SUSISIEKITE SU MUMIS
        </Typography>
        <Typography variant="body2" color={theme.palette.darkGrey[600]}>
          Visi klausimai dėl Privatumo politikos{' '}
          <a href="mailto:tenisopartneris@gmail.com">
            tenisopartneris@gmail.com
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
