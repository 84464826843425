import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';

const NavItem = ({ page }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const p = page;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            marginBottom: '20px',
            '&:hover': {
              backgroundColor: theme.palette.blue[500],
            },
          }}
          onClick={() => navigate(p.href)}
        >
          <Typography
            variant="body3"
            color={theme.palette.base.white}
            fontWeight={600}
          >
            {t(p.title)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

NavItem.propTypes = {
  page: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default NavItem;
