import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mission from 'assets/images/mission.png';

const PriceCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const prices = [
    {
      title: t('rent1'),
      price: t('rentPrice1'),
    },
    {
      title: t('rent2'),
      price: t('rentPrice2'),
    },
    {
      title: t('rent3'),
      price: t('rentPrice3'),
    },
  ];

  return (
    <Box
      width={'100%'}
      maxHeight={{ xs: 'auto', sm: 400 }}
      height={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          padding: { xs: '48px 24px', sm: '64px 32px', md: '0 64px' },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            letterSpacing: '-0.72px',
            marginBottom: '20px',
          }}
        >
          {t('rentalPrices')}:
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          {prices.map((item, i) => (
            <Box
              key={i}
              display={'flex'}
              alignItems={'center'}
              sx={{ marginBottom: '20px' }}
            >
              <Typography
                variant="subtitle1"
                color={theme.palette.darkGrey[600]}
                sx={{ fontWeight: 700 }}
              >
                &#8226;&nbsp;&nbsp;{item.title}
              </Typography>
              <Typography
                variant="subtitle1"
                color={theme.palette.darkGrey[600]}
              >
                &nbsp;{item.price}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography
          variant="body2"
          color={theme.palette.darkGrey[600]}
          sx={{
            fontWeight: 400,
            textAlign: 'left',
            maxWidth: '607px',
            width: '100%',
          }}
        >
          * {t('rentInfo')}{' '}
          <a
            href="https://www.dalinuosi.lt/sporto-prekiu-nuoma/vilnius/tennis-tutor-prolite-kamuoliuku-masina-20840"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant="body2"
              color={theme.palette.darkGrey[600]}
              sx={{
                fontWeight: 400,
                textAlign: 'left',
                maxWidth: '607px',
                width: '100%',
                textDecoration: 'underline',
              }}
            >
              dalinuosi.lt
            </Typography>
          </a>
        </Typography>
      </Box>
      <Box
        display={'flex'}
        maxWidth={{ xs: '100%', sm: '525px' }}
        width={'100%'}
      >
        <Box
          component={'img'}
          src={mission}
          alt="mission image"
          height={400}
          maxWidth={'100%'}
          sx={{
            borderTopRightRadius: { xs: '0px', sm: '16px' },
            borderBottomRightRadius: '16px',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: { xs: '16px', sm: '0px' },
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
  );
};

export default PriceCard;
