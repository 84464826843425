import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import chevronLeft from 'assets/icons/chevron-left.svg';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import Button from 'components/Button';
import SpinningBox from 'components/SpinningBox';
import { get, getUserFullNameFromToken, post } from 'api';

const PartnerResult = ({ state }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    chSet1: '',
    opSet1: '',
    chSet2: '',
    opSet2: '',
    chSet3: '',
    opSet3: '',
    winner: '',
    loserId: '',
    winnerPoints: 3,
    loserPoints: 1,
  });

  const { matchData } = state;
  const {
    challenger_id,
    challenger_full_name,
    challenger_image,
    opponent_id,
    opponent_full_name,
    opponent_image,
    id,
    type,
  } = matchData;

  useEffect(() => {
    setForm({
      ...form,
      challenger_id,
      opponent_id,
      id,
      type,
    });
  }, [matchData]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response = await post('/accounts/challengervsopponent', {
      ...form,
      match_id: form.id,
      user_id: form.challenger_id,
      opponent_id: form.opponent_id,
      ch_set1: form.chSet1,
      op_set1: form.opSet1,
      ch_set2: form.chSet2,
      op_set2: form.opSet2,
      ch_set3: form.chSet3,
      op_set3: form.opSet3,
      match_winner: form.winner,
      match_loser: form.loserId,
      match_type: form.type,
      ch_dp_partner_id: '',
      op_dp_partner_id: '',
      winner_points: form.winnerPoints,
      loser_points: form.loserPoints,
    });
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        setIsSubmitting(false);
        setForm({
          ...form,
          chSet1: '',
          opSet1: '',
          chSet2: '',
          opSet2: '',
          chSet3: '',
          opSet3: '',
          winner: '',
        });
        navigate('/info');
      }, 1500);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        marginBottom: '100px',
        maxWidth: '864px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={() => navigate('/info')}
        sx={{ cursor: 'pointer' }}
        marginBottom={'32px'}
      >
        <Box
          component={'img'}
          src={chevronLeft}
          marginRight={'12px'}
          height={1}
          alt="chevron-left"
        />
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('goBack')}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        fontWeight={700}
        color={theme.palette.darkGrey[700]}
      >
        {t('enterScore')}
      </Typography>
      <Typography
        variant="body3"
        color={theme.palette.darkGrey[700]}
        sx={{ marginTop: '12px' }}
      >
        {t('enterScoreText')}
      </Typography>
      <Box
        sx={{
          marginTop: '32px',
          maxWidth: '864px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '228px',
            display: 'flex',
            border: `1px solid ${theme.palette.darkGrey[300]}`,
            borderRadius: '12px',
            overflow: 'hidden',
            overflowX: { xs: 'auto', sm: 'hidden' },
            boxSizing: 'border-box',
            '::-webkit-scrollbar': {
              height: '4px',
              borderRadius: '8px',
              background: theme.palette.darkGrey[200],
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.darkGrey[300],
              borderRadius: '8px',
              height: '4px',
            },
          }}
        >
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '278px',
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                {t('player')}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: '28px',
                display: 'flex',
                width: '278px',
                alignItems: 'center',
                borderRadius: '0px 8px 8px 0px',
                padding: '8px 12px',
                backgroundColor:
                  form.winner === challenger_id
                    ? theme.palette.darkGrey[100]
                    : 'transparent',
                borderTop:
                  form.winner === challenger_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderRight:
                  form.winner === challenger_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderBottom:
                  form.winner === challenger_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                boxSizing: 'border-box',
                height: '48px',
              }}
            >
              <Box
                component={'img'}
                src={
                  challenger_image
                    ? `https://backend.tenisopartneris.lt/public/images/${challenger_image}`
                    : form.winner === challenger_id
                    ? avatarWhite
                    : avatarGrey
                }
                alt="avatar"
                sx={{
                  width: '32px',
                  height: '32px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  marginRight: '6px',
                }}
              />
              <Typography
                variant="body4"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 600 }}
              >
                {challenger_full_name}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: '28px',
                display: 'flex',
                width: '278px',
                alignItems: 'center',
                borderRadius: '0px 8px 8px 0px',
                padding: '8px 12px',
                backgroundColor:
                  form.winner === opponent_id
                    ? theme.palette.darkGrey[100]
                    : 'transparent',
                borderTop:
                  form.winner === opponent_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderRight:
                  form.winner === opponent_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                borderBottom:
                  form.winner === opponent_id
                    ? `1px solid ${theme.palette.darkGrey[300]}`
                    : 'none',
                boxSizing: 'border-box',
                height: '48px',
              }}
            >
              <Box
                component={'img'}
                src={
                  opponent_image
                    ? `https://backend.tenisopartneris.lt/public/images/${opponent_image}`
                    : form.winner === opponent_id
                    ? avatarWhite
                    : avatarGrey
                }
                alt="avatar"
                sx={{
                  width: '32px',
                  height: '32px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  marginRight: '6px',
                }}
              />
              <Typography
                variant="body4"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 600 }}
              >
                {opponent_full_name}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '120px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                1 set
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="chSet1"
                id="chSet1"
                onChange={handleChange}
                value={form.chSet1}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="opSet1"
                id="opSet1"
                onChange={handleChange}
                value={form.opSet1}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '120px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                2 set
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="chSet2"
                id="chSet2"
                onChange={handleChange}
                value={form.chSet2}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="opSet2"
                id="opSet2"
                onChange={handleChange}
                value={form.opSet2}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '120px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRight: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700 }}
              >
                3 set
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="chSet3"
                id="chSet3"
                onChange={handleChange}
                value={form.chSet3}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Box
                component={'input'}
                name="opSet3"
                id="opSet3"
                onChange={handleChange}
                value={form.opSet3}
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.darkGrey[300]}`,
                  textAlign: 'center',
                  fontSize: '12px',
                  color: theme.palette.darkGrey[700],
                  fontWeight: 600,
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: theme.palette.darkGrey[100],
                padding: '12px 16px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '230px',
                borderBottom: `1px solid ${theme.palette.darkGrey[300]}`,
              }}
            >
              <Typography
                variant="body3"
                color={theme.palette.darkGrey[700]}
                sx={{ fontWeight: 700, display: 'flex', alignItems: 'center' }}
              >
                {t('winner')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Checkbox
                onChange={(e) =>
                  setForm({
                    ...form,
                    winner: challenger_id,
                    loserId: opponent_id,
                  })
                }
                checked={form.winner === challenger_id}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  width: '20px',
                  height: '20px',
                  color: 'transparent',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.grey[300]}`,
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '28px',
                height: '48px',
              }}
            >
              <Checkbox
                onChange={(e) =>
                  setForm({
                    ...form,
                    winner: opponent_id,
                    loserId: challenger_id,
                  })
                }
                checked={form.winner === opponent_id}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  width: '20px',
                  height: '20px',
                  color: 'transparent',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.grey[300]}`,
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            width: '100%',
            marginTop: { xs: '32px', sm: '24px' },
          }}
        >
          <Button
            maxWidth="416px"
            width="100%"
            buttontype={'orange'}
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
          >
            {isSubmitting ? <SpinningBox /> : t('submitResult')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PartnerResult;
