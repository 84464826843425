import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SelectInput, TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { post, get } from 'api';
import { ErrorToast, SuccessToast } from 'utils/Toasts';
import { Cities } from 'constants/Selections';
import { Checkbox } from '@mui/material';
import SpinningBox from 'components/SpinningBox';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const Signup = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState({});
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    city: '',
    password: '',
    repeatPassword: '',
    terms: false,
  });
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const successToastRef = useRef();
  const errorToastRef = useRef();

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleSelectChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (event.target.name === 'email' && !emailRegex.test(event.target.value)) {
      setEmailError('Įveskite teisingo formato el. paštą');
    } else {
      setEmailError('');
    }

    if (event.target.name === 'password') {
      setPasswordError(
        event.target.value.length < 8
          ? 'Slaptažodis turi būti bent 8 simbolių ilgio'
          : '',
      );
    }
    if (event.target.name === 'repeatPassword') {
      setRepeatPasswordError(
        form.password !== event.target.value ? 'Slaptažodžiai nesutampa' : '',
      );
    }

    if (event.target.name === 'phone') {
      const phoneNumber = parsePhoneNumberFromString(
        `+370${event.target.value}`,
      );

      if (
        !phoneNumber ||
        !phoneNumber.isValid() ||
        !event.target.value.startsWith('6')
      ) {
        setPhoneError(
          'Įveskite teisingo formato telefono numerį, pvz: (612 34567)',
        );
      } else {
        setPhoneError('');
      }
    }

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (
      !form.email ||
      !form.firstName ||
      !form.lastName ||
      !form.phone ||
      !form.city ||
      !form.password ||
      !form.repeatPassword
    ) {
      errorToastRef.current.showError('Prašome užpildyti visus laukus');
      setIsSubmitting(false);
      return;
    }
    if (!form.terms) {
      errorToastRef.current.showError(
        'Prašome sutikti su sąlygomis ir taisyklėmis',
      );
      setIsSubmitting(false);
      return;
    }
    setTimeout(() => {
      setIsSubmitting(false);
    }, 3000);
    const response = await post(`/auth/register`, form);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(t('signupSuccess'));
      setTimeout(() => {
        setForm({
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          city: '',
          password: '',
          repeatPassword: '',
          terms: false,
        });
        setIsSubmitting(false);
      }, 3000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '415px',
        width: '100%',
        mx: 'auto',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        sx={{
          mb: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          mb={'12px'}
        >
          {t('signupTitle')}
        </Typography>
        <Typography variant="body3" color={theme.palette.darkGrey[600]}>
          {t('signupSubtitle')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TextInput
          label={`${t('email')}*`}
          id="email"
          name="email"
          type="email"
          placeholder={t('enterEmail')}
          helperText={emailError}
          error={!!emailError}
          required
          inputProps={{
            autoComplete: 'email',
          }}
          handleInputChange={handleChange}
          value={form.email}
        />
        <Box sx={{ mt: '16px' }} />
        <TextInput
          label={`${t('firstName')}*`}
          id="firstName"
          name="firstName"
          type="text"
          placeholder={t('firstName')}
          required
          inputProps={{
            autoComplete: 'first name',
          }}
          handleInputChange={handleChange}
          value={form.firstName}
        />
        <Box sx={{ mt: '16px' }} />
        <TextInput
          label={`${t('lastName')}*`}
          required
          id="lastName"
          name="lastName"
          type="text"
          inputProps={{
            autoComplete: 'last name',
          }}
          placeholder={t('lastName')}
          handleInputChange={handleChange}
          value={form.lastName}
        />
        <Box sx={{ mt: '16px' }} />
        <TextInput
          label={`${t('phoneNumber')} (6XX XXXXX)*`}
          id="phone"
          name="phone"
          type="tel"
          placeholder="6XX XXXXX"
          required
          handleInputChange={handleChange}
          value={form.phone}
          inputProps={{ maxLength: 8, autoComplete: 'tel' }}
          helperText={phoneError}
          error={!!phoneError}
        />
        <Box sx={{ mt: '16px' }} />
        <Typography
          variant="body4"
          fontWeight={500}
          color={theme.palette.darkGrey[700]}
        >
          {t('city')}*
        </Typography>
        <SelectInput
          name="city"
          id="city"
          placeholder={t('selectCity')}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleChange={handleSelectChange}
          topMargin={'6px'}
          options={Cities.filter((city) => city.value !== '')}
          value={form.city}
        />
        <Box marginTop={'16px'} />
        <TextInput
          label={`${t('password')}*`}
          id="password"
          name="password"
          type="password"
          placeholder={t('enterPassword')}
          helperText={passwordError}
          error={!!passwordError}
          required
          handleInputChange={handleChange}
          value={form.password}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        <Box marginTop={'32px'} />
        <TextInput
          label={`${t('repeatPassword')}*`}
          id="repeatPassword"
          name="repeatPassword"
          type="password"
          placeholder={t('repeatPassword')}
          helperText={repeatPasswordError}
          error={!!repeatPasswordError}
          required
          handleInputChange={handleChange}
          value={form.repeatPassword}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        <Box marginTop={'32px'} />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }}>
          <Checkbox
            onChange={(e) => setForm({ ...form, terms: e.target.checked })}
            checked={form.terms}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              padding: '0px',
              marginRight: '12px',
              color: theme.palette.darkGrey[300],
              '&.Mui-checked': {
                color: theme.palette.orange[600],
              },
            }}
          />
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('agreePrivacy')}{' '}
            <Link
              href="/privatumo-politika"
              variant="body4"
              fontWeight={600}
              color={theme.palette.orange[800]}
              sx={{ textDecoration: 'none' }}
            >
              {t('privacyPolicy')}
            </Link>
          </Typography>
        </Box>
        <Button
          buttontype="orange"
          width="100%"
          disabled={isSubmitting}
          onClick={() => handleSubmit()}
        >
          {isSubmitting ? <SpinningBox /> : t('getStarted')}
        </Button>
        <Box
          sx={{
            mt: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body4" color={theme.palette.darkGrey[600]}>
            {t('alreadyHaveAccount?')}
          </Typography>
          <Box
            onClick={() => navigate('/prisijungimas')}
            fontWeight={600}
            color={theme.palette.orange[800]}
            display="flex"
            alignItems="center"
            marginLeft={'8px'}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="body4"
              color={theme.palette.orange[800]}
              fontWeight={600}
            >
              {t('login')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Signup;
