import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SecondaryTab } from 'components/Tabs';
import MenRatings from '../Men/MenRatings';
import WomenRatings from '../Women/WomenRatings';
import AllRatings from '../All/All';
import RatingsSummary from '../Summary';

const ContentView = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(t('all'));

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box marginTop={{ sm: '40px' }} marginBottom={{ xs: '48px', sm: '40px' }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          maxWidth={'864px'}
          width={'100%'}
          sx={{
            borderBottom: {
              xs: 'none',
              sm: `1px solid ${theme.palette.darkGrey[300]}`,
            },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '12px', md: '0px' },
          }}
        >
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('all')}
            page={'top players'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('men')}
            page={'top players'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('women')}
            page={'top players'}
          />
          <SecondaryTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabName={t('ratingsSummary')}
            page={'ratings explanation'}
          />
        </Box>
      </Box>
      <Box>
        {activeTab === t('all') && <AllRatings />}
        {activeTab === t('men') && <MenRatings />}
        {activeTab === t('women') && <WomenRatings />}
        {activeTab === t('ratingsSummary') && <RatingsSummary />}
      </Box>
    </Box>
  );
};

export default ContentView;
