import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import contactImg from 'assets/images/contacts.png';
import {
  Typography,
  Box,
  TextField,
  Grid,
  Link,
  Checkbox,
} from '@mui/material';
import { TextInput } from 'components/Inputs';
import Button from 'components/Button';
import { post } from 'api';
import SpinningBox from 'components/SpinningBox';
import { ErrorToast, SuccessToast } from 'utils/Toasts';

const ContactForm = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    terms: false,
  });
  const successToastRef = useRef();
  const errorToastRef = useRef();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (
      !form.email ||
      !form.firstName ||
      !form.lastName ||
      !form.phone ||
      !form.message
    ) {
      errorToastRef.current.showError('Prašome užpildyti visus laukus');
      setIsSubmitting(false);
      return;
    }
    if (!form.terms) {
      errorToastRef.current.showError(
        'Prašome sutikti su sąlygomis ir taisyklėmis',
      );
      setIsSubmitting(false);
      return;
    }
    setTimeout(() => {
      setIsSubmitting(false);
    }, 3000);
    const response = await post(`/sendmail/contactform`, form);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setTimeout(() => {
        setIsSubmitting(false);
        setForm({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
          terms: false,
        });
      }, 3000);
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {' '}
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box maxWidth={{ xs: '100%', sm: '464px', md: '528px' }} width={'100%'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              variant="h4"
              color={theme.palette.darkGrey[700]}
              sx={{
                fontWeight: 700,
                letterSpacing: '-0.72px',
                marginBottom: '20px',
              }}
            >
              {t('contactsTitle')}
            </Typography>
            <Typography variant="body1" color={theme.palette.darkGrey[600]}>
              {t('contactsSubtitle')}
            </Typography>
          </Box>
          <Box component={'form'} marginTop={'48px'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label={`${t('firstName')}*`}
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder={t('firstName')}
                  required
                  autoComplete="first name"
                  handleInputChange={handleChange}
                  value={form.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label={`${t('lastName')}*`}
                  required
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="last name"
                  placeholder={t('lastName')}
                  handleInputChange={handleChange}
                  value={form.lastName}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: '16px' }} />
            <TextInput
              label={`${t('email')}*`}
              id="email"
              name="email"
              type="email"
              placeholder={t('enterEmail')}
              required
              autoComplete="email"
              handleInputChange={handleChange}
              value={form.email}
            />
            <Box sx={{ mt: '16px' }} />
            <TextInput
              label={`${t('phoneNumber')}*`}
              id="phone"
              name="phone"
              type="text"
              placeholder={t('enterPhone')}
              required
              autoComplete="tel"
              handleInputChange={handleChange}
              value={form.phone}
            />
            <Box sx={{ mt: '16px' }} />
            <Typography
              variant="body4"
              fontWeight={500}
              color={theme.palette.darkGrey[700]}
            >
              {t('comment')}
            </Typography>
            <TextField
              hiddenLabel
              margin="normal"
              fullWidth
              id="message"
              name="message"
              multiline
              rows={4}
              placeholder={t('leaveMessage')}
              inputProps={{
                sx: {
                  fontSize: '16px',
                  lineHeight: 1.5,
                  color: theme.palette.darkGrey[500],
                  '&::placeholder': {
                    color: theme.palette.darkGrey[500],
                    opacity: 1,
                    fontSize: '16px',
                  },
                },
              }}
              sx={{
                margin: 0,
                mt: '6px',
              }}
              onChange={handleChange}
              value={form.message}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '32px',
                mt: '24px',
              }}
            >
              <Checkbox
                onChange={(e) => setForm({ ...form, terms: e.target.checked })}
                checked={form.terms}
                inputProps={{
                  'aria-label': 'controlled',
                }}
                sx={{
                  padding: '0px',
                  color: theme.palette.darkGrey[300],
                  '&.Mui-checked': {
                    color: theme.palette.orange[600],
                  },
                }}
              />
              <Typography
                variant="body4"
                fontWeight={500}
                color={theme.palette.darkGrey[700]}
                sx={{ ml: '8px' }}
              >
                {t('agreePrivacy')}{' '}
                <Link
                  href="/privatumo-politika"
                  variant="body4"
                  fontWeight={600}
                  color={theme.palette.orange[800]}
                  sx={{ textDecoration: 'none' }}
                >
                  {t('privacyPolicy')}
                </Link>
              </Typography>
            </Box>
            <Button
              buttontype="orange"
              width="100%"
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
            >
              {isSubmitting ? <SpinningBox /> : t('sendMessage')}
            </Button>
          </Box>
        </Box>
        <Box
          width={'100%'}
          maxWidth={{ xs: '100%', sm: '466px', md: '639px' }}
          maxHeight={'800px'}
          height={{ xs: '300px', sm: '100%', md: '100%' }}
          marginTop={{ xs: '48px', sm: 0, md: 0 }}
        >
          <Box
            component={'img'}
            src={contactImg}
            alt="contact"
            height={1}
            width={1}
            sx={{
              objectFit: 'cover',
              borderRadius: { xs: '16px', md: '0px' },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ContactForm;
