import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Link, Typography, useMediaQuery } from '@mui/material';

const Description = ({ tournamentInfo }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    earlyBird,
    earlyBirdDate,
    registrationDate,
    startDate,
    finalsDate,
    groupsDate,
    eliminationsDate,
  } = tournamentInfo;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box maxWidth={'752px'} width={'100%'} margin={'0 auto'}>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8px', md: '12px' }}
        >
          {t('registration')}
        </Typography>
        <Typography
          variant={'body3'}
          fontWeight={400}
          color={theme.palette.darkGrey[600]}
        >
          {t('registrationText')} {moment(earlyBirdDate).format('LL')}
        </Typography>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8px', md: '12px' }}
        >
          {t('categories')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            {t('categoriesTextOne')}
          </Typography>
        </Box>
        <Box marginTop={'12px'}>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            {t('categoriesTextTwo')}
          </Typography>
        </Box>
        <Box marginTop={'12px'}>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            {t('categoriesTextThree')}{' '}
            <Link
              href="https://tenisopartneris.lt/naujienos/ntrp-sistema-lauko-tenise-vertinimas-ir-nustatymas"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'underline',
                fontWeight: '600',
                color: theme.palette.darkGrey[600],
              }}
            >
              {t('categoriesTextFour')}
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8px', md: '12px' }}
        >
          {t('groupsMenWomen')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>Hard</strong> - {t('hardLevel')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>Power</strong> - {t('powerLevel')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>Middle</strong> - {t('middleLevel')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>Light</strong> - {t('lightLevel')}
          </Typography>
        </Box>
        <Typography
          variant={'body3'}
          fontWeight={400}
          color={theme.palette.darkGrey[600]}
        >
          <strong>Super Light</strong> - {t('superlightLevel')}
        </Typography>
      </Box>
      <Box marginBottom={'32px'}>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8px', md: '12px' }}
        >
          {t('matchOrganization')}
        </Typography>
        <Typography
          variant={'body3'}
          fontWeight={400}
          color={theme.palette.darkGrey[600]}
        >
          {t('matchOrganizationText')}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={{ xs: '8px', md: '12px' }}
        >
          {t('keyDates')}
        </Typography>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>{t('commencement')}:</strong>{' '}
            {moment(startDate).format('LL')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>{t('groupStage')}:</strong> {moment(startDate).format('LL')}{' '}
            – {moment(groupsDate).format('LL')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>{t('eliminationStage')}:</strong>{' '}
            {moment(groupsDate).format('LL')} –{' '}
            {moment(eliminationsDate).format('LL')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body3'}
            fontWeight={400}
            color={theme.palette.darkGrey[600]}
          >
            <strong>{t('championshipDay')}:</strong> iki{' '}
            {moment(finalsDate).format('LL')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Description;
