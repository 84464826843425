import React, { useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { post } from 'api';
import { SuccessToast, ErrorToast } from 'utils/Toasts';
import SpinningBox from 'components/SpinningBox';
import { TextInput } from 'components/Inputs';
import { TextField } from '@mui/material';

const Notifications = () => {
  const theme = useTheme();
  const successToastRef = useRef();
  const errorToastRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    title: '',
    body: '',
  });

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const body = {
      title: form.title,
      body: form.body,
    };
    const response = await post('/notification', body);
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      successToastRef.current.showSuccess(data.msg);
      setIsSubmitting(false);
      setForm({
        title: '',
        body: '',
      });
    } else {
      errorToastRef.current.showError(data.err);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '100%', md: '516px' },
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SuccessToast ref={successToastRef} />
      <ErrorToast ref={errorToastRef} />
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={{ xs: '100%', md: '100%' }}
        marginTop={'24px'}
      >
        <TextInput
          label={'Pranešimo pavadinimas:'}
          id="title"
          name="title"
          type="text"
          placeholder={'Pranešimo pavadinimas'}
          required
          handleInputChange={handleChange}
          value={form.title}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        maxWidth={{ xs: '100%', ssm: '100%' }}
        marginTop={'25px'}
      >
        <Typography
          variant="body4"
          fontWeight={500}
          color={theme.palette.darkGrey[700]}
        >
          Pranešimo turinys:
        </Typography>
        <TextField
          hiddenLabel
          margin="normal"
          fullWidth
          id="body"
          name="body"
          multiline
          rows={3}
          placeholder="Pranešimo turinys"
          onChange={handleChange}
          value={form.body}
          inputProps={{
            sx: {
              fontSize: '16px',
              lineHeight: 1.5,
              color: theme.palette.darkGrey[500],
              '&::placeholder': {
                color: theme.palette.darkGrey[500],
                opacity: 1,
                fontSize: '16px',
              },
            },
          }}
          sx={{
            margin: '6px 0 0 0',
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          maxWidth={{ xs: '100%', md: '100%' }}
          width={'100%'}
          buttontype={'orange'}
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SpinningBox /> : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
};

export default Notifications;
