import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import chevronRight from 'assets/icons/chevron-right.svg';
import { BlogCard } from 'components/Cards';
import { useMediaQuery } from '@mui/material';
import client from 'utils/client';

const Blog = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [blogPosts, setBlogPosts] = useState([]);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  useEffect(() => {
    const fetchPosts = async () => {
      const result = await client.fetch(
        `*[_type == "post"] | order(publishedAt desc) {
          title,
          slug,
          mainImage,
          publishedAt,
          excerpt,
          author->{
            name,
            image,
            bio
          },
          categories[]->{
            title
          },
          body
        }`,
      );
      setBlogPosts(result);
    };
    fetchPosts();
  }, []);

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={'48px'}
      >
        <Box>
          <Typography
            variant={isMd ? 'h4' : 'h5'}
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 700, marginBottom: { xs: '16px', sm: '20px' } }}
          >
            {t('homeBlogTitle')}
          </Typography>
          <Typography
            variant={isMd ? 'body1' : 'body3'}
            color={theme.palette.darkGrey[600]}
          >
            {t('homeBlogSubtitle')}
          </Typography>
        </Box>
        <Box
          display={{ xs: 'none', sm: 'flex' }}
          alignItems={'center'}
          sx={{
            cursor: 'pointer',
            marginBottom: '32px',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'translateX(3px)',
            },
          }}
          onClick={() => navigate('/naujienos')}
        >
          <Typography
            variant="body3"
            color={theme.palette.darkGrey[700]}
            sx={{ fontWeight: 600 }}
          >
            {t('seeAll')}
          </Typography>
          <Box component={'img'} src={chevronRight} alt="chevron right" />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: { xs: '48px', sm: '32px' },
        }}
      >
        {blogPosts.slice(0, 3).map((item, i) => (
          <BlogCard key={i} data={item} />
        ))}
      </Box>
      <Box
        display={{ xs: 'flex', sm: 'none' }}
        marginTop={'24px'}
        alignItems={'center'}
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate('/naujienos')}
      >
        <Typography
          variant="body3"
          color={theme.palette.darkGrey[700]}
          sx={{ fontWeight: 600 }}
        >
          {t('seeAll')}
        </Typography>
        <Box component={'img'} src={chevronRight} alt="chevron right" />
      </Box>
    </Box>
  );
};

export default Blog;
