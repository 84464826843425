import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import machine from 'assets/images/machine.png';
import machineMobile from 'assets/images/machine-mobile.jpg';
import orangeCheck from 'assets/icons/orangeCheck.svg';

const SpecsCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const missionList = [
    {
      title: t('spec1'),
    },
    {
      title: t('spec2'),
    },
    {
      title: t('spec3'),
    },
    {
      title: t('spec4'),
    },
    {
      title: t('spec5'),
    },
    {
      title: t('spec6'),
    },
    {
      title: t('spec7'),
    },
    {
      title: t('spec8'),
    },
  ];

  return (
    <Box
      width={'100%'}
      maxHeight={{ xs: 'auto', sm: '486px', md: 566 }}
      height={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      sx={{
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
      }}
    >
      <Box
        component={'img'}
        src={isMd ? machine : machineMobile}
        alt="machine"
        height={{ xs: 400, sm: 486, md: 566 }}
        maxWidth={'100%'}
        sx={{
          borderTopLeftRadius: { xs: '0px', sm: '16px', md: '16px' },
          borderBottomLeftRadius: { xs: '16px', sm: '16px', md: '16px' },
          borderTopRightRadius: { xs: '0px', sm: '0px', md: '0px' },
          borderBottomRightRadius: { xs: '16px', sm: '0px', md: '0px' },
        }}
      />
      <Box
        sx={{
          padding: { xs: '48px 24px', md: '64px' },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant={isMd ? 'h4' : 'h5'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            letterSpacing: '-0.72px',
            marginBottom: { xs: '16px', sm: '20px' },
          }}
        >
          {t('specsTitle')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          {missionList.map((item, i) => (
            <Box
              key={i}
              display={'flex'}
              alignItems={'center'}
              sx={{ '&:not(:last-of-type)': { marginBottom: '20px' } }}
            >
              <Box
                component={'img'}
                src={orangeCheck}
                alt="check"
                width={28}
                height={28}
                sx={{ marginRight: '12px' }}
              />
              <Typography
                variant={'body2'}
                color={theme.palette.darkGrey[600]}
                sx={{ fontWeight: 400 }}
              >
                {item.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SpecsCard;
