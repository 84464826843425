import React from 'react';
import Box from '@mui/material/Box';
import MensDivision from './Mens';
import WomensDivision from './Womens';

const Participants = ({ data, viewData }) => {
  const menDivision = data.filter((item) => item.tournament_gender === 'Vyrai');
  const womenDivision = data.filter(
    (item) => item.tournament_gender === 'Moterys',
  );

  return (
    <Box width={'100%'}>
      <MensDivision menDivision={menDivision} viewData={viewData} />
      <Box marginTop={{ xs: '64px', md: '96px' }}>
        <WomensDivision womenDivision={womenDivision} viewData={viewData} />
      </Box>
    </Box>
  );
};

export default Participants;
