import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { PartnerCard, ResultsCard } from 'components/Cards';
import Button from 'components/Button';
import CustomPagination from 'components/Pagination';
import { get, getUserIdFromToken } from 'api';
import CryptoJS from 'crypto-js';

const MyMatches = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [pastMatches, setPastMatches] = useState([]);
  const [hideButtons, setHideButtons] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [loading, setLoading] = useState(true);

  const itemsPerPage = isMd ? 6 : 3;

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const isChallenger = (item) => {
    const challengerId = id ? parseInt(id) : getUserIdFromToken();
    return item.challenger_id === challengerId;
  };

  const fetchUpcomingMatches = async (userId) => {
    try {
      const endpoint = userId
        ? `/accounts/upcoming-matches/${userId}`
        : '/accounts/upcoming-matches';
      const response = await get(endpoint);
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUpcomingMatches(decryptedData);
    } catch (error) {
      console.error('Failed to fetch matches:', error);
    }
  };

  const fetchPastMatches = async (userId) => {
    try {
      const endpoint = userId
        ? `/accounts/past-matches/${userId}`
        : '/accounts/past-matches';
      const response = await get(endpoint);
      const data = await response.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setPastMatches(decryptedData);
    } catch (error) {
      console.error('Failed to fetch matches:', error);
    }
  };

  useEffect(() => {
    if (id) {
      setHideButtons(true);
      Promise.all([fetchPastMatches(id), fetchUpcomingMatches(id)]).then(() =>
        setLoading(false),
      );
    } else {
      Promise.all([fetchPastMatches(null), fetchUpcomingMatches(null)]).then(
        () => setLoading(false),
      );
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const paginatedMatches = pastMatches.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  return (
    <Box>
      <Box marginBottom={'48px'}>
        <Typography
          variant={'h4'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={'32px'}
        >
          {t('upcomingMatches')}
        </Typography>
        {upcomingMatches.length === 0 ? (
          <Box
            sx={{
              maxWidth: { xs: '100%', sm: '464px', md: '416px' },
              width: '100%',
            }}
          >
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {t('noUpcomingMatches')}
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={'32px'}>
            {upcomingMatches.length > 0 &&
              upcomingMatches.map((item, i) => {
                const isCH = isChallenger(item);
                return (
                  <Grid item xs={12} sm={6} md={5} key={i}>
                    <PartnerCard
                      matchId={item.id}
                      avatar={
                        isCH ? item.opponent_image : item.challenger_image
                      }
                      name={
                        isCH
                          ? item.opponent_first_name
                          : item.challenger_first_name
                      }
                      ntrp={item.ntrp}
                      date={item.match_date}
                      time={item.match_time}
                      location={item.city}
                      tennisCourt={item.place}
                      details={item.comment}
                      userId={isCH ? item.opponent_id : item.challenger_id}
                      mailTo={
                        isCH ? item.opponent_email : item.challenger_email
                      }
                      page={'overview'}
                      fetchMatches={() => fetchUpcomingMatches(id)}
                      hideButtons={hideButtons}
                      matchData={item}
                    />
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Box>
      <Box>
        <Typography
          variant={'h4'}
          fontWeight={700}
          color={theme.palette.darkGrey[700]}
          marginBottom={'32px'}
        >
          {t('pastMatches')}
        </Typography>
        {pastMatches.length === 0 ? (
          <Box
            sx={{
              maxWidth: { xs: '100%', sm: '464px', md: '416px' },
              width: '100%',
            }}
          >
            <Typography variant={'body3'} color={theme.palette.darkGrey[600]}>
              {t('noPastMatches')}
            </Typography>
          </Box>
        ) : (
          <>
            <Grid container spacing={'32px'}>
              {pastMatches.length > 0 &&
                paginatedMatches.map((item, i) => {
                  return (
                    <Grid item xs={12} sm={4} key={i}>
                      <ResultsCard data={item} />
                    </Grid>
                  );
                })}
            </Grid>
            <Box
              marginTop="20px"
              paddingTop="20px"
              maxWidth="100%"
              display="flex"
              justifyContent="center"
              borderTop={`1px solid ${theme.palette.darkGrey[200]}`}
            >
              <CustomPagination
                page={page}
                handlePaginationChange={handlePaginationChange}
                count={Math.ceil(pastMatches.length / itemsPerPage)}
              />
            </Box>
          </>
        )}
      </Box>
      {pastMatches.length === 0 && (
        <Box marginTop={'32px'}>
          <Button
            width={'auto'}
            buttontype={'orange'}
            onClick={() => navigate('/partnerio-paieska')}
          >
            {t('findTennisPartner')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MyMatches;
