import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import avatarGrey from 'assets/images/defaultAvatar-grey.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.darkGrey[100],
    color: theme.palette.darkGrey[700],
    fontWeight: 600,
    fontSize: 16,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    color: theme.palette.darkGrey[700],
    fontWeight: 600,
    backgroundColor: theme.palette.base.white,
    boxShadow: 'none',

    '&.MuiTableCell-root': {
      // border: `1px solid ${theme.palette.darkGrey[300]}`,
      borderRadius: '8px',
    },
    '&:first-of-type': {
      width: '111px !important',
    },
    '&:nth-child(n+3)': {
      width: '169px !important',
    },
    '&:nth-child(2)': {
      width: '304px !important',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  boxShadow: 'none',
}));

function createData(
  ranking,
  avatar,
  userId,
  player,
  played,
  won,
  lost,
  winPercentage,
) {
  return { ranking, avatar, userId, player, played, won, lost, winPercentage };
}

const TopTable = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rows = data.map((row, i) => {
    return createData(
      i + 1,
      row.image,
      row.id,
      row.full_name,
      row.matches_total,
      row.matches_won,
      row.matches_lost,
      row.win_percentage,
    );
  });

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: '48px',
        boxShadow: 'none',
        maxHeight: { xs: '440px', sm: '640px', md: '100%' },
        overflowY: { xs: 'auto', sm: 'hidden' },
        overflowX: { xs: 'auto', sm: 'hidden' },
        borderRadius: '8px',
        border: `1px solid ${theme.palette.darkGrey[300]}`,
      }}
    >
      <Table
        sx={{ maxWidth: '1088px', width: '1088px' }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{t('ranking')}</StyledTableCell>
            <StyledTableCell align="center">{t('player')}</StyledTableCell>
            <StyledTableCell align="center">{t('played')}</StyledTableCell>
            <StyledTableCell align="center">{t('won')}</StyledTableCell>
            <StyledTableCell align="center">{t('lost')}</StyledTableCell>
            <StyledTableCell align="center">{t('winning')} %</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.ranking}
              </StyledTableCell>
              <StyledTableCell align="center" width={'304px !important'}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  onClick={() => navigate(`/info/${row.userId}`)}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    height={52}
                    width={52}
                    marginRight={'16px'}
                  >
                    <Box
                      component={'img'}
                      src={
                        row.avatar
                          ? `https://backend.tenisopartneris.lt/public/images/${row.avatar}`
                          : avatarGrey
                      }
                      alt="avatar"
                      width={52}
                      height={52}
                      sx={{
                        objectFit: 'cover',
                        borderRadius: '50%',
                      }}
                    />
                  </Box>
                  {row.player}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center">{row.played}</StyledTableCell>
              <StyledTableCell align="center">{row.won}</StyledTableCell>
              <StyledTableCell align="center">{row.lost}</StyledTableCell>
              <StyledTableCell align="center">
                {Number(row.winPercentage).toFixed(2)}%
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopTable;
