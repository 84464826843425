import React from 'react';
import moment from 'moment/moment';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery } from '@mui/material';
import urlFor from 'utils/image';

const Hero = ({ title, excerpt, publishedAt, image }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      width={'100%'}
      height={{ xs: '100%', sm: 'auto' }}
      sx={{
        padding: { xs: '32px 24px', md: '96px 64px 0px 64px' },
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          height: '100%',
          maxWidth: '864px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          margin: '0 auto',
        }}
      >
        <Typography
          variant={isMd ? 'body3' : 'body4'}
          sx={{
            color: theme.palette.orange[800],
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: { xs: '8px', md: '12px' },
          }}
        >
          Patalpinta {moment(publishedAt).format('LL')}
        </Typography>
        <Typography
          variant={isMd ? 'h3' : 'h5'}
          sx={{
            color: theme.palette.darkGrey[700],
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant={isMd ? 'body1' : 'body3'}
          sx={{
            textAlign: 'center',
            color: theme.palette.darkGrey[600],
            marginTop: { xs: '12px', md: '24px' },
          }}
        >
          {excerpt}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: { xs: '32px', sm: '48px', md: '64px' },
          maxHeight: { xs: 'auto', sm: 'auto', md: '710px' },
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          component={'img'}
          src={image ? urlFor(image) : ''}
          height={1}
          alt="hero image"
          width={1}
          sx={{
            objectFit: 'cover',
            borderRadius: '24px',
            width: { xs: '100%', sm: '100%', md: '1312px' },
            height: { xs: '100%', sm: '100%', md: '710px' },
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
